<template>
    <div>
        <!-- Modal de listagem de erros -->
        <div class="fixed inset-0 bg-black/60 flex items-center justify-center z-[10000]">
            <div
                class="bg-white rounded-lg shadow-lg p-6 w-[90%] sm:w-[600px] max-h-[80vh] overflow-y-auto transform transition-transform duration-300 ease-out scale-95 sm:scale-100">
                <!-- Cabeçalho do modal -->
                <div class="flex justify-between items-center mb-4 border-b pb-2">
                    <h2 class="text-lg font-bold text-green-600">Lista de Funções e Condições</h2>
                    <button @click.prevent="$emit('fecharModal')"
                        class="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 ease-in-out hover:bg-gray-100">
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>

                <div class="flex flex-col p-4 text-corBase">
                    <p class="text-base">• arred(valor): Arredonda</p>
                    <p class="text-xs mb-4">EX: arred(0.3) retorna 0 | arred(0.7) retorna 1</p>

                    <p class="text-base">• trunc(valor, casas_decimais(máx. 2)): Ajusta os decimais</p>
                    <p class="text-xs mb-4">EX: trunc(1.345, 1) retorna 1.3 | trunc(1.999, 0) retorna 1</p>

                    <p class="text-base">• area(valor1, valor2): Calcula a área</p>
                    <p class="text-xs mb-4">EX: area(2,3) retorna 6 | area(4,5) retorna 20</p>

                    <p class="text-base">• perimetro(valor1, valor2): Calcula o perímetro</p>
                    <p class="text-xs mb-4">EX: perimetro(2,3) retorna 10 | perimetro(4,6) retorna 20</p>

                    <p class="text-base">• se(condição, valor_verdadeiro, valor_falso): Retorna um valor baseado em uma
                        condição</p>
                    <p class="text-xs mb-4">EX: se(VPEXEMPLO=1,5,1)</p>

                    <p class="text-base">• and: E (todas as condições devem ser verdadeiras)</p>
                    <p class="text-xs mb-4">EX: VPTESTE=1 and VPTESTE2=2
                    </p>

                    <p class="text-base">• ou: Verifica se o valor está dentro da lista</p>
                    <p class="text-xs mb-4">EX: VPTESTE=(1 ou 2 ou 3)</p>
                </div>


            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ListaFuncoesECondicoes",

    emits: ['fecharModal'],
};
</script>

<style scoped>
/* Estilos adicionais para animações */
</style>