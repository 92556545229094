<template>

  <div class="block">
    <div class="mb-4">
      <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
        <div class="px-2 w-1/3">
          <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
            <option value="codigo">Código</option>
            <option value="descricao">Descrição</option>
            <option value="corte">Corte</option>
            <option value="cor">Cor</option>
            <option value="fase">Fase</option>
            <option value="aplicacao">Aplicação</option>
          </select>
        </div>

        <div class="w-full">
          <v-select v-if="filtro.campo === 'corte'" v-model="filtro.valor"
            :options="['45/45', '45/90', '90/45', '90/90']" placeholder="Escolha uma opção" />

          <v-select v-else-if="filtro.campo === 'cor'" v-model="filtro.valor" :options="lista_cores" label="nome_razao"
            placeholder="Escolha uma opção" :reduce="cor => cor.id" />

          <v-select v-else-if="filtro.campo === 'fase'" v-model="filtro.valor" :options="fasesEsquadria"
            label="descricao" placeholder="Escolha uma opção" :reduce="fase => fase.descricao" />

          <v-select v-else-if="filtro.campo === 'aplicacao'" v-model="filtro.valor"
            :options="['A Definir', 'Produção', 'Obra']" placeholder="Escolha uma opção" />

          <input v-else v-model="filtro.valor" type="text" class="input-form-chapa" placeholder="Insira o valor" />
        </div>

        <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
      </div>

      <div class="flex w-full justify-center">
        <button class="text-ativo" @click.prevent="adicionarFiltro">
          <i class="fas fa-plus-circle"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="flex flex-wrap sm:flex-nowrap w-full justify-between items-center mt-6 gap-4 mb-2">
    <div class="flex flex-wrap sm:flex-nowrap items-center gap-3 w-full sm:w-auto">
      <button
        class="botoes bg-corBase text-white hover:bg-screenCor hover:border-corBase hover:text-corBase w-full sm:w-auto"
        @click.prevent="getListaPerfisProjeto()">
        Pesquisar
      </button>
      <button
        class="botoes bg-blue-500 text-white hover:bg-screenCor hover:border-blue-500 hover:text-blue-500 w-full sm:w-auto"
        @click.prevent="alterarSelecionados()">
        Alterar Selecionados
      </button>
      <button
        class="botoes bg-red-500 text-white hover:bg-screenCor hover:border-red-500 hover:text-red-500 w-full sm:w-auto"
        @click.prevent="verificarExclusao()">
        Excluir Selecionados
      </button>
      <button
        class="botoes bg-orange-500 text-white hover:bg-screenCor hover:border-orange-500 hover:text-orange-500 w-full sm:w-auto"
        @click.prevent="exportarPerfis()">
        Exportar Perfis
      </button>
      <button
        class="botoes bg-yellow-500 text-white hover:bg-screenCor hover:border-yellow-500 hover:text-yellow-500 w-full sm:w-auto"
        @click.prevent="triggerFileInput">
        Importar Perfis
      </button>
      <button
        class="botoes bg-gray-500 text-white hover:bg-screenCor hover:border-gray-500 hover:text-gray-500 w-full sm:w-auto"
        @click.prevent="toggleFuncoesECondicoes()">
        Ver funções e condições
      </button>
    </div>

    <input type="file" ref="fileInput" class="hidden" @change="importarPerfis" accept=".csv" />

  </div>


  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
        <tr class="border-b">
          <th scope="col" class="px-6 py-2 text-center">#</th>
          <th scope="col" class="px-6 py-2 text-center">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-2 text-start">Opções</th>
          <th scope="col" class="px-6 py-2 text-center">Código</th>
          <th scope="col" class="px-6 py-2 text-start">Imagem</th>
          <th scope="col" class="px-6 py-2 text-center">Descrição</th>
          <th scope="col" class="px-6 py-2 text-start">Peso</th>
          <th scope="col" class="px-28 py-2 text-start">Condição</th>
          <th scope="col" class="px-6 py-2 text-start">Corte</th>
          <th scope="col" class="px-6 py-2 text-start">Quantidade</th>
          <th scope="col" class="px-6 py-2 text-start">Medida</th>
          <th scope="col" class="px-6 py-2 text-start">Posição</th>
          <th scope="col" class="px-20 py-2 text-start">Observação</th>
          <th scope="col" class="px-28 py-2 text-start">Cor</th>
          <th scope="col" class="px-10 py-2 text-start">Fase</th>
          <th scope="col" class="px-6 py-2 text-start">Aplicação</th>
        </tr>
      </thead>

      <!-- Linhas de Perfis -->
      <draggable :list="perfis" tag="tbody" handle=".handle" itemKey="id">
        <template v-slot:item="{ element, index }">
          <tr :key="index" class="escopo-tabela">
            <th scope="col" class="text-center font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              <div class="handle cursor-pointer">
                <span>☰</span> <!-- Handle para arrastar -->
                {{ element.index }}
              </div>
            </th>
            <td scope="col" class="text-center">
              <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox" v-model="element.selected">
            </td>
            <td class="w-full text-center">
              <i class="fa-solid fa-magnifying-glass cursor-pointer mr-2"
                @click.prevent="toggleMenuProdutos(element.id)"></i>
              <i class="fa-solid fa-copy cursor-pointer" @click.prevent="toggleMenuCopiar(element)"></i>
            </td>
            <td scope="col" class="text-start text-xs">
              <v-select v-model="element.perfil_id" :options="perfisPesquisados" label="codigo_base"
                class="bg-white w-32" :clearable="false" @input="(s) => buscarPerfis(s.target.value)"
                @keydown.enter="atualizarUnicoPerfil(element.perfil_id, element.index)">
                <template v-slot:option="option">
                  <div @click.prevent="atualizarUnicoPerfil(option, element.index)">
                    <span class="font-bold">
                      {{ option.codigo_base }}
                    </span> -
                    {{ option.nome_base }}
                  </div>
                </template>
              </v-select>
              <!-- Cor Base: {{ element.perfil_id.cor_base?.nome || 'S/C' }} <br>
              Cor selecionada: {{ element.cor?.nome }} <br> -->
            </td>
            <td scope="col" class="px-6 py-2">
              <div class="flex justify-center items-center">
                <img v-if="element.perfil_id.imagem" :src="element.perfil_id.imagem.url_documento"
                  class="cursor-pointer w-10 h-10"
                  @click.prevent="openPreviewImage(element.perfil_id.imagem.url_documento)">
                <img v-else
                  src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                  alt="Sem foto" class="w-10 h-10">
              </div>
            </td>

            <td scope="col" class="text-center text-xs">
              {{ element.perfil_id.nome_base }}
            </td>
            <td scope="col" class="text-center text-xs">
              {{ element.perfil_id.peso_base }}
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.condicao">
            </td>
            <td scope="col" class="text-start text-xs">
              <select v-model="element.corte" class="input-campos">
                <option v-for="corte in ['45/45', '45/90', '90/45', '90/90']" :key="corte" :value="corte">
                  {{ corte }}
                </option>
              </select>
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.qtd_formula">
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.medida_formula">
            </td>
            <td scope="col" class="text-start text-xs">
              <v-select v-model="element.posicao" :options="['H', 'L', 'V']"
                class="bg-white w-full custom-width text-xs" />
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.observacao">
            </td>
            <td scope="col" class="text-start text-xs">
              <v-select v-model="element.cor"
                :options="element.perfil_id.bloquear_cor ? element.perfil_id.cor_base : coresPerfil" label="descricao"
                class="bg-white w-full custom-width"
                :disabled="element.perfil_id.bloquear_cor && element.perfil_id.cor_base.length == 1"
                placeholder="Escolha uma opção" />
              <!-- Cor Bloqueada: {{ element.perfil_id.bloquear_cor }} <br>
              Cor: {{element.perfil_id.cor_base.map(cor => cor.descricao).join(', ') || '-'}} -->
            </td>
            <td scope="col" class="text-start text-xs">
              <select v-model="element.fase_esquadria" class="input-campos">
                <option v-for="fase in fasesEsquadria" :key="fase" :value="fase">
                  {{ fase.descricao }}
                </option>
              </select>
            </td>
            <td scope="col" class="text-start text-xs">
              <select v-model="element.aplicacao" class="input-campos">
                <option v-for="aplicacao in ['A Definir', 'Produção', 'Obra']" :key="aplicacao" :value="aplicacao">
                  {{ aplicacao }}
                </option>
              </select>
            </td>
          </tr>
        </template>
      </draggable>

      <tr v-if="adicionarNovoPerfil" class="escopo-tabela">
        <td class="w-full"></td>
        <td class="w-full"></td>
        <td class="w-full text-center">
          <i class="fa-solid fa-magnifying-glass cursor-pointer" @click.prevent="toggleMenuProdutos()"></i>
        </td>
        <td class="w-full">
          <v-select v-model="perfilSelecionado" label="codigo_base" @input="(s) => buscarPerfis(s.target.value)"
            :options="perfisPesquisados" @select="onSelect()">
            <template v-slot:option="option">
              <div class="w-full" @click.prevent="onSelect(option)">
                <span class="font-bold">
                  {{ option.codigo_base }}
                </span> -
                {{ option.nome_base }}
              </div>
            </template>
          </v-select>
        </td>
        <td scope="col" class="px-6 py-2">
          <div class="flex justify-center items-center">
            <img v-if="novoPerfil.imagem" :src="novoPerfil.imagem" class="cursor-pointer w-10 h-10"
              @click.prevent="openPreviewImage(novoPerfil.imagem)">
            <img v-else
              src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
              alt="Sem foto" class="w-10 h-10">
          </div>
        </td>
        <td class="w-full text-center">
          {{ novoPerfil.descricao }}
        </td>
        <td class="w-full text-center">
          {{ novoPerfil.peso }}
        </td>
        <td class="w-full">
          <input class="input-campos" v-model="novoPerfil.condicao">
        </td>
        <td class="w-full">
          <select v-model="novoPerfil.corte" class="input-campos">
            <option v-for="corte in ['45/45', '45/90', '90/45', '90/90']" :key="corte" :value="corte">
              {{ corte }}
            </option>
          </select>
        </td>
        <td class="w-full">
          <input class="input-campos" v-model="novoPerfil.qtd_formula">
        </td>
        <td class="w-full">
          <input class="input-campos" v-model="novoPerfil.medida_formula">
        </td>
        <td class="w-full">
          <select v-model="novoPerfil.posicao" class="input-campos">
            <option v-for="posicao in ['H', 'L', 'V']" :key="posicao" :value="posicao">
              {{ posicao }}
            </option>
          </select>
        </td>
        <td class="w-full">
          <input class="input-campos" v-model="novoPerfil.observacao">
        </td>
        <td class="w-full">
          <v-select v-model="novoPerfil.corPerfil"
            :options="novoPerfil.bloquear_cor ? novoPerfil.lista_cores : coresPerfil" label="descricao"
            class="bg-white w-full custom-width" placeholder="Escolha uma opção" :clearable="false"
            :reduce="cor_perfil => cor_perfil.id" :disabled="novoPerfil.bloquear_cor" />
          <!-- Tem cor igual: {{ corProprioPerfil }} -->
          {{ novoPerfil.bloquear_cor ? 'cor do perfil' : 'cores importadas' }}
        </td>
        <td class="w-full">
          <select v-model="novoPerfil.fase_esquadria" class="input-campos">
            <option v-for="fase in fasesEsquadria" :key="fase.id" :value="fase.id">
              {{ fase.descricao }}
            </option>
          </select>
        </td>
        <td class="w-full">
          <select v-model="novoPerfil.aplicacao" class="input-campos">
            <option v-for="aplicacao in ['A Definir', 'Produção', 'Obra']" :key="aplicacao" :value="aplicacao">
              {{ aplicacao }}
            </option>
          </select>
        </td>
      </tr>

    </table>
    <div v-if="adicionarNovoPerfil" class="flex w-full justify-end itens-center mt-6 px-4 mb-5 gap-2">
      <button class="bg-red-500 hover:bg-screenCor border border-screenCor hover:border-red-500
      text-white hover:text-red-500 py-1 px-4" @click.prevent="togglePerfil">
        Cancelar
      </button>
      <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
      text-white hover:text-ativo py-1 px-4" @click.prevent="postNovoPerfil">
        Adicionar
      </button>
    </div>
    <div v-else class="flex justify-center">
      <button class="text-ativo" @click.prevent="togglePerfil">
        <i class="fas fa-plus-circle"></i>
      </button>
    </div>
  </div>

  <div class="flex items-center w-full sm:w-auto justify-center sm:justify-end mt-2">
    <button class="botoes bg-ativo text-white hover:bg-screenCor hover:border-ativo hover:text-ativo w-full sm:w-auto"
      @click.prevent="atualizarPerfis(perfis)">
      Salvar/Atualizar
    </button>
  </div>

  <MensagemConfirmacaoExclusao mensagem="Deseja mesmo excluir os itens selecionados?" :abrir="modal_confirmacao"
    @fecharModal="fecharModalConfirmacao" @confirmarExclusao="excluirSelecionados" />

  <alterar-perfis v-if="modalAlterarSelecionados === true" @fecharModal="toggleAlterarSelecionados()"
    @atualizarLista="getListaPerfisProjeto()" :perfis="perfisSelecionados" :coresPerfil="coresPerfil"
    :fasesEsquadria="fasesEsquadria" />

  <copiar-item-lista v-if="modalCopiar" produto="Perfil" @fecharModal="toggleMenuCopiar()"
    @atualizarLista="getListaPerfisProjeto()" :itemParaCopiar="itemParaCopiar" />

  <modal-produtos v-if="modalProdutos === true" produto="Perfil" :itemListaSelecionado="itemListaSelecionado"
    @itemNovoProduto="onSelect" @atualizarLista="getListaPerfisProjeto()" @fecharModal="toggleMenuProdutos()" />

  <preview-image v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />

  <listagem-erros v-if="exibirErros === true" :erros="erros" @fecharModal="toggleErros()" />

  <ListaFuncoesECondicoes v-if="exibirFuncoesECondicoes" @fecharModal="toggleFuncoesECondicoes" />

  <sucesso-rapido v-if="sucesso === true" :mensagem="sucessoMessage" />

</template>

<script>
import axios from 'axios'
import Search from '@/utils/Search.vue'
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue'
import AlterarPerfis from '@/components/midais/Editar/projeto/alterar_selecionados/AlterarPerfis.vue'
import ModalProdutos from '@/components/cadastros_projetos_componentes/alterar_projeto/ModalProdutos.vue'
import CopiarItemLista from '@/components/midais/Editar/projeto/CopiarItemLista.vue'
import draggable from 'vuedraggable'
import Search2 from '@/utils/Search2.vue'
import vSelect from "vue-select";
import PreviewImage from '@/components/midais/ged/PreviewImage.vue'
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue'
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue'
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue'
import ListaFuncoesECondicoes from '@/components/midais/ajudas/ListaFuncoesECondicoes.vue'
import Papa from "papaparse";

export default {
  name: "PerfilProjeto",

  props: ['projeto_id', 'dadosProjeto'],

  // emits: ['atualizarPerfis', 'getListaPerfisProjeto', 'atualizarUnicoPerfil'],

  data() {
    return {

      filtros: [{ campo: "codigo", valor: "" }],

      perfis: [],

      perfilSelecionado: [],
      perfisPesquisados: [],
      fasesEsquadria: [],
      coresPerfil: [],
      preview_imagem: '',

      novoPerfil: {
        projeto_id: this.projeto_id,
        perfil_id: null,
        descricao: '',
        peso: '',
        corte: '',
        qtd_formula: '',
        medida_formula: '',
        posicao: '',
        condicao: '',
        cor: '',
        observacao: '',
        fase_esquadria: '',
        aplicacao: 'A Definir',
      },

      checkAll: false,
      modal_confirmacao: false,

      exibirFuncoesECondicoes: false,

      modalProdutos: false,
      itemListaSelecionado: '',

      modalCopiar: false,
      itemParaCopiar: [],

      adicionarNovoPerfil: false,

      modalAlterarSelecionados: false,
      perfisSelecionados: [],

      exibirErros: false,
      erros: [],

      sucesso: false,
      sucessoMessage: '',
    }
  },

  components: {
    Search,
    MensagemConfirmacaoExclusao,
    AlterarPerfis,
    ModalProdutos,
    CopiarItemLista,
    draggable,
    Search2,
    vSelect,
    PreviewImage,
    ListagemErros,
    ErroRapido,
    SucessoRapido,
    ListaFuncoesECondicoes,
  },

  created() {
    console.log('this.dadosProjeto: ', this.dadosProjeto)
    this.buscaFases()
    this.buscaCoresPerfil()
    this.getListaPerfisProjeto()
  },

  computed: {
    checkSome() {
      return this.perfis.some(perfil => perfil.selected)
    },


  },

  methods: {

    atualizarFiltro(index) {
      this.filtros[index].valor = null;
    },
    adicionarFiltro() {
      this.filtros.push({ campo: "", valor: "" });
    },
    removerFiltro(index) {
      this.filtros.splice(index, 1);
    },

    async buscaCoresPerfil() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get(`produtos/cor-perfil/?page_size=9999`, {
          headers: { Authorization: `Token ${token}` }
        });
        this.coresPerfil = response.data.results.map((cor_perfil) => {
          return {
            id: cor_perfil.cor_base,
            descricao: cor_perfil.descricao,
          }
        });
      } catch (error) {
        console.error(error);
      }
    },

    async buscaFases() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get(`projeto/fase-esquadria/?page_size=9999`, {
          headers: { Authorization: `Token ${token}` }
        });
        this.fasesEsquadria = response.data.results;

        const faseDefault = this.fasesEsquadria.find(item => item.descricao === 'A Definir');
        this.novoPerfil.fase_esquadria = faseDefault ? faseDefault.id : null;
      } catch (error) {
        console.error(error);
      }
    },

    async buscarPerfis(searchQuery) {
      if (searchQuery.length > 2) {
        try {
          const token = await this.$store.dispatch('getAuthTokenCookie');
          await this.$store.dispatch('fetchURLrequest');

          this.$store.dispatch("setLoading", true);

          const response = await axios.get(`produtos/produto/?tipo_produto=Perfil&filtro_base=${searchQuery}&ativo=True&page_size=9999`, {
            headers: { Authorization: `Token ${token}` }
          });
          this.perfisPesquisados = response.data.results;
        } catch (error) {
          console.error(error);
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      } else {
        this.perfisPesquisados = [];
      }
    },

    onSelect(perfil) {
      console.log(perfil)
      this.perfilSelecionado = `${perfil.codigo_base}`

      console.log(perfil.cor_base.length)

      if (perfil.bloquear_cor && Array.isArray(perfil.cor_base) && perfil.cor_base.length === 1) {
        this.novoPerfil.corPerfil = perfil.cor_base[0].id
      } else {
        this.novoPerfil.corPerfil = ''
      }

      // console.log(this.coresPerfil)

      // if (perfil.cor_base?.nome) {
      //   // Busca a cor correspondente no array `coresPerfil`
      //   const corEncontrada = this.coresPerfil.find(cor => cor.nome === perfil.cor_base.nome);

      //   // Se encontrar, define o campo `novoComponente.cor` como o ID da cor encontrada
      //   if (corEncontrada) {
      //     this.novoPerfil.cor = corEncontrada.id;
      //     this.corProprioPerfil = true
      //     alert('achou a cor')
      //   } else {
      //     console.warn(`Cor com o nome "${perfil.cor_base.nome}" não encontrada em coresPerfil.`);
      //     this.corProprioPerfil = false
      //     alert('n achou a cor')
      //   }
      // }

      this.novoPerfil.perfil_id = perfil.id
      this.novoPerfil.descricao = perfil.nome_base
      this.novoPerfil.bloquear_cor = perfil.bloquear_cor
      this.novoPerfil.peso = perfil.peso_base
      this.novoPerfil.lista_cores = perfil.cor_base
      this.novoPerfil.imagem = perfil.imagem?.url_documento
    },

    async getListaPerfisProjeto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      this.$store.dispatch("setLoading", true);

      const params = this.filtros
        .filter((filtro) => filtro.campo && filtro.valor)
        .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
        .join("&");

      await axios.get(`projeto/projeto-perfil/?projeto_id=${this.projeto_id}&${params}&page_size=999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.perfis = response.data.results
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.$store.dispatch("setLoading", false);
        })
    },

    async putListaPerfis(perfis) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      this.$store.dispatch("setLoading", true);

      const data = perfis.map((perfil) => {
        perfil.perfil_id ? perfil.perfil_id = perfil.perfil_id.id : ''
        perfil.projeto_id ? perfil.projeto_id = this.projeto_id : ''

        return perfil
      })

      console.log(data)

      await axios.put(`projeto/projeto-perfil-bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.getListaPerfisProjeto()
          this.sucesso = true
          this.sucessoMessage = "Dados do projeto atualizados com sucesso."
          setTimeout(() => {
            this.sucesso = false;
            this.sucessoMessage = ''
          }, 3000);
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.$store.dispatch("setLoading", false);
        })

    },

    async putPerfil(perfilId, perfilProjetoId, corId = null) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      this.$store.dispatch("setLoading", true);

      const data = { perfil_id: perfilId };

      // Se a cor foi passada, inclui no payload
      if (corId) {
        data.cor = corId;
      }

      await axios.patch(`projeto/projeto-perfil/${perfilProjetoId}/`, data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      }).then(response => {
        this.getListaPerfisProjeto();
        this.sucesso = true;
        this.sucessoMessage = "Dados do projeto atualizados com sucesso.";
        setTimeout(() => {
          this.sucesso = false;
          this.sucessoMessage = '';
        }, 3000);
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.$store.dispatch("setLoading", false);
      });
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    // SEM PAPAPARSE
    // exportarPerfis() {
    //   const headers = [
    //     "Código",
    //     "Condição",
    //     "Corte",
    //     "Quantidade",
    //     "Medida",
    //     "Posição",
    //     "Observação",
    //     "Cor",
    //     "Fase Montagem",
    //     "Aplicação",
    //   ];

    //   const escapeCSV = (value) => {
    //     if (value == null) return ""; // Tratamento para valores nulos ou undefined
    //     const escaped = value.toString().replace(/"/g, '""'); // Escape de aspas duplas
    //     return `"${escaped}"`; // Envolver em aspas duplas para garantir compatibilidade
    //   };

    //   const rows = this.perfis.map((perfil) => [
    //     escapeCSV(perfil.perfil_id?.codigo_base),
    //     escapeCSV(perfil.condicao),
    //     escapeCSV(perfil.corte),
    //     escapeCSV(perfil.qtd_formula),
    //     escapeCSV(perfil.medida_formula),
    //     escapeCSV(perfil.posicao),
    //     escapeCSV(perfil.observacao),
    //     escapeCSV(perfil.cor?.nome),
    //     escapeCSV(perfil.fase_esquadria?.descricao),
    //     escapeCSV(perfil.aplicacao),
    //   ]);

    //   const csvContent = [headers, ...rows]
    //     .map((row) => row.join(",")) // Junta os valores com vírgula
    //     .join("\r\n"); // Usa \r\n para maior compatibilidade

    //   const bom = new Uint8Array([0xef, 0xbb, 0xbf]); // Adiciona BOM para encoding UTF-8
    //   const blob = new Blob([bom, csvContent], { type: "text/csv;charset=utf-8;" });

    //   const link = document.createElement("a");
    //   link.href = URL.createObjectURL(blob);
    //   link.download = "perfis.csv";
    //   document.body.appendChild(link); // Garante que o link esteja no DOM
    //   link.click();
    //   document.body.removeChild(link); // Remove o link após o download
    // },

    // Método para importar os dados


    exportarPerfis() {
      this.$store.dispatch("setLoading", true);
      if (!this.perfis || this.perfis.length === 0) {
        console.warn("Nenhum perfil disponível para exportação.");
        return;
      }

      const headers = [
        "Numeração",
        "Código",
        "Condição",
        "Corte",
        "Quantidade",
        "Medida",
        "Posição",
        "Observação",
        "Cor",
        "Fase Montagem",
        "Aplicação",
      ];

      const data = this.perfis.map((perfil) => ({
        "Numeração": perfil.index || "",
        "Código": perfil.perfil_id.codigo_base || "",
        "Condição": perfil.condicao || "",
        "Corte": perfil.corte || "",
        "Quantidade": perfil.qtd_formula || "",
        "Medida": perfil.medida_formula || "",
        "Posição": perfil.posicao || "",
        "Observação": perfil.observacao || "",
        "Cor": perfil.cor?.descricao || "",
        "Fase Montagem": perfil.fase_esquadria?.descricao || "",
        "Aplicação": perfil.aplicacao || "",
      }));

      const csvContent = Papa.unparse(data, {
        quotes: true,
        delimiter: ";",
        header: true,
        skipEmptyLines: true,
      });

      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, csvContent], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "perfis.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.$store.dispatch("setLoading", false);
    },

    importarPerfis(event) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = async (e) => {
        const content = e.target.result;

        const parsedData = Papa.parse(content, {
          header: true,
          skipEmptyLines: true,
          transform: (value) => (value === "" ? null : value),
        });

        const rows = parsedData.data;
        console.log("Dados importados:", rows);

        this.erros = { erros: [] };

        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');
        this.$store.dispatch("setLoading", true);

        try {
          for (const [index, row] of rows.entries()) {
            const perfil = {
              index: row["Numeração"]?.trim() || null,
              codigo: row["Código"]?.trim() || null,
              condicao: row["Condição"]?.trim() || null,
              corte: row["Corte"]?.trim() || null,
              qtd_formula: row["Quantidade"]?.trim() || null,
              medida_formula: row["Medida"]?.trim() || null,
              posicao: row["Posição"]?.trim() || null,
              observacao: row["Observação"]?.trim() || null,
              cor: row["Cor"]?.trim() || null,
              fase_esquadria: row["Fase Montagem"]?.trim() || null,
              aplicacao: row["Aplicação"]?.trim() || null,
            };

            const camposFaltando = [];
            if (!perfil.codigo) camposFaltando.push("Código");
            if (!perfil.corte) camposFaltando.push("Corte");
            if (!perfil.qtd_formula) camposFaltando.push("Quantidade");
            if (!perfil.medida_formula) camposFaltando.push("Medida");
            if (!perfil.fase_esquadria) camposFaltando.push("Fase Montagem");

            if (camposFaltando.length > 0) {
              this.erros.erros.push(
                `Linha ${index + 2}: Campos obrigatórios ausentes: ${camposFaltando.join(", ")}`
              );
              continue;
            }

            // Buscar IDs
            const codigoResponse = await this.getIDPerfil(perfil.codigo);
            perfil.perfil_id = codigoResponse?.data?.results[0]?.id || null;

            if (perfil.cor) {
              const corResponse = await this.getIDCor(perfil.cor);
              perfil.cor_id = corResponse?.data?.results[0]?.cor_base || null;
            }

            if (perfil.fase_esquadria) {
              const faseResponse = await this.getIDFaseMontagem(perfil.fase_esquadria);
              perfil.fase = faseResponse?.data?.results[0]?.id || null;
            }

            const novoPerfil = {
              projeto_id: this.projeto_id,
              index: perfil.index,
              perfil_id: perfil.perfil_id,
              descricao: perfil.descricao,
              peso: perfil.peso,
              corte: perfil.corte,
              qtd_formula: perfil.qtd_formula,
              medida_formula: perfil.medida_formula,
              posicao: perfil.posicao,
              corPerfil: perfil.cor_id,
              condicao: perfil.condicao,
              observacao: perfil.observacao,
              fase_esquadria: perfil.fase,
              aplicacao: perfil.aplicacao,
            };

            await axios.post('projeto/projeto-perfil/', novoPerfil, {
              headers: { Authorization: `Token ${token}` }
            });

            console.log("Perfil cadastrado:", novoPerfil);
          }

          if (this.erros.erros.length > 0) {
            this.exibirErros = true;
          } else {
            this.sucesso = true;
            this.sucessoMessage = "Perfis importados e cadastrados com sucesso.";
            this.getListaPerfisProjeto();
            setTimeout(() => {
              this.sucesso = false;
              this.sucessoMessage = false;
            }, 3000);
          }

        } catch (error) {
          console.error("Erro geral ao importar perfis:", error);
          this.erros.erros.push("Erro inesperado ao processar perfis.");
          this.exibirErros = true;
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      };

      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }
      reader.readAsText(file);
    },


    async getIDPerfil(codigo) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      return axios.get(`produtos/produto/?codigo_base=${codigo}`, {
        headers: { Authorization: `Token ${token}` }
      });
    },

    async getIDCor(cor) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      return axios.get(`produtos/cor-perfil/?cor=${cor}`, {
        headers: { Authorization: `Token ${token}` }
      });
    },

    async getIDFaseMontagem(fase) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      return axios.get(`projeto/fase-esquadria/?fase=${fase}`, {
        headers: { Authorization: `Token ${token}` }
      });
    },


    selectAll() {
      // const checkboxes = document.getElementsByClassName('checkbox');
      // checkboxes.forEach((checkbox) => {
      //   checkbox.checked = this.checkAll;
      // });
      this.perfis.forEach((perfil) => {
        perfil.selected = this.checkAll;
      });
    },

    verificarExclusao() {
      if (this.checkSome || this.checkAll) {
        this.modal_confirmacao = true;
      } else {
        this.erros = { perfil: 'Selecione pelo menos um Perfil para ser excluído.' }
        this.toggleErros()
      }
    },

    async deletarPerfis(perfisADeletar) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      const perfisADeletarPromisses = perfisADeletar.map(perfil =>
        axios.patch(`projeto/projeto-perfil/${perfil.id}/`, { deletado: 'True' }, {
          headers: { Authorization: `Token ${token}` }
        })
      );

      await Promise.all(perfisADeletarPromisses);
      this.getListaPerfisProjeto()
    },

    excluirSelecionados() {
      if (this.checkAll) {
        this.deletarPerfis(this.perfis.filter((perfil) => perfil.selected));
        this.modal_confirmacao = false;
        this.checkAll = false;
        return;
      }

      const perfisADeletar = this.perfis.filter(perfil => perfil.selected);
      const perfisAAtualizar = this.perfis.filter(perfil => !perfisADeletar.includes(perfil));

      this.deletarPerfis(perfisADeletar);
      this.modal_confirmacao = false;
      this.atualizarPerfis(perfisAAtualizar);
    },

    fecharModalConfirmacao() {
      this.modal_confirmacao = false;
    },


    atualizarUnicoPerfil(perfil, index) {
      const perfilProjetoAAtualizarId = this.perfis[index - 1].id;

      // 🔒 Se a cor estiver bloqueada e só houver uma opção, define automaticamente
      if (perfil.bloquear_cor && Array.isArray(perfil.cor_base) && perfil.cor_base.length === 1) {
        perfil.cor = perfil.cor_base[0].id;
      }

      this.perfisPesquisados = [];
      this.putPerfil(perfil.id, perfilProjetoAAtualizarId, perfil.cor);
    },

    atualizarPerfis(perfisAAtualizar) {
      let erroCor = false;

      perfisAAtualizar.forEach((perfil, index) => {
        console.log(perfil);
        perfil.index = index + 1;
        // perfil.fase_esquadria = perfil.fase_esquadria?.id || '';
        // perfil.cor = perfil.cor?.id || perfil.cor || '';

        // 🔍 Verificação de cor obrigatória se desabilitar o uso de cores opcionais
        if (!this.dadosProjeto.habilitar_cor_perfil && !perfil.cor) {
          erroCor = true;
        }
      });

      if (erroCor) {
        this.erros = { perfil: 'Você precisa definir cor em todos os perfis, já que o Filtro de Cores de Perfil está desabilitado.' }
        this.toggleErros()
        return;
      }

      const perfisParaSalvar = perfisAAtualizar.map(perfil => ({
        ...perfil,
        fase_esquadria: perfil.fase_esquadria?.id || perfil.fase_esquadria || '',
        cor: perfil.cor?.id || perfil.cor || ''
      }));

      this.putListaPerfis(perfisParaSalvar);
    },

    alterarSelecionados() {
      if (this.checkSome || this.checkAll) {
        this.toggleAlterarSelecionados();
        this.perfisSelecionados = this.perfis.filter(perfil => perfil.selected);
      } else {
        this.erros = { perfil: 'Selecione pelo menos um Perfil para ser alterado.' }
        this.toggleErros()
      }
    },

    toggleAlterarSelecionados() {
      this.modalAlterarSelecionados = !this.modalAlterarSelecionados;
    },

    toggleMenuProdutos(itemId) {
      this.itemListaSelecionado = itemId;
      this.modalProdutos = !this.modalProdutos;
    },

    toggleMenuCopiar(item) {
      this.modalCopiar = !this.modalCopiar;
      this.itemParaCopiar = { ...item };
    },

    closed_preview_image() {
      this.preview_imagem = ''
    },

    openPreviewImage(url) {
      this.preview_imagem = url
    },


    async postNovoPerfil() {

      if (!this.dadosProjeto.habilitar_cor_perfil && !this.novoPerfil.corPerfil) {
        this.erros = { cor: 'Você precisa adicionar uma cor, já que o Filtro de Cores de Perfil está desabilitado.' }
        this.toggleErros()
        return
      }

      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');
        this.$store.dispatch("setLoading", true);

        this.novoPerfil.corPerfil = this.novoPerfil.corPerfil?.id || this.novoPerfil.corPerfil || '';
        // this.novoPerfil.index ? this.novoPerfil.index = this.novoPerfil.index : this.novoPerfil.index = this.perfil.length + 1 || 1

        // (this.novoPerfil.index !== undefined && this.novoPerfil.index !== null)
        // ? this.novoPerfil.index 
        // : (this.perfis ? this.perfis.length + 1 : 1);

        await axios.post('projeto/projeto-perfil/', this.novoPerfil, {
          headers: { Authorization: `Token ${token}` }
        });

        this.sucesso = true;
        this.sucessoMessage = 'Perfil criado com sucesso!';
        setTimeout(() => this.sucesso = false, 3000);

        this.adicionarNovoPerfil = false;
        this.perfisPesquisados = [];
        this.limparCampos();
        this.getListaPerfisProjeto();
      } catch (error) {
        console.error(error);
        this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
        this.toggleErros();
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    togglePerfil() {
      this.adicionarNovoPerfil = !this.adicionarNovoPerfil;
      this.limparCampos();
      this.novoPerfil.index = this.perfis.length + 1 || 1
    },


    limparCampos() {
      this.perfilSelecionado = [];
      // Object.assign(this.novoPerfil, {
      //   perfil_id: '',
      //   descricao: '',
      //   peso: '',
      //   imagem: '',
      //   corte: '',
      //   qtd_formula: '',
      //   medida_formula: '',
      //   posicao: '',
      //   condicao: '',
      //   cor: '',
      //   observacao: '',
      // });

      this.novoPerfil.perfil_id = ''
      this.novoPerfil.index = ''
      this.novoPerfil.descricao = ''
      this.novoPerfil.peso = ''
      this.novoPerfil.imagem = ''
      this.novoPerfil.corte = ''
      this.novoPerfil.qtd_formula = ''
      this.novoPerfil.medida_formula = ''
      this.novoPerfil.posicao = ''
      this.novoPerfil.condicao = ''
      this.novoPerfil.corPerfil = ''
      this.novoPerfil.observacao = ''

      this.novoPerfil.aplicacao = 'A Definir'

      const faseDefault = this.fasesEsquadria.find(item => item.descricao === 'A Definir');
      this.novoPerfil.fase_esquadria = faseDefault ? faseDefault.id : null;
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros;
    },

    toggleFuncoesECondicoes() {
      this.exibirFuncoesECondicoes = !this.exibirFuncoesECondicoes
    },
  }

}
</script>

<style scoped>
.botoes {
  @apply px-4 py-1 border
}

.input-campos {
  @apply w-full
}
</style>