<template>


    <div class="container-modelo-tabela">
        <table class="container-tabela overflow-auto">
            <thead class="cabecalho-tabela">
                <tr class="border-b">
                    <th scope="col" class="px-6 py-2 text-start">#</th>
                    <th scope="col" class="px-6 py-2 flex justify-start">
                        <div class="flex items-center">
                            <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
                            <label class="sr-only">checkbox</label>
                        </div>
                    </th>
                    <th scope="col" class="px-4 py-2 text-start">Condição</th>
                    <th scope="col" class="px-6 py-2 text-center">Imagem</th>
                </tr>
            </thead>


            <tbody>
                <tr v-for="(imagem, index) in imagens_variavel" :key="index" class="escopo-tabela">
                    <th scope="col"
                        class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                        {{ index + 1 }}
                    </th>
                    <td class="px-6 text-start">
                        <div class="selec">
                            <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox-opcao"
                                v-model="imagem.selected">
                            <label class="sr-only">checkbox</label>
                        </div>
                    </td>

                    <td scope="col" class="text-center text-xs">
                        <input type="text" class="input-campos" v-model="imagem.condicao">
                    </td>

                    <td scope="col" class="w-full flex text-center justify-center items-center flex-col">
                        <img v-if="imagem.imagem" :src="imagem.imagem.url_documento" alt="Imagem da opção"
                            class="w-10 cursor-pointer" @click.prevent="openPreviewImage(imagem.imagem.url_documento)">

                        <img v-else
                            src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                            alt="Sem imagem" class="w-10">

                        <button @click.prevent="editarImagem(imagem, index)">
                            Substituir Imagem
                        </button>
                    </td>

                </tr>
            </tbody>

            <tbody>
                <tr v-if="adicionarNovaOpcao" class="escopo-tabela text-center">
                    <td class=""></td>
                    <td class=""></td>

                    <td class="w-full text-center">
                        <input type="text" class="input-campos" v-model="novaImagemVariavel.condicao">
                    </td>

                    <td class="w-full flex text-center justify-center items-center flex-col">
                        <img v-if="novaImagemVariavel.imagem" :src="novaImagemVariavel.imagem.url_documento"
                            alt="Imagem da Nova Opção" class="w-10 h-10 cursor-pointer"
                            @click.prevent="openPreviewImage(novaImagemVariavel.imagem.url_documento)">
                        <button @click.prevent="abrir_galeria" v-if="!novaImagemVariavel.imagem">
                            Escolha uma Imagem
                        </button>
                        <button @click.prevent="abrir_galeria" v-else>
                            Substituir Imagem
                        </button>
                    </td>

                </tr>
            </tbody>
        </table>

        <div class="flex w-full justify-end itens-center mt-6 px-4 mb-5 gap-2" v-if="adicionarNovaOpcao">
            <button class="bg-red-500 hover:bg-screenCor border border-screenCor hover:border-red-500
            text-white hover:text-red-500 py-2 px-4" @click.prevent="toggleAdicionarOpcao">
                Cancelar
            </button>
            <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
      text-white hover:text-ativo py-1 px-4" @click.prevent="postNovaImagemVariavel">
                Adicionar
            </button>
        </div>

        <div class="flex justify-center" v-if="!adicionarNovaOpcao">
            <button class="text-ativo" @click.prevent="toggleAdicionarOpcao">
                <i class="fas fa-plus-circle"></i>
            </button>
        </div>
    </div>

    <div class="flex flex-wrap sm:flex-nowrap w-full justify-between items-center mt-6 gap-4">
        <div class="flex flex-wrap sm:flex-nowrap items-center gap-3 w-full sm:w-auto">
            <button class="bg-red-500 hover:bg-screenCor border border-screenCor hover:border-red-500
            text-white hover:text-red-500 py-1 px-4" @click.prevent="verificarExclusao()">
                Excluir Selecionados
            </button>
        </div>

        <div class="flex items-center w-full sm:w-auto justify-center sm:justify-end">
            <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
            text-white hover:text-ativo py-1 px-4" @click.prevent="salvarListaVariaveis()">
                Salvar/Atualizar
            </button>
        </div>
    </div>

    <MensagemConfirmacaoExclusao mensagem="Deseja mesmo excluir os itens selecionados?"
        :abrir="modal_confirmacao_opcao_variavel" @fecharModal="fecharModalConfirmacao"
        @confirmarExclusao="excluirSelecionados" />

    <preview-image v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />

    <listagem-erros v-if="exibirErros === true" :erros="erros" @fecharModal="toggleErros()" />

    <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />

    <Galeria v-if="galeria === true" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput" />
</template>

<script>
import axios from 'axios'
import Galeria from '@/components/midais/ged/Galeria.vue';
import PreviewImage from '@/components/midais/ged/PreviewImage.vue'
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';

export default {
    name: 'ImagensVariaveis',

    props: ['projeto_id'],

    data() {
        return {
            galeria: false,
            preview_imagem: '',
            imagemEmEdicaoIndex: null,

            imagens_variavel: [],

            novaImagemVariavel: {
                projeto_id: this.projeto_id,
                condicao: '',
                imagem: '',
                // index: this.opcoesVariavel.length + 1
            },

            adicionarNovaOpcao: false,

            erros: {},
            exibirErros: false,
            erroModal: false,

            sucesso: false,
            sucessoMessage: '',

            checkAll: false,
            modal_confirmacao_opcao_variavel: false,
        }
    },

    props: ['projeto_id', 'imagensVariavel'],

    emits: ['getListaImagensVariavel', 'atualizarImagensVariavel'],

    components: {
        Galeria,
        PreviewImage,
        MensagemConfirmacaoExclusao,
        SucessoRapido,
        ListagemErros
    },

    computed: {

        checkSome() {
            return this.imagens_variavel.some(opcao => opcao.selected)
        },
    },

    created() {
        this.getListaImagensVariavel()
    },

    methods: {

        async getListaImagensVariavel() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            this.$store.dispatch("setLoading", true);

            try {
                const response = await axios.get(`projeto/projeto-imagem-variavel/?projeto=${this.projeto_id}&page_size=9999`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                })

                console.log('IMAGENS VARIAVEIS')
                console.log(response.data.results)
                this.imagens_variavel = response.data.results
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async postNovaImagemVariavel() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            this.$store.dispatch("setLoading", true);

            this.novaImagemVariavel.imagem ? this.novaImagemVariavel.imagem = this.novaImagemVariavel.imagem.id : ''

            try {
                const response = await axios.post(`projeto/projeto-imagem-variavel/`, this.novaImagemVariavel, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                })

                console.log(response)
                this.getListaImagensVariavel()
                this.toggleAdicionarOpcao()
                this.sucesso = true
                this.sucessoMessage = 'Imagem da Variável criada com sucesso.'
                setTimeout(() => {
                    this.sucesso = false;
                    this.sucessoMessage = ''
                }, 3000);
            } catch (error) {
                console.error(error)
                this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
                this.toggleErros()
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        editarImagem(imagem, index) {
            this.imagemEmEdicaoIndex = index;
            this.abrir_galeria();
        },

        async salvarListaVariaveis() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            this.$store.dispatch("setLoading", true);

            const data = this.imagens_variavel.map((imagem) => ({
                id: imagem.id,  // Mantém o ID original
                projeto_id: imagem.projeto_id ? imagem.projeto_id.id : null,
                imagem: imagem.imagem ? imagem.imagem.id : null,
                condicao: imagem.condicao
            }));

            try {
                await axios.put(`projeto/projeto-imagem-variavel-bulk-update/`, data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`
                    }
                });

                this.getListaImagensVariavel();
                this.sucesso = true;
                this.sucessoMessage = 'Dados do projeto atualizados com sucesso.';

                setTimeout(() => {
                    this.sucesso = false;
                    this.sucessoMessage = '';
                }, 3000);

            } catch (error) {
                console.error(error);
                this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
                this.toggleErros()
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        fecharModalConfirmacao() {
            this.modal_confirmacao_opcao_variavel = false
        },

        async deletarImagemVariavel(imagemVariavelADeletar) {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest')

                const imagensVariaveisADeletarPromisses = []

                for (const imagemVariavel of imagemVariavelADeletar) {

                    imagensVariaveisADeletarPromisses.push(
                        axios.patch(`projeto/projeto-imagem-variavel/${imagemVariavel.id}/`, { deletado: 'True' }, {
                            headers: {
                                Authorization: `Token ${token}`
                            }
                        })
                    )
                }

                await Promise.all(imagensVariaveisADeletarPromisses)

            } catch (error) {
                console.error(error)
            }
        },

        async excluirSelecionados() {
            if (this.checkAll) {
                await this.deletarImagemVariavel(this.imagens_variavel)
                this.modal_confirmacao_opcao_variavel = false
                this.checkAll = false
                this.getListaImagensVariavel()
                return
            }
            const imagensVariaveisADeletar = this.imagens_variavel.filter((opcao) => {
                if (opcao.selected) {
                    return opcao
                }
            })

            await this.deletarImagemVariavel(imagensVariaveisADeletar)
            this.modal_confirmacao_opcao_variavel = false
            this.getListaImagensVariavel()
        },

        selectAll() {
            const checkboxes = document.getElementsByClassName('checkbox-opcao');
            checkboxes.forEach((checkbox) => {
                checkbox.checked = this.checkAll;
            });

            this.imagens_variavel.forEach((variavel) => {
                variavel.selected = this.checkAll;
            });
        },

        closed_preview_image() {
            this.preview_imagem = ''
        },

        openPreviewImage(url) {
            this.preview_imagem = url
        },

        atualizarFileInput(objeto) {
            if (this.imagemEmEdicaoIndex !== null) {
                this.imagens_variavel[this.imagemEmEdicaoIndex].imagem = objeto;
                this.imagemEmEdicaoIndex = null;
            } else {
                this.novaImagemVariavel.imagem = objeto
            }
        },

        abrir_galeria() {
            this.galeria = !this.galeria
        },

        verificarExclusao() {
            console.log(this.checkSome, this.checkAll)
            if (this.checkSome || this.checkAll) {
                this.modal_confirmacao_opcao_variavel = true;
            } else {
                this.erros = { variavel: 'Selecione pelo menos uma Variável para ser excluída.' }
                this.toggleErros()
            }
        },

        toggleAdicionarOpcao() {
            this.adicionarNovaOpcao = !this.adicionarNovaOpcao
            this.limparCampos();
        },

        limparCampos() {
            this.novaImagemVariavel.condicao = ''
            this.novaImagemVariavel.imagem = ''
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        }

    }
}
</script>

<style scoped lang="scss"></style>