<template>
    <div>
        <div class="fixed inset-0 bg-black/60 flex items-center justify-center z-[10000]">
            <div
                class="bg-white rounded-lg shadow-lg p-6 w-[90%] sm:w-[600px] max-h-[80vh] overflow-y-auto transform transition-transform duration-300 ease-out scale-95 sm:scale-100">
                <!-- Cabeçalho do Modal -->
                <div class="flex justify-between items-center mb-4 border-b pb-2">
                    <h2 class="text-lg font-bold text-blue-600">Orçamentos Vinculados a Obra</h2>
                    <button @click.prevent="$emit('fecharModal')"
                        class="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 ease-in-out hover:bg-gray-100">
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>

                <div class="space-y-4">
                    <div v-for="orcamento in orcamentosVinculados" :key="orcamento.id"
                        class="flex flex-col md:flex-row justify-between items-center bg-gray-50 p-4 rounded-lg border border-gray-200">
                        <div class="text-gray-700 font-medium">
                            <i class="fa-solid fa-file-invoice mr-2 text-corBase"></i>
                            Orçamento: <span class="text-corBase font-semibold">#{{ orcamento.codigo }}</span>
                        </div>

                        <button
                            class="mt-3 md:mt-0 bg-corBase hover:bg-corBase-dark text-white px-4 py-2 rounded transition duration-200 ease-in-out flex items-center gap-2"
                            @click.prevent="acessarOrcamento(orcamento.id)">
                            Acessar
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "ListarEnderecos",
    props: ['obra'],
    emits: ['fecharModal'],

    data() {
        return {
            orcamentosVinculados: [],
        }
    },


    created() {
        this.getOrcamentosObra(this.obra)
    },

    methods: {
        async getOrcamentosObra(obraId) {
            try {

                this.$store.dispatch("setLoading", true);

                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get(`orcamentos/?obra=${obraId}&ativo=True&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.orcamentosVinculados = response.data.results
                // console.log(response.data.results)

            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        acessarOrcamento(idOrcamento) {
            window.open(`/orcamento/${idOrcamento}`, '_blank');
        }
    }
};
</script>

<style scoped></style>