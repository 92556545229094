<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Cores Componentes</h1>
      </div>

      <router-link to="/configuracoes/cores" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="block">

      <div class="mb-4">
        <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
          <div class="px-2 w-1/2">
            <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
              <option value="codigo">Código</option>
              <option value="cor">Descrição</option>
              <option value="ativo">Status</option>
            </select>
          </div>

          <div class="w-full">

            <v-select v-if="filtro.campo === 'ativo'" v-model="filtro.valor" :options="lista_status" label="nome"
              :reduce="status => status.value" placeholder="Escolha uma opção" />

            <input v-else v-model="filtro.valor" type="text" class="input-form-chapa" placeholder="Digite o desejado" />
          </div>

          <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
        </div>

        <div class="flex w-full justify-center">
          <button class="text-ativo" @click.prevent="adicionarFiltro">
            <i class="fas fa-plus-circle"></i>
          </button>
        </div>
      </div>


    </div>

    <div class="flex">

      <div class="flex flex-wrap gap-2">

        <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
      text-white hover:text-corBase py-2 px-4" @click.prevent="mudarPagina(1)">Pesquisar
        </button>

        <!-- 
        <button class="bg-blue-500 hover:bg-screenCor border border-screenCor hover:border-blue-500
  text-white hover:text-blue-500 py-2 px-4" @click.prevent="alterarSelecionados()">
          Alterar Selecionados
        </button> -->

        <button @click.prevent="toggleImportarCoresComponente()" class="bg-yellow-500 hover:bg-screenCor 
        border border-screenCor hover:border-yellow-500 text-white hover:text-yellow-500 py-2 px-4">
          Importar
        </button>

      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela pt-2">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th class="px-6 py-3 w-full">
              Descrição
            </th>
            <th class="px-6 py-3 w-full">
              Código
            </th>
            <th scope="col" class="px-6 py-3">
              Status
            </th>
            <th scope="col" class="px-10 py-3">
              Ação
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(cor, index) in lista_cores_componente" :key="index" class="escopo-tabela">

            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox" v-model="cor.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-xs">
              {{ cor.descricao }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ cor.codigo || '-' }}
            </td>

            <td>
              <div class="px-1 sm:px-6 py-1 sm:py-4 text-center">
                <i
                  :class="cor.ativo ? 'fa-solid fa-circle-check text-ativo' : 'fa-solid fa-circle-xmark text-inativo'"></i>
              </div>
            </td>

            <td class="px-6 py-2 text-xs">
              <a @click="selecionarCorEditar(cor)" href="#" class="font-medium text-corBase pl-2 hover:underline">
                <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
              </a>
            </td>

          </tr>
        </tbody>
      </table>

      <Pagination v-if="lista_cores_componente.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
    </div>

  </div>

  <ImportarCorComponente v-if="importarCoresComponente" @fecharModal="toggleImportarCoresComponente"
    @atualizarLista="mudarPagina(paginaAtual)" />

  <EditarCorComponente v-if="exibirEditarCor" :cor="corSelecionada" @fecharModal="toggleEditarCor"
    @atualizarLista="mudarPagina(paginaAtual)" />

</template>

<script>
import axios from "axios";
import EditarCorComponente from "@/components/midais/Editar/cores/EditarCorComponente";
import ImportarCorComponente from "./importação/ImportarCorComponente.vue";
import Pagination from "@/utils/Pagination.vue";
import vSelect from "vue-select";

export default {
  name: "ListaCadastroComponente",

  components: {
    EditarCorComponente,
    ImportarCorComponente,
    Pagination,
    vSelect,
  },

  data() {
    return {
      lista_cores_componente: [],

      filtros: [{ campo: "codigo", valor: "" }],
      lista_status: [
        { value: 'True', nome: "Ativo" },
        { value: 'False', nome: "Inativo" },
        // { value: 'Todos', nome: "Todos" },
      ],

      corSelecionada: [],
      exibirEditarCor: false,

      importarCoresComponente: false,

      checkAll: false,
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: '',

    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },

    checkSome() {
      return this.lista_cores_componente.some(cor_componente => cor_componente.selected)
    }
  },

  created() {

  },

  methods: {

    atualizarFiltro(index) {
      this.filtros[index].valor = null;
    },
    adicionarFiltro() {
      this.filtros.push({ campo: "", valor: "" });
    },
    removerFiltro(index) {
      this.filtros.splice(index, 1);
    },

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      this.lista_cores_componente.forEach((cor_componente) => {
        cor_componente.selected = this.checkAll;
      });
    },

    async getListaCorComponente(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {

        const params = this.filtros
          .filter((filtro) => filtro.campo && filtro.valor)
          .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
          .join("&");

        const response = await axios.get(`/produtos/cor-componente/?${params}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.lista_cores_componente = response.data.results;
        this.countItens = response.data.count;
        console.log(this.lista_cores_componente);

      } catch (error) {
        console.error("Erro ao buscar lista de cores de componente:", error);
      }
    },

    selecionarCorEditar(cor) {
      this.corSelecionada = {...cor}
      this.toggleEditarCor()
    },

    toggleEditarCor() {
      this.exibirEditarCor = !this.exibirEditarCor
    },

    toggleImportarCoresComponente() {
      this.importarCoresComponente = !this.importarCoresComponente
    },

    mudarPagina(pagina) {
      this.checkAll = false
      this.paginaAtual = pagina;
      this.getListaCorComponente(pagina);
    }



  }
}
</script>

<style lang="scss"></style>