<template>

    <div class="block">
        <div class="mb-4">
            <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
                <div class="px-2 w-1/3">
                    <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
                        <option value="variavel">Variável</option>
                        <option value="descricao">Descrição</option>
                    </select>
                </div>

                <div class="w-full">
                    <!-- <v-select v-if="filtro.campo === 'cor'" v-model="filtro.valor" :options="lista_cores" label="nome_razao"
            placeholder="Escolha uma opção" :reduce="cor => cor.id" />

          <v-select v-else-if="filtro.campo === 'fase'" v-model="filtro.valor" :options="lista_fases" label="descricao"
            placeholder="Escolha uma opção" :reduce="fase => fase.descricao" />-->

                    <!-- v-else -->
                    <input v-model="filtro.valor" type="text" class="input-form-chapa" placeholder="Insira o valor" />
                </div>

                <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
            </div>

            <div class="flex w-full justify-center">
                <button class="text-ativo" @click.prevent="adicionarFiltro">
                    <i class="fas fa-plus-circle"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="flex">

        <div class="flex flex-wrap gap-2 mb-2">

            <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
text-white hover:text-corBase py-2 px-4" @click.prevent="getVariaveisOrcamento()">
                Pesquisar
            </button>

            <button class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500
            text-white hover:text-yellow-500 py-2 px-4" @click.prevent="toggleModalVariaveis()">
                Alterar Variáveis
            </button>

            <!-- <button class="bg-blue-500 hover:bg-screenCor border border-screenCor hover:border-blue-500
                text-white hover:text-blue-500 py-2 px-4" @click.prevent="alterarSelecionadosComponentes()">
                Alterar Selecionados
            </button>

            <button class="bg-red-500 hover:bg-screenCor border border-screenCor hover:border-red-500
            text-white hover:text-red-500 py-2 px-4" @click.prevent="verificarExclusao()">
                Excluir Selecionados
            </button>

            <button class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500
            text-white hover:text-yellow-500 py-2 px-4" @click.prevent="editarTodos()">
                {{ editandoTodos ? 'Salvar Todos' : 'Editar Todos' }}
            </button>

            <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
            text-white hover:text-ativo py-2 px-4" @click.prevent="salvarOrdem()">
                Salvar/Atualizar Ordem
            </button> -->

            <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
    text-white hover:text-ativo py-2 px-4" @click.prevent="recalcularTudoItemOrcamento()">
                Recalcular
            </button>

        </div>
    </div>

    <div class="container-modelo-tabela">
        <table class="container-tabela overflow-auto">
            <thead class="cabecalho-tabela">
                <tr>
                    <th scope="col" class="px-3 py-3">#</th>
                    <th scope="col" class="px-3 py-3">Variável</th>
                    <th scope="col" class="px-3 py-3">Descrição</th>
                    <th scope="col" class="px-3 py-3">Opção Selecionada</th>
                    <th scope="col" class="px-3 py-3">Condição</th>
                    <th scope="col" class="px-3 py-3">Condição Resultado</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(variavel, index) in variaveis" :key="variavel.id" class="escopo-tabela text-left">
                    <th scope="row" class="px-3 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ index + 1 }}
                    </th>
                    <td class="px-3 w-60 py-2">
                        {{ variavel.variavel }}
                    </td>
                    <td class="px-3 w-60 py-2">
                        {{ variavel.descricao }}
                    </td>
                    <td class="px-3 py-2">
                        <!-- Exibe a descrição da opção selecionada ou o valor manual -->
                        <div v-if="getOpcoesByVariavelId(variavel.id).length > 0">
                            {{ getDescricaoOpcaoSelecionada(variavel.id) || 'Nenhuma opção selecionada' }} ({{
                                variavel.medida_valor }})
                        </div>
                        <div v-else>
                            {{ selected[variavel.id] || 'Nenhum valor definido' }}
                        </div>
                    </td>
                    <td class="px-3 w-60 py-2">
                        {{ variavel.condicao_formula || '-' }}
                    </td>
                    <td class="px-3 w-60 py-2">
                        {{ variavel.condicao_calculada ? 'Verdadeiro' : 'Falso' }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- Botões de Cancelar e Salvar -->
    <div class="flex w-full justify-end itens-center mt-6 gap-2">
        <button class="bg-red-500 hover:bg-screenCor border border-screenCor hover:border-red-500
            text-white hover:text-red-500 py-2 px-4" @click.prevent="$router.push('/orcamentos')">Cancelar</button>
        <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
            text-white hover:text-ativo py-2 px-4" @click.prevent="salvarVariaveis()">Salvar</button>
    </div>

    <!-- <AlterarSelecionadosVidrosOrcamento v-if="exibirAlterarSelecionados" :listaAreasVidro="lista_localizacoes" :lista_fases="lista_fases"
    :vidros="vidrosSelecionados" @fecharModal="toggleAlterarSelecionados()"
    @atualizarLista="getVidrosOrcamento()" />
  <MensagemConfirmacaoExclusao mensagem="Deseja mesmo excluir os itens selecionados?" :abrir="modal_confirmacao"
    @fecharModal="toggleModalConfirmacao()" @confirmarExclusao="deletarVidros" />
    <ListaFuncoesECondicoes v-if="exibirFuncoesECondicoes" @fecharModal="toggleFuncoesECondicoes" /> -->
    <ModalVariaveisOrcamento v-if="exibirVariaveis" :id="itemOrcamentoId" :altura="itemOrcamento.altura"
        :largura="itemOrcamento.largura" :codigo_orcamento="itemOrcamento.orcamento.codigo"
        :descricao_detalhada="itemOrcamento.descricao" :imagem="itemOrcamento.imagem"
        :quantidade="itemOrcamento.quantidade" @fecharModalVariaveis="toggleModalVariaveis()"
        @atualizarLista="getVariaveisOrcamento()" />
    <!-- :descricao_detalhada="itemOrcamentoSelecionado.descricao"
        :codigo_orcamento="orcamento.codigo" :imagem="itemOrcamentoSelecionado.imagem"
        :largura="itemOrcamentoSelecionado.largura" :altura="itemOrcamentoSelecionado.altura"
        :quantidade="itemOrcamentoSelecionado.quantidade" -->
    <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />
    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />
</template>

<script>
import axios from 'axios'
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';
import ModalVariaveisOrcamento from '../../ModalVariaveisOrcamento.vue';
import vSelect from 'vue-select';

export default {
    name: 'AlterarVariaveisOrcamento',

    components: {
        SucessoRapido,
        ModalVariaveisOrcamento,
        ErroRapido,
        ListagemErros,
        vSelect,
    },

    props: ['itemOrcamentoId', 'itemOrcamento'],

    data() {
        return {
            filtros: [{ campo: "variavel", Variável: "" }],

            variaveis: [],
            id_variaveis: [],
            opcoesVariaveis: [],
            selected: {},

            exibirVariaveis: false,

            sucesso: false,
            erro: false,
            exibirErros: false,
            erros: [],
        };
    },

    created() {
        this.getVariaveisOrcamento();
    },

    methods: {

        atualizarFiltro(index) {
            this.filtros[index].valor = null;
        },
        adicionarFiltro() {
            this.filtros.push({ campo: "", valor: "" });
        },
        removerFiltro(index) {
            this.filtros.splice(index, 1);
        },

        async getVariaveisOrcamento() {
            try {

                this.$store.dispatch("setLoading", true);

                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                const params = this.filtros
                    .filter((filtro) => filtro.campo && filtro.valor)
                    .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
                    .join("&");

                const response = await axios.get(`orcamentos/variavel-local-orcamento/?item_orcamento_id=${this.itemOrcamentoId}&${params}&page_size=9999`, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                this.variaveis = response.data.results;
                this.id_variaveis = this.variaveis.map((variavel) => variavel.id);
                this.getOpcoesVariaveisOrcamento();
            } catch (error) {
                console.error(error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getOpcoesVariaveisOrcamento() {
            try {

                this.$store.dispatch("setLoading", true);

                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');
                const response = await axios.get(`orcamentos/opcao-variavel-local-orcamento/?item_orcamento_id=${this.itemOrcamentoId}&page_size=9999`, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                this.opcoesVariaveis = response.data.results;
                this.setInitialSelections();
            } catch (error) {
                console.error(error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        setInitialSelections() {
            this.variaveis.forEach((variavel) => {
                const opcoes = this.getOpcoesByVariavelId(variavel.id);
                const selectedOpcao = opcoes.find(
                    (opcao) => String(opcao.valor) === String(variavel.medida_valor)
                );
                if (selectedOpcao) {
                    this.selected[variavel.id] = selectedOpcao.valor;
                } else {
                    this.selected[variavel.id] = variavel.medida_valor;
                }
            });
        },

        getOpcoesByVariavelId(id_variavel) {
            return this.opcoesVariaveis.filter(
                (opcao) => opcao.variavel.id === id_variavel
            );
        },

        getDescricaoOpcaoSelecionada(id_variavel) {
            const opcoes = this.getOpcoesByVariavelId(id_variavel);
            const selectedOpcao = opcoes.find(
                (opcao) => String(opcao.valor) === String(this.selected[id_variavel])
            );
            return selectedOpcao ? selectedOpcao.descricao : null;
        },

        async salvarVariaveis() {

        },

        async recalcularItemOrcamento() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get(
                    `engine/recalcular-item-orcamento/${this.itemOrcamentoId}/?variaveis=true`,
                    {
                        headers: { 'Authorization': `Token ${token}` },
                    }
                );

                console.log("Itens atualizados com sucesso:", response.data);
                this.sucesso = true;
                this.sucessoMessage = 'Variáveis recalculadas com sucesso.';
                this.getVariaveisOrcamento()
                setTimeout(() => {
                    this.sucesso = false;
                    this.sucessoMessage = '';
                }, 3000);

            } catch (error) {
                console.error("Erro ao atualizar itens do orçamento:", error);
                this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
                this.toggleErros();
            }
        },

        async recalcularTudoItemOrcamento() {

            try {

                this.$store.dispatch("setLoading", true);

                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get(`engine/${this.itemOrcamentoId}/`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                })

                console.log(response.data)
                this.sucesso = true
                this.sucessoMessage = 'Item do Orçamento recalculado com sucesso.'
                this.getVariaveisOrcamento()
                setTimeout(() => {
                    this.sucesso = false
                    this.sucessoMessage = ''
                }, 3000)

            } catch (error) {
                console.error(error)
                this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
                this.toggleErros()
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        toggleModalVariaveis() {
            this.exibirVariaveis = !this.exibirVariaveis
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },
    },
};
</script>

<style scoped></style>