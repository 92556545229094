<template>
    <div class="container-formulario">

        <!-- Cabeçalho -->
        <div class="cabecalho-formulario">
            <h1 class="text-xl text-corBase font-bold">
                Editar Obra {{ obra.descricao }}
            </h1>

            <button @click.prevent="$router.push('/obras')" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </button>
        </div>

        <div class="w-full block justify-center mb-2">
            <div class="flex flex-wrap gap-2 justify-center">

                <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
                    dashboardAtivo == 'DadosDashboard'
                        ? 'bg-screenCor text-corBase border border-corBase'
                        : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
                    @click.prevent="abrirDashboard('DadosDashboard')">
                    Dados
                </button>

                <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
                    dashboardAtivo == 'VaosDashboard'
                        ? 'bg-screenCor text-corBase border border-corBase'
                        : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
                    @click.prevent="abrirDashboard('VaosDashboard')">
                    Vãos
                </button>
            </div>
        </div>

        <EditarDadosObra v-if="dashboardAtivo == 'DadosDashboard'" :obra="obra" />
        <ListarVaos v-if="dashboardAtivo == 'VaosDashboard'" :obra="obra" />
    </div>


    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />
    <!-- <SucessoRapido v-if="sucesso" mensagem="Orçamento calculado com sucesso!" /> -->
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select';
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import EditarDadosObra from './EditarDadosObra.vue'
import ListarVaos from './ListarVaos.vue'

export default {
    name: 'AlterarObra',

    components: {
        vSelect,
        ListagemErros,
        SucessoRapido,
        EditarDadosObra,
        ListarVaos,
    },

    data() {
        return {
            obra: [],

            lista_status: [
                { value: 'True', nome: "Ativo" },
                { value: 'False', nome: "Inativo" },
            ],

            dashboardAtivo: null,

            sucesso: false,
            erros: [],
            exibirErros: false,
        }
    },

    created() {
        this.obraId = this.$route.params.obra;
        this.buscarDadosObra();
    },

    methods: {

        abrirDashboard(nome) {
            this.dashboardAtivo = nome;
        },

        async buscarDadosObra() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            this.$store.dispatch("setLoading", true);
            try {
                const response = await axios.get(`/obra/obra/${this.obraId}`, {
                    headers: { Authorization: `Token ${token}` },
                });
                this.obra = response.data;
                if (this.$route.query.listaVao) {
                    this.abrirDashboard('VaosDashboard')
                } else {
                    this.abrirDashboard('DadosDashboard')
                }

                // await this.buscarPessoasAlocadasObra();
                // await this.buscarDocumentosObra();
            } catch (error) {
                console.error(error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        }
    }
}
</script>

<style scoped lang="scss"></style>