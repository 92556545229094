<template>
    <div class="container-formulario">

        <!-- Cabeçalho lista de obras -->
        <div class="container-cabecalho flex justify-between mb-4">
            <div>
                <h1 class="text-xl text-corBase font-bold">Obras</h1>
            </div>

            <router-link to="/dashboard" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </router-link>
        </div>

        <div class="mb-3">

            <div class="px-2 w-full md:w-[30%]">
                <label class="text-xl text-corBase font-bold">Status:</label>
                <v-select v-model="status" :options="lista_status" class="w-full" />
            </div>

        </div>

        <div class="mb-4">
            <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
                <div class="px-2 w-1/2">
                    <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
                        <!-- <option value="pessoa">Pessoa</option> -->
                        <option value="cliente">Cliente</option>
                        <option value="descricao">Descrição da Obra</option>
                        <option value="cno">CNO</option>
                        <option value="responsavel_tecnico">Responsável Técnico</option>
                        <option value="stakeholder">Stakeholders</option>

                        <option value="logradouro">Logradouro</option>
                        <option value="predio_condominio">Prédio/Condomínio</option>
                        <option value="bairro">Bairro</option>
                        <option value="uf">Estado</option>
                        <option value="cidade">Cidade</option>
                    </select>
                </div>

                <div class="w-full">

                    <v-select v-if="filtro.campo === 'cliente'" v-model="filtro.valor" :options="lista_clientes"
                        label="text" :reduce="cliente => cliente.id"></v-select>

                    <v-select v-else-if="filtro.campo === 'responsavel_tecnico'" v-model="filtro.valor"
                        :options="lista_pessoas" :reduce="pessoa => pessoa.text" label="text" />

                    <v-select v-else-if="filtro.campo === 'uf'" v-model="filtro.valor" :options="estados"
                        :reduce="estado => estado.sigla" label="nome" />

                    <v-select v-else-if="filtro.campo === 'cidade'" v-model="filtro.valor" :options="cidades"
                        :reduce="cidade => cidade.nome" label="nome">
                        <template #no-options>
                            <span v-if="filtros.some(f => f.campo === 'uf' && f.valor)">
                                Nenhuma cidade encontrada.
                            </span>
                            <span v-else>
                                Selecione um estado primeiro.
                            </span>
                        </template>
                    </v-select>

                    <div v-else-if="filtro.campo === 'stakeholder'" class="flex gap-2">
                        <v-select v-model="filtro.funcao" :options="lista_funcoes_stakeholder"
                            :reduce="funcao => funcao.text" label="text" class="w-full"
                            placeholder="Selecione a atividade" />
                        <v-select v-model="filtro.valor" :options="lista_stakeholders" :reduce="pessoa => pessoa.id"
                            label="text" class="w-full" placeholder="Selecione a pessoa">

                            <template #no-options>
                                <span v-if="filtros.some(f => f.campo === 'stakeholder' && f.funcao)">
                                    Nenhum stakeholder com esta atividade encontrado.
                                </span>
                                <span v-else>
                                    Selecione uma atividade.
                                </span>
                            </template>

                        </v-select>
                    </div>



                    <input v-else v-model="filtro.valor" type="text" class="input-form-chapa"
                        placeholder="Insira o valor" />
                </div>

                <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index, filtro.campo)"></i>
            </div>

            <div class="flex w-full justify-center">
                <button class="text-ativo" @click.prevent="adicionarFiltro">
                    <i class="fas fa-plus-circle"></i>
                </button>
            </div>
        </div>

        <div class="flex items-end gap-8 mb-6">
            <div class="flex gap-2">
                <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click.prevent="mudarPagina(1)">Pesquisar</button>
                <!-- v-if="isadmin === true" -->
                <div>
                    <button @click.prevent="$router.push(`/criar-obra`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4">Adicionar
                    </button>
                </div>
            </div>
        </div>

        <!-- Filtros e Ações -->
        <!-- <div class="flex gap-4 mb-6 items-end w-[100%]">
            <div class="w-full">
                <label class="text-corBase">Cliente</label>
                <v-select v-model="clienteFilter" :options="lista_clientes" label="text"
                    :reduce="clienteFilter => clienteFilter.id" />
            </div>
            <div>
                <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="mudarPagina(1)">Pesquisar</button>
            </div>
            <div>
                <button @click="$router.push(`/criar-obra`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 whitespace-nowrap">
                    Nova Obra
                </button>
            </div>
        </div> -->

        <!-- Listagem das obras -->
        <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
                <thead class="cabecalho-tabela">
                    <tr class="border-b">
                        <th class="py-2 px-4 text-center">Ações</th>
                        <th class="py-2 px-2 text-center">Status</th>
                        <th class="py-2 px-4 text-center">Vinculação Orçamento</th>
                        <th class="py-2 px-4">Imagem</th>
                        <th class="py-2 px-4">Descrição da Obra</th>
                        <th class="py-2 px-4 text-center">Pessoa</th>
                        <th class="py-2 px-4">Cliente</th>
                        <th class="py-2 px-4">Localização</th>
                        <th class="py-2 px-4"></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="obra in obras" :key="obra.id">
                        <tr class="escopo-tabela">
                            <td class="py-2 px-4 text-xs text-center">
                                <div class="flex justify-center items-center gap-2">
                                    <i class="fas fa-times cursor-pointer" @click.prevent="selecionarObraDeletar(obra)"
                                        title="Excluir Obra"></i>

                                    <i class="fa-solid fa-pen-to-square cursor-pointer"
                                        @click.prevent="this.$router.push(`/editar-obra/${obra.id}`)"
                                        title="Editar Obra"></i>

                                    <i class="fa-solid fa-arrows-rotate cursor-pointer"
                                        @click.prevent="alterarStatus(obra)" title="Alterar Status">
                                    </i>

                                    <!-- <i class="fas fa-paperclip" @click.prevent="this.$router.push(`/obra/${obra.id}`)"
                                        title="Exibir Vãos da Obra"></i> -->
                                </div>

                            </td>
                            <td class="py-2 px-2 border-b text-center">
                                {{ obra.status_obra || '-' }}
                                <p v-if="obra.status_obra == 'Cancelado'" class="text-xs text-gray-500">
                                    {{ obra.motivo_cancelamento || '-' }}
                                </p>
                            </td>
                            <td class="py-2 px-4 border-b text-center">
                                <p class="cursor-pointer text-blue-500" v-if="obra.vinculada_orcamento"
                                    @click.prevent="selecionarObraVinculada(obra.id)">Sim</p>
                                <p v-else>Não</p>
                            </td>
                            <td class="py-2 px-4 border-b">
                                <!-- Verifica se a Obra tem uma imagem -->
                                <img v-if="obra.imagem" :src="obra.imagem" alt="Foto da Obra" class="foto-obra"
                                    @click="openPreviewImage(obra.imagem)" />

                                <!-- Se não houver imagem, exibe uma mensagem alternativa -->
                                <img v-else class="foto-obra"
                                    src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                                    alt="Sem foto">
                            </td>
                            <td class="py-2 px-4 border-b">{{ obra.descricao }}</td>
                            <td class="px-6 py-3 text-center">
                                <div class="flex justify-center items-center">
                                    <i v-if="obra.cliente?.identificacao && obra.cliente.identificacao.length > 14"
                                        class="fa-solid fa-building"></i>
                                    <i v-else class="fa-solid fa-person"></i>
                                </div>
                            </td>
                            <td class="py-2 px-4 border-b">{{ obra.cliente.nome_razao }}</td>
                            <td class="py-2 px-4 border-b">
                                <a :href="obra.localizacao" target="_blank" class="text-blue-600">Localização</a>
                            </td>
                            <td class="py-2 px-4">
                                <button @click.prevent="selecionarObra(obra)" class="text-gray-500"
                                    title="Abrir Projetos do Vão">
                                    <i
                                        :class="obraSelecionada.id === obra.id ? 'fa-solid fa-caret-up' : 'fa-solid fa-caret-down'"></i>
                                </button>
                            </td>
                        </tr>

                        <!-- Linha para obra selecionada -->
                        <tr v-if="obraSelecionada.id === obra.id" class="bg-gray-200">
                            <td colspan="20" class="py-4 px-6">
                                <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
                                    <!-- Informações gerais da obra -->
                                    <div>
                                        <p>
                                            <strong>CNO:</strong> {{ obraSelecionada.cno || '-' }}
                                        </p>

                                        <p>
                                            <strong>Endereço:</strong>
                                            {{ obraSelecionada.endereco }}, nº {{ obraSelecionada.numero }}, {{
                                                obraSelecionada.bairro }},

                                            <span v-if="obraSelecionada.predio_condominio">
                                                {{ obraSelecionada.predio_condominio }},
                                            </span>

                                            <span v-if="obraSelecionada.complemento">
                                                {{ obraSelecionada.complemento }},
                                            </span>


                                            {{ obraSelecionada.cidade }} - {{ obraSelecionada.estado }}
                                        </p>

                                        <p>
                                            <strong>Responsável Técnico:</strong>
                                            {{ obraSelecionada.responsavel_tecnico?.nome_razao || '' }} -
                                            {{ obraSelecionada.contato_responsavel_tecnico || '' }}
                                        </p>

                                        <p>
                                            <strong>Cliente:</strong>
                                            {{ obraSelecionada.cliente.nome_razao }} - {{
                                                obraSelecionada.contato_cliente }}
                                        </p>
                                        <p>
                                            <strong>Responsável da Obra:</strong>
                                            {{ obraSelecionada.responsavel.nome_razao }} - {{
                                                obraSelecionada.contato_responsavel }}
                                        </p>
                                        <p>
                                            <strong>Patrocinador:</strong>
                                            {{ obraSelecionada.patrocinador.nome_razao }} - {{
                                                obraSelecionada.contato_patrocinador }}
                                        </p>
                                    </div>

                                    <!-- Stakeholders -->
                                    <div>
                                        <!-- <p>
                                            <strong>Cores Perfil:</strong>
                                        </p>
                                        <ul class="list-disc pl-6">
                                            <li v-for="(corPerfil, index) in obraSelecionada.lista_cor_perfil"
                                                :key="index">
                                                {{ corPerfil.descricao }}
                                            </li>
                                        </ul>

                                        <p>
                                            <strong>Cores Componente:</strong>
                                        </p>
                                        <ul class="list-disc pl-6">
                                            <li v-for="(corComponente, index) in obraSelecionada.lista_cor_componente"
                                                :key="index">
                                                {{ corComponente.descricao }}
                                            </li>
                                        </ul>

                                        <p>
                                            <strong>Vidros:</strong>
                                        </p>
                                        <ul class="list-disc pl-6">
                                            <li v-for="(corVidro, index) in obraSelecionada.lista_vidro" :key="index">
                                                {{ corVidro.descricao }}
                                            </li>
                                        </ul>

                                        <p>
                                            <strong>Cores Chapa:</strong>
                                        </p>
                                        <ul class="list-disc pl-6">
                                            <li v-for="(corChapa, index) in obraSelecionada.lista_cor_chapa"
                                                :key="index">
                                                {{ corChapa.descricao }}
                                            </li>
                                        </ul> -->

                                        <p>
                                            <strong>Stakeholders:</strong>
                                        </p>
                                        <ul v-if="stakeholders.length > 0" class="list-disc pl-6">
                                            <li v-for="(stakeholder, index) in stakeholders" :key="stakeholder.id">
                                                {{ index + 1 }} - {{ stakeholder.pessoa.nome_razao }} -
                                                {{ stakeholder.responsabilidade.descricao }} -
                                                {{ stakeholder.contato || 'Sem contato' }}
                                            </li>
                                        </ul>
                                        <p v-else>Sem stakeholders definidos.</p>
                                    </div>
                                </div>

                                <!-- Documentos -->
                                <div class="flex flex-wrap gap-4 mt-6 justify-center">
                                    <div v-for="arquivo in documentos" :key="arquivo.id"
                                        class="flex items-center p-4 bg-gray-100 rounded-lg shadow-lg cursor-pointer hover:bg-gray-300 transition duration-300 w-full max-w-xs sm:w-[48%] lg:w-[30%]"
                                        @click="abrirArquivo(arquivo.url_documento)">
                                        <div class="text-3xl mr-4 text-corBase flex-shrink-0">
                                            <i class="fa-solid fa-file-pdf"></i>
                                        </div>
                                        <div class="flex-1 overflow-hidden">
                                            <p class="text-gray-700 font-medium truncate">{{ arquivo.name }}</p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                    </template>
                </tbody>
            </table>
            <!-- Botões da paginação -->
            <Pagination v-if="obras.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
                @change-page="mudarPagina" />
        </div>
        <preview-image v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />
    </div>

    <ModalObrasVinculadas v-if="exibirObrasVinculadas" :obra="obraVinculadaSelecionada"
        @fecharModal="toggleModalObraVinculada()" />
    <ModalAlterarStatus v-if="mostrarAlterarStatus" :obra="obraEditarSelecionada" @fecharModal="toggleAlterarStatus()"
        @atualizarLista="mudarPagina(paginaAtual)" />
    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />
    <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />
    <MensagemConfirmacaoExclusao :abrir="exibirModalExclusao" mensagem="Deseja excluir a obra?"
        @confirmarExclusao="deleteObra(obraDeletarSelecionada)" />
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import PreviewImage from "@/components/midais/ged/PreviewImage";
import MensagemConfirmacaoExclusao from "@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue";
import ModalObrasVinculadas from "./EtapasCriacaoObra/Modais/ModalObrasVinculadas.vue";
import ModalAlterarStatus from "./EtapasCriacaoObra/Modais/ModalAlterarStatus.vue";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import Pagination from "@/utils/Pagination.vue";

export default {
    name: "ListaObras",

    components: {
        vSelect,
        PreviewImage,
        ModalObrasVinculadas,
        MensagemConfirmacaoExclusao,
        ModalAlterarStatus,
        ListagemErros,
        SucessoRapido,
        Pagination
    },

    data() {
        return {

            filtros: [{ campo: "cliente", valor: "" }],
            lista_clientes: [],
            lista_status: ['Sem Visitação Inicial', 'Aguardando Agendamento de Visita Inicial', 'Visita Inicial Agendada', 'Obra Visitada', 'Aguardando Agendamento de Visita Complementar', 'Visita Complementar Agendada', 'Cancelado', 'Finalizado'],
            lista_pessoas: [],
            estados: [],
            cidades: [],
            lista_funcoes_stakeholder: [],
            lista_stakeholders: [],
            status: '',

            obras: [],
            stakeholders: [],
            documentos: [],

            obraSelecionada: [],
            exibirObrasVinculadas: false,
            obraVinculadaSelecionada: [],

            paginaAtual: 1,
            countItens: '',
            clienteFilter: '',
            preview_imagem: null,

            exibirModalExclusao: false,
            obraDeletarSelecionada: null,

            mostrarAlterarStatus: false,
            obraEditarSelecionada: null,

            sucesso: false,
            sucessoMessage: '',
            exibirErros: false,
            erros: [],
        };
    },

    watch: {
        filtros: {
            handler(novosFiltros) {
                const filtroEstado = novosFiltros.find(filtro => filtro.campo === 'uf' && filtro.valor);
                if (filtroEstado) {
                    this.buscarCidades(filtroEstado.valor);
                }

                novosFiltros.forEach(filtro => {
                    if (filtro.campo === 'stakeholder') {
                        if (!filtro.funcaoAnterior) {
                            filtro.funcaoAnterior = filtro.funcao;
                        }

                        if (filtro.funcao !== filtro.funcaoAnterior) {
                            filtro.valor = '';
                            filtro.funcaoAnterior = filtro.funcao;
                            this.carregarPessoasPorFuncao(filtro.funcao);
                        }
                    }
                });

            },
            deep: true,
        }
    },

    created() {
        // this.getObras(1)
        this.getListaClientes()
        this.getListaPessoasFisicaJuridica()
        this.carregarEstados()
        this.getListaFuncoesStakeholder()

    },

    methods: {

        atualizarFiltro(index) {
            this.filtros[index].valor = null;
        },
        adicionarFiltro() {
            this.filtros.push({ campo: "", valor: "" });
        },
        removerFiltro(index) {
            this.filtros.splice(index, 1);
        },

        async getListaClientes() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`pessoa/pessoas-fisica-juridica/?atividade=Cliente&ativo=True&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.lista_clientes = response.data.results.map(person => ({
                    id: person.pessoa.id,
                    text: person.nome || person.razao_social,
                }))
                console.log(this.lista_clientes)
            } catch (error) {
                console.error(error)
            }
        },

        async getListaPessoasFisicaJuridica() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`pessoa/pessoas-fisica-juridica/?atividade=Colaborador&ativo=True&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.lista_pessoas = response.data.results.map(person => ({
                    id: person.pessoa.id,
                    text: person.nome || person.razao_social,
                }))
                console.log(this.lista_clientes)
            } catch (error) {
                console.error(error)
            }
        },

        async carregarEstados() {
            try {
                const token = await this.$store.dispatch("getAuthTokenCookie");
                await this.$store.dispatch("fetchURLrequest");
                const response = await axios.get(`obra/estados/`, {
                    headers: {
                        "Authorization": `Token ${token}`
                    }
                });

                this.estados = response.data.map((estado) => ({
                    sigla: estado.sigla,
                    nome: `${estado.nome} (${estado.sigla})`,
                }))

            } catch (error) {
                console.error("Erro ao carregar estados:", error);
            }
        },

        async buscarCidades(estado) {
            try {
                const token = await this.$store.dispatch("getAuthTokenCookie");
                await this.$store.dispatch("fetchURLrequest");
                const response = await axios.get(`obra/municipios/?estado=${estado}`, {
                    headers: {
                        "Authorization": `Token ${token}`
                    }
                });
                this.cidades = response.data.map((cidade) => ({
                    id: cidade.id,
                    nome: cidade.nome,
                }))

            } catch (error) {
                console.error("Erro ao carregar cidades:", error);
            }
        },

        async getListaFuncoesStakeholder() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get('pessoas/tipo-pessoas/?ativo=True&page_size=9999', {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                })

                this.lista_funcoes_stakeholder = response.data.results.map(responsabilidade => ({
                    id: responsabilidade.id,
                    text: responsabilidade.descricao
                }));
            } catch (error) {
                console.error(error)
            }
        },

        async carregarPessoasPorFuncao(funcao) {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`pessoa/pessoas-fisica-juridica/?funcao=${funcao}&ativo=True&page_size=9999`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });
                // console.log(response.data)
                this.lista_stakeholders = response.data.results.map(person => ({
                    id: person.pessoa.id,
                    text: person.nome || person.razao_social,
                    // telefone: person.pessoa_fisica.telefone_pessoa[0]
                }));
            } catch (error) {
                console.error(error);
            }
        },

        selecionarObra(obra) {
            console.log(this.obraSelecionada == obra)
            if (this.obraSelecionada == obra) {
                this.obraSelecionada = {};
                this.stakeholders = []
            } else {
                this.obraSelecionada = obra;
                console.log(this.obraSelecionada)
                this.getPessoasAlocadas(this.obraSelecionada.id)
                this.getDocumentos(this.obraSelecionada.id)
            }
        },

        async getObras(pagina) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                this.$store.dispatch("setLoading", true);

                const params = this.filtros
                    .filter((filtro) => filtro.campo && filtro.valor)
                    .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
                    .join("&");

                const response = await axios.get(`obra/?${params}&status=${this.status || ''}&page=${pagina}`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });
                this.obras = response.data.results
                this.countItens = response.data.count

            } catch (error) {
                console.error(error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getPessoasAlocadas(obra_id) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`obra/pessoa-alocada/?ativo=True&page_size=9999&obra=${obra_id}`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                })
                this.stakeholders = response.data.results
                // console.log(this.stakeholders)
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getDocumentos(obra_id) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`obra/documentos-obra/?page_size=9999&obra=${obra_id}`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                })
                this.documentos = response.data.results
                console.log(this.documentos)
            } catch (error) {
                console.error(error)
            }
        },

        selecionarObraVinculada(obraId) {
            this.obraVinculadaSelecionada = obraId
            this.toggleModalObraVinculada()
        },

        toggleModalObraVinculada() {
            this.exibirObrasVinculadas = !this.exibirObrasVinculadas
        },

        abrirArquivo(arquivo) {
            window.open(arquivo, '_blank');
        },

        selecionarObraDeletar(obra) {
            if (obra.vinculada_orcamento) {
                this.erros = { deletar_obra: 'Você não pode deletar esta obra pois ela está vinculada a um orçamento.' }
                this.toggleErros()
                return
            }

            this.obraDeletarSelecionada = obra
            this.toggleModalDeletar()
        },

        toggleModalDeletar() {
            this.exibirModalExclusao = !this.exibirModalExclusao
        },

        async deleteObra(obra) {

            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.patch(`obra/obra/${obra.id}/`, { ativo: 'False' }, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                })

                this.sucesso = true
                this.sucessoMessage = 'Obra excluída com sucesso.'
                this.toggleModalDeletar()
                setTimeout(() => {
                    this.sucesso = false;
                    this.sucessoMessage = '';
                    this.mudarPagina(this.paginaAtual)
                }, 3000);
            } catch (error) {
                console.error(error)
            }
        },

        alterarStatus(obra) {
            this.obraEditarSelecionada = { ...obra }
            this.toggleAlterarStatus()
        },

        toggleAlterarStatus() {
            this.mostrarAlterarStatus = !this.mostrarAlterarStatus
        },

        mudarPagina(pagina) {
            this.obraSelecionada = {}
            this.paginaAtual = pagina
            this.getObras(pagina)
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },

        openPreviewImage(url) {
            this.preview_imagem = url
        },

        closed_preview_image() {
            this.preview_imagem = ''
        },
    }
}
</script>

<style scoped>
.container-cards-produtos {
    @apply flex flex-wrap px-3 py-3
}

/*input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}*/

.foto-obra {
    min-width: 50px;
    min-height: 50px;
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
}

.modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-foto {
    max-width: 50%;
    max-height: 50%;
    border-radius: 10px;
}
</style>
