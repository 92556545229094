<template>
    <div class="flex flex-wrap gap-4">

        <!-- Campos à esquerda -->
        <div class="flex flex-col gap-4 w-[60%]">

            <!-- Descrição da Obra -->
            <div class="form-group">
                <label>Descrição da Obra:</label>
                <input v-model="obra.descricao" type="text" id="descricao" placeholder="Digite aqui..."
                    class="input-campos w-full" />
            </div>

            <!-- CNO e Status (lado a lado) -->
            <div class="flex space-x-4">

                <div class="w-1/2 form-group">
                    <label>CNO:</label>
                    <input v-model="obra.cno" type="text" id="cno" placeholder="Digite aqui..."
                        class="input-campos w-full" />
                </div>

                <div class="w-1/2 form-group">
                    <label>Status:</label>
                    <v-select v-model="obra.status_obra" :options="lista_status" placeholder="Selecione o status"
                        class="w-full" />
                </div>

                <div class="w-1/2 form-group" v-if="obra.status_obra === 'Cancelado'">
                    <span class="font-semibold">Motivo do Cancelamento:</span>
                    <v-select :options="lista_motivos" v-model="obra.motivo_cancelamento"
                        placeholder="Escolha uma opção" />
                </div>

            </div>

            <!-- Responsável Técnico e Contato (lado a lado) -->
            <div class="flex space-x-4">

                <div class="w-1/2 form-group">
                    <label>Responsável Técnico:</label>
                    <v-select v-model="obra.responsavel_tecnico" :options="listaPessoas" label="nome_razao"
                        class="w-full" />
                </div>

                <div class="w-1/2 form-group">
                    <label>Contato Responsável Técnico:</label>
                    <input v-model="obra.contato_responsavel_tecnico" type="text" maxlength="15"
                        v-mask="'(##) #####-####'" class="input-campos w-full" />
                </div>

            </div>

        </div>

        <!-- Imagem à direita -->
        <div class="w-[35%] flex flex-col items-center justify-start">
            <div class="form-group w-full">

                <!-- Campo para capturar com a câmera -->
                <input type="file" id="cameraInput" ref="cameraInput" accept="image/*" capture="environment"
                    class="hidden" @change="processarImagem" />

                <!-- Campo para selecionar imagem da galeria -->
                <input type="file" id="galeriaInput" ref="galeriaInput" accept="image/*" class="hidden"
                    @change="processarImagem" />

                <!-- Título -->
                <label class="text-corBase text-xl font-bold">Imagem:</label>

                <!-- Pré-visualização da imagem -->
                <div @click.prevent="openPreviewImage(obra.imagem)" class="previewImagemObra"
                    :style="{ 'background-image': `url(${obra.imagem})` }">
                </div>

                <!-- Botões para câmera ou galeria -->
                <div class="flex gap-3 mt-4">
                    <div class="selecionar-arquivo flex justify-center">
                        <button @click.prevent="abrirCamera">
                            <i class="fa-solid fa-camera fa-xl"></i>
                            <p>Capturar com a Câmera</p>
                        </button>
                    </div>
                    <div class="selecionar-arquivo flex justify-center">
                        <button @click.prevent="abrirGaleria">
                            <i class="fa-solid fa-photo-film fa-xl"></i>
                            <p>Selecionar da Galeria</p>
                        </button>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <!-- Componentes adicionais -->
    <Galeria v-if="galeria" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput" />
    <PreviewImage v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />
    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />

</template>


<script>
import axios from 'axios'
import vSelect from "vue-select";
import Galeria from '@/components/midais/ged/Galeria.vue';
import PreviewImage from "@/components/midais/ged/PreviewImage.vue";
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';

export default {
    name: 'Obra',
    components: {
        vSelect,
        Galeria,
        PreviewImage,
        ListagemErros,
    },
    props: ['obra'],
    data() {
        return {
            lista_status: ['Sem Visitação Inicial', 'Aguardando Agendamento de Visita Inicial'],
            listaPessoas: [],
            lista_motivos: ['Cliente comprou com a concorrência', 'Desistência do Cliente', 'Decisão Empresarial'],

            // medidores: [],
            // novoMedidor: {
            //     pessoa: null,
            //     contato: '',
            // },

            exibirErros: false,
            erros: [],

            galeria: false,
            preview_imagem: null,
        };
    },

    watch: {
        'obra.responsavel_tecnico': {
            async handler(newPessoa) {
                if (newPessoa?.id) { // Só busca se o contato estiver vazio
                    try {
                        const telefone = await this.buscarTelefonePessoa(newPessoa.id);
                        if (telefone) {
                            this.obra.contato_responsavel_tecnico = `(${telefone.ddd}) ${telefone.telefone}`;
                        } else {
                            this.obra.contato_responsavel_tecnico = '';
                        }
                    } catch (error) {
                        console.error(`Erro ao buscar telefone para pessoa com ID ${newPessoa.id}:`, error);
                    }
                }
            },
            immediate: true,
            deep: true,
        },

        // 'novoMedidor.pessoa': {
        //     immediate: true,
        //     async handler(newPessoa) {
        //         if (newPessoa?.id) {
        //             try {
        //                 const telefone = await this.buscarTelefonePessoa(newPessoa.id);
        //                 if (telefone) {
        //                     this.novoMedidor.contato = `(${telefone.ddd}) ${telefone.telefone}`;
        //                 } else {
        //                     this.novoMedidor.contato = '';
        //                     this.erros = { medidor: ['Este medidor não possui telefone cadastrado.'] };
        //                     this.toggleErros();
        //                 }
        //             } catch (error) {
        //                 console.error(`Erro ao buscar telefone para pessoa com ID ${newPessoa.id}:`, error);
        //             }
        //         } else {
        //             this.novoMedidor.contato = ''; // Limpa o contato caso a pessoa seja desmarcada
        //         }
        //     },
        // },
    },
    created() {
        this.getListaPessoas()
        this.getStatus(this.obra.status_obra)
    },
    methods: {

        async getListaPessoas() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get('pessoa/pessoas-fisica-juridica/?atividade=Colaborador&ativo=True&page_size=9999', {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });
                // console.log(response.data)
                this.listaPessoas = response.data.results.map(person => ({
                    id: person.pessoa.id,
                    nome_razao: person.nome || person.razao_social,
                    // telefone: person.pessoa_fisica.telefone_pessoa[0]
                }));
            } catch (error) {
                console.error(error);
            }
        },

        async getStatus(status_atual) {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get(`obra/status-obra/?obra_id=${this.obra.id}&status_atual=${status_atual}`, {
                    headers: { Authorization: `Token ${token}` }
                })

                this.lista_status = response.data.status_permitidos
            } catch (error) {
                console.error(error)
            }
        },

        // adicionarMedidor() {
        //     if (this.novoMedidor.pessoa && this.novoMedidor.contato) {
        //         const medidorExistente = this.obra.medidores.some(
        //             medidor => medidor.pessoa?.id === this.novoMedidor.pessoa.id || medidor.medidor?.id === this.novoMedidor.pessoa.id
        //         );

        //         if (medidorExistente) {
        //             this.erros = { medidor: ["Este medidor já foi adicionado."] };
        //             this.toggleErros();
        //         } else {
        //             this.obra.medidores.push({ ...this.novoMedidor });
        //             this.novoMedidor = { pessoa: null, contato: '' }; // Resetar o formulário
        //         }
        //     } else {
        //         this.erros = { medidor: ["Preencha os campos para adicionar."] };
        //         this.toggleErros();
        //     }
        // },

        // removerMedidor(index) {
        //     this.obra.medidores.splice(index, 1); // Remove o medidor pelo índice
        // },

        toggleErros() {
            this.exibirErros = !this.exibirErros;
        },

        async buscarTelefonePessoa(idPessoa) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`pessoas/telefones/?pessoa=${idPessoa}`, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                return response.data.results[0];
            } catch (error) {
                console.error(error);
            }
        },


        // Funções relacionadas a imagem do bucket

        abrirCamera() {
            this.$refs.cameraInput.click();
        },
        abrirGaleria() {
            this.$refs.galeriaInput.click();
        },
        abrir_galeria() {
            this.galeria = !this.galeria
        },

        openPreviewImage(url) {
            this.preview_imagem = url
        },

        closed_preview_image() {
            this.preview_imagem = ''
        },

        atualizarFileInput(file) {
            this.obra.imagem = file;
        },

        // abrirCamera() {
        //     this.$refs.imagemInput.click();
        // },

        processarImagem(event) {
            const file = event.target.files[0];
            if (file) {
                // Armazena o arquivo real para envio posterior
                this.obra.imagemFile = file;

                // Cria uma URL temporária para exibição
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.obra.imagem = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },

    }
};
</script>

<style scoped>
.etapas-navegacao {
    display: flex;
    justify-content: center;
    overflow-x: auto;
}

.etapas-navegacao button {
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    margin: 20px;
}

.etapas-navegacao .ativo {
    background-color: #1f2937;
    color: white;
}

.previewImagemObra {
    @apply mt-2 block h-[250px] w-full cursor-pointer border border-corBase bg-white bg-contain bg-center bg-no-repeat
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input {
    width: 100%;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
</style>