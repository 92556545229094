<template>
    <div class="fundo-midal">
        <div class="midal">

            <div class="secao-icone-fechar">
                <router-link to="/lista-orcamentos" class="fa-solid fa-xmark icone-fechar"></router-link>
            </div>

            <div class="secao-mensagem-sucesso-midal">
                <p class="mensagem-sucesso-midal">Orçamento #{{ novoOrcamento.codigo }} criado!</p>
                <div class="fundo-icone-deu-certo"><i class="fa-solid fa-check icone-deu-certo"></i></div>
            </div>

            <div class="secao-botao">
                <router-link class="px-4 py-1 cursor-pointer text-white bg-corBase border hover:bg-screenCor hover:text-corBase hover:border-corBase rounded-lg" to="/lista-orcamentos">
                    Voltar
                </router-link>
                <button class="px-4 py-1 cursor-pointer text-white bg-ativo border hover:bg-screenCor hover:text-ativo hover:border-ativo rounded-lg" @click.prevent="navegarParaOrcamento">
                    Adicionar Produtos
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MensagemSucessoOrcamento",

    data() {
        return {
        }
    },

    props: ['novoOrcamento'],

    created() {
        this.midal = true
    },

    methods: {
        navegarParaOrcamento() {
            this.$router.push(`/orcamento/${this.novoOrcamento.id}?listaVao=true`);
        }
    }
}
</script>

<style scoped>
.fundo-midal {
    @apply absolute flex justify-center items-center bg-black bg-opacity-30 left-0 top-0 w-screen h-screen z-[1000]
}

.midal {
    @apply absolute z-[1000] flex flex-col justify-between items-center bg-white w-72 h-96 py-6 px-4 rounded-xl shadow-xl
}

.secao-icone-fechar {
    @apply w-full flex justify-end
}

.icone-fechar {
    @apply text-corBase text-2xl cursor-pointer
}

.secao-mensagem-sucesso-midal {
    @apply flex flex-col items-center
}

.mensagem-sucesso-midal {
    @apply flex flex-col items-center text-corBase font-bold text-center text-lg
}

.fundo-icone-deu-certo {
    @apply flex justify-center items-center mt-8 bg-ativo w-14 h-14 rounded-full
}

.icone-deu-certo {
    @apply text-white text-3xl
}

.secao-botao {
    @apply w-full flex items-center gap-2
}

.botao-midal {
    @apply flex justify-center items-center w-full h-8 text-sm font-medium rounded-lg
}

.botao-midal-voltar {
    @apply bg-corBase text-white
}

.botao-midal-continuar {
    @apply bg-ativo text-white
}
</style>