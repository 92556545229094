<template>

  <div class="container-formulario">

    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Criar Projeto:</h1>

      <router-link to="/lista-projetos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarProjeto" enctype="multipart/form-data">
      <div class="w-full mx-auto mt-8">
        <div class="space-y-4">

          <!-- Seção 1 - Dados do Projeto -->
          <div>
            <!-- <button @click.prevent="toggleSection(1)" class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
              Dados do Projeto
            </button> -->

            <div class="p-4 flex flex-col border border-corBase/60">

              <div class="flex gap-5 pb-4">

                <div class="w-full">
                  <label class="font-bold text-corBase">Sistemista</label>
                  <v-select v-model="sistemista" :options="lista_sistemistas" label="text"
                    :reduce="(option) => option.id" class="bg-white">
                  </v-select>
                </div>

                <div class="w-full">
                  <label class="font-bold text-corBase">Linha/Sistema</label>
                  <v-select v-model="linha" :options="lista_linhas" label="text" :reduce="(option) => option.id"
                    class="input-disabled bg-white" :disabled="!sistemista">
                  </v-select>
                </div>

                <div class="w-full">
                  <label class="font-bold text-corBase">Modelo</label>
                  <v-select v-model="modelo" :options="lista_modelos" label="text" :reduce="(option) => option.id"
                    class="bg-white" :disabled="!linha">
                  </v-select>
                </div>

              </div>


              <div class="flex gap-5 pb-4">
                <!-- Tipologia - Ocupa 2/3 da linha -->
                <div class="w-2/3">
                  <label class="font-bold text-corBase">Tipologia</label>
                  <v-select v-model="tipologia" :options="lista_tipologias" label="text" :reduce="(option) => option.id"
                    class="input-disabled bg-white" :disabled="!modelo"></v-select>
                </div>

                <!-- Status e Privado/Público - Dividem o 1/3 restante -->
                <div class="w-1/3 flex gap-5">
                  <!-- Status -->
                  <div class="w-2/3">
                    <label class="font-bold text-corBase">Status</label>
                    <v-select v-model="status_projeto" class="w-full z-15 bg-white" :options="lista_status"
                      :settings="{ width: '100%' }" label="text"></v-select>
                  </div>

                  <div class="w-1/3 text-center flex flex-col justify-center items-center">
                    <label class="font-bold text-corBase cursor-pointer" @click.prevent="togglePrivado()">
                      <div class="flex flex-col items-center px-2 py-1">
                        <!-- Ícone do cadeado -->
                        <i :class="`fas ${privado ? 'fa-lock' : 'fa-unlock'} fa-xl text-corBase`"></i>
                        <!-- Texto abaixo do ícone -->
                        <p class="mt-2">{{ privado ? 'Privado' : 'Público' }}</p>
                      </div>
                    </label>
                  </div>

                </div>
              </div>



              <div class="flex gap-5 pb-4">

                <div class="w-1/3">
                  <label class="font-bold text-corBase">Cod. Projeto</label>
                  <input type="text" class="input-campos" :placeholder="valorPadrao" @input="cod_projeto = forcarPrefixo($event.target.value)"
                    v-model="cod_projeto">
                </div>

                <div class="w-2/3">
                  <label class="font-bold text-corBase">Descrição</label>
                  <input type="text" class="input-campos" v-model="descricao_fixa">
                </div>

                <div class="w-1/4">
                  <label class="font-bold text-corBase">Unidade</label>
                  <v-select v-model="unidade" class="w-full z-15 bg-white" :options="lista_unidades_medida"
                    :settings="{ width: '100%' }" label="text"></v-select>
                </div>

              </div>

              <div class="flex gap-5 pb-4">

                <div class="w-full">
                  <label class="font-bold text-corBase">Descrição Detalhada</label>
                  <input type="text" maxlength="90" class="input-campos" v-model="descricao_variavel">
                </div>

              </div>

            </div>
          </div>

          <!-- Seção 2 - Configurações -->
          <!-- <div>
            <button @click.prevent="toggleSection(2)" class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
              Configurações
            </button>
            <div v-if="activeSection === 2" class="p-4 flex flex-col border border-corBase/60"> -->
          <!-- Campos de cadastro para a segunda seção -->
          <!-- <div class="flex gap-5 pb-4">
                <div class="w-full">
                  <label class="font-bold text-corBase">Unidade</label>
                  <input type="text" class="input-campos" v-model="unidade" required>
                </div>

                <div class="w-full">
                  <label class="font-bold text-corBase">NCM</label>
                  <input type="text" class="input-campos" v-model="ncm" required>
                </div>

                <div class="w-full">
                  <label class="font-bold text-corBase flex" @click.prevent="togglePrivado()">
                    <p>Privado?</p>
                    <div class="flex items-center justify-center px-2 py-1 cursor-pointer">
                      <i :class="`fas ${privado ? 'fa-lock' : 'fa-unlock'} fa-xl text-corBase`"></i>
                    </div>
                  </label>
                  <p>{{ privado ? 'Somente sua empresa pode ver' : 'Todos podem ver' }}</p>
                </div>

              </div> -->

          <!-- <div class="flex gap-5 pb-4"> -->
          <!-- <div>
                  <labe class="text-corBase">KIT</labe>
                  <input type="text" class="input-campos" v-model="kit" required>
                </div> -->
          <!-- <div class="w-full">
                  <label class="text-corBase">Grupo Cor de Vidros/Chapas</label>
                  <v-select v-model="grupo_cor_vidros_chapas" class="z-10" :options="lista_grupo_cor_vidros_chapas"
                    :settings="{ width: '100%' }" label="text" />
                </div>

                <div class="w-full">
                  <label class="text-corBase">Grupo Cor de Perfis</label>
                  <v-select v-model="grupo_cor_perfis" class="z-10" :options="lista_grupo_cor_perfis"
                    :settings="{ width: '100%' }" label="text" />
                </div>

                <div class="w-full">
                  <label class="text-corBase">Grupo Cor de Componentes</label>
                  <v-select v-model="grupo_cor_componentes" class="z-10" :options="lista_grupo_cor_componentes"
                    :settings="{ width: '100%' }" label="text" />
                </div>

              </div> -->

          <!-- <div class="flex gap-5 pb-4">
                <div class="w-full">
                  <label class="text-corBase">CFOP Saída</label>
                  <input type="text" class="input-campos" v-model="cfop_saida" required>
                </div>

                <div class="w-full">
                  <label class="text-corBase">CFOP Fora UF</label>
                  <input type="text" class="input-campos" v-model="cfop_fora_uf" required>
                </div>

              </div>

            </div>
          </div> -->

          <!-- Seção 3 - Esquadria -->
          <!-- <div>
            <button @click.prevent="toggleSection(3)" class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
              Esquadria
            </button>
            <div v-if="activeSection === 3" class="p-4 flex flex-col border border-corBase/60"> -->
          <!-- Campos de cadastro para a terceira seção -->
          <!-- <div class="flex gap-5 pb-4">

                <div class="w-full">
                  <label class="text-corBase">Tempo de Produção</label>
                  <input type="text" class="input-campos" v-model="temp_producao">
                </div>

                <div class="w-full">
                  <label class="text-corBase">Tempo de Instalação</label>
                  <input type="text" class="input-campos" v-model="temp_instalacao">
                </div>

              </div>
            </div>
          </div> -->

          <!-- Seção 4 - Normatização -->
          <!-- <div>
            <button @click.prevent="toggleSection(4)" class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
              Normatização
            </button>
            <div v-if="activeSection === 4" class="p-4 flex flex-col border border-corBase/60"> -->
          <!-- Campos de cadastro para a quarta seção -->
          <!-- <div class="flex gap-5 pb-4">

                <div class="w-full">
                  <label class="font-bold text-corBase">Altura Mínima</label>
                  <input type="text" class="input-campos" v-model="altura_minima" required>
                </div>

                <div class="w-full">
                  <label class="font-bold text-corBase">Altura Máxima</label>
                  <input type="text" class="input-campos" v-model="altura_maxima" required>
                </div>

                <div class="w-full">
                  <label class="font-bold text-corBase">Largura Mínima</label>
                  <input type="text" class="input-campos" v-model="largura_minima" required>
                </div>

                <div class="w-full">
                  <label class="font-bold text-corBase">Largura Máxima</label>
                  <input type="text" class="input-campos" v-model="largura_maxima" required>
                </div>

              </div>

              <div class="flex gap-5 pb-4">

                <div class="w-full">
                  <label class="text-corBase">Lista de Espessuras Permitidas (EX.: 4,6,8,10)</label>
                  <v-select v-model="espessuras" :options="lista_espessuras" label="text" multiple
                    class="w-full z-10 bg-white" />

                </div>
              </div>

            </div> 
          </div> -->

          <!-- Botao para salvar -->
          <div class="flex w-full gap-8 justify-end">
            <router-link to="/lista-projetos"
              class="mt-2 border bg-red-600 text-white py-2 px-4 hover:bg-screenCor hover:text-red-600 hover:border-red-600 ">Cancelar</router-link>
            <button class="btn-enviar" type="submit">Salvar</button>
          </div>
        </div>
      </div>
    </form>

  </div>


  <!-- <continuar-criar-projeto v-if="continuar == true" :projeto="projeto_id" /> -->
  <listagem-erros v-if="exibirErros === true" :erros="erros" @fecharModal="toggleErros()" />
  <sucesso-rapido v-if="sucesso === true" mensagem="Projeto criado com sucesso!" />

</template>


<script>
// import ContinuarCriarProjeto from "@/components/toasts/toast_sucesso/ContinuarCriarProjeto";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import SucessoRapido from "../toasts/toast_sucesso/SucessoRapido.vue";
import axios from "axios";
import Search from "@/utils/Search.vue";
import vSelect from "vue-select";

export default {
  name: 'CadastrosProjetos',

  components: {
    // ContinuarCriarProjeto,
    SucessoRapido,
    ListagemErros,
    Search,
    vSelect
  },

  data() {
    return {
      projeto_id: '',
      // variaveis dos formularios
      id_tenant: '',
      valorPadrao: '',
      privado: false,
      sistemista: '',
      tipologia: '',
      modelo: '',
      linha: '',
      cod_projeto: '',
      descricao_fixa: '',
      descricao_variavel: '',
      unidade: '',
      ncm: '',
      lib_venda: true,
      grupo_cor_vidros_chapas: '',
      grupo_cor_perfis: '',
      grupo_cor_componentes: '',
      cfop_saida: '',
      cfop_fora_uf: '',
      temp_producao: '',
      temp_instalacao: '',
      status_projeto: '',
      perfis: [],
      vidros: [],
      servicos: [],
      componentes: [],
      altura_minima: '',
      altura_maxima: '',
      largura_minima: '',
      largura_maxima: '',
      espessuras: [],

      opcoes: [],

      // viaveis para lista
      lista_sistemistas: [],
      lista_tipologias: [],
      lista_modelos: [],
      lista_status: [],
      lista_espessuras: [],
      lista_linhas: [],
      lista_unidades_medida: [],
      lista_grupo_cor_vidros_chapas: [],
      lista_grupo_cor_componentes: [],
      lista_grupo_cor_perfis: [],

      // variaveis para manipulaçao de elementos
      // activeSection: 1,
      // continuar: false,
      sucesso: false,

      sessoesAtivas: [],

      exibirErros: false,
      erros: {}
    }
  },

  created() {
    this.getListaSistemistas()
    this.getListaModelos()
    this.getListaStatus()
    this.getListaEspessuras()
    this.getListaCorPerfis()
    this.getListaCorComponentes()
    this.getListaCorVidros()
    this.getListaUnidadeMedida()
    this.getTenantId()
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  watch: {
    sistemista(newValue) {
      if (newValue) {
        this.getListaLinhas(newValue)
        this.linha = ''
      }

      if (!newValue) {
        this.linha = ''
        this.lista_linhas = []
      }
    },

    'modelo': {
      handler(novoModelo) {
        if (novoModelo) {
          this.getListaTipologia(novoModelo);
          this.tipologia = ''
        }
      },
      immediate: false
    },

    status_projeto(newValue) {
      if (newValue.text === 'Desenvolvimento' || newValue.text === 'Manutenção') {
        this.privado = true
      }
    }
  },

  methods: {

    togglePrivado() {
      if (this.status_projeto.text === 'Desenvolvimento' || this.status_projeto.text === 'Manutenção') {
        this.privado = true
      } else {
        this.privado = !this.privado
      }
    },

    // continuar_cadastro() {
    //   this.continuar = !this.continuar
    //   console.log(this.continuar)
    // },

    toggleSection(sectionNumber) {
      // Alterna a exibição da seção com base no número do acordeão clicado
      this.activeSection = this.activeSection === sectionNumber ? null : sectionNumber;
    },

    abrirSessao(sessao) {
      this.sessoesAtivas = []
      // this.$emit('fecharTodasAbas')
      this.fecharOpcoesFilhos()
      this.sessoesAtivas.push(sessao)
    },

    abrirSessaoDireto(sessao) {
      this.sessoesAtivas = []
      // this.$emit('fecharTodasAbas')
      this.fecharOpcoesFilhos()
      this.sessoesAtivas.push(sessao)
      // this.emitEvent(sessao)
    },

    fecharOpcoesFilhos() {
      Object.keys(this.opcoes).forEach((key) => {
        this.opcoes[key] = false;
      });
    },

    async getTenantId() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const tenant = window.location.hostname.split('.')[0]
      console.log('tenant')
      console.log(tenant)
      console.log('-----------')

      await axios.get(`tenant/client/?nome=${tenant}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.id_tenant = response.data.results[0]?.id
          this.valorPadrao = this.id_tenant ? `${this.id_tenant}-` : '0-'
          // console.log(response.data.results[0].id)
        })
        .catch(error => {
          console.error(error)
        })
    },

    forcarPrefixo(element) {
      const prefixo = this.valorPadrao;
      console.log('Antes:', element);

      // Se não começa com o prefixo, adiciona e converte tudo para maiúsculas
      if (!element.startsWith(prefixo)) {
        element = (prefixo + element).toUpperCase();
      } else {
        element = element.toUpperCase();
      }

      console.log('Depois:', element);
      return element
    },

    async cadastrarProjeto() {

      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      // console.log('id tenant ' + this.id_tenant)

      const data = {
        gestor: this.id_tenant,
        codigo: this.cod_projeto,
        descricao_fixa: this.descricao_fixa,
        descricao_detalhada: this.descricao_variavel,
        ncm: this.ncm,
        producao: this.producao,
        instalacao: this.instalacao,
        tipologia: this.tipologia,
        modelo_variacao: this.modelo,
        status_projeto: this.status_projeto?.id || '',
        privado: this.privado,
        linha_sistema: this.linha,
        sistemista: this.sistemista,
        unidade: this.unidade?.id || '',
        cfop_saida: this.cfop_saida,
        cfop_fora_uf: this.cfop_fora_uf,
        altura_minima: this.altura_minima,
        altura_maxima: this.altura_maxima,
        largura_minima: this.largura_minima,
        largura_maxima: this.largura_maxima,
        espessuras_permitidas: this.espessuras,
        perfil: this.perfis.map((perfil) => perfil.id),
        vidro: this.vidros.map((vidro) => vidro.id),
        servico: this.servicos.map((servico) => servico.id),
        componente: this.componentes.map((componente) => componente.id)

      }
      this.$store.dispatch("setLoading", true);

      console.log(data)


      await axios.post('projeto/projeto/', data, {
        headers: {
          'Authorization': `Token ${token}`,
        }
      })
        .then(response => {
          console.log(response.data)
          this.projeto_id = response.data.id
          // this.continuar_cadastro()
          this.$store.dispatch("setLoading", false);
          this.sucesso = true
          setTimeout(() => {
            this.sucesso = false;
            this.$router.push(`/alterar-projeto/${this.projeto_id}`);
          }, 3000);
        })
        .catch(error => {
          console.error(error)
          if (error.response && error.response.data) {
            if (typeof error.response.data === 'string' && error.response.data.includes('<!DOCTYPE html>')) {
              this.erros = {
                geral: ["Já existe um projeto com este Sistemista, Linha/Sistema, Modelo e Tipologia."],
              };
            } else if (Array.isArray(error.response.data)) {
              this.erros = { detalhes: error.response.data };
            } else if (typeof error.response.data === 'object') {
              this.erros = error.response.data;
            } else {
              this.erros = { geral: ["Erro desconhecido ao salvar o projeto."] };
            }
          } else {
            this.erros = { geral: ["Erro desconhecido ao salvar o projeto."] };
          }
          this.toggleErros();
          this.$store.dispatch("setLoading", false);
        })
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros
    },

    async getListaSistemistas() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/projetista-sistemista/?page_size=999', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_sistemistas = response.data.results.map(item => ({
            id: item.id,
            text: item.nome
          }))
        })
        .catch(error => {
          console.error(error)
        })

    },

    async getListaModelos() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/modelo-variacao/?page_size=999', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_modelos = response.data.results.map(item => ({
            id: item.id,
            text: item.descricao
          }));

          console.log('MODELOS VARIAÇÃO')
          console.log(this.lista_modelos)
          console.log('-------------------')
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getListaUnidadeMedida() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/unidade-medida/?page_size=999', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          console.log(response.data.results)
          this.lista_unidades_medida = response.data.results.map(item => ({
            id: item.id,
            text: item.nome
          }))

          const defaultUnidade = this.lista_unidades_medida.find(item => item.text === 'Unidade');
          console.log(defaultUnidade)
          this.unidade = defaultUnidade ? defaultUnidade : null
        })
        .catch(error => {
          console.error(error)
        })

    },

    async getListaCorPerfis() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/grupo-cor-perfil/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_grupo_cor_perfis = response.data.results.map(item => ({
            id: item.id,
            text: item.nome
          }));
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getListaCorComponentes() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/grupo-cor-componente/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_grupo_cor_componentes = response.data.results.map(item => ({
            id: item.id,
            text: item.nome
          }));
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getListaCorChapas() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/grupo-cor-chapa/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          const result = response.data.results.map(item => ({
            id: item.id,
            text: `${item.nome} - CHAPA`
          }));

          this.lista_grupo_cor_vidros_chapas = [...result, ...this.lista_grupo_cor_vidros_chapas]
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getListaCorVidros() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/grupo-cor-vidro/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          const result = response.data.results.map(item => ({
            id: item.id,
            text: `${item.nome} - VIDRO`
          }));

          this.lista_grupo_cor_vidros_chapas = [...result, ...this.lista_grupo_cor_vidros_chapas]
          this.getListaCorChapas()
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getListaTipologia(modelo) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/tipologia/?modelo=${modelo}`, {
        headers: {
          Authorization: `Token ${token}`
        }
      })
        .then(response => {
          this.lista_tipologias = response.data.results.map(item => ({
            id: item.id,
            text: item.nome
          }));
        })
        .catch(error => {
          console.error(error)
        })

    },

    async getListaLinhas(sistemistaId) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/linha-sistema/?projetista_sistemista_id=${sistemistaId}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_linhas = response.data.results.map(item => ({
            id: item.id,
            text: item.linha
          }));
        })
        .catch(error => {
          console.error(error)
        })
    },


    async getListaStatus() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/status-projeto', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_status = response.data.results.map(item => ({
            id: item.id,
            text: item.descricao
          }));

          const statusDesenvolvimento = this.lista_status.find(item => item.text === 'Desenvolvimento');
          console.log(statusDesenvolvimento)
          this.status_projeto = statusDesenvolvimento ? statusDesenvolvimento : null
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getListaEspessuras() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/espessura', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_espessuras = response.data.results.map(item => ({
            id: item.id,
            text: item.espessura
          }));
        })
        .catch(error => {
          console.error(error)
        })
    },
  }

}

</script>


<style lang="scss">
.input-disabled {
  position: relative;
  transition: opacity 0.3s ease, cursor 0.3s ease;

  &:disabled {
    opacity: 0.6; // Deixa o botão opaco
    cursor: not-allowed; // Muda o cursor para bloqueado

    // Estilização para a mensagem flutuante
    &::after {
      content: 'Selecione um sistemista'; // Mensagem flutuante
      position: absolute;
      top: -35px; // Posição acima do botão
      left: -10%;
      transform: translateX(-50%);
      background-color: rgba(0, 0, 0, 0.75); // Fundo da mensagem
      color: white; // Cor da mensagem
      padding: 5px 10px; // Espaçamento interno
      border-radius: 4px; // Bordas arredondadas
      font-size: 12px; // Tamanho da fonte
      white-space: nowrap;
      opacity: 0; // Inicialmente invisível
      transition: opacity 0.3s ease;
      pointer-events: none; // Previne que a mensagem receba cliques
    }

    &:hover::after {
      opacity: 1; // Torna a mensagem visível ao passar o mouse
    }
  }
}
</style>
