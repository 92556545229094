<template>
    <div>
        <div class="mb-5">
            <p class="text-lg font-semibold">Ativar Gestor para próprio Sistema:</p>
            <select v-model="ativar_gestor_proprio" @change="ativarGestor()" class="border p-2 rounded w-full">
                <option v-for="option in lista_ativar" :key="option.value" :value="option.value">
                    {{ option.label }}
                </option>
            </select>
        </div>

        <div>
            <p class="text-lg font-semibold">Ativar Gestor para Integração:</p>
            <select v-model="ativar_gestor_integracao" @change="ativarGestorIntegracao()"
                class="border p-2 rounded w-full">
                <option v-for="option in lista_ativar" :key="option.value" :value="option.value">
                    {{ option.label }}
                </option>
            </select>
        </div>
    </div>

    <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />
</template>

<script>
import axios from 'axios'
import SucessoRapido from '../toasts/toast_sucesso/SucessoRapido.vue';

export default {
    name: 'SessaoGestor',

    emits: ['proprioGestorHabilitado', 'gestorIntegracaoHabilitado'],

    components: {
        SucessoRapido
    },

    data() {
        return {
            lista_ativar: [
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
            ],

            ativar_gestor_proprio: false,
            ativar_gestor_integracao: false,

            gestorHabilitado: [],
            tenant: { id: null, nome: "" },

            sucesso: false,
            sucessoMessage: '',
        }
    },

    created() {
        this.getTenantId();
    },

    methods: {
        async getTenantId() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                // this.$store.dispatch("setLoading", true);

                const hostname = window.location.hostname;
                const partes = hostname.split(".");
                const tenant_name = partes.length >= 2 ? partes[0] : null;

                if (tenant_name === "admin") {
                    this.tenant = { id: 0, nome: "ManagerGlass", habilitado_integracao: true };
                    this.ativar_gestor_integracao = this.tenant.habilitado_integracao
                    this.$emit('gestorIntegracaoHabilitado', this.ativar_gestor_integracao);
                } else {
                    const response = await axios.get(`tenant/client/?nome=${tenant_name}`, {
                        headers: { 'Authorization': `Token ${token}` }
                    });

                    if (response.data.results.length > 0) {
                        this.tenant = response.data.results[0];
                        this.ativar_gestor_integracao = response.data.results[0].habilitado_integracao
                        this.$emit('gestorIntegracaoHabilitado', this.ativar_gestor_integracao);
                    }
                }
                await this.proprioGestorHabilitado();
            } catch (error) {
                console.error("Erro ao buscar tenant:", error);
            } finally {
                // this.$store.dispatch("setLoading", false);
            }
        },

        async proprioGestorHabilitado() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                // this.$store.dispatch("setLoading", true);

                let response; // Definição da variável antes do bloco condicional

                if (this.tenant.id === 0) {
                    response = await axios.get(`projeto/gestor-habilitado/?cliente=0`, {
                        headers: { 'Authorization': `Token ${token}` }
                    });
                } else {
                    response = await axios.get(`projeto/gestor-habilitado/?client=${this.tenant.id}`, {
                        headers: { 'Authorization': `Token ${token}` }
                    });
                }

                // console.log(response.data.results);

                if (response.data.count > 0) {
                    this.gestorHabilitado = response.data.results[0];
                    this.ativar_gestor_proprio = response.data.results[0].ativo;
                    this.$emit('proprioGestorHabilitado', this.ativar_gestor_proprio);
                } else {
                    await this.criarGestorHabilitado();
                }
            } catch (error) {
                console.error("Erro ao verificar gestor habilitado:", error);
            } finally {
                // this.$store.dispatch("setLoading", false);
            }
        },

        async criarGestorHabilitado() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');

                const response = await axios.post(`projeto/gestor-habilitado/`, {
                    gestor: this.tenant.id === 0 ? null : this.tenant.id,
                    ativo: this.ativar_gestor_proprio
                }, {
                    headers: { 'Authorization': `Token ${token}` }
                });

                this.gestorHabilitado = response.data;
            } catch (error) {
                console.error("Erro ao criar gestor habilitado:", error);
            }
        },

        async ativarGestor() {
            if (!this.gestorHabilitado) return;

            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');

                await axios.patch(`projeto/gestor-habilitado/${this.gestorHabilitado.id}/`, {
                    ativo: this.ativar_gestor_proprio
                }, {
                    headers: { 'Authorization': `Token ${token}` }
                });

                await this.proprioGestorHabilitado();
                this.sucessoMessage = 'Gestor atualizado com sucesso.';
                this.sucesso = true;
                setTimeout(() => { this.sucesso = false; }, 3000);
            } catch (error) {
                console.error("Erro ao ativar gestor:", error);
            }
        },

        async ativarGestorIntegracao() {

            try {

                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.patch(`tenant/client/${this.tenant.id}`, {
                    habilitado_integracao: this.ativar_gestor_integracao
                },
                    { headers: { 'Authorization': `Token ${token}` } }
                )

                // console.log(response)
                this.$emit('gestorIntegracaoHabilitado', this.ativar_gestor_integracao);
            } catch (error) {
                console.error(error)
            }
        }
    }
}
</script>
