<template>
    <div>
        <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
            <div class="flex flex-col bg-white rounded-lg shadow-lg w-full max-h-[90vh] overflow-hidden">

                <!-- Título fixo no topo -->
                <div class="border-b pb-2 mb-4 px-5 pt-4 flex justify-between items-center">
                    <h1 class="text-2xl font-bold text-center w-full">Variáveis Orçamento #{{ codigo_orcamento }}</h1>
                    <i class="fa-solid fa-x cursor-pointer text-gray-500 hover:text-gray-800" v-if="!rotaVoltar"
                        @click.prevent="$emit('fecharModalVariaveis')"></i>
                </div>

                <!-- Conteúdo com rolagem interna -->
                <div class="flex flex-col md:flex-row gap-4 px-5 pb-5 overflow-y-auto flex-1 justify-center">

                    <!-- Bloco de Dados do Projeto -->
                    <div class="bg-white p-5 rounded-lg shadow w-full md:max-w-[300px] flex flex-col items-center">
                        <h2 class="text-xl text-corBase font-bold mb-4">Dados do Projeto</h2>

                        <div class="w-full flex justify-center items-center mb-4">
                            <img v-if="imagem" :src="imagem.url_documento"
                                class="max-w-[190px] max-h-[190px] w-auto h-auto cursor-pointer rounded-lg"
                                @click.prevent="openPreviewImage(imagem.url_documento)">

                            <img v-else
                                src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                                class="max-w-[190px] max-h-[190px] w-auto h-auto rounded-lg">
                        </div>

                        <p v-if="projeto">{{ projeto.descricao_detalhada }}</p>
                        <p v-if="descricao_detalhada">{{ descricao_detalhada }}</p>
                        <p>{{ largura }} x {{ altura }} = {{ quantidade }}</p>
                    </div>


                    <!-- Bloco de Variáveis -->
                    <div class="bg-white p-5 rounded-lg shadow w-full md:max-w-[600px] overflow-y-auto">
                        <h2 class="text-xl text-corBase font-bold mb-4">Variáveis do Projeto</h2>

                        <div v-for="variavel in variaveis" :key="variavel.id" class="mb-4">
                            <h3 class="flex">
                                <p v-if="variavel.obrigatorio_orcamento" title="Obrigatório" class="text-red-500">*</p>{{
                                    variavel.descricao }}:
                            </h3>
                            <div>
                                <select v-if="getOpcoesByVariavelId(variavel.id).length > 0"
                                    v-model="selected[variavel.id]" @change="atualizarImagem(variavel.id)"
                                    class="w-full p-2 bg-gray-100 border border-black rounded-lg cursor-pointer">
                                    <option disabled value="">Selecione uma opção</option>
                                    <option v-for="opcao in getOpcoesByVariavelId(variavel.id)" :key="opcao.id"
                                        :value="opcao.valor">
                                        {{ opcao.descricao }}
                                    </option>
                                </select>

                                <input v-else v-model.number="selected[variavel.id]" type="number" min="0"
                                    placeholder="Insira um valor numérico"
                                    class="w-full p-2 bg-gray-100 border border-black rounded-lg" />
                            </div>
                        </div>
                    </div>

                    <!-- Bloco da Imagem -->
                    <div class="bg-white p-5 rounded-lg shadow w-full md:max-w-[400px] flex flex-col items-center">
                        <h2 class="text-xl text-corBase font-bold mb-4">Imagem da Variável</h2>

                        <div v-if="imagemSelecionada && imagemSelecionada.length > 0"
                            class="flex flex-col items-center justify-center w-full">

                            <!-- Imagem com tamanho máximo definido -->
                            <img :src="imagemSelecionada[currentImageIndex].url_documento"
                                alt="Imagem da variável selecionada"
                                class="max-w-[320px] max-h-[280px] w-auto h-auto rounded-lg shadow-md cursor-pointer object-contain"
                                @click.prevent="openPreviewImage(imagemSelecionada[currentImageIndex].url_documento)" />

                            <!-- Controles abaixo da imagem -->
                            <div class="flex items-center justify-between w-full mt-3 px-6">
                                <!-- Botão para voltar -->
                                <button @click.prevent="prevImage" class="bg-gray-200 rounded-full p-2 shadow-md">
                                    <i class="fa-solid fa-chevron-left text-gray-700"></i>
                                </button>

                                <!-- Indicador da imagem -->
                                <span class="text-gray-700 font-semibold">
                                    {{ currentImageIndex + 1 }} / {{ imagemSelecionada.length }}
                                </span>

                                <!-- Botão para avançar -->
                                <button @click.prevent="nextImage" class="bg-gray-200 rounded-full p-2 shadow-md">
                                    <i class="fa-solid fa-chevron-right text-gray-700"></i>
                                </button>
                            </div>
                        </div>
                    </div>



                </div>

                <!-- Rodapé fixo com botões -->
                <div class="border-t py-4 px-5 flex justify-end gap-4 bg-white">
                    <button
                        class="border border-gray-200 bg-ativo text-white px-4 py-2 hover:bg-screenCor hover:text-ativo hover:border-ativo"
                        @click.prevent="salvarVariaveis()">
                        Salvar
                    </button>
                </div>

            </div>
        </div>
    </div>

    <SucessoRapido v-if="sucesso" mensagem="Alteração feita com sucesso." />
    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />
    <ModalEscolhaVidros v-if="escolherVidros" :item_orcamento="id" :rotaVoltar="rotaVoltar"
        @fecharModal="toggleEscolhaVidros()" />
    <PreviewImage v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />
</template>


<script>
import axios from 'axios'
import SucessoRapido from '../toasts/toast_sucesso/SucessoRapido.vue';
import ListagemErros from '../toasts/toast_erro/ListagemErros.vue';
import PreviewImage from '../midais/ged/PreviewImage.vue';
import ModalEscolhaVidros from './ModalEscolhaVidros.vue';

export default {
    name: 'ModalVariaveisOrcamento',
    data() {
        return {
            variaveis: [],
            id_variaveis: [],
            opcoesVariaveis: [],
            imagemSelecionada: null,
            currentImageIndex: 0,
            selected: {},
            preview_imagem: null,
            escolherVidros: false,
            sucesso: false,
            exibirErros: false,
            erros: [],
        };
    },
    // props: {
    //     id: String,
    //     rotaVoltar: String
    // },

    props: ['id', 'rotaVoltar', 'projeto', 'codigo_orcamento', 'imagem', 'descricao_detalhada', 'largura', 'altura', 'quantidade'],
    emits: ['fecharModalVariaveis', 'atualizarLista'],

    components: {
        SucessoRapido,
        ListagemErros,
        PreviewImage,
        ModalEscolhaVidros,
    },

    computed: {
        projetoAtual() {
            return this.$store.state.projetoAtual;
        },
    },

    created() {
        // this.getVariaveisOrcamento();
        this.calcularVariaveisOrcamento()
        // console.log(this.projetoAtual)
    },

    methods: {

        prevImage() {
            if (this.currentImageIndex > 0) {
                this.currentImageIndex--;
            }
        },
        nextImage() {
            if (this.currentImageIndex < this.imagemSelecionada.length - 1) {
                this.currentImageIndex++;
            }
        },

        // Salvar as variáveis alteradas do orçamento
        async salvarVariaveis() {
            // Verifica se há variáveis obrigatórias não preenchidas
            const variaveisNaoPreenchidas = this.variaveis.filter(variavel =>
                variavel.obrigatorio_orcamento &&
                (!this.selected[variavel.id] || this.selected[variavel.id] === "")
            );

            if (variaveisNaoPreenchidas.length > 0) {
                this.erros = variaveisNaoPreenchidas.map(variavel =>
                    `A variável "${variavel.descricao}" é obrigatória e não foi preenchida.`
                );
                this.toggleErros()
                return;
            }

            const data = {
                variaveis: this.variaveis.map(variavel => {
                    const opcaoSelecionada = this.getOpcoesByVariavelId(variavel.id).find(opcao => opcao.id === this.selected[variavel.id]);
                    return {
                        id: variavel.id,
                        opcao_selecionada: opcaoSelecionada ? opcaoSelecionada.id : null,
                        medida_valor: opcaoSelecionada ? null : this.selected[variavel.id]
                    };
                })
            };

            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.put(`orcamentos/variavel-local-orcamento-update/`, data, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });

                console.log(response.data);
                this.sucesso = true;
                this.recalcularItemOrcamento()
                setTimeout(() => {
                    this.sucesso = false;
                    if (this.rotaVoltar && this.projetoAtual && !this.projetoAtual.habilitar_produto_vidro) {
                        this.$router.push(`${this.rotaVoltar}`);
                    } else if (this.rotaVoltar) {
                        this.escolherVidros = true
                    } else {
                        this.$emit('fecharModalVariaveis');
                        this.$emit('atualizarLista')
                    }
                }, 3000);
            } catch (error) {
                console.error(error);
                this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
                this.toggleErros()
            }
        },

        async recalcularItemOrcamento() {

            try {

                this.$store.dispatch("setLoading", true);

                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get(`engine/${this.id}/`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                })

                console.log(response.data)
                // this.sucesso = true
                // this.sucessoMessage = 'Item do Orçamento recalculado com sucesso.'
                // this.getPerfisOrcamento()
                // setTimeout(() => {
                //     this.sucesso = false
                //     this.sucessoMessage = ''
                // }, 3000)

            } catch (error) {
                console.error(error)
                this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
                this.toggleErros()
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async salvarVariaveisParcial() {
            const data = {
                variaveis: this.variaveis.map(variavel => {
                    const opcaoSelecionada = this.getOpcoesByVariavelId(variavel.id).find(opcao => opcao.id === this.selected[variavel.id]);
                    return {
                        id: variavel.id,
                        opcao_selecionada: opcaoSelecionada ? opcaoSelecionada.id : null,
                        medida_valor: opcaoSelecionada ? null : this.selected[variavel.id]
                    };
                })
            };

            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')
            try {
                const response = await axios.put(`orcamentos/variavel-local-orcamento-update/`, data, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });

                console.log(response)
                this.calcularVariaveisOrcamento()
            } catch (error) {
                console.error(error);
                this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
                this.toggleErros()
            }
        },

        async calcularVariaveisOrcamento() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get(
                    `engine/recalcular-item-orcamento/${this.id}/?variaveis=true`,
                    {
                        headers: { 'Authorization': `Token ${token}` },
                    }
                );

                this.getVariaveisOrcamento()

            } catch (error) {
                console.error("Erro ao atualizar itens do orçamento:", error);
                this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
                this.toggleErros();
            }
        },

        // Função para buscar as variáveis do orçamento
        async getVariaveisOrcamento() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')
            try {

                this.$store.dispatch("setLoading", true);

                const response = await axios.get(`orcamentos/variavel-local-orcamento/?item_orcamento_id=${this.id}&aplicado_orcamento=Sim&page_size=999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });
                this.variaveis = response.data.results;
                this.id_variaveis = this.variaveis.map(variavel => variavel.id);
                this.getOpcoesVariaveisOrcamento();
            } catch (error) {
                console.error(error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        // Função para buscar as opções das variáveis do orçamento
        async getOpcoesVariaveisOrcamento() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')
            try {

                this.$store.dispatch("setLoading", true);

                const response = await axios.get(`orcamentos/opcao-variavel-local-orcamento/?item_orcamento_id=${this.id}&aplicado_orcamento=Sim&page_size=999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });
                this.opcoesVariaveis = response.data.results;
                this.setInitialSelections();
            } catch (error) {
                console.error(error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        // Função para definir os valores iniciais caso exista
        setInitialSelections() {
            this.variaveis.forEach((variavel, index) => {
                const opcoes = this.getOpcoesByVariavelId(variavel.id);
                const selectedOpcao = opcoes.find(opcao =>
                    String(opcao.valor) === String(variavel.medida_valor)
                );
                if (selectedOpcao) {
                    this.selected[variavel.id] = selectedOpcao.valor;
                    // Mostra imagem inicial se houver
                    // if (selectedOpcao.imagem) {
                    //     this.imagemSelecionada = selectedOpcao.imagem;
                    // }
                } else {
                    this.selected[variavel.id] = variavel.medida_valor;
                }

                // if (index === 0 && opcoes.length > 0 && opcoes[0].imagem) {
                //     this.imagemSelecionada = opcoes[0].imagem;
                //     this.currentImageIndex = 0;
                // }
            });
        },

        atualizarImagem(variavelId) {
            const opcaoSelecionada = this.getOpcoesByVariavelId(variavelId).find(opcao =>
                opcao.valor === this.selected[variavelId]
            );
            this.imagemSelecionada = opcaoSelecionada?.imagem || null;
            this.currentImageIndex = 0

            this.salvarVariaveisParcial()
        },

        // Função para buscar as opções da variável do orçamento
        getOpcoesByVariavelId(id_variavel) {
            return this.opcoesVariaveis.filter(opcao => opcao.variavel.id === id_variavel);
        },

        openPreviewImage(url) {
            this.preview_imagem = url
        },

        closed_preview_image() {
            this.preview_imagem = ''
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        }
    },
};
</script>

<!-- Adiciona estilo para o modal -->
<style scoped></style>