<template>

    <!-- <div class="mt-2">
        <h1 class="text-xl font-bold text-corBase">
            Vidros:
        </h1>
        <div v-for="(vidros, index) in listaVidros" :key="index"
            :class="index % 2 === 0 ? 'bg-gray-100' : 'bg-gray-200'" class="mb-2 p-4 rounded-lg">
            <h2 class="text-base font-semibold text-gray-700 mb-2">
                {{ vidros.vidro__descricao ? vidros.vidro__descricao : vidros.sem_vidro ? 'Sem Vidro' : '-' }}
            </h2>
            <div class="flex gap-5">
                <p> FORNECEDOR: {{ vidros.vidro__fornecedor__nome_razao || '-' }} </p>
                <p> QTD: {{ vidros.total_qtd }} </p>
                <p> ESPESSURA: {{ vidros.espessura_referencia__espessura }}mm </p>
                <p> Total Altura: {{ vidros.total_altura }} </p>
                <p> Total Largura: {{ vidros.total_largura }} </p>
                <p> M²: {{ vidros.total_m2 }} </p>
                <p> PESO UN: {{ vidros.vidro__vidro_base__peso_base }} </p>
                <p> PESO: {{ vidros.peso }} </p>
            </div>
        </div>

        PESO TOTAL VIDROS: {{ pesoTotalVidros }}
    </div> -->

    <div class="container-modelo-tabela mt-2">
        <table class="container-tabela overflow-auto">
            <thead class="cabecalho-tabela">
                <tr class="border-b">
                    <th class="px-12 py-2 text-center">Vidro</th>
                    <th class="px-8 py-2 text-center">Fornecedor</th>
                    <th class="px-8 py-2 text-center">Quantidade</th>
                    <th class="px-8 py-2 text-center">Espessura</th>
                    <th class="px-8 py-2 text-center">Largura</th>
                    <th class="px-8 py-2 text-center">Altura</th>
                    <th class="px-8 py-2 text-center">M²</th>
                    <th class="px-8 py-2 text-center">Peso Un.</th>
                    <th class="px-8 py-2 text-center">Peso Total</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(vidros, index) in listaVidros" :key="index" class="escopo-tabela">

                    <td class="px-8 py-2 text-center">
                        {{ vidros.vidro__descricao ? vidros.vidro__descricao : vidros.sem_vidro ? 'Sem Vidro' : '-' }}
                    </td>

                    <td class="px-8 py-2 text-center">
                        {{ vidros.vidro__fornecedor__nome_razao || '-' }}
                    </td>

                    <td class="px-8 py-2 text-center">
                        {{ vidros.total_qtd || '-' }}
                    </td>

                    <td class="px-8 py-2 text-center">
                        {{ vidros.espessura_referencia__espessura || '-' }}mm
                    </td>

                    <td class="px-8 py-2 text-center">
                        {{ formatarNumero(vidros.total_largura) }}
                    </td>

                    <td class="px-8 py-2 text-center">
                        {{ formatarNumero(vidros.total_altura) }}
                    </td>

                    <td class="px-8 py-2 text-center">
                        {{ formatarNumero(vidros.total_m2) }}
                    </td>


                    <td class="px-8 py-2 text-center">
                        {{ vidros.vidro__vidro_base__peso_base || '-' }}
                    </td>

                    <td class="px-8 py-2 text-center">
                        {{ vidros.peso || '-' }}
                    </td>

                </tr>
            </tbody>
            <tr class="escopo-tabela">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="px-8 py-2 text-center text-black">
                    {{ pesoTotalVidros }}
                </td>
            </tr>
        </table>
    </div>

</template>

<script>
export default {
    name: 'CustosMargensPerfil',

    props: ['listaVidros', 'pesoTotalVidros'],

    data() {
        return {
            // listaVidros: [],
            // pesoTotalVidros: 0
        }
    },

    methods: {
        formatarNumero(valor) {
            if (valor == null) return '-';
            return Number(valor).toLocaleString('pt-BR');
        }
    }


}
</script>