<template>
    <div>
        <form @submit.prevent="atualizarDadosOrcamento">

            <div class="w-full mx-auto mt-8">
                <div class="space-y-4">
                    <div>
                        <div class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Dados
                        </div>

                        <div class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <DadosOrcamento :orcamento="orcamento" />
                        </div>
                    </div>

                    <div>
                        <div class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Endereço Orçamento
                        </div>

                        <div class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <EnderecoOrcamento :orcamento="orcamento" />
                        </div>
                    </div>

                    <div v-if="orcamento.obra">
                        <div class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Endereço Obra
                        </div>

                        <div class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <EnderecoObraOrcamento :orcamento="orcamento" />
                        </div>
                    </div>

                    <div class="flex w-full gap-2 justify-end">
                        <button
                            class="px-4 py-1 cursor-pointer text-white bg-inativo border hover:bg-screenCor hover:text-inativo hover:border-inativo"
                            @click.prevent="$router.push(`/lista-orcamentos`)">
                            Cancelar
                        </button>
                        <button
                            class="px-4 py-1 cursor-pointer text-white bg-ativo border hover:bg-screenCor hover:text-ativo hover:border-ativo"
                            type="submit">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />

    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select'
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue'
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue'
import DadosOrcamento from '../../sessoes_orcamento/DadosOrcamento.vue'
import EnderecoObraOrcamento from '../../sessoes_orcamento/EnderecoObraOrcamento.vue'
import EnderecoOrcamento from '../../sessoes_orcamento/EnderecoOrcamento.vue'

export default {
    name: 'EditarDadosOrcamento',

    props: ['orcamento'],

    components: {
        vSelect,
        SucessoRapido,
        ListagemErros,
        DadosOrcamento,
        EnderecoObraOrcamento,
        EnderecoOrcamento,
    },

    data() {
        return {

            // controle de exibição
            sucesso: false,
            sucessoMessage: '',

            exibirErros: false,
            erros: [],
        }
    },

    created() {
    },

    methods: {

        async atualizarDadosOrcamento() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const data = {
                    cliente: this.orcamento.cliente?.id ? this.orcamento.cliente.id : this.orcamento.cliente || 0,
                    vendedor: this.orcamento.vendedor?.id ? this.orcamento.vendedor.id : this.orcamento.vendedor || '',
                    status: this.orcamento.status,
                    referencia: this.orcamento.referencia,
                    obra: this.orcamento.obra?.id ? this.orcamento.obra.id : this.orcamento.obra || '',
                    cep: this.orcamento.cep,
                    logradouro: this.orcamento.logradouro,
                    numero: this.orcamento.numero,
                    complemento: this.orcamento.complemento,
                    predio_condominio: this.orcamento.predio_condominio,
                    bairro: this.orcamento.bairro,
                    estado: this.orcamento.estado,
                    cidade: this.orcamento.cidade,
                }

                this.$store.dispatch("setLoading", true);

                const response = await axios.patch(`orcamentos/orcamento/${this.orcamento.id}/`, data, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                })

                this.novoOrcamento = response.data

                this.sucessoMessage = `Orçamento #${this.orcamento.codigo} atualizado com sucesso!`
                this.sucesso = true

                setTimeout(() => {
                    this.sucesso = false;
                }, 3000);

            } catch (error) {
                console.error(error)
                this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
                this.toggleErros
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        // async getListaVendedores() {
        //   try {
        //     const token = await this.$store.dispatch('getAuthTokenCookie')
        //     await this.$store.dispatch('fetchURLrequest')

        //     const response = await axios.get('pessoa/pessoas-fisica-juridica/?ativo=True&page_size=9999', {
        //       headers: {
        //         Authorization: `Token ${ token }`
        //       }
        //     })

        //     this.listaVendedores = response.data.results.map(item => ({
        //       id: item.id,
        //       text: item.nome || item.razao_social
        //     }))

        //   } catch (error) {
        //     console.error(error)
        //   }
        // },

        // async getListaClientes() {
        //   const token = await this.$store.dispatch('getAuthTokenCookie');
        //   await this.$store.dispatch('fetchURLrequest');
        //   try {
        //     const response = await axios.get(`pessoa / pessoas - fisica - juridica /? atividade = Cliente & ativo=True & page_size=9999`, {
        //       headers: {
        //         'Authorization': `Token ${ token }`
        //       }
        //     })

        //     this.listaClientes = response.data.results.map(person => ({
        //       id: person.pessoa.id,
        //       text: person.nome || person.razao_social,
        //     }))
        //     console.log(this.listaClientes)
        //   } catch (error) {
        //     console.error(error)
        //   }
        // },

        // async getClientes(pesquisa) {
        //   try {
        //     const token = await this.$store.dispatch('getAuthTokenCookie')
        //     await this.$store.dispatch('fetchURLrequest')

        //     const response = await axios.get(`pessoas /? razao_social = ${ pesquisa }`, {
        //       headers: {
        //         Authorization: `Token ${ token }`
        //       }
        //     })

        //     this.listaClientes = response.data.results
        //   } catch (error) {
        //     console.error(error)
        //   }
        // },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },

    }
}
</script>

<style scoped lang="scss">
.check-box {
    @apply flex justify-center gap-3
}

input,
select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
</style>