<template>
  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
      <tr>
        <th scope="col" class="px-6 py-3">
          #
        </th>
        <th class="px-6 py-3">
          Serviço
        </th>
        <th class="px-6 py-3">
          QTDE
        </th>
        <th scope="col" class="px-6 py-3">
          Condição
        </th>
        <th scope="col" class="px-6 py-3">
          Data Rel. Produção
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(servico, index) in servicos" :key="index" class="escopo-tabela">
        <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
          {{ index + 1 }}
        </th>
        <td class="px-6 py-2 text-xs">
          {{ servico.servico_id.nome_especifico }}
        </td>
        <td class="px-6 py-2 text-xs">
          <input class="input-campos" v-model="servico.qtd_formula">
          <!--                  {{ servico.qtd_formula }}-->
        </td>
        <td class="px-6 py-2 text-xs">
          <input class="input-campos" v-model="servico.condicao">
          <!--                  {{ servico.condicao }}-->
        </td>
        <td class="px-6 py-2 text-xs">
          <input class="input-campos" v-model="servico.data_rel_producao">
          <!--                  {{ servico.data_rel_producao }}-->
        </td>

      </tr>
      </tbody>
    </table>
  </div>
  <div class="flex w-full justify-end itens-center mt-6">
    <button class="bg-red-500 hover:bg-screenCor border border-screenCor hover:border-red-500
      text-white hover:text-red-500 py-1 px-4" @click.prevent="getListaServicos">Cancelar</button>
    <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
      text-white hover:text-ativo py-1 px-4" @click.prevent="atualizarServicos">Salvar</button>
  </div>
</template>

<script>
export default {
  name: "ServicoProjeto",

  props: ['servicos'],

  emits: ['atualizarServicos'],

  data() {
    return {}
  },

  created(){
    console.log(this.servicos)
  },

  methods: {
    atualizarServicos() {
      this.$emit('atualizarServicos', this.servicos)
    }
  }
}
</script>

<style scoped>

</style>