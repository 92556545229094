<template>
    <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
        <div
            class="bg-white p-4 md:p-6 rounded-lg shadow-lg min-w-[60%] max-w-[70%] max-h-[80vh] overflow-y-auto flex flex-col">

            <!-- Cabeçalho -->
            <div class="border-b pb-2 mb-4 flex items-center justify-between">
                <!-- Título centralizado -->
                <h2 class="text-2xl font-semibold text-gray-800 text-center w-full">
                    Orçamentos Iguais
                </h2>

                <!-- Ícone "X" no lado direito -->
                <i class="fa-solid fa-x cursor-pointer text-gray-600 hover:text-gray-900 ml-auto"
                    @click.prevent="$emit('fecharModal')">
                </i>
            </div>

            <div class="flex-1 space-y-4 px-2">

                <div class="text-center">
                    <p class="text-lg font-semibold text-corBase">
                        Você possui <span class="font-bold">{{ countOrcamento }}</span> orçamentos com o mesmo Cliente e
                        Obra.
                    </p>
                    <p class="text-gray-600">Deseja inativar por venda?</p>
                </div>

                <!-- Lista de Orçamentos -->
                <div class="w-full space-y-4 flex flex-col items-center">
                    <div v-for="orcamento in orcamentos" :key="orcamento.id"
                        class="flex w-[50%] items-center gap-4 bg-white shadow-md p-4 rounded-lg border border-gray-200">
                        <input type="checkbox" v-model="orcamentosSelecionados" :value="orcamento.id"
                            class="w-5 h-5 text-corBase border-gray-300 rounded focus:ring focus:ring-corBase">

                        <div class="flex flex-col text-sm text-gray-700 w-full">
                            <p><span class="font-semibold text-corBase">Orçamento #{{ orcamento.codigo }}</span></p>
                            <p><span class="font-semibold">Cliente:</span> {{ orcamento.cliente?.nome_razao || 'ERRO' }}
                            </p>
                            <p><span class="font-semibold">Obra:</span> {{ orcamento.obra?.descricao || 'ERRO' }}</p>
                            <p><span class="font-semibold">Status atual:</span> {{ orcamento.status || 'ERRO' }}</p>
                        </div>
                    </div>
                </div>
            
            </div>

            <hr class="m-5">
            ou
            <hr class="m-5">

            <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                <div v-for="orcamento in orcamentos" :key="orcamento.id"
                    class="flex items-start gap-4 bg-white shadow-md p-4 rounded-lg border border-gray-200 min-h-[100px]">

                    <input type="checkbox" v-model="orcamentosSelecionados" :value="orcamento.id"
                        class="w-5 h-5 text-corBase border-gray-300 rounded focus:ring focus:ring-corBase">

                    <div class="flex flex-col text-sm text-gray-700 w-full">
                        <p><span class="font-semibold text-corBase">Orçamento #{{ orcamento.codigo }}</span></p>
                        <p><span class="font-semibold">Cliente:</span> {{ orcamento.cliente?.nome_razao || 'ERRO' }}</p>
                        <p><span class="font-semibold">Obra:</span> {{ orcamento.obra?.descricao || 'ERRO' }}</p>
                        <p><span class="font-semibold">Status atual:</span> {{ orcamento.status || 'ERRO' }}</p>
                    </div>
                </div>
            </div>


            <hr class="m-5">
            ou
            <hr class="m-5">

            <div class="container-modelo-tabela">
                <table class="container-tabela">
                    <thead class="cabecalho-tabela">
                        <tr class="border-b">
                            <th scope="col" class="px-3 py-3">
                                <div class="flex items-center">
                                    <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
                                    <label class="sr-only">checkbox</label>
                                </div>
                            </th>
                            <th class="px-12 py-3 text-center">
                                Nº Orçamento
                            </th>
                            <th class="px-6 py-3 text-center">
                                Cliente
                            </th>
                            <th class="px-6 py-3 text-center">
                                Status Atual
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="orcamento in orcamentos" :key="orcamento.id" class="border-b">
                            <td class="w-4 px-3 py-3">
                                <div class="flex items-center">
                                    <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                                        v-model="orcamento.selected">
                                    <label class="sr-only">checkbox</label>
                                </div>
                            </td>
                            <td class="py-3 text-center text-gray-900">
                                {{ orcamento.codigo || 'ERRO' }}
                            </td>
                            <td class="px-6 py-3 text-center">
                                {{ orcamento.cliente.nome_razao || 'ERRO' }}
                            </td>
                            <td class="px-6 py-3 text-center">
                                {{ orcamento.status || 'ERRO' }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Botões -->
            <div class="mt-4 flex justify-center space-x-4 border-t pt-4">
                <button class="border border-gray-200 bg-red-500 text-white px-4 py-2 hover:bg-screenCor hover:text-red-500 hover:border-red-500" @click.prevent="$emit('fecharModal')">
                    Fechar
                </button>
                <button class="bborder border-gray-200 bg-ativo text-white px-4 py-2 hover:bg-screenCor hover:text-ativo hover:border-ativo" @click="inativarOrcamentos()">
                    Inativar
                </button>
            </div>
        </div>

        <SucessoRapido v-if="sucesso === true" mensagem="Perfis atualizados com sucesso!" />
        <!-- <ErroRapido v-if="erro === true" mensagem="Ocorreu um erro ao atualizar Perfis." /> -->
        <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />
    </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';

export default {
    name: 'OrcamentosIguais',

    props: ['orcamentos', 'countOrcamento'],

    emits: ['fecharModal'],

    components: {
        vSelect,
        SucessoRapido,
        ErroRapido,
        ListagemErros,
    },

    computed: {
        checkSome() {
            return this.orcamentos.some(perfil => perfil.selected)
        }
    },

    data() {
        return {
            orcamentosSelecionados: [],

            sucesso: false,
            exibirErros: false,
            erros: [],
        };
    },

    methods: {

        selectAll() {
            this.orcamentos.forEach((orcamento) => {
                orcamento.selected = this.checkAll;
            });
        },

        async inativarOrcamentos() {
            try {

                console.log(this.orcamentosSelecionados)

                this.$store.dispatch("setLoading", true);

                const token = await this.$store.dispatch("getAuthTokenCookie");
                await this.$store.dispatch("fetchURLrequest");

                // await axios.patch(
                //     `orcamentos/atualizar-perfis-orcamento/`,
                //     { itens: perfisAtualizados },
                //     { headers: { Authorization: `Token ${token}` } }
                // );

                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    // this.$emit("fecharModal");
                }, 3000);
            } catch (error) {
                console.error("Erro ao salvar alterações:", error);
                this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
                this.toggleErros()
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        toggleErros(){
            this.exibirErros = !this.exibirErros
        }


    },
};
</script>

<style scoped>
.input-campos {
    @apply block w-full p-2 border rounded-md text-corBase;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    @apply bg-[#888] rounded-lg border border-[#f1f1f1]
}

::-webkit-scrollbar-thumb:hover {
    @apply bg-corBase
}
</style>