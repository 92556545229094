<template>

    <!-- <div class="mt-2">
        <h1 class="text-xl font-bold text-corBase">
            Componentes:
        </h1>
        <div v-for="(componentes, index) in listaComponentes" :key="index"
            :class="index % 2 === 0 ? 'bg-gray-100' : 'bg-gray-200'" class="mb-2 p-4 rounded-lg">
            <h2 class="text-base font-semibold text-gray-700 mb-2">
                {{ componentes.componente__codigo_base }} - {{ componentes.componente__nome_base }}
                <span class="text-sm text-gray-600 font-normal">
                    ({{ componentes.cor__descricao }})
                </span>
            </h2>
            <div class="flex gap-5">
                <p> QTD: {{ componentes.total_qtd }} </p>
                <p> MEDIDA: {{ componentes.total_medida }} </p>
                <p> PESO UN: {{ componentes.componente__peso_base }} </p>
                <p> PESO: {{ componentes.peso }} </p>
            </div>
        </div>

        PESO TOTAL COMPONENTES: {{ pesoTotalComponentes }}
    </div> -->

    <div class="container-modelo-tabela">
        <table class="container-tabela overflow-auto">
            <thead class="cabecalho-tabela">
                <tr class="border-b">
                    <th class="px-12 py-2 text-center">Código</th>
                    <th class="px-20 py-2 text-center">Descrição</th>
                    <th class="px-8 py-2 text-center">Cor</th>
                    <th class="px-8 py-2 text-center">Unidade Medida</th>
                    <th class="px-8 py-2 text-center">Unidade Medida Emabalagem</th>
                    <th class="px-8 py-2 text-center">Itens na Embalagem</th>
                    <th class="px-8 py-2 text-center">Quantidade</th>
                    <th class="px-8 py-2 text-center">Peso Un.</th>
                    <th class="px-8 py-2 text-center">Peso Total</th>
                    <th class="px-8 py-2 text-center">Medida Total</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(componentes, index) in listaComponentes" :key="index" class="escopo-tabela">

                    <td class="px-8 py-2 text-center">
                        {{ componentes.componente__codigo_base || '-' }}
                    </td>

                    <td class="px-8 py-2 text-center">
                        {{ componentes.componente__nome_base || '-' }}
                    </td>

                    <td class="px-8 py-2 text-center">
                        {{ componentes.cor__descricao || '-' }}
                    </td>

                    <td class="px-8 py-2 text-center">
                        {{ componentes.componente__unidade_medida__nome || '-' }}
                    </td>

                    <td class="px-8 py-2 text-center">
                        {{ componentes.componente__unidade_medida_embalagem__nome || '-' }}
                    </td>

                    <td class="px-8 py-2 text-center">
                        {{ componentes.componente__quantidade_embalagem || '-' }}
                    </td>

                    <td class="px-8 py-2 text-center">
                        {{ componentes.total_qtd || '-' }}
                    </td>

                    <td class="px-8 py-2 text-center">
                        {{ formatarNumero(componentes.componente__peso_base) }}
                    </td>

                    <td class="px-8 py-2 text-center">
                        {{ formatarNumero(componentes.peso) }}
                    </td>

                    <td class="px-8 py-2 text-center">
                        {{ formatarNumero(componentes.total_medida) }}
                    </td>

                </tr>
            </tbody>
            <tr class="escopo-tabela">
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> 
                <td class="px-8 py-2 text-center text-black">
                    {{ formatarNumero(pesoTotalComponentes) }}
                </td>
                <td></td> 
            </tr>
        </table>
    </div>

</template>

<script>
export default {
    name: 'CustosMargensComponente',

    props: ['listaComponentes', 'pesoTotalComponentes'],

    data() {
        return {
            // listaComponentes: [],
            // pesoTotalComponentes: 0
        }
    },

    methods: {
        formatarNumero(valor) {
            if (valor == null) return '-';
            return Number(valor).toLocaleString('pt-BR');
        }
    }


}
</script>