<template>
    <div>

        <div class="my-4">
            <h2 class="text-corBase text-xl font-bold">Regras</h2>
        </div>

        <div>
            <div class="block">
                <div class="mb-4">

                    <div v-for="(regra, index) in regras" :key="index" class="flex items-center w-full gap-2 mb-2">

                        <!-- Se o produto -->
                        <span class="text-sm whitespace-nowrap">Se o produto</span>
                        <select v-model="regra.produto" @change="atualizarFiltro(index)" class="input-campos w-full">
                            <option disabled value="">Escolha uma opção</option>
                            <option v-for="produto in lista_produtos" :key="produto.id" :value="produto">
                                {{ produto.nome }}
                            </option>
                        </select>

                        <!-- Não possuir a cor -->
                        <span class="text-sm whitespace-nowrap">não possuir a cor</span>
                        <select v-model="regra.cor1" @change="regra.cor2 = ''" class="input-campos w-full">
                            <option disabled value="">Escolha uma opção</option>
                            <option v-for="cor in regra.lista_cores" :key="cor.id" :value="cor.id">
                                {{ cor.descricao }}
                            </option>
                        </select>

                        <!-- Então use a cor -->
                        <span class="text-sm whitespace-nowrap">, então use a cor</span>
                        <select v-model="regra.cor2" class="input-campos w-full" :disabled="!regra.cor1">
                            <option disabled value="">Escolha uma opção</option>
                            <option v-for="cor in filtroCorDestino(regra)" :key="cor.id" :value="cor.id">
                                {{ cor.descricao }}
                            </option>
                        </select>

                        <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)">
                        </i>
                    </div>

                    <div class="flex w-full justify-center mt-4">
                        <button class="text-ativo flex items-center" @click.prevent="adicionarFiltro">
                            <i class="fas fa-plus-circle"></i>
                        </button>
                    </div>

                </div>
            </div>
        </div>

        <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
                <thead class="cabecalho-tabela">
                    <tr class="border-b">
                        <th class="px-2 py-2 text-center">Opções</th>
                        <th class="px-2 py-2 text-center">Produto</th>
                        <th class="px-2 py-2 text-center">Se não possuir a cor</th>
                        <th class="px-2 py-2 text-center">então use</th>
                    </tr>
                </thead>

                <!-- Linhas de Perfis -->
                <tbody>
                    <tr v-for="regra in lista_regras" :key="regra.id" class="escopo-tabela">
                        <td class="px-2 py-2 text-center">
                            <i class="fa-solid fa-x cursor-pointer" @click.prevent="excluirRegra(regra.id)"></i>
                        </td>

                        <td class="px-2 py-2 text-center">
                            {{ regra.produto?.nome || '-' }}
                        </td>
                        <td class="px-2 py-2 text-center">
                            {{ regra.cor1?.descricao || '-' }}
                        </td>
                        <td class="px-2 py-2 text-center">
                            {{ regra.cor2?.descricao || '-' }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- <div>
            <div v-for="regras in lista_regras" :key="regras.id">
                Produto: {{ regras.produto?.nome }} - Se não tiver a cor {{ regras.cor1?.descricao }}, então use {{
                    regras.cor2?.descricao }}
            </div>
        </div> -->

    </div>

    <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />

</template>

<script>
import vSelect from "vue-select";
import axios from 'axios'
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";

export default {
    name: 'RegrasCor',

    components: {
        vSelect,
        SucessoRapido,
    },

    props: ['projeto', 'regras', 'lista_regras'],
    emits: ['atualizarLista'],

    data() {
        return {
            lista_produtos: [],
            lista_cores: [],

            sucesso: false,
            sucessoMessage: '',
        }
    },

    // watch: {
    //     regras: {
    //         deep: true,
    //         handler(novasRegras) {
    //             novasRegras.forEach((regra, index) => {
    //                 if (regra.produto) {
    //                     this.getListaCores(regra.produto, index);
    //                 }
    //             });
    //         }
    //     }
    // },

    created() {
        this.getListaProdutosSistema()
    },

    methods: {
        atualizarFiltro(index) {
            this.regras[index].cor1 = '';
            this.regras[index].cor2 = '';
            this.regras[index].lista_cores = [];
            this.getListaCores(this.regras[index].produto, index)
        },
        adicionarFiltro() {
            this.regras.push({ produto: "", cor1: "", cor2: "" });
        },
        removerFiltro(index) {
            this.regras.splice(index, 1);
        },

        filtroCorDestino(regra) {
            if (!regra.cor1 || !regra.lista_cores) return [];

            return regra.lista_cores.filter(cor => cor.id !== regra.cor1);
        },


        async getListaProdutosSistema() {

            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get(`produtos/produtos-sistema/`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.lista_produtos = response.data.results
            } catch (error) {
                console.error(error)
            }
        },

        async getListaCores(produto, index) {

            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get(`produtos/cor-produto-base/?produto_nome=${produto.nome.toLowerCase()}&ativo=True&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.regras[index].lista_cores = response.data.results || [];
            } catch (error) {
                console.error(error)
            }
        },

        async excluirRegra(regraId) {
            try {

                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.patch(`projeto/regra-cores-projeto/${regraId}/`, { ativo: false }, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                console.log(response)
                this.sucessoMessage = 'Regra excluída com sucesso.'
                this.sucesso = true
                this.$emit('atualizarLista')
                setTimeout(() => {
                    this.sucesso = false;
                    this.sucessoMessage = ''
                }, 3000);

            } catch (error) {
                console.error(error)
            }
        }


    }
}
</script>

<style scoped></style>
