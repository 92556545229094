<template>


  <div class="container-formulario">

    <div class="cabecalho-dashboard">
      <h1>Cadastro de Grupos de Listagem</h1>

      <button @click="$router.push(`/configuracoes`)" class="text-2xl">
        <i class="fas fa-chevron-left mr-1 "></i>
      </button>
    </div>

    <div class="flex flex-wrap">

      <button class="escopo-dashboard-desktop" @click="$router.push('/lista-grupo-cor-perfil')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Perfil</h2>
            <i class="fa-solid fa-hammer"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Grupos de Cores de Serviço presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/lista-grupo-cor-componente')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Componentes</h2>
            <i class="fa-solid fa-gears"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Grupos de Cores de Componentes presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button @click="$router.push('/lista-grupo-cor-chapa')" class="escopo-dashboard-desktop">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Chapas</h2>
            <i class="fa-solid fa-layer-group"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Grupos de Cores de Chapas presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button @click="$router.push('/lista-grupo-cor-vidro')" class="escopo-dashboard-desktop">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Vidros</h2>
            <i class="fa-solid fa-layer-group"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Grupos de Cores de Vidros presentes em seu sistema!</p>
          </div>
        </div>
      </button>
    </div>


  </div>


</template>

<script>
export default {
  name: "DashboardCadastroGrupoAplicacoes",
}
</script>

<style lang="scss"></style>