<template>
    <div class="w-full">

      <div class="flex flex-col gap-4 mt-4 mb-6">
        <h2 class="text-xl font-bold text-corBase">Definições do Projeto</h2>
      </div>
  
      <div class="block">
        <div class="mb-4">
          <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
            <div class="px-2 w-1/3">
              <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
                <option value="codigo">Código</option>
                <option value="descricao">Descrição</option>
                <option value="formula">Fórmula</option>
                <option value="condicao">Condição</option>
              </select>
            </div>
  
            <div class="w-full">
              <!-- <v-select v-if="filtro.campo === 'cor'" v-model="filtro.valor" :options="lista_cores" label="nome_razao"
              placeholder="Escolha uma opção" :reduce="cor => cor.id" />
  
            <v-select v-else-if="filtro.campo === 'fase'" v-model="filtro.valor" :options="lista_fases" label="descricao"
              placeholder="Escolha uma opção" :reduce="fase => fase.descricao" />-->
  
              <!-- v-else -->
              <input v-model="filtro.valor" type="text" class="input-form-chapa" placeholder="Insira o valor" />
            </div>
  
            <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
          </div>
  
          <div class="flex w-full justify-center">
            <button class="text-ativo" @click.prevent="adicionarFiltro">
              <i class="fas fa-plus-circle"></i>
            </button>
          </div>
        </div>
      </div>
  
      <div class="flex">
  
        <div class="flex flex-wrap gap-2 mb-2">
  
          <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
              text-white hover:text-corBase py-1 px-4" @click.prevent="getListaDefinicoesProjeto()">
            Pesquisar
          </button>
  
          <button class="bg-red-500 hover:bg-screenCor border border-screenCor hover:border-red-500
              text-white hover:text-red-500 py-1 px-4" @click.prevent="modal_confirmacao = true">
            Excluir Selecionados
          </button>
  
          <button
            class="bg-orange-500 text-white hover:bg-screenCor border hover:border-orange-500 hover:text-orange-500 py-1 px-4"
            @click.prevent="exportarDefinicoes()">
            Exportar Definições
          </button>
  
          <button
            class="bg-yellow-500 text-white hover:bg-screenCor border hover:border-yellow-500 hover:text-yellow-500 py-1 px-4"
            @click.prevent="triggerFileInput">
            Importar Definições
          </button>
  
          <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
        text-white hover:text-ativo py-1 px-4" @click.prevent="atualizarDefinicoes(lista_definicoes)">Salvar</button>
  
          <input type="file" ref="fileInput" class="hidden" @change="importarDefinicoes" accept=".csv" />
  
        </div>
      </div>
  
      <div class="container-modelo-tabela">
        <table class="container-tabela overflow-auto">
          <thead class="cabecalho-tabela">
            <tr>
              <th scope="col" class="px-2 py-3 text-center w-10">
                #
              </th>
              <th scope="col" class="p-4 w-12">
                <div class="flex items-center">
                  <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
                  <label class="sr-only">checkbox</label>
                </div>
              </th>
              <th class="px-6 py-3 text-center w-20">
                Opções
              </th>
              <th class="px-4 py-3 w-24"> <!-- Código menor -->
                Código
              </th>
              <th class="px-4 py-3 w-60"> <!-- Descrição maior -->
                Descrição
              </th>
              <th class="px-4 py-3 w-60"> <!-- Fórmula maior -->
                Fórmula
              </th>
              <th class="px-4 py-3 w-60"> <!-- Condição maior -->
                Condição
              </th>
            </tr>
          </thead>
          <draggable :list="lista_definicoes" tag="tbody" handle=".handle" itemKey="id">
            <template v-slot:item="{ element, index }">
              <tr :key="index" class="escopo-tabela">
                <th scope="row"
                  class="px-2 py-2 text-center font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white w-10">
                  <div class="handle cursor-pointer">
                    <span>☰</span> <!-- Handle para arrastar -->
                    {{ element.index }}
                  </div>
                </th>
                <td class="w-12 p-4">
                  <div class="selec">
                    <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                      v-model="element.selected">
                    <label class="sr-only">checkbox</label>
                  </div>
                </td>
                <td class="px-6 py-3 w-20">
                  <div class="flex items-center justify-center">
                    <i class="fa-solid fa-copy cursor-pointer" @click.prevent="toggleMenuCopiar(element)"></i>
                  </div>
                </td>
                <td class="px-2 py-2 text-xs w-24">
                  <input type="text" class="w-full p-1 text-corBase border border-corBase/40" v-model="element.codigo"
                    @input="forcarPrefixo(element)" :placeholder="valorPadrao">
                </td>
                <td class="px-2 py-2 text-xs w-60">
                  <input type="text" class="w-full p-1 text-corBase border border-corBase/40" v-model="element.descricao">
                </td>
                <td class="px-2 py-2 text-xs w-60">
                  <input type="text" class="w-full p-1 text-corBase border border-corBase/40" v-model="element.formula">
                </td>
                <td class="px-2 py-2 text-xs w-60">
                  <input type="text" class="w-full p-1 text-corBase border border-corBase/40" v-model="element.condicao">
                </td>
              </tr>
            </template>
          </draggable>
  
          <tr v-if="adicionarNovaDefinicao" class="escopo-tabela">
            <td class="w-10"></td>
            <td class="w-12"></td>
            <td class="w-20"></td>
            <td class="px-2 py-2 text-xs w-24">
              <input type="text" class="w-full p-1 text-corBase border border-corBase/40" v-model="novaDefinicao.codigo"
                @input="forcarPrefixo(novaDefinicao)" :placeholder="valorPadrao">
            </td>
            <td class="px-2 py-2 text-xs w-60">
              <input type="text" class="w-full p-1 text-corBase border border-corBase/40" v-model="novaDefinicao.descricao"
                @input="novaDefinicao.descricao = novaDefinicao.descricao.toUpperCase()">
            </td>
            <td class="px-2 py-2 text-xs w-60">
              <input type="text" class="w-full p-1 text-corBase border border-corBase/40" v-model="novaDefinicao.formula">
            </td>
            <td class="px-2 py-2 text-xs w-60">
              <input type="text" class="w-full p-1 text-corBase border border-corBase/40" v-model="novaDefinicao.condicao">
            </td>
          </tr>
        </table>
  
        <div class="flex w-full justify-end itens-center mt-6 px-4 mb-5 gap-2" v-if="adicionarNovaDefinicao">
          <button class="bg-red-500 hover:bg-screenCor border border-screenCor hover:border-red-500
          text-white hover:text-red-500 py-1 px-4" @click.prevent="toggleDefinicao">
            Cancelar
          </button>
          <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
          text-white hover:text-ativo py-1 px-4" @click.prevent="postnovaDefinicao">
            Adicionar
          </button>
        </div>
  
        <div class="flex justify-center" v-else>
          <button class="text-ativo" @click.prevent="toggleDefinicao">
            <i class="fas fa-plus-circle"></i>
          </button>
        </div>
      </div>
    </div>
  
    <CopiarItemLista v-if="modalCopiar" produto="Definição" @fecharModal="toggleMenuCopiar()"
      @atualizarLista="getListaDefinicoesProjeto()" :itemParaCopiar="itemParaCopiar" />
  
    <MensagemConfirmacaoExclusao mensagem="Deseja mesmo excluir os itens selecionados?" :abrir="modal_confirmacao"
      @feharModal="feharModalConfirmacao" @confirmarExclusao="excluirSelecionados" />
  
    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />
  
    <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />
  
  </template>
  
  <script>
  import axios from 'axios'
  import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue'
  import CopiarItemLista from '@/components/midais/Editar/projeto/CopiarItemLista.vue'
  import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue'
  import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue'
  import draggable from 'vuedraggable'
  import Papa from 'papaparse'
  
  export default {
    name: "DefinicaoProjeto",
  
    props: ['projeto_id'],
  
    data() {
      return {
  
        filtros: [{ campo: "codigo", valor: "" }],
  
        valorPadrao: 'DP',
  
        lista_definicoes: [],
  
        novaDefinicao: {
          projeto: this.projeto_id,
          codigo: '',
          descricao: '',
          formula: '',
          condicao: '',
        },
  
        adicionarNovaDefinicao: false,
  
        modalCopiar: false,
        itemParaCopiar: [],
  
        checkAll: false,
        modal_confirmacao: false,
  
        erros: [],
        exibirErros: false,
  
        sucesso: false,
        sucessoMessage: '',
      }
    },
  
    components: {
      MensagemConfirmacaoExclusao,
      CopiarItemLista,
      SucessoRapido,
      ListagemErros,
      draggable
    },
  
    computed: {
      checkSome() {
        return this.lista_definicoes.some(definicao => definicao.selected)
      },
  
    },
  
    created() {
      this.getListaDefinicoesProjeto()
    },
  
    methods: {
  
      atualizarFiltro(index) {
        this.filtros[index].valor = null;
      },
      adicionarFiltro() {
        this.filtros.push({ campo: "", valor: "" });
      },
      removerFiltro(index) {
        this.filtros.splice(index, 1);
      },
  
      async getListaDefinicoesProjeto() {
        const token = await this.$store.dispatch('getAuthTokenCookie')
  
        await this.$store.dispatch('fetchURLrequest')
  
        this.$store.dispatch("setLoading", true);
  
        const params = this.filtros
          .filter((filtro) => filtro.campo && filtro.valor)
          .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
          .join("&");
  
        await axios.get(`projeto/definicao-projeto/?projeto=${this.projeto_id}&${params}&page_size=999`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
          .then(response => {
            this.lista_definicoes = response.data.results
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            this.$store.dispatch("setLoading", false);
          })
  
      },
  
      async putListaDefinicoesProjeto(definicoes) {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')
        this.$store.dispatch("setLoading", true);
  
        const data = definicoes.map((definicao) => {
          definicao.projeto ? definicao.projeto = this.projeto_id : ''
  
          return definicao
        })
  
        await axios.put(`projeto/definicao-projeto-bulk-update/`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
          }
        })
          .then(response => {
            this.getListaDefinicoesProjeto()
            this.sucesso = true
            this.sucessoMessage = 'Dados do projeto atualizados com sucesso.'
            setTimeout(() => {
              this.sucesso = false;
              this.sucessoMessage = ''
            }, 3000);
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            this.$store.dispatch("setLoading", false);
          })
  
      },
  
      atualizarDefinicoes(definicoesAAtualizar) {
        definicoesAAtualizar.forEach((definicao, index) => {
          definicao.index = index + 1
        })
  
        this.putListaDefinicoesProjeto(definicoesAAtualizar)
      },
  
      feharModalConfirmacao() {
        this.modal_confirmacao = false
      },
  
      forcarPrefixo(definicao) {
        const prefixo = this.valorPadrao;
        if (!definicao.codigo.startsWith(prefixo)) {
          definicao.codigo = prefixo + definicao.codigo.slice(prefixo.length);
        }
        // Converte o restante para maiúsculas
        definicao.codigo = definicao.codigo.toUpperCase();
      },
  
      selectAll() {
        const checkboxes = document.getElementsByClassName('checkbox');
        checkboxes.forEach((checkbox) => {
          checkbox.checked = this.checkAll;
        });
      },
  
      excluirSelecionados() {
        if (this.checkAll) {
          this.deletarDefinicao(this.lista_definicoes)
          this.modal_confirmacao = false
          this.checkAll = false
          return
        }
        const definicoesADeletar = this.lista_definicoes.filter((definicao) => {
          if (definicao.selected) {
            return definicao
          }
        })
  
        const definicoessAAtualizar = this.lista_definicoes.filter(definicao =>
          !definicoesADeletar.some(deletar => deletar.id === definicao.id)
        )
  
  
        this.deletarDefinicao(definicoesADeletar)
        this.modal_confirmacao = false
        this.atualizarDefinicoes(definicoessAAtualizar)
      },
  
      async deletarDefinicao(definicoesADeletar) {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
  
        const definicoesADeletarPromisses = []
  
        for (const definicao of definicoesADeletar) {
  
          definicoesADeletarPromisses.push(
            axios.patch(`projeto/definicao-projeto/${definicao.id}/`, { deletado: 'True' }, {
              headers: {
                Authorization: `Token ${token}`
              }
            })
          )
        }
  
        await Promise.all(definicoesADeletarPromisses)
  
        this.getListaDefinicoesProjeto()
      },
  
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
  
      exportarDefinicoes() {
        this.$store.dispatch("setLoading", true);
        if (!this.lista_definicoes || this.lista_definicoes.length === 0) {
          console.warn("Nenhuma definição disponível para exportação.");
          return;
        }
  
        const headers = [
          "Numeração",
          "Código",
          "Descrição",
          "Fórmula",
          "Condição",
        ];
  
        const data = this.lista_definicoes.map((definicao) => ({
          "Numeração": definicao.index || "",
          "Código": definicao.codigo || "",
          "Descrição": definicao.descricao || "",
          "Fórmula": definicao.formula || "",
          "Condição": definicao.condicao || "",
        }));
  
        const csvContent = Papa.unparse(data, {
          quotes: true,
          delimiter: ";",
          header: true,
          skipEmptyLines: true,
        });
  
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const blob = new Blob([bom, csvContent], { type: "text/csv;charset=utf-8;" });
  
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "definicao.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.$store.dispatch("setLoading", false);
      },
  
      importarDefinicoes(event) {
        const file = event.target.files[0];
        if (!file) return;
  
        const reader = new FileReader();
        reader.onload = async (e) => {
          const content = e.target.result;
  
          const parsedData = Papa.parse(content, {
            header: true,
            skipEmptyLines: true,
            transform: (value) => (value === "" ? null : value),
          });
  
          const rows = parsedData.data;
          console.log("Definições importadas:", rows);
  
          this.erros = { erros: [] };
  
          const token = await this.$store.dispatch('getAuthTokenCookie');
          await this.$store.dispatch('fetchURLrequest');
          this.$store.dispatch("setLoading", true);
  
          try {
            for (const [index, row] of rows.entries()) {
              let codigo = row["Código"]?.trim() || null;
  
              // Verificação se o código existe
              if (codigo && !codigo.startsWith(this.valorPadrao)) {
                codigo = `${this.valorPadrao}${codigo}`;
              }
  
              const definicao = {
                index: row["Numeração"]?.trim() || null,
                codigo: codigo,
                descricao: row["Descrição"]?.trim() || null,
                formula: row["Fórmula"]?.trim() || null,
                condicao: row["Condição"]?.trim() || null,
              };
  
              const camposFaltando = [];
              if (!definicao.codigo) camposFaltando.push("Código");
              if (!definicao.descricao) camposFaltando.push("Descrição");
              if (!definicao.formula) camposFaltando.push("Fórmula");
  
              if (camposFaltando.length > 0) {
                this.erros.erros.push(
                  `Linha ${index + 2}: Campos obrigatórios ausentes: ${camposFaltando.join(", ")}`
                );
                continue;
              }
  
              const novaDefinicao = {
                projeto: this.projeto_id,
                index: definicao.index,
                codigo: definicao.codigo,
                descricao: definicao.descricao,
                formula: definicao.formula,
                condicao: definicao.condicao,
              };
  
              console.log(`Definição ${index + 1} pronta para envio:`, novaDefinicao);
  
              try {
                await axios.post('projeto/definicao-projeto/', novaDefinicao, {
                  headers: { Authorization: `Token ${token}` }
                });
              } catch (error) {
                console.error(`Erro ao cadastrar definição na linha ${index + 2}:`, error);
                this.erros.erros.push(`Linha ${index + 2}: Erro ao cadastrar definição.`);
              }
            }
  
            if (this.erros.erros.length > 0) {
              this.exibirErros = true;
            } else {
              this.sucesso = true;
              this.sucessoMessage = "Definições importadas e cadastradas com sucesso.";
              this.getListaDefinicoesProjeto();
              setTimeout(() => {
                this.sucesso = false;
                this.sucessoMessage = false;
              }, 3000);
            }
          } catch (error) {
            console.error("Erro geral ao importar definições:", error);
            this.erros.erros.push("Erro inesperado ao processar definições.");
            this.exibirErros = true;
          } finally {
            this.$store.dispatch("setLoading", false);
          }
        };
  
        if (this.$refs.fileInput) {
          this.$refs.fileInput.value = "";
        }
        reader.readAsText(file);
      },
  
      toggleMenuCopiar(item) {
        this.modalCopiar = !this.modalCopiar;
        this.itemParaCopiar = { ...item };
      },
  
      toggleDefinicao() {
        this.adicionarNovaDefinicao = !this.adicionarNovaDefinicao
        this.limparCampos();
        this.novaDefinicao.index = this.lista_definicoes.length + 1 || 1
      },
  
      limparCampos() {
        this.novaDefinicao.projeto = this.projeto_id,
          this.novaDefinicao.codigo = '',
          this.novaDefinicao.descricao = '',
          this.novaDefinicao.condicao = '',
          this.novaDefinicao.formula = ''
      },
  
      // async postnovaDefinicao() {
      //   const token = await this.$store.dispatch('getAuthTokenCookie');
      //   await this.$store.dispatch('fetchURLrequest')
  
      //   const novasFormulasPromisses = []
  
      //   for (const novaDefinicao of this.novasFormulas) {
      //     console.log(novaDefinicao)
  
      //     novasFormulasPromisses.push(
      //       axios.post('projeto/formula-projeto/', novaDefinicao, {
      //         headers: {
      //           Authorization: `Token ${token}`
      //         }
      //       })
      //     )
      //   }
  
      //   await Promise.all(novasFormulasPromisses)
  
      //   this.novasFormulas = []
      //   this.limparCampos()
      //   this.getListaDefinicoesProjeto()
      // },
  
      async postnovaDefinicao() {
        try {
          const token = await this.$store.dispatch('getAuthTokenCookie');
          await this.$store.dispatch('fetchURLrequest');
          this.$store.dispatch("setLoading", true);
  
          console.log('this.novaDefinicao recebida: ', this.novaDefinicao)
  
          await axios.post('projeto/definicao-projeto/', this.novaDefinicao, {
            headers: { Authorization: `Token ${token}` }
          });
  
          this.sucesso = true;
          this.sucessoMessage = 'Definição criada com sucesso!';
          setTimeout(() => this.sucesso = false, 3000);
  
          this.adicionarNovaDefinicao = false;
          this.limparCampos();
          this.getListaDefinicoesProjeto();
        } catch (error) {
          console.error(error);
          this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
          this.toggleErros();
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      },
  
      toggleErros() {
        this.exibirErros = !this.exibirErros
      },
  
    }
  
  }
  </script>
  
  <style lang="scss"></style>