<template>
    <div class="container-modal-editar">
        <div class="sombra-modal">
            <div class="conteudo-modal-editar">
                <div class="flex justify-end">
                    <button @click.prevent="this.$emit('fecharModal')">
                        <i class="fas fa-close text-2xl text-corBase"></i>
                    </button>
                </div>

                <div>
                    <div class="cabecalho-formulario">
                        <h1 class="text-xl text-corBase font-bold">Variáveis:</h1>
                    </div>

                    <div class="flex flex-wrap sm:flex-nowrap w-full justify-between items-center mt-6 gap-4">
                        <div class="flex flex-wrap sm:flex-nowrap items-center gap-3 w-full sm:w-auto mb-2">

                            <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
                text-white hover:text-corBase py-2 px-4" @click.prevent="getListaVariaveis()">
                                Pesquisar
                            </button>

                            <button class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500
                text-white hover:text-yellow-500 py-2 px-4" @click.prevent="importarVariaveis()">
                                Importar
                            </button>

                        </div>
                    </div>

                    <div class="container-modelo-tabela">
                        <table class="container-tabela overflow-auto">
                            <thead class="cabecalho-tabela">
                                <tr class="border-b">
                                    <th scope="col" class="px-6 py-2 text-center">
                                        <div class="flex items-center">
                                            <input type="checkbox" class="checkbox" v-model="checkAll"
                                                @change="selectAll">

                                            <label class="sr-only">checkbox</label>
                                        </div>
                                    </th>
                                    <th scope="col" class="px-24 py-2 text-center">Condição</th>
                                    <th scope="col" class="px-12 py-2 text-center">Variável</th>
                                    <th scope="col" class="px-24 py-2 text-center">Descrição</th>
                                    <th scope="col" class="px-3 py-2 text-center">Resposta Padrão</th>
                                    <th scope="col" class="px-3 py-2 text-center">Mínimo</th>
                                    <th scope="col" class="px-3 py-2 text-center">Máximo</th>
                                    <th scope="col" class="px-6 py-2 text-start">
                                        <div class="flex flex-col items-center justify-center">
                                            <span class="block text-center">Obrigatório Obra</span>
                                            <!-- <input type="checkbox" class="checkbox mt-2" v-model="checkAllObrigatorio"
                                                @change="selectAllObrigatorio"> -->
                                        </div>
                                    </th>
                                    <th scope="col" class="px-6 py-2 text-start">
                                        <div class="flex flex-col items-center justify-center">
                                            <span class="block text-center">Obrigatório Orçamento</span>
                                            <!-- <input type="checkbox" class="checkbox mt-2" v-model="checkAllObrigatorio"
                                                @change="selectAllObrigatorio"> -->
                                        </div>
                                    </th>
                                    <th scope="col" class="px-3 py-2 text-start">
                                        <div class="flex flex-col items-center justify-center">
                                            <span class="block text-center">Relatório Orçamento</span>
                                            <!-- <input type="checkbox" class="checkbox mt-2" v-model="checkAllRelOrcamento"
                                                @change="selectAllRelOrcamento"> -->
                                        </div>
                                    </th>
                                    <th scope="col" class="px-3 py-2 text-start">
                                        <div class="flex flex-col items-center justify-center">
                                            <span class="block text-center">Relatório Produção</span>
                                            <!-- <input type="checkbox" class="checkbox mt-2" v-model="checkAllRelProducao"
                                                @change="selectAllRelProducao"> -->
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(variavel, index) in lista_variaveis" :key="variavel.id">
                                    <tr class="escopo-tabela">
                                        <td class="px-6 py-2">
                                            <div class="flex justify-center bg-red-500">
                                                <input :id="'checkbox-table-' + (index + 2)" type="checkbox"
                                                    class="checkbox" v-model="variavel.selected">
                                                <label class="sr-only">checkbox</label>
                                            </div>
                                        </td>
                                        <td scope="col" class="text-center text-xs">
                                            {{ variavel.condicao }}
                                        </td>
                                        <td scope="col" class="text-center text-xs">
                                            {{ variavel.variavel }}
                                        </td>
                                        <td scope="col" class="text-center text-xs">
                                            {{ variavel.descricao }}
                                        </td>
                                        <td scope="col" class="text-center text-xs">
                                            {{ variavel.medida_valor }}
                                        </td>
                                        <td scope="col" class="text-center text-xs">
                                            {{ variavel.valor_min }}
                                        </td>
                                        <td scope="col" class="text-center text-xs">
                                            {{ variavel.valor_max }}
                                        </td>
                                        <td scope="col" class="text-center text-xs">
                                            <input type="checkbox"
                                                class="w-full p-1 text-corBase border border-corBase/40"
                                                v-model="variavel.obrigatorio_obra" @click.prevent>
                                        </td>
                                        <td scope="col" class="text-center text-xs">
                                            <input type="checkbox"
                                                class="w-full p-1 text-corBase border border-corBase/40"
                                                v-model="variavel.obrigatorio_orcamento" @click.prevent>
                                        </td>
                                        <td scope="col" class="text-center text-xs">
                                            <input type="checkbox"
                                                class="w-full p-1 text-corBase border border-corBase/40"
                                                v-model="variavel.rel_orc" @click.prevent>
                                        </td>
                                        <td scope="col" class="text-center text-xs">
                                            <input type="checkbox"
                                                class="w-full p-1 text-corBase border border-corBase/40"
                                                v-model="variavel.rel_prod" @click.prevent>
                                        </td>

                                    </tr>
                                </template>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>


    <PreviewImage v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />

    <ListagemErros v-if="exibirErros === true" :erros="erros" @fecharModal="toggleErros()" />

    <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />

</template>

<script>
import axios from 'axios'
import PreviewImage from '@/components/midais/ged/PreviewImage.vue'
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';

export default {
    name: 'SelecionarVariaveisImportar',

    data() {
        return {
            gestorAtual: '',
            projetoId: '',

            lista_variaveis: [],

            preview_imagem: null,

            exibirVariaveisProjeto: false,

            erros: [],
            exibirErros: false,

            sucesso: false,
            sucessoMessage: '',

            checkAll: false,
            checkAllObrigatorio: false,
            checkAllRelOrcamento: false,
            checkAllRelProducao: false,
        }
    },

    props: ['projetoSelecionado'],

    emits: ['fecharModal', 'atualizarLista'],
    // 'atualizarOpcoesVariavel'

    components: {
        PreviewImage,
        ListagemErros,
        SucessoRapido,
    },

    computed: {

        checkSome() {
            return this.lista_variaveis.some(variavel => variavel.selected)
        },
    },

    created() {
        console.log('projetoSelecionado: ', this.projetoSelecionado)
        this.projetoId = this.$route.params.id
        console.log(this.projetoId)
        this.getListaVariaveis()
    },

    methods: {

        async getListaVariaveis() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest')
                this.$store.dispatch("setLoading", true);

                const response = await axios.get(`projeto/variavel-local/?projeto=${this.projetoSelecionado.id}&page_size=9999`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                })

                this.lista_variaveis = response.data.results

            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async importarVariaveis() {
            if (this.checkSome) {
                const variaveisSelecionadas = this.lista_variaveis
                    .filter((variavel) => variavel.selected)
                    .map((variaveisFiltradas) => ({
                        projeto: this.projetoId,
                        variavel: variaveisFiltradas.id
                    }))

                console.log(variaveisSelecionadas)

                try {

                    const token = await this.$store.dispatch('getAuthTokenCookie')
                    await this.$store.dispatch('fetchURLrequest')

                    this.$store.dispatch("setLoading", true);

                    const response = await axios.post(`projeto/copiar-variaveis/`, {
                        projeto_destino: this.projetoId,
                        variaveisSelecionadas: this.lista_variaveis
                            .filter((variavel) => variavel.selected)
                            .map((variavel) => variavel.id),
                    }, {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    })

                    console.log(response)
                    this.sucesso = true
                    this.sucessoMessage = 'Variáveis importadas do projeto com sucesso!'
                    setTimeout(() => {
                        this.sucesso = false;
                        this.sucessoMessage = ''
                        this.$emit('fecharModal')
                        this.$emit('atualizarLista')
                    }, 3000);
                } catch (error) {
                    console.error(error)
                    this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
                    this.toggleErros()
                } finally {
                    this.$store.dispatch("setLoading", false);
                }
            } else {
                this.erros = { importar: 'Selecione pelo menos uma Variável para importar.' }
                this.toggleErros()
            }
        },

        selectAll() {
            this.lista_variaveis.forEach((variavel) => {
                variavel.selected = this.checkAll;
            });
        },

        toggleErros(){
            this.exibirErros = !this.exibirErros
        },

        // selectAllObrigatorio() {
        //     this.lista_variaveis.forEach((variavel) => {
        //         variavel.obrigatorio = this.checkAllObrigatorio;
        //     });
        // },
        // selectAllRelOrcamento() {
        //     this.lista_variaveis.forEach((variavel) => {
        //         variavel.rel_orc = this.checkAllRelOrcamento;
        //     });
        // },
        // selectAllRelProducao() {
        //     this.lista_variaveis.forEach((variavel) => {
        //         variavel.rel_prod = this.checkAllRelProducao;
        //     });
        // },

    }
}

</script>

<style scoped lang="scss"></style>