<template>

    <div class="container-formulario">
  
      <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
      <div class="cabecalho-formulario">
        <h1 class="text-xl text-corBase">Dados Grupo Cores Vidro:</h1>
  
        <router-link to="/lista-grupo-cor-vidro" class="text-xl text-corBase font-bold">
          <i class="fas fa-chevron-left"></i>
        </router-link>
      </div>
  
      <!-- Escopo do formulario de cadastro mobile-->
      <form @submit.prevent="cadastrarGrupoCorVidro">
        <!--        PARTE DE DADOS DO PRODUTO-->
        <div class="flex flex-col md:flex-row gap-3 my-3 w-full ">
  
          <div class="campo-chapa">
            <label class="font-bold text-corBase">Nome</label>
            <input type="text" v-model="grupo" class="input-form-chapa">
          </div>
  
          <div class="campo-chapa">
            <label class="font-bold text-corBase">Cores</label>
            <v-select v-model="cores" :options="lista_cores" label="descricao" class="w-full" multiple :reduce="cor => cor.id" />
          </div>
  
        </div>
  
  
        <div class="flex w-full justify-end">
          <button class="btn-enviar" type="submit">Salvar</button>
        </div>
  
      </form>
    </div>
    <MensagemSucesso v-if="sucesso == true" rotas="/lista-grupo-cor-vidro"
      mensagem="Grupo de Cores de Vidro cadastrado com sucesso!" />
  
    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />
  
  </template>
  
  <script>
  import axios from "axios";
  import vSelect from "vue-select";
  import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
  import ListagemErros from "../toasts/toast_erro/ListagemErros.vue";
  
  export default {
    name: "CadastroGrupoAplicacaoServico",
  
    components: {
      vSelect,
      MensagemSucesso,
      ListagemErros,
    },
  
  
    data() {
      return {
        sucesso: false,
        grupo: '',
        cores: null,
        lista_cores: [],
  
        exibirErros: false,
        erros: [],
        sucesso: false,
      }
    },
  
    computed: {
      minhaEmpresa() {
        return localStorage.getItem('minhaEmpresa');
      },
  
      isadmin() {
        return this.$store.state.isAdminUser
      },
    },
  
    created() {
      console.log('minhaEmpresa: ', this.minhaEmpresa)
      if (this.minhaEmpresa == 'admin') {
        this.getListaCoresBase()
      } else {
        this.getListaCores()
      }
    },
  
    methods: {
  
      async getListaCoresBase() {
  
        try {
          const token = await this.$store.dispatch('getAuthTokenCookie')
          await this.$store.dispatch('fetchURLrequest')
  
          const response = await axios.get(`produtos/cor-produto-base/?produto_nome=Vidro&ativo=True&page_size=9999`, {
            headers: {
              'Authorization': `Token ${token}`,
            }
          })
  
          this.lista_cores = response.data.results
        } catch (error) {
          console.error(error)
        }
      },
  
      async getListaCores() {
  
        try {
          const token = await this.$store.dispatch('getAuthTokenCookie')
          await this.$store.dispatch('fetchURLrequest')
  
          const response = await axios.get(`produtos/cor-vidro/?ativo=True&page_size=9999`, {
            headers: {
              'Authorization': `Token ${token}`,
            }
          })
  
          this.lista_cores = response.data.results.map((cor_vidro) => {
            return {
              id: cor_vidro.cor_base,
              descricao: cor_vidro.descricao,
            }
          })
        } catch (error) {
          console.error(error)
          this.erros = error.response?.data || {geral: 'Ocorreu um erro desconhecido.'}
          this.toggleErros()
        }
      },
  
  
      async cadastrarGrupoCorVidro() {
        try {
          const token = await this.$store.dispatch('getAuthTokenCookie');
          await this.$store.dispatch('fetchURLrequest');
  
          this.$store.dispatch("setLoading", true);
          this.sucesso = false;
  
          const data = {
            ativo: true,
            nome: this.grupo,
            cor_vidro: this.cores
          };
  
          const response = await axios.post('projeto/grupo-cor-vidro/', data, {
            headers: {
              'Authorization': `Token ${token}`,
            }
          });
  
          console.log(response.data);
          this.sucesso = true;
        } catch (error) {
          console.error("Erro ao cadastrar grupo de cor de vidro:", error);
          this.erros = error.response?.data || { geral: "Erro desconhecido ao salvar o grupo de cor de vidro." };
          this.toggleErros();
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      },
  
      toggleErros(){
        this.exibirErros = !this.exibirErros 
      }
  
    }
  
  }
  </script>
  
  <style lang="scss"></style>