<template>
    <div>

        <div class="flex gap-2 w-full">

            <div class="w-full">
                <label class="font-bold text-corBase">Cliente:</label>
                <v-select :options="listaClientes" label="nome_razao" v-model="orcamento.cliente"
                    placeholder="Escolha uma opção" />
            </div>

            <div class="w-full">
                <label class="font-bold text-corBase">Obra:</label>
                <v-select v-model="orcamento.obra" class="w-full" :options="lista_obras" label="descricao"
                    @search="handleSearchObra" :disabled="!orcamento.cliente"
                    :placeholder="orcamento.cliente ? '' : 'Selecione um cliente'">
                    <template #no-options>
                        <span>
                            {{ searchQuery.length < 3 ? 'Digite pelo menos 3 caracteres para buscar.' : buscando
                                ? 'Carregando...' : 'Nenhuma obra encontrada.' }} </span>
                    </template>
                </v-select>
            </div>

            <div class="w-full">
                <label class="font-bold text-corBase">Referência:</label>
                <input type="text" v-model="orcamento.referencia" maxlength="100" placeholder="Digite aqui..." />
            </div>

            <div class="w-full">
                <label class="font-bold text-corBase">Vendedor:</label>
                <v-select v-model="orcamento.vendedor" class="w-full" :options="listaVendedores" label="nome_razao"
                    :reduce="vendedor => vendedor.id" placeholder="Selecione uma opção" />
            </div>

        </div>

        <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />

    </div>
</template>

<script>
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';
import axios from 'axios'
import vSelect from 'vue-select';

export default {
    name: 'DadosOrcamento',

    components: {
        ListagemErros,
        vSelect,
    },

    props: ['orcamento'],

    data() {
        return {
            hoje: this.formatarData(new Date()),

            searchQuery: "",
            buscando: false,

            lista_obras: [],

            estados: [],
            cidades: [],
            lista_status: [],
            listaClientes: [],
            listaVendedores: [],

            exibirErros: false,
            erros: [],
        }
    },

    created() {
        // if (!this.orcamento.criado_em) {
        //     this.orcamento.data_cadastro = this.hoje;
        // } else {
        //     this.orcamento.data_cadastro = this.formatarData(this.orcamento.criado_em.split('T')[0]);
        // }

        this.getListaClientes()
        this.getListaVendedores()
    },

    methods: {

        async handleSearchObra(searchTerm) {
            this.searchQuery = searchTerm; // Atualiza o termo de busca

            if (!searchTerm || searchTerm.length < 3) {
                this.lista_obras = []; // Limpa a lista se não atender o critério
                return;
            }

            try {

                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                this.buscando = true
                // this.$store.dispatch("setLoading", true);

                const response = await axios.get(`obra/?cliente=${this.orcamento?.cliente?.id}&liberado_orcamento=True&descricao=${searchTerm}&page_size=9999`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                this.lista_obras = response.data.results;
            } catch (error) {
                console.error("Erro ao buscar obras:", error);
            } finally {
                this.buscando = false
                // this.$store.dispatch("setLoading", false);
            }
        },

        async getListaVendedores() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get('rh/colaborador/?funcao=Vendedor&ativo=True&page_size=9999', {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                })

                this.listaVendedores = response.data.results.map(item => ({
                    id: item.pessoa.id,
                    nome_razao: item.pessoa.nome_razao
                }))

            } catch (error) {
                console.error(error)
            }
        },

        async getListaClientes() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`pessoa/pessoas-fisica-juridica/?atividade=Cliente&ativo=True&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.listaClientes = response.data.results.map(person => ({
                    id: person.pessoa.id,
                    nome_razao: person.nome || person.razao_social,
                }))
                console.log(this.listaClientes)
            } catch (error) {
                console.error(error)
            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },

        formatarData(data) {
            const d = new Date(data);
            const dia = String(d.getDate()).padStart(2, '0');
            const mes = String(d.getMonth() + 1).padStart(2, '0'); // Mês começa do zero, por isso +1
            const ano = d.getFullYear();
            return `${dia}/${mes}/${ano}`;
        }

    }
}
</script>

<style scoped>
input,
select {
    @apply w-full p-1 border border-[#ccc] rounded-md
}
</style>