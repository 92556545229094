<template>
    <div>

        <!-- Filtros e Ações -->
        <!-- <div class="flex flex-wrap items-end gap-4 mb-6 w-full">
            <div class="w-full md:flex-1">
                <label class="text-corBase">Local</label>
                <v-select v-model="localFilter" :options="lista_locais_vao" label="nome"
                    :reduce="localFilter => localFilter.id" class="w-full" />
            </div>
            <div class="w-full md:flex-1">
                <label class="text-corBase">Código Arquitetônico</label>
                <input v-model="codigoFilter" class="w-full z-10" type="text">
            </div>
        </div> -->
        <div class="mb-4">
            <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
                <div class="px-2 w-1/2">
                    <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
                        <!-- <option value="pessoa">Pessoa</option> -->
                        <option value="ambiente">Ambiente</option>
                        <option value="descricao">Referência</option>
                        <option value="codigo_arquitetonico">Código Arquitetônico</option>
                        <option value="situacao_vao">Situação do Vão</option>
                        <option value="responsavel">Responsável Medida</option>
                        <option value="medidor">Medidor</option>
                        <option value="contramarco">Contramarco</option>
                        <option value="arremate">Arremate</option>

                    </select>
                </div>

                <div class="w-full">

                    <v-select v-if="filtro.campo === 'ambiente'" v-model="filtro.valor" :options="lista_locais_vao"
                        label="nome" :reduce="status => status.nome"></v-select>

                    <v-select v-else-if="filtro.campo === 'situacao_vao'" v-model="filtro.valor"
                        :options="lista_situacao_vao" :reduce="status => status.value" label="label" />

                    <v-select v-else-if="filtro.campo === 'responsavel'" v-model="filtro.valor"
                        :options="lista_responsavel" :reduce="pessoa => pessoa.value" label="label" />

                    <v-select v-else-if="filtro.campo === 'medidor'" v-model="filtro.valor" :options="(() => {
                        const responsavel = filtros.find(f => f.campo === 'responsavel' && f.valor)?.valor;
                        if (responsavel === 'CT') {
                            return lista_stakeholders;
                        } else if (responsavel === 'CD') {
                            return lista_responsavel_medidor;
                        }
                        return [];
                    })()" :reduce="pessoa => pessoa.id" label="nome_razao">
                        <template #no-options>
                            <span v-if="filtros.some(f => f.campo === 'responsavel' && f.valor)">
                                Nenhuma pessoa encontrada.
                            </span>
                            <span v-else>
                                Selecione quem mediu.
                            </span>
                        </template>
                    </v-select>

                    <v-select v-else-if="filtro.campo === 'contramarco' || filtro.campo === 'arremate'"
                        v-model="filtro.valor" :options="lista_contramarco_arremate"
                        :reduce="contramarco_arremate => contramarco_arremate.value" label="label" />


                    <input v-else v-model="filtro.valor" type="text" class="input-form-chapa"
                        placeholder="Insira o valor" />
                </div>

                <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index, filtro.campo)"></i>
            </div>

            <div class="flex w-full justify-center">
                <button class="text-ativo" @click.prevent="adicionarFiltro">
                    <i class="fas fa-plus-circle"></i>
                </button>
            </div>
        </div>

        <div class="flex items-end gap-8 mb-6">
            <div class="flex gap-2">
                <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getVaos(1)">Pesquisar</button>
                <!-- v-if="isadmin === true" -->
                <div v-if="obra.status_obra !== 'Cancelado' && obra.status_obra !== 'Finalizado'">
                    <button @click="$router.push(`/criar-vao/${this.obra.id}`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
                    text-white hover:text-ativo py-2 px-4">
                        Adicionar
                    </button>
                </div>
            </div>
        </div>



        <!-- Listagem dos itens da obra -->
        <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
                <thead class="cabecalho-tabela">
                    <tr class="border-b">
                        <th class="py-2 px-4 text-center">Ações</th>
                        <th class="py-2 px-4 text-center">Imagem do Vão</th>
                        <th class="py-2 px-4 text-center">Quantidade</th>
                        <th class="py-2 px-4 text-center whitespace-nowrap">Largura x Altura <br> (MM)</th>
                        <th class="py-2 px-4 text-center">Ambiente</th>
                        <th class="py-2 px-4 text-center">Código Arquitetônico</th>
                        <th class="py-2 px-12">Referência</th>
                        <th class="py-2 px-4 text-center">Situação do Vão</th>
                        <th class="py-2 px-4 text-center">Medidas</th>
                        <th class="py-2 px-4 text-center">Responsável Medida</th>
                        <th class="py-2 px-4 text-center">Medidor</th>
                        <!-- <th class="py-2 px-4">Observação</th> -->
                        <th class="py-2 px-4"></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="vao in listaVaos" :key="vao.id">
                        <tr class="border-b">
                            <td class="py-2 px-4 text-xs text-center">
                                <div class="flex justify-center items-center gap-2">
                                    <i class="fas fa-times cursor-pointer" @click="selecionarVaoDeletar(vao)"
                                        title="Excluir Vão"></i>

                                    <i class="fa-solid fa-pen-to-square cursor-pointer"
                                        @click="$router.push(`/editar-vao/${obra.id}/${vao.id}`)"
                                        title="Editar Vão"></i>
                                </div>
                            </td>
                            <td class="py-2 px-4">
                                <!-- Verifica se o vão tem uma imagem -->
                                <img v-if="vao.imagem" :src="vao.imagem" alt="Foto do Vão" class="foto-vao"
                                    @click.prevent="openPreviewImage(vao.imagem)" />

                                <!-- Se não houver imagem, exibe uma mensagem alternativa -->
                                <img v-else class="foto-vao"
                                    src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                                    alt="Sem foto">
                            </td>
                            <td class="py-2 px-4 text-center">
                                    {{ vao.quantidade || '-' }}
                            </td>
                            <td class="py-2 px-4 text-center">
                                <p v-if="vao.largura && vao.altura">
                                    {{ vao.largura }} x {{ vao.altura }}
                                </p>
                                <p v-else> - </p>
                            </td>
                            <td class="py-2 px-4 text-center">
                                {{vao.local_vao.map(ambiente => ambiente.nome).join(', ') || '-'}}
                            </td>
                            <td class="py-2 px-4 text-center">{{ vao.codigo_arquitetonico || '-' }}</td>
                            <td class="py-2 px-12"> {{ vao.descricao || '-' }} </td>
                            <td class="py-2 px-4 text-center">{{ replaceSiglaSituacaoVao(vao.situacao_vao) }}</td>
                            <td class="py-2 px-4 text-center">{{ vao.medidas ? 'Sim' : 'Não' }}</td>
                            <td class="py-2 px-4 text-center">
                                {{ vao.medidas && vao.quem_mediu ? replaceSiglaQuemMediu(vao.quem_mediu) : '-' }}
                            </td>
                            <td class="py-2 px-4 text-center">
                                {{ vao.medidas && vao.medidor?.nome_razao ? vao.medidor.nome_razao : '-' }}
                            </td>
                            <!-- <td class="py-2 px-4 text-center">{{ vao.observacao || '-' }}</td> -->
                            <td class="py-2 px-4">
                                <button @click="selecionarVao(vao)" class="text-gray-500" title="Abrir Projetos do Vão">
                                    <i
                                        :class="vaosSelecionados.some(v => v.id === vao.id) ? 'fa-solid fa-caret-up' : 'fa-solid fa-caret-down'"></i>
                                </button>
                            </td>
                        </tr>

                        <!-- Detalhes dos Projetos e Linhas relacionadas -->
                        <tr :id="`vao-${vao.id}`" v-if="vaosSelecionados.some(v => v.id === vao.id)"
                            class="bg-gray-200">
                            <td colspan="20" class="px-4 py-2">
                                <div>
                                    <div>
                                        <h2 class="text-lg font-bold mb-4 text-gray-800">Combinações de Cores</h2>
                                        <div v-for="(cores, index) in vao.combinacoes_cores" :key="index"
                                            class="bg-white shadow-md rounded-lg p-4 mb-4">
                                            <h3 class="text-md font-semibold text-gray-700 mb-2">Combinação {{ index + 1
                                                }}</h3>
                                            <div class="flex flex-wrap gap-4 text-gray-600">
                                                <div class="flex items-center">
                                                    <p class="font-medium text-gray-800 mr-2">Cor Perfil:</p>
                                                    <p>{{ cores.cor_perfil?.descricao || '-' }}</p>
                                                </div>
                                                <div class="flex items-center">
                                                    <p class="font-medium text-gray-800 mr-2">Cor Componente:</p>
                                                    <p>{{ cores.cor_componente?.descricao || '-' }}</p>
                                                </div>
                                                <div class="flex items-center">
                                                    <p class="font-medium text-gray-800 mr-2">Vidro:</p>
                                                    <p>{{ cores.cor_vidro?.descricao || '-' }}</p>
                                                </div>
                                                <div class="flex items-center">
                                                    <p class="font-medium text-gray-800 mr-2">Cor Chapa:</p>
                                                    <p>{{ cores.cor_chapa?.descricao || '-' }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <ul v-if="vao.detalhes && vao.detalhes.length > 0"
                                        class="flex flex-wrap justify-center gap-6">

                                        <!-- Itera sobre cada detalhe de `vao.detalhes` -->
                                        <li v-for="(detalhe, index) in vao.detalhes" :key="index"
                                            class="w-full sm:w-1/2 lg:w-1/4 p-4 bg-white border border-corBase/40 shadow-md rounded-md flex flex-col items-center">

                                            <!-- {{ detalhe }} -->
                                            <!-- Verifica e exibe o projeto -->
                                            <div v-if="detalhe.projeto && detalhe.projeto.length > 0"
                                                class="text-center items-center justify-center mb-4">
                                                <input type="radio" v-model="vao.selectedProjetoIndex" :value="index"
                                                    class="mb-2">

                                                <div v-for="(proj, i) in detalhe.projeto" :key="i">
                                                    <div class="items-center justify-center flex">
                                                        <img v-if="proj.imagem?.url_documento"
                                                            :src="proj.imagem?.url_documento" alt="Imagem do Projeto"
                                                            class="max-w-32 max-h-32 object-cover rounded mb-2 cursor-pointer"
                                                            @click.prevent="openPreviewImage(proj.imagem?.url_documento)">

                                                        <img v-else class="max-w-32 max-h-32 object-cover rounded mb-2"
                                                            src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                                                            alt="Sem foto">
                                                    </div>

                                                    <strong class="block text-base font-semibold">Projeto:</strong>
                                                    <span class="text-gray-700 whitespace-nowrap text-sm">{{
                                                        proj.descricao_fixa }}</span>
                                                </div>
                                            </div>

                                            <!-- Verifica e exibe as tipologias associadas -->
                                            <div v-if="detalhe.tipologias && detalhe.tipologias.length > 0 && detalhe.projeto.length == 0"
                                                class="text-center mt-2">
                                                <span v-for="(tipologia, i) in detalhe.tipologias" :key="i"
                                                    class="text-gray-700 whitespace-nowrap text-sm">
                                                    <div class="items-center justify-center flex">
                                                        <img :src="tipologia.imagem.url_documento"
                                                            alt="Imagem da Tipologia"
                                                            class="max-w-32 max-h-32 object-cover rounded mb-2">
                                                    </div>
                                                    <strong class="block text-base font-semibold ">Tipologia:</strong>
                                                    <span>{{ tipologia.nome }}</span>
                                                    <span v-if="i < detalhe.tipologias.length - 1">,

                                                    </span>
                                                </span>
                                            </div>

                                            <!-- Verifica e exibe as linhas associadas -->
                                            <div v-if="detalhe.linhas && detalhe.linhas.length > 0"
                                                class="text-center mt-2">
                                                <strong class="block text-base font-semibold">Linha:</strong>
                                                <span v-for="(linha, i) in detalhe.linhas" :key="i"
                                                    class="text-gray-700 whitespace-nowrap text-sm">
                                                    {{ linha.linha }}<span v-if="i < detalhe.linhas.length - 1">,
                                                    </span>
                                                </span>
                                            </div>
                                        </li>
                                    </ul>

                                    <!-- Mensagem se não houver projetos nem tipologias -->
                                    <p v-else class="text-center text-gray-500">Sem projetos ou tipologias cadastrados
                                        para este vão.</p>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>

            <Pagination v-if="listaVaos.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
                @change-page="mudarPagina" />
        </div>

        <div class="flex justify-end mt-4">
            <button class="btn-salvar" @click.prevent="gerarOrcamento()">Gerar Orçamento</button>
        </div>
    </div>

    <preview-image v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />
    <SucessoRapido v-if="sucesso === true" :mensagem="sucessoMessage" />
    <ErroRapido v-if="erro === true" :mensagem="erroMessage" />
    <!-- <ModalVariaveisOrcamento v-if="mostrarModalVariaveis" :id="idOrcamento"
        @fecharModalVariaveis="fecharModalVariaveis" /> -->
    <MensagemConfirmacaoExclusao :abrir="exibirModalExclusao" mensagem="Deseja excluir o vão da obra?"
        @confirmarExclusao="deleteVao(vaoSelecionado)" @fecharModal="toggleModalDeletar()" />
</template>


<script>
import axios from 'axios';
import Pagination from '@/utils/Pagination.vue';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue';
import PreviewImage from '@/components/midais/ged/PreviewImage.vue';
import vSelect from 'vue-select';
// import ModalVariaveisOrcamento from '@/components/cadastros_orcamentos_componentes/ModalVariaveisOrcamento.vue';

export default {
    name: "ListarVaos",
    props: ['obra'],

    components: {
        Pagination,
        SucessoRapido,
        ErroRapido,
        MensagemConfirmacaoExclusao,
        PreviewImage,
        vSelect,
        // ModalVariaveisOrcamento
    },

    data() {
        return {
            filtros: [{ campo: "ambiente", valor: "" }],
            lista_locais_vao: [],
            lista_situacao_vao: [
                { label: 'A Construir', value: 'AC' },
                { label: 'Em Construção', value: 'EC' },
                { label: 'Construído e Sem Acabamento', value: 'CSA' },
                { label: 'Construído e Acabado', value: 'CA' },
            ],
            lista_responsavel: [
                { label: 'Contratante', value: 'CT' }, { label: 'Contratada', value: 'CD' }
            ],
            lista_responsavel_medidor: [],
            lista_contramarco_arremate: [
                { label: 'Sim', value: 'True' }, { label: 'Não', value: 'False' }
            ],

            listaVaos: [],
            vaosSelecionados: [],
            linhasProjetoVao: [],

            projetos: [],
            linhas: [],

            preview_imagem: null,

            exibirModalExclusao: false,
            vaoSelecionado: [],

            paginaAtual: 1,
            countItens: '',

            sucesso: false,
            sucessoMessage: '',
            erro: false,
            erroMessage: '',

            codigoFilter: '',
            localFilter: '',

            idOrcamento: '',
            mostrarModalVariaveis: false,
        };
    },

    created() {
        // this.getVaos(1);
        this.getLocaisVao()
        // this.getDadosObra()
        this.getListaPessoasAlocadas(this.obra.id)

        // Adicionar medidores
        if (this.obra.medidores) {
            this.obra.medidores.forEach(medidorItem => {
                if (medidorItem.medidor) {
                    this.lista_responsavel_medidor.push({
                        id: medidorItem.medidor.id,
                        nome_razao: `${medidorItem.medidor.nome_razao} (Medidor)`
                    });
                }
            });
        }

        // Adicionar responsável técnico
        if (this.obra.responsavel_tecnico) {
            this.lista_responsavel_medidor.push({
                id: this.obra.responsavel_tecnico.id,
                nome_razao: `${this.obra.responsavel_tecnico.nome_razao} (Responsável Técnico)`
            });
        }
    },

    watch: {
        vaosSelecionados: {
            deep: true,
            handler(newVal) {
                newVal.forEach((vao, index) => {
                    if (vao.selectedProjetoIndex !== null) {
                        // Determina o índice do próximo vão
                        const currentIndex = this.listaVaos.findIndex(v => v.id === vao.id);
                        const nextIndex = currentIndex + 1;

                        // Verifica se o próximo índice está dentro dos limites da lista de vãos
                        if (nextIndex < this.listaVaos.length) {
                            const nextVao = this.listaVaos[nextIndex];
                            const isAlreadySelected = this.vaosSelecionados.some(v => v.id === nextVao.id);

                            // Se o próximo vão não estiver selecionado, apenas abre o próximo
                            if (!isAlreadySelected) {
                                this.selecionarVao(nextVao);

                                // Aguardar a abertura do próximo vão e rolar suavemente até ele
                                this.$nextTick(() => {
                                    const vaoElement = document.getElementById(`vao-${nextVao.id}`);
                                    if (vaoElement) {
                                        vaoElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                    }
                                });
                            }
                        }
                    }
                });
            }
        },

        filtros: {
            deep: true,
            handler() {
                // Encontra o filtro 'Responsável'
                const filtroResponsavel = this.filtros.find((f) => f.campo === "responsavel");
                // Encontra o filtro 'Medidor'
                const filtroMedidor = this.filtros.find((f) => f.campo === "medidor");

                if (filtroResponsavel && filtroMedidor) {
                    // Se o filtro 'Responsável' mudou, reseta o valor do 'Medidor'
                    if (filtroResponsavel.valor !== filtroMedidor.dependenteDoResponsavel) {
                        filtroMedidor.valor = ""; // Reseta o valor do Medidor
                        filtroMedidor.dependenteDoResponsavel = filtroResponsavel.valor; // Salva o estado atual
                    }
                }
            },
        },

    },

    methods: {

        atualizarFiltro(index) {
            this.filtros[index].valor = null;
        },
        adicionarFiltro() {
            this.filtros.push({ campo: "", valor: "" });
        },
        removerFiltro(index) {
            this.filtros.splice(index, 1);
        },

        replaceSiglaSituacaoVao(status) {
            if (typeof status !== 'string' || !status) {
                return '-';
            }

            return status
                .replace('AC', 'A Construir')
                .replace('EC', 'Em Construção')
                .replace('CSA', 'Construído e Sem Acabamento')
                .replace('CA', 'Construído e Acabado');
        },

        replaceSiglaQuemMediu(status) {
            if (typeof status !== 'string') {
                return '-';
            }

            return status
                .replace('CT', 'Contratante')
                .replace('CD', 'Contratada')
        },

        // fecharModalVariaveis() {
        //     this.mostrarModalVariaveis = false;
        // },

        // Buscar lista de locais do vão
        async getLocaisVao() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`obra/local-vao/?ativo=True&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                // console.log(response.data.results)
                this.lista_locais_vao = response.data.results
            } catch (error) {
                console.error(error);
            }
        },

        // async getDadosObra() {
        //     const token = await this.$store.dispatch("getAuthTokenCookie");
        //     await this.$store.dispatch("fetchURLrequest");

        //     try {
        //         this.$store.dispatch("setLoading", true);
        //         const response = await axios.get(`obra/obra/${this.$route.params.id}`, {
        //             headers: {
        //                 'Authorization': `Token ${token}`
        //             }
        //         })
        //         this.obra = response.data
        //         this.getListaPessoasAlocadas(this.obra.id)
        //         console.log('Obra:')
        //         console.log(this.obra)

        //         // Adicionar medidores
        //         if (this.obra.medidores) {
        //             this.obra.medidores.forEach(medidorItem => {
        //                 if (medidorItem.medidor) {
        //                     this.lista_responsavel_medidor.push({
        //                         id: medidorItem.medidor.id,
        //                         nome_razao: `${medidorItem.medidor.nome_razao} (Medidor)`
        //                     });
        //                 }
        //             });
        //         }

        //         // Adicionar responsável técnico
        //         if (this.obra.responsavel_tecnico) {
        //             this.lista_responsavel_medidor.push({
        //                 id: this.obra.responsavel_tecnico.id,
        //                 nome_razao: `${this.obra.responsavel_tecnico.nome_razao} (Responsável Técnico)`
        //             });
        //         }

        //         console.log("Lista Responsável Medidor:", this.lista_responsavel_medidor);


        //     } catch (error) {
        //         console.error(error)
        //     } finally {
        //         this.$store.dispatch("setLoading", false);
        //     }
        // },

        async getListaPessoasAlocadas() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`obra/pessoa-alocada/?ativo=True&page_size=9999&obra=${this.obra.id}`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                })
                console.log(response.data.results)
                this.lista_stakeholders = response.data.results.map(stakeholder => {
                    return {
                        id: stakeholder.pessoa.id,
                        nome_razao: `${stakeholder.pessoa.nome_razao} (${stakeholder.responsabilidade.descricao})`
                    }
                })
                console.log(this.lista_stakeholders)
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        // Buscar lista de vãos da obra
        async getVaos(pagina) {
            this.vaosSelecionados = []
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            this.$store.dispatch("setLoading", true);
            try {
                const params = this.filtros
                    .filter((filtro) => filtro.campo && filtro.valor)
                    .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
                    .join("&");
                const response = await axios.get(`obra/vao-obra/?${params}&obra=${this.obra.id}&page=${pagina}`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                this.listaVaos = response.data.results;
                // console.log(this.listaVaos)
                this.countItens = response.data.count;
            } catch (error) {
                console.error(error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        // Buscar Projetos e Linhas do Vão selecionado
        async getLinhasProjetoVao(vao_id) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`obra/linhas-projeto-vao-obra/?vao=${vao_id}`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                // console.log(response.data.results)

                // Inicializa um array para armazenar cada item de projeto com suas linhas e tipologias
                const linhasProjetoVao = response.data.results.map(item => ({
                    projeto: Array.isArray(item.projeto) ? item.projeto : (item.projeto ? [item.projeto] : []),
                    linhas: Array.isArray(item.linhas) ? item.linhas : (item.linhas ? [item.linhas] : []),
                    tipologias: Array.isArray(item.tipologias) ? item.tipologias : (item.tipologias ? [item.tipologias] : [])
                }));

                console.log(linhasProjetoVao)

                const vaoIndex = this.vaosSelecionados.findIndex(v => v.id === vao_id);

                if (vaoIndex !== -1) {
                    // Atualiza o vão com a estrutura completa de `linhasProjetoVao`
                    this.vaosSelecionados[vaoIndex].detalhes = linhasProjetoVao;
                } else {
                    // Caso o vão não esteja em vaosSelecionados, adiciona-o com a estrutura completa
                    this.vaosSelecionados.push({
                        id: vao_id,
                        detalhes: linhasProjetoVao
                    });
                }

                // console.log(this.vaosSelecionados[vaoIndex]);
            } catch (error) {
                console.error('Erro ao buscar projetos:', error);
            }
        },

        selecionarVaoDeletar(vao) {
            // if (obra.vinculada_orcamento) {
            //     this.erros = { deletar_obra: 'Você não pode deletar esta obra pois ela está vinculada a um orçamento.' }
            //     this.toggleErros()
            //     return
            // }

            this.vaoSelecionado = vao
            this.toggleModalDeletar()
        },

        toggleModalDeletar() {
            this.exibirModalExclusao = !this.exibirModalExclusao
        },

        // Deletar vão da lista
        async deleteVao(vao) {

            this.$store.dispatch("setLoading", true);

            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                await axios.patch(`obra/vao-obra/${vao.id}/`, { ativo: 'False' }, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                this.sucesso = true
                this.sucessoMessage = 'Vão excluído com sucesso.'
                this.toggleModalDeletar()
                setTimeout(() => {
                    this.sucesso = false;
                    this.sucessoMessage = ''
                    this.mudarPagina(this.paginaAtual);
                }, 3000);

            } catch (error) {
                console.error(error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async gerarOrcamento() {
            const orcamentoDetalhes = this.vaosSelecionados.map(vao => {
                const projetoSelecionado = vao.detalhes[vao.selectedProjetoIndex];
                console.log(projetoSelecionado)

                // Verifica se existe um projeto selecionado
                if (projetoSelecionado) {
                    return {
                        descricaoVao: vao.descricao,
                        projetoNome: projetoSelecionado.projeto[0].descricao_fixa,
                        projetoId: projetoSelecionado.projeto[0].id,
                        linha: projetoSelecionado.linhas[0].nome,
                        linhaId: projetoSelecionado.linhas[0].id,
                        // item_orcamento: projetoSelecionado.item_orcamento
                    };
                }
            }).filter(item => item !== undefined);

            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            // Fazer requisição para cada item do orçamento
            for (const detalhe of orcamentoDetalhes) {
                try {
                    // const response = await axios.get(`engine/ae9251f3-488b-4e6b-ac54-0117e08f22fd/`, {
                    //     headers: {
                    //         Authorization: `Token ${token}`
                    //     }
                    // });

                    // console.log(`Response para projeto ${detalhe.projetoNome}:`, response.data);
                } catch (error) {
                    console.error(`Erro ao gerar orçamento para o projeto ${detalhe.projetoNome}:`, error);
                }
            }

            console.log("Detalhes do Orçamento:", orcamentoDetalhes);
            this.sucesso = true;
            this.sucessoMessage = 'Orçamento gerado com sucesso.';
            setTimeout(() => {
                this.sucesso = false;
                this.sucessoMessage = '';
            }, 3000);
        },

        // Abrir modal quando clicar em um vão
        async selecionarVao(vao) {
            const vaoIndex = this.vaosSelecionados.findIndex(v => v.id === vao.id);

            if (vaoIndex === -1) {
                if (!vao.hasOwnProperty('selectedProjetoIndex')) {
                    vao.selectedProjetoIndex = null;
                }

                this.vaosSelecionados.push(vao);
                await this.getLinhasProjetoVao(vao.id);

                // Rolar suavemente até o próximo vão após adicionar
                this.$nextTick(() => {
                    const vaoElement = document.getElementById(`vao-${vao.id}`);
                    if (vaoElement) {
                        vaoElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                });

            } else {
                this.vaosSelecionados.splice(vaoIndex, 1);
            }
        },

        async mostrarVariaveis(orcamentoId) {
            this.idOrcamento = orcamentoId
            this.mostrarModalVariaveis = true
        },

        mudarPagina(pagina) {
            this.paginaAtual = pagina;
            this.getVaos(pagina);
        },

        openPreviewImage(url) {
            this.preview_imagem = url
        },

        closed_preview_image() {
            this.preview_imagem = ''
        },
    }
}
</script>


<style scoped>
input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.foto-vao {
    min-width: 50px;
    min-height: 50px;
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
}
</style>
