<template>

  <div class="container-formulario">

    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-2xl text-corBase font-bold">Cadastro da Imagem:</h1>

      <router-link to="/ged/lista-imagens" class="text-2xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarImagem">
      <!--        PARTE DE DADOS DO PRODUTO-->
      <div class="div-produtos flex flex-col md:flex md:flex-row">
        <div class="flex flex-col w-full mt-2">


          <div class="imagePreviewWrapper" :style="{ 'background-image': `url(${previewImage})` }"
               @click="selectImage">
          </div>
          <div class="flex gap-3 mt-4">
            <label for="file-input" class="selecionar-arquivo">
              Selecionar
            </label>
            <input id="file-input" type="file" class="hidden" ref="fileInput" @change="handleImagemUpload"
                   @input="pickFile">

          </div>


        </div>
        <div class="flex flex-col w-full">
          <div class="campo-chapa mt-8">
            <label class="text-xl font-bold text-corBase">Descrição:</label>
            <input type="text" v-model="descricao" class="input-form-chapa" required autofocus>
          </div>
          <div class="campo-chapa mt-8">
            <label class="text-xl font-bold text-corBase">Categoria:</label>
            <!-- <Select2 v-model="categoria"
                     class="w-full z-10"
                     :options="lista_categorias"
                     :settings="{ width: '100%'}"
            /> -->
            <v-select v-model="categoria" :options="lista_categorias" :reduce="categoria => categoria.id" label="text" />
          </div>
          <div class="campo-chapa mt-8">
            <label class="text-xl font-bold text-corBase">Subcategoria:</label>
            <!-- <Select2 v-model="sub_categoria"
                     class="w-full z-10"
                     :options="lista_sub_categorias"
                     :settings="{ width: '100%'}"
            /> -->
            <v-select v-model="sub_categoria" :options="lista_sub_categorias" :reduce="sub_categoria => sub_categoria.id" label="text" />
          </div>
        </div>


      </div>


      <div class="flex w-full justify-end">
        <button class="btn-enviar" type="submit">Salvar</button>
      </div>

    </form>
  </div>
  <mensagem-sucesso v-if="sucesso === true" mensagem="Imagem Cadastrada com Sucesso!" rotas="/ged/lista-imagens"/>

</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";
import vSelect from "vue-select";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";

export default {
  name: "CadastroImgaemComponente",

  components: {
    Select2,
    vSelect,
    MensagemSucesso
  },

  data() {
    return {
      sucesso: false,
      previewImage: null,
      fileInput: null,
      lista_categorias: [],
      categoria: '',
      lista_sub_categorias: [],
      sub_categoria: '',
      descricao: '',
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  created() {
    this.getCategorias()
    this.getSubCategorias()
  },

  methods: {

    async cadastrarImagem() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      const data = new FormData();
      data.append('ativo', 'True')
      data.append('descricao', this.descricao)
      data.append('categoria', this.categoria)
      data.append('subcategoria', this.sub_categoria)
      data.append('url_documento', this.$refs.fileInput.files[0]);
      this.$store.dispatch("setLoading", true);
      this.sucesso = false
      await axios.post('ged/documentos/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data);
            this.$store.dispatch("setLoading", false);
            this.descricao = ''
            this.categoria = ''
            this.sub_categoria = ''
            this.fileInput = null
            this.previewImage = null
            this.sucesso = true
          })
          .catch(error => {
            console.log(error);
            this.$store.dispatch("setLoading", false);
          })

    },

    async getCategorias() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('ged/categoria/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_categorias = response.data.results.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getSubCategorias() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('ged/subcategoria/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_sub_categorias = response.data.results.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    handleImagemUpload(event) {
      this.fileInput = event.target.files[0];
    },
    selectImage() {
      this.$refs.fileInput.click()
    },

    pickFile() {
      let input = this.$refs.fileInput
      let file = input.files
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    }

  }


}
</script>

<style lang="scss">

</style>

