<template>
    <div class="container-formulario">

        <div class="cabecalho-formulario">
            <h1 class="text-xl text-corBase font-bold">Vendas</h1>

            <router-link to="/dashboard" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </router-link>
        </div>

        <div class="w-full mb-2 flex">

            <div class="px-2 w-1/3">
                <label class="text-xl text-corBase font-bold">Status:</label>
                <v-select v-model="status" :options="lista_status" class="w-full" :clearable="false" />
            </div>

            <div class="w-full">
                <label class="text-xl text-corBase font-bold ml-2">Datas:</label>
                <div v-for="(filtro, index) in filtros_datas" :key="index" class="flex items-center w-full gap-2">
                    <div class="px-2 w-1/3">
                        <select v-model="filtro.campo" @change="atualizarFiltroData(index)" class="input-form-chapa">
                            <option value="data_venda">Data Venda</option>
                            <!-- <option value="data_criacao">Data Cadastro</option> -->
                            <!-- <option value="data_entrega">Data Entrega</option> -->
                            <!-- <option value="data_cancelamento">Data Cancelamento</option> -->
                        </select>
                    </div>

                    <div class="w-full">
                        <div class="w-full flex gap-2 items-center">
                            <input type="date" v-model="filtro.data_inicial" class="input-form-chapa" :min="dataMinima"
                                :max="dataAtual" placeholder="Data Inicial" />
                            <span>até</span>
                            <input type="date" v-model="filtro.data_final" class="input-form-chapa" :min="dataMinima"
                                :max="dataAtual" placeholder="Data Final" />
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="block">
            <div class="mb-4">
                <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
                    <div class="px-2 w-1/3">
                        <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
                            <option value="codigo">Nº Venda</option>
                            <option value="codigo_orcamento">Nº Orçamento</option>
                            <!-- <option value="cliente">Cliente</option>
                            <option value="obra">Obra</option>
                            <option value="referencia">Referência</option>
                            <option value="vendedor">Vendedor</option>
                            <option value="cep">CEP</option>
                            <option value="logradouro">Logradouro</option>
                            <option value="predio_condominio">Prédio/Condomínio</option>
                            <option value="bairro">Bairro</option>
                            <option value="estado">Estado</option>
                            <option value="cidade">Cidade</option> -->
                        </select>
                    </div>

                    <div class="w-full">
                        <v-select v-if="filtro.campo === 'cliente'" v-model="filtro.valor" :options="lista_clientes"
                            label="nome_razao" :reduce="cliente => cliente.id" placeholder="Escolha uma opção" />

                        <!-- <v-select v-else-if="filtro.campo === 'obra'" v-model="filtro.valor" :options="lista_obras"
                            label="descricao" :reduce="obra => obra.id" placeholder="Escolha uma opção">
                            <template #no-options>
                                <span v-if="filtros.some(f => f.campo === 'cliente' && f.valor)">
                                    Nenhuma obra encontrada.
                                </span>
                                <span v-else>
                                    Selecione um cliente primeiro.
                                </span>
                            </template>
                        </v-select> -->

                        <!-- <v-select v-else-if="filtro.campo === 'vendedor'" v-model="filtro.valor"
                            :options="lista_vendedores" label="nome_razao" :reduce="cliente => cliente.id"
                            placeholder="Escolha uma opção" /> -->

                        <!-- <v-select v-else-if="filtro.campo === 'estado'" v-model="filtro.valor" :options="estados"
                            :reduce="estado => estado.sigla" label="nome" /> -->

                        <!-- <v-select v-else-if="filtro.campo === 'cidade'" v-model="filtro.valor" :options="cidades"
                            :reduce="cidade => cidade.nome" label="nome">
                            <template #no-options>
                                <span v-if="filtros.some(f => f.campo === 'uf' && f.valor)">
                                    Nenhuma cidade encontrada.
                                </span>
                                <span v-else>
                                    Selecione um estado primeiro.
                                </span>
                            </template>
                        </v-select> -->

                        <input v-else v-model="filtro.valor" type="text" class="input-form-chapa"
                            placeholder="Insira o valor" />
                    </div>

                    <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
                </div>

                <div class="flex w-full justify-center">
                    <button class="text-ativo" @click.prevent="adicionarFiltro">
                        <i class="fas fa-plus-circle"></i>
                    </button>
                </div>
            </div>
        </div>



        <div class="flex">

            <div class="flex flex-wrap gap-2 mb-2">

                <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
        text-white hover:text-corBase py-2 px-4" @click.prevent="mudarPagina(1)">Pesquisar
                </button>

                <!-- <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
          text-white hover:text-ativo py-2 px-4" @click.prevent="$router.push(`vendas/cadastro`)">Adicionar
                </button> -->

            </div>
        </div>


        <div class="container-modelo-tabela">
            <table class="container-tabela">
                <thead class="cabecalho-tabela">
                    <tr class="border-b">
                        <th class="px-2 py-3">
                            #
                        </th>
                        <th class="px-2 py-3 text-center">
                            Ações
                        </th>
                        <th class="px-6 py-3 text-center">
                            Status
                        </th>
                        <th class="px-12 py-3 text-center">
                            Nº Venda
                        </th>
                        <th class="px-12 py-3 text-center">
                            Nº Orcamento
                        </th>
                        <th class="px-6 py-3 text-center">
                            Data Venda
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(venda, index) in vendas" :key="index" :class="getCorStatus(venda.status)">
                        <td class="px-2 py-3 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                            {{ index + 1 }}
                        </td>
                        <td class="px-2 py-3 text-xs text-center">
                            <div class="flex justify-center items-center gap-2">
                                <i class="fa-solid fa-paperclip cursor-pointer"
                                    @click.prevent="$router.push(`/orcamento/${orcamento.id}`)"
                                    title="Alterar Orçamento">
                                </i>

                                <i class="fa-solid fa-arrows-rotate cursor-pointer"
                                    @click.prevent="alterarStatus(venda)" title="Alterar Status">
                                </i>

                                <i class="fa-solid fa-copy cursor-pointer" title="Duplicar Orçamento"></i>

                                <i class="fa-solid fa-print cursor-pointer" title="Imprimir Orçamento">
                                </i>
                            </div>
                        </td>
                        <td class="px-6 py-3 text-center">
                            {{ venda.status || '-' }}
                            <p v-if="venda.status == 'Cancelado'" class="text-xs text-gray-500">{{
                                venda.motivo_cancelamento || '-' }}</p>
                        </td>
                        <td class="py-3 text-center text-gray-900">
                            {{ venda.codigo || '-' }}
                        </td>
                        <td class="py-3 text-center text-gray-900">
                            {{ venda.orcamento?.codigo || '-' }}
                        </td>
                        <td class="px-6 py-3 text-center">
                            {{ formataData(venda.criado_em) }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <Pagination v-if="vendas.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
                @change-page="mudarPagina" />
        </div>
    </div>

    <MudarStatus v-if="mostrarAlterarStatus" :venda="vendaSelecionada" @fecharModal="toggleAlterarStatus()"
        @atualizarLista="mudarPagina(paginaAtual)" />
    <!--<ListarStakeholders v-if="mostrarStakeholders" :obraSelecionada="obraSelecionada"
        @fecharModal="toggleStakeholders" />
    <ListarTodosEnderecos v-if="mostrarEnderecos" :orcamentoSelecionado="orcamentoSelecionado"
        @fecharModal="toggleEnderecos()" />
    <ListarContatos v-if="mostrarContatos" :pessoa="pessoaSelecionada" @fecharModal="toggleContatos()" /> -->
    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" /> 
</template>

<script>
import axios from 'axios'

// import ListarStakeholders from './modais/ListarStakeholders.vue';
// import ListarTodosEnderecos from './modais/ListarTodosEnderecos.vue';
// import ListarContatos from './modais/ListarContatos.vue';
import MudarStatus from './modais/MudarStatus.vue';

import ListagemErros from '../toasts/toast_erro/ListagemErros.vue';

import Pagination from '@/utils/Pagination.vue';
import vSelect from 'vue-select';
import formatarData from '@/utils/FormatarData';

export default {
    name: 'ListaVendas',

    components: {
        Pagination,
        // ListarStakeholders,
        // ListarTodosEnderecos,
        // ListarContatos,
        MudarStatus,
        ListagemErros,
        vSelect,
        formatarData
    },

    data() {
        return {
            vendas: [],

            filtros: [{ campo: "codigo", valor: "" }],

            dataAtual: new Date().toISOString().split("T")[0],
            dataMinima: (() => {
                const data = new Date();
                data.setMonth(data.getMonth() - 6);
                return data.toISOString().split("T")[0];
            })(),

            filtros_datas: [{ campo: "data_venda", data_inicial: "", data_final: "" }],

            lista_clientes: [],
            lista_obras: [],
            lista_vendedores: [],
            estados: [],
            cidades: [],
            lista_status: ['A Faturar', 'Faturado', 'Cancelado', 'Todos'],

            status: 'A Faturar',

            vendaSelecionada: [],

            mostrarAlterarStatus: false,
            mostrarStakeholders: false,
            mostrarEnderecos: false,
            mostrarContatos: false,

            exibirErros: false,
            erros: [],

            paginaAtual: 1,
            countItens: '',
        }
    },

    watch: {
        filtros: {
            handler(novosFiltros) {
                const filtroEstado = novosFiltros.find(filtro => filtro.campo === 'estado' && filtro.valor);
                if (filtroEstado) {
                    this.buscarCidades(filtroEstado.valor);
                }

                const filtroObras = novosFiltros.find(filtro => filtro.campo === 'cliente' && filtro.valor);
                if (filtroObras) {
                    this.buscarObras(filtroObras.valor);
                }
            },
            deep: true,
        }
    },

    created() {
        // this.getListaVendas(1)
        console.log('dia de hoje: ', this.dataAtual)
        console.log('1 ano atrás: ', this.dataMinima)
        console.log('filtros_datas', this.filtros_datas)
        this.filtros_datas[0].data_final = this.dataAtual
        this.getListaClientes()
        this.getListaVendedores()
        this.carregarEstados()
    },

    methods: {

        getCorStatus(status) {
            const cores = {
                "Criação": "bg-white border-b",
                "Conferência": "bg-gray-200 border-b",
                "Entregue": "bg-yellow-200 border-b",
                "Expirado": "bg-purple-300 border-b",
                "Vendido": "bg-green-300 border-b",
                "Inativo por Venda": "bg-orange-300 border-b",
                "Cancelado": "bg-red-300 border-b",
            };
            return cores[status] || "bg-white border-b";
        },

        atualizarFiltro(index) {
            this.filtros[index].valor = null;
        },
        adicionarFiltro() {
            this.filtros.push({ campo: "", valor: "" });
        },
        removerFiltro(index) {
            this.filtros.splice(index, 1);
        },

        atualizarFiltroData(index) {
            this.filtros_datas[index].data_inicial = null;
            this.filtros_datas[index].data_final = this.dataAtual;
        },

        async getListaClientes() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')
            try {
                const response = await axios.get(`pessoa/pessoas-fisica-juridica/?&atividade=Cliente&ativo=True&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                })

                this.lista_clientes = response.data.results.map((cliente) => {
                    return {
                        id: cliente.pessoa.id,
                        nome_razao: cliente.nome || cliente.razao_social
                    }
                })
            } catch (error) {
                console.error(error)
            }
        },

        async carregarEstados() {
            try {
                const token = await this.$store.dispatch("getAuthTokenCookie");
                await this.$store.dispatch("fetchURLrequest");
                const response = await axios.get(`obra/estados/`, {
                    headers: {
                        "Authorization": `Token ${token}`
                    }
                });

                this.estados = response.data.map((estado) => ({
                    sigla: estado.sigla,
                    nome: `${estado.nome} (${estado.sigla})`,
                }))

            } catch (error) {
                console.error("Erro ao carregar estados:", error);
            }
        },

        async buscarCidades(estado) {
            try {
                const token = await this.$store.dispatch("getAuthTokenCookie");
                await this.$store.dispatch("fetchURLrequest");
                const response = await axios.get(`obra/municipios/?estado=${estado}`, {
                    headers: {
                        "Authorization": `Token ${token}`
                    }
                });
                this.cidades = response.data.map((cidade) => ({
                    id: cidade.id,
                    nome: cidade.nome,
                }))

            } catch (error) {
                console.error("Erro ao carregar cidades:", error);
            }
        },

        async buscarObras(cliente) {
            try {
                const token = await this.$store.dispatch("getAuthTokenCookie");
                await this.$store.dispatch("fetchURLrequest");
                const response = await axios.get(`obra/?cliente=${cliente}&page_size=9999`, {
                    headers: {
                        "Authorization": `Token ${token}`
                    }
                });
                this.lista_obras = response.data.results

            } catch (error) {
                console.error("Erro ao carregar cidades:", error);
            }
        },

        async getListaVendedores() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')
            try {
                const response = await axios.get(`rh/colaborador/?funcao=Vendedor&ativo=True&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                })

                this.lista_vendedores = response.data.results.map((vendedor) => {
                    return {
                        id: vendedor.pessoa.id,
                        nome_razao: vendedor.pessoa.nome_razao
                    }
                })
            } catch (error) {
                console.error(error)
            }
        },

        async getListaVendas(pagina) {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                this.$store.dispatch("setLoading", true);

                let erroDetectado = false;

                const params = this.filtros
                    .filter((filtro) => filtro.campo && filtro.valor)
                    .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
                    .join("&");

                const paramsData = this.filtros_datas
                    .filter(filtro => filtro.campo && (filtro.data_inicial && filtro.data_final))
                    .map(filtro => {
                        if (['data_criacao', 'data_entrega', 'data_venda', 'data_cancelamento'].includes(filtro.campo)) {
                            const paramsDatas = [];

                            if (filtro.data_inicial) {
                                if (filtro.data_inicial < this.dataMinima) {
                                    this.erros = { data_inicial: 'A data inicial não pode ser anterior a 6 meses.' };
                                    this.toggleErros();
                                    erroDetectado = true;
                                    return null;
                                } else {
                                    paramsDatas.push(`${filtro.campo}_inicial=${encodeURIComponent(filtro.data_inicial)}`);
                                }
                            }

                            if (filtro.data_final) {
                                if (filtro.data_final > this.dataAtual) {
                                    this.erros = { data_final: 'A data final não pode ser posterior a hoje.' };
                                    this.toggleErros();
                                    erroDetectado = true;
                                    return null;
                                } else {
                                    paramsDatas.push(`${filtro.campo}_final=${encodeURIComponent(filtro.data_final)}`);
                                }
                            }

                            return paramsDatas.length ? paramsDatas.join("&") : null;
                        }

                    })
                    .filter(Boolean)

                if (erroDetectado) {
                    console.warn("Erro detectado na validação dos filtros. Requisição cancelada.");
                    return;
                }

                const response = await axios.get(`vendas/?${params}&${paramsData.join("&")}&status=${this.status || ''}&page=${pagina}`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });

                this.vendas = response.data.results;
                this.countItens = response.data.count;
                console.log(this.vendas);

            } catch (error) {
                console.error(error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        mudarPagina(pagina) {
            this.paginaAtual = pagina;
            this.getListaVendas(pagina);
        },

        listarStakeholders(obra) {
            this.obraSelecionada = obra
            this.toggleStakeholders()
        },

        toggleStakeholders() {
            this.mostrarStakeholders = !this.mostrarStakeholders
        },

        listarEnderecos(venda) {
            this.vendaSelecionada = venda
            this.toggleEnderecos()
        },

        toggleEnderecos() {
            this.mostrarEnderecos = !this.mostrarEnderecos
        },

        listarContatos(pessoaId) {
            this.pessoaSelecionada = pessoaId
            this.toggleContatos()
        },

        toggleContatos() {
            this.mostrarContatos = !this.mostrarContatos
        },

        alterarStatus(venda) {
            this.vendaSelecionada = { ...venda }
            this.toggleAlterarStatus()
        },

        toggleAlterarStatus() {
            this.mostrarAlterarStatus = !this.mostrarAlterarStatus
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },

        formataData(data) {
            if (!data) {
                return '-'
            }

            return formatarData(data)
        }
    }
}
</script>

<style scoped lang="scss">

</style>