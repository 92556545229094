<template>

    <div class="block mt-8">

        <div class="mb-4">
            <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
                <div class="px-2 w-1/2">
                    <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
                        <option value="descricao">Projeto</option>
                        <option value="ambiente">Ambiente</option>
                        <option value="ativo">Status</option>
                    </select>
                </div>

                <div class="w-full">

                    <v-select v-if="filtro.campo === 'ativo'" v-model="filtro.valor" :options="lista_status"
                        label="nome" :reduce="status => status.value" placeholder="Escolha uma opção" />

                    <input v-else v-model="filtro.valor" type="text" class="input-form-chapa"
                        placeholder="Digite o desejado" />
                </div>

                <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
            </div>

            <div class="flex w-full justify-center">
                <button class="text-ativo" @click.prevent="adicionarFiltro">
                    <i class="fas fa-plus-circle"></i>
                </button>
            </div>
        </div>

    </div>
    <div class="flex">

        <div class="flex flex-wrap gap-2 mb-2">

            <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
            text-white hover:text-corBase py-2 px-4" @click.prevent="mudarPagina(1)">
                Pesquisar
            </button>

            <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
            text-white hover:text-ativo py-2 px-4" @click.prevent="$router.push(`/orcamento/selecionar-projeto/`)">
                Adicionar
            </button>

            <button class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500
            text-white hover:text-yellow-500 py-2 px-4" @click.prevent="alterarStatusSelecionados()">
                Alterar Status
            </button>

            <button class="bg-blue-500 hover:bg-screenCor border border-screenCor hover:border-blue-500
            text-white hover:text-blue-500 py-2 px-4" @click.prevent="alterarSelecionados()">
                Alterar Selecionados
            </button>

            <button class="bg-red-500 hover:bg-screenCor border border-screenCor hover:border-red-500
            text-white hover:text-red-500 py-2 px-4" @click.prevent="confirmacaoExcluirSelecionados()">
                Excluir Selecionados
            </button>

            <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
            text-white hover:text-ativo py-2 px-4" @click.prevent="salvarItensOrcamento()">
                Salvar
            </button>

            <button class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500
            text-white hover:text-yellow-500 py-2 px-4" @click.prevent="calcularOrcamento()">
                Calcular
            </button>

            <button class="bg-orange-500 hover:bg-screenCor border border-screenCor hover:border-orange-500
            text-white hover:text-orange-500 py-2 px-4" @click.prevent="imprimirEnviarOrcamento()">
                Imprimir/Enviar
            </button>

            <button class="bg-green-500 hover:bg-screenCor border border-screenCor hover:border-green-500
            text-white hover:text-green-500 py-2 px-4" @click.prevent="venderOrcamento()">
                Vender
            </button>

            <button @click.prevent="getOrcamentosIguais()">getOrcamentosIguais</button>

        </div>
    </div>

    <div>
        <h2 class=" text-corBase font-bold">Projetos do Orçamento</h2>
    </div>

    <!-- ARRUMAR CADASTRO DE PROJETO -->

    <div class="container-modelo-tabela">
        <table class="container-tabela overflow-auto">
            <thead class="cabecalho-tabela">
                <tr>
                    <th scope="col" class="px-3 py-3"></th>
                    <th scope="col" class="px-3 py-3">
                        #
                    </th>
                    <th scope="col" class="px-3 py-3">
                        <div class="flex items-center">
                            <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
                            <label class="sr-only">checkbox</label>
                        </div>
                    </th>
                    <th scope="col" class="px-3 py-3 text-center">
                        Ações
                    </th>
                    <th scope="col" class="px-3 py-3 text-center">
                        Status
                    </th>
                    <th scope="col" class="px-3 py-3 text-center">
                        Imagem
                    </th>
                    <th scope="col" class="px-3 py-3">
                        Projeto
                    </th>
                    <th scope="col" class="px-3 py-3 text-center">
                        Largura x Altura
                    </th>
                    <th scope="col" class="px-3 py-3 text-center">
                        Quantidade
                    </th>
                    <th scope="col" class="px-3 py-3 text-center">
                        Ambiente
                    </th>
                    <th scope="col" class="px-3 py-3 text-center">
                        $ Unitário
                    </th>
                    <th scope="col" class="px-3 py-3 text-center">
                        $ Total
                    </th>
                    <th scope="col" class="px-3 py-3 text-center">
                        $ Alterado
                    </th>

                </tr>
            </thead>
            <draggable :list="listaItensOrcamento" tag="tbody" handle=".handle" itemKey="id">
                <template v-slot:item="{ element, index }">
                    <tr :key="index" class="escopo-tabela">
                        <td class="px-3 py-3 text-xs text-center relative">
                            <button @click="toggleDetalhes(element.id)" class="text-gray-500">
                                <i class="fa-solid"
                                    :class="{ 'fa-solid fa-caret-down': expandedRow !== element.id, 'fa-solid fa-caret-up': expandedRow === element.id }"></i>
                            </button>

                            <!-- Div que exibe os detalhes abaixo -->
                            <div v-if="expandedRow === element.id"
                                class="absolute left-0 top-full w-[50vw] text-center bg-gray-100 p-4 shadow-lg rounded-lg z-10">
                                <div class="flex justify-between gap-4">

                                    <div class="w-1/3">
                                        <strong class="flex justify-center mb-2">Dados do Produto:</strong>
                                        <p>
                                            <strong>Cor Perfil: </strong>
                                            <span v-if="element.sem_perfis">Sem Perfis</span>
                                            <span v-else>{{ element.cor_perfis?.descricao || '-' }}</span>
                                        </p>
                                        <p>
                                            <strong>Cor Componente: </strong>
                                            <span v-if="element.sem_componentes">Sem Componentes</span>
                                            <span v-else>{{ element.cor_componentes?.descricao || '-' }}</span>
                                        </p>
                                        <p><strong>Contramarco:</strong> {{ element.contramarco ? 'Sim' : 'Não' }}</p>
                                        <p v-if="element.contramarco"><strong>Medida Contramarco:</strong> 
                                        {{ element.medida_contramarco }}</p>
                                        <p><strong>Perfil de Arremate:</strong> {{ element.arremate ? 'Sim' : 'Não' }}
                                        </p>
                                        <p><strong>Observação:</strong> {{ element.observacao || '-' }}</p>
                                    </div>

                                    <div class="w-1/3">
                                        <strong class="flex justify-center mb-2">Serviços:</strong>
                                        
                                    </div>

                                    <div class="w-1/3">
                                        <strong class="flex justify-center mb-2">Dados do Vão:</strong>
                                        <p><strong>Ambiente:</strong> {{ element.ambiente?.nome || '-' }}</p>
                                        <p><strong>Referência:</strong> {{ element.referencia || '-' }}</p>
                                        <p><strong>Código Arquitetônico:</strong> {{ element.codigo_arquitetonico || '-'
                                            }}</p>
                                        <p><strong>Situação do Vão:</strong> {{
                                            replaceSiglaSituacaoVao(element.situacao_vao) }}</p>
                                        <p><strong>Medidas:</strong> {{ element.medidas ? 'Sim' : 'Não' }}</p>
                                        <p v-if="element.medidas"><strong>Responsável Medida:</strong> {{
                                            replaceSiglaResponsavelMedida(element.quem_mediu) }}</p>
                                        <p v-if="element.medidas"><strong>Medidor:</strong> {{ element.medidor || '-' }}
                                        </p>
                                    </div>

                                </div>
                            </div>

                        </td>
                        <th scope="col"
                            class="text-center font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                            <div class="handle cursor-pointer">
                                <span>☰</span> <!-- Handle para arrastar -->
                                {{ element.index }}
                            </div>
                        </th>
                        <td class="w-4 px-3 py-3">
                            <div class="flex items-center">
                                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                                    v-model="element.selected">
                                <label class="sr-only">checkbox</label>
                            </div>
                        </td>
                        <td class="px-3 py-3 text-xs text-center">
                            <div class="flex justify-center items-center gap-2">
                                <i class="fa-solid fa-pen-to-square cursor-pointer"
                                    @click.prevent="$router.push(`/item-orcamento/${element.id}`)"
                                    title="Editar Item Orçamento"></i>

                                <i class="fa-solid fa-code-fork cursor-pointer"
                                    @click.prevent="mostrarVariaveis(element)" title="Variáveis Item Orçamento"></i>

                                <i class="fa-solid fa-copy cursor-pointer" title="Duplicar Item Orçamento"></i>
                            </div>
                        </td>
                        <td class="px-6 py-3 text-center">
                            <i v-if="element.ativo" class="fa-solid fa-circle-check text-ativo"></i>
                            <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                        </td>
                        <td scope="col" class="px-6 py-2">
                            <div class="flex justify-center items-center">
                                <img v-if="element.imagem" :src="element.imagem.url_documento"
                                    class="cursor-pointer w-10 h-10"
                                    @click="openPreviewImage(element.imagem.url_documento)">
                                <img v-else
                                    src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                                    alt="Sem foto" class="w-10 h-10">
                            </div>
                        </td>
                        <td class="px-3 py-3 text-xs">
                            {{ element.descricao }}
                        </td>
                        <td class="px-3 py-3 text-xs text-center">
                            {{ element.largura }} x {{ element.altura }}
                        </td>
                        <td class="px-3 py-3 text-xs text-center">
                            {{ element.quantidade }}
                        </td>
                        <td class="px-3 py-3 text-xs text-center">
                            {{ element.ambiente?.nome || '-' }}
                        </td>
                        <td class="px-3 py-3 text-xs text-center">
                            {{ element.valor_unitario || '-' }}
                        </td>
                        <td class="px-3 py-3 text-xs text-center">
                            {{ element.valor_total || '-' }}
                        </td>
                        <td class="px-3 py-3 text-xs text-center">
                            <input type="number" v-model="element.valor_alterado" class="input-form-chapa">
                        </td>
                    </tr>
                </template>
            </draggable>
        </table>
        <Pagination v-if="listaItensOrcamento.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
            @change-page="mudarPagina" />
    </div>

    <PreviewImage v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />

    <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />

    <ModalVariaveisOrcamento v-if="exibirVariaveis" :id="itemOrcamentoSelecionado.id"
        @fecharModalVariaveis="toggleModalVariaveis()" :descricao_detalhada="itemOrcamentoSelecionado.descricao"
        :codigo_orcamento="orcamento.codigo" :imagem="itemOrcamentoSelecionado.imagem"
        :largura="itemOrcamentoSelecionado.largura" :altura="itemOrcamentoSelecionado.altura"
        :quantidade="itemOrcamentoSelecionado.quantidade" />

    <MensagemConfirmacaoExclusao :abrir="modalExcluirSelecionados"
        mensagem="Deseja excluir o(s) Item(ns) do Orçamento selecionado(s)?" @confirmarExclusao="excluirSelecionados()"
        @fecharModal="toggleExcluirSelecionados()" />

    <AlterarStatusVaosOrcamento v-if="modalAlterarStatusSelecionados" :itensOrcamento="itensOrcamentoSelecionados"
        @fecharModal="toggleAlterarStatusSelecionados()" @atualizarLista="mudarPagina(paginaAtual)" />

    <AlterarSelecionadosVaoOrcamento v-if="modalAlterarSelecionados" :itensOrcamento="itensOrcamentoSelecionados"
        @fecharModal="toggleAlterarSelecionados()" @atualizarLista="mudarPagina(paginaAtual)" />

    <OrcamentosIguais v-if="exibirOrcamentosIguais" :orcamentos="orcamentosIguais"
        :countOrcamento="countOrcamentosIguais" @fecharModal="toggleModalOrcamentosIguais()" />

    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />


</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select';
import ModalVariaveisOrcamento from '../../ModalVariaveisOrcamento.vue';
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue';
import AlterarStatusVaosOrcamento from '../modais/AlterarSelecionados/AlterarStatusVaosOrcamento.vue';
import AlterarSelecionadosVaoOrcamento from '../modais/AlterarSelecionados/AlterarSelecionadosVaoOrcamento.vue';
import OrcamentosIguais from '../modais/OrcamentosIguais.vue';
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import Pagination from '@/utils/Pagination.vue';
import draggable from 'vuedraggable'
import PreviewImage from '@/components/midais/ged/PreviewImage.vue';

export default {
    name: 'VaosOrcamento',

    props: ['orcamento'],

    components: {
        vSelect,
        ModalVariaveisOrcamento,
        MensagemConfirmacaoExclusao,
        AlterarStatusVaosOrcamento,
        AlterarSelecionadosVaoOrcamento,
        OrcamentosIguais,
        ListagemErros,
        SucessoRapido,
        Pagination,
        draggable,
        PreviewImage,
    },

    data() {
        return {
            checkAll: false,

            filtros: [{ campo: "descricao", valor: "" }],

            listaItensOrcamento: [],

            expandedRow: null,

            preview_imagem: null,

            exibirVariaveis: false,
            itemOrcamentoSelecionado: null,

            itensOrcamentoSelecionados: [],
            modalAlterarStatusSelecionados: false,
            modalAlterarSelecionados: false,

            modalExcluirSelecionados: false,
            itemOrcamentoDeletarSelecionado: null,

            orcamentosIguais: [],
            countOrcamentosIguais: 0,
            exibirOrcamentosIguais: false,

            paginaAtual: 1,
            countItens: 0,

            sucesso: false,
            sucessoMessage: '',
            exibirErros: false,
            erros: [],
        }
    },

    computed: {
        checkSome() {
            return this.listaItensOrcamento.some(itemOrcamento => itemOrcamento.selected)
        }
    },

    created() {
        console.log('this.orcamento: ', this.orcamento)
        this.$store.state.orcamentoIdAtual = this.orcamento.id
        this.$store.state.orcamentoAtual = this.orcamento
    },

    methods: {

        atualizarFiltro(index) {
            this.filtros[index].valor = null;
        },
        adicionarFiltro() {
            this.filtros.push({ campo: "", valor: "" });
        },
        removerFiltro(index) {
            this.filtros.splice(index, 1);
        },

        replaceSiglaSituacaoVao(status) {
            if (typeof status !== 'string' || !status) {
                return '-';
            }

            return status
                .replace('AC', 'A Construir')
                .replace('EC', 'Em Construção')
                .replace('CSA', 'Construído e Sem Acabamento')
                .replace('CA', 'Construído e Acabado');
        },

        replaceSiglaResponsavelMedida(responsavel) {
            if (typeof responsavel !== 'string' || !responsavel) {
                return '-';
            }

            return responsavel
                .replace('CT', 'Contratante')
                .replace('CD', 'Contratada')
        },

        selectAll() {
            this.listaItensOrcamento.forEach((item_orcamento) => {
                item_orcamento.selected = this.checkAll;
            });
        },

        toggleDetalhes(id) {
            this.expandedRow = this.expandedRow === id ? null : id;
        },

        async getItensOrcamento(pagina) {
            try {

                this.$store.dispatch("setLoading", true);
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                const params = this.filtros
                    .filter((filtro) => filtro.campo && filtro.valor)
                    .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
                    .join("&");

                const response = await axios.get(`orcamentos/item-orcamento/?orcamento_id=${this.orcamento.id}&${params}&page=${pagina}`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.listaItensOrcamento = response.data.results
                this.countItens = response.data.count
            } catch (err) {
                console.error(err.message)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        mostrarVariaveis(ItemOrcamento) {
            this.itemOrcamentoSelecionado = ItemOrcamento
            this.toggleModalVariaveis()
        },

        toggleModalVariaveis() {
            this.exibirVariaveis = !this.exibirVariaveis
        },

        alterarStatusSelecionados() {
            if (this.checkSome) {
                this.itensOrcamentoSelecionados = this.listaItensOrcamento.filter(perfil => perfil.selected);
                this.toggleAlterarStatusSelecionados();
            } else {
                this.erros = { projeto: ["Selecione pelo menos um Projeto para ser alterado."] };
                this.toggleErros();
            }
        },

        toggleAlterarStatusSelecionados() {
            this.modalAlterarStatusSelecionados = !this.modalAlterarStatusSelecionados;
        },

        alterarSelecionados() {
            if (this.checkSome) {
                this.itensOrcamentoSelecionados = this.listaItensOrcamento.filter(perfil => perfil.selected);
                this.toggleAlterarSelecionados();
            } else {
                this.erros = { projeto: ["Selecione pelo menos um Projeto para ser alterado."] };
                this.toggleErros();
            }
        },

        toggleAlterarSelecionados() {
            this.modalAlterarSelecionados = !this.modalAlterarSelecionados;
        },

        confirmacaoExcluirSelecionados() {
            if (this.checkSome) {
                this.itemOrcamentoDeletarSelecionado = this.listaItensOrcamento.filter(perfil => perfil.selected);
                this.toggleExcluirSelecionados();
            } else {
                this.erros = { projeto: ["Selecione pelo menos um Projeto para ser excluído."] };
                this.toggleErros();
            }
        },

        toggleExcluirSelecionados() {
            this.modalExcluirSelecionados = !this.modalExcluirSelecionados;
        },

        async excluirSelecionados() {
            this.$store.dispatch("setLoading", true);

            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            const dadosParaAtualizar = this.itemOrcamentoDeletarSelecionado.map(itemOrcamento => ({
                id: itemOrcamento.id,
                deletado: true,
                status: false,
            }));

            try {
                const response = await axios.patch(`orcamentos/item-orcamento/atualizar-itens/`,
                    { itens: dadosParaAtualizar },
                    { headers: { Authorization: `Token ${token}` } }
                )

                console.log(response.data)
                this.sucesso = true
                this.sucessoMessage = 'Item(ns) excluído(s) com sucesso!'
                this.toggleExcluirSelecionados()
                this.mudarPagina(this.paginaAtual)
                setTimeout(() => {
                    this.sucesso = false;
                    this.sucessoMessage = ''
                }, 3000);
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async salvarItensOrcamento(exibirMensagemSucesso = true) {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                // Preparando os dados para serem enviados
                const dadosParaSalvar = this.listaItensOrcamento.map((item, index) => ({
                    id: item.id,
                    index: index + 1,
                    valor_alterado: item.valor_alterado
                }));

                const response = await axios.patch(
                    `orcamentos/item-orcamento/atualizar-itens/`,
                    { itens: dadosParaSalvar },
                    {
                        headers: {
                            'Authorization': `Token ${token}`,
                        }
                    }
                );

                console.log("Itens atualizados com sucesso:", response.data);

                if (exibirMensagemSucesso) {
                    this.sucesso = true;
                    this.sucessoMessage = 'Itens salvos/atualizados com sucesso.';
                    setTimeout(() => {
                        this.sucesso = false;
                        this.sucessoMessage = ''
                    }, 3000);
                }

                this.getItensOrcamento(this.paginaAtual);

            } catch (err) {
                console.error("Erro ao atualizar itens do orçamento:", err);
                this.erros = { salvar: "Erro ao salvar itens do orçamento." };
                this.toggleErros();
            }
        },


        async calcularOrcamento() {
            if (this.countItens > 0) {
                this.salvarItensOrcamento(false)
            }

            try {


                this.$store.dispatch("setLoading", true);

                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get(`engine/${this.orcamento.id}/`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                })

                console.log(response.data)
                this.sucesso = true
                this.sucessoMessage = 'Orçamento calculado com sucesso.'
                setTimeout(() => {
                    this.sucesso = false
                    this.sucessoMessage = ''
                }, 3000)

            } catch (error) {
                console.error(error)
                this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
                this.toggleErros()
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async imprimirEnviarOrcamento() {
            if (this.countItens > 0) {
                this.salvarItensOrcamento(false)
            }

            try {

                this.$store.dispatch("setLoading", true);

                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                alert('Imprimir')

                // const response = await axios.get(`engine/${this.orcamento.id}/`, {
                //     headers: {
                //         'Authorization': `Token ${token}`,
                //     }
                // })

                // console.log(response.data)
            } catch (error) {
                console.error(error)
                this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
                this.toggleErros()
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async venderOrcamento() {
            if (this.countItens > 0) {
                this.salvarItensOrcamento(false)
            }

            try {

                this.$store.dispatch("setLoading", true);

                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.post(`vendas/`, {
                    orcamento: this.orcamento.id,
                }, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                })

                // console.log(response.data)
                this.sucesso = true
                this.sucessoMessage = `Orçamento #${this.orcamento.codigo} vendido com sucesso!`
                setTimeout(() => {
                    this.getOrcamentosIguais()
                }, 1000);
                setTimeout(() => {
                    this.sucesso = false;
                    this.sucessoMessage = ''
                }, 3000);
            } catch (error) {
                console.error(error)
                this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
                this.toggleErros()
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getOrcamentosIguais() {
            try {
                this.$store.dispatch("setLoading", true);

                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get(`orcamentos/?orcamento_id=${this.orcamento.id}&cliente=${this.orcamento.cliente.id}&obra=${this.orcamento.obra.id}`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                })

                console.log(response.data)
                this.orcamentosIguais = response.data.results
                this.countOrcamentosIguais = response.data.count
                if (this.countOrcamentosIguais > 0) {
                    this.toggleModalOrcamentosIguais()
                }

            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        toggleModalOrcamentosIguais() {
            this.exibirOrcamentosIguais = !this.exibirOrcamentosIguais
        },

        openPreviewImage(url) {
            this.preview_imagem = url
        },

        closed_preview_image() {
            this.preview_imagem = ''
        },

        mudarPagina(pagina) {
            this.checkAll = false
            this.paginaAtual = pagina
            this.getItensOrcamento(pagina)
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        }

    }
}
</script>