<template>
    <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
        <div class="bg-white p-6 md:p-8 rounded-lg shadow-lg w-full sm:w-full md:w-3/4 lg:w-3/4 max-w-lg">
            <div class="border-b mb-4">
                <h2 class="text-2xl font-semibold text-gray-800 text-center">Alterar Vãos Orçamento</h2>
            </div>

            <div class="space-y-4">

                <div class="w-full flex gap-2">

                    <div class="text-center flex flex-col items-center w-full">
                        <label class="block text-sm font-medium text-corBase mb-1">Cor Perfil:</label>
                        <v-select :options="lista_status" label="label" placeholder="Escolha uma opção" v-model="ativo"
                            :reduce="status => status.value" class="w-full" />
                    </div>

                    <div class="text-center flex flex-col items-center w-full">
                        <label class="block text-sm font-medium text-corBase mb-1">Cor Componente:</label>
                        <v-select :options="lista_status" label="label" placeholder="Escolha uma opção" v-model="ativo"
                            :reduce="status => status.value" class="w-full" />
                    </div>

                </div>


                <div class="w-full flex gap-2">

                    <div class="flex flex-col md:flex-row gap-2 w-full">
                        <div class="flex flex-col w-full">
                            <label class="text-corBase">Contramarco:</label>
                            <v-select v-model="contramarco" :options="lista_contramarco_arremate" label="label"
                                placeholder="Escolha uma opção" class="bg-white"
                                :reduce="contramarco => contramarco.value" :clearable="false" />
                        </div>

                        <div v-if="contramarco" class="flex flex-col w-full">
                            <label class="text-corBase whitespace-nowrap">Medida do Contramarco:</label>
                            <v-select v-model="medida_contramarco" :options="lista_medida_contramarco" label="label"
                                placeholder="Escolha uma opção" class="bg-white"
                                :reduce="medida_contramarco => medida_contramarco.value" :clearable="false" />
                        </div>

                        <div class="flex flex-col w-full">
                            <label class="text-corBase whitespace-nowrap">Perfil de Arremate:</label>
                            <v-select v-model="arremate" :options="lista_contramarco_arremate" label="label"
                                placeholder="Escolha uma opção" class="bg-white" :reduce="arremate => arremate.value"
                                :clearable="false" />
                        </div>
                    </div>

                </div>

            </div>

            <!-- Botões -->
            <div class="mt-6 flex justify-center flex-wrap space-x-4">
                <button class="btn-cancelar" @click.prevent="$emit('fecharModal')">
                    Fechar
                </button>
                <button class="btn-salvar" @click="salvarAlteracoes()">
                    Salvar Alterações
                </button>
            </div>
            <SucessoRapido v-if="sucesso" mensagem="Itens do Orçamento atualizados com sucesso!" />
            <ErroRapido v-if="erro" mensagem="Ocorreu um erro ao atualizar os Itens do Orçamento." />
            <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />
        </div>
    </div>

</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';

export default {
    name: 'AlterarSelecionadosItemOrcamento',
    data() {
        return {
            corPerfil: '',
            corComponente: '',
            contramarco: null,
            medida_contramarco: null,
            arremate: null,

            lista_contramarco_arremate: [
                { label: 'Sim', value: true },
                { label: 'Não', value: false }
            ],
            lista_medida_contramarco: [
                { label: 'Interna', value: 'Interna' },
                { label: 'Externa', value: 'Externa' }
            ],

            sucesso: false,
            erro: false,

            erros: [],
            exibirErros: false,
        };
    },

    components: {
        vSelect,
        SucessoRapido,
        ListagemErros,
        ErroRapido,
    },

    props: ['itensOrcamento'],

    emits: ['fecharModal', 'atualizarLista'],

    created() {
    },

    methods: {

        async salvarAlteracoes() {

            if (this.contramarco && !this.medida_contramarco) {
                this.erros = { medida_contramarco: 'Selecione a medida do contramarco.' }
                this.toggleErros()
                return
            }

            if (this.contramarco == false) {
                this.medida_contramarco = ''
            }

            try {

                this.$store.dispatch("setLoading", true);

                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                const dadosParaAtualizar = this.itensOrcamento.map(itemOrcamento => ({
                    id: itemOrcamento.id,
                    contramarco: this.contramarco != null ? this.contramarco : itemOrcamento.contramarco,
                    medida_contramarco: this.medida_contramarco != null ? this.medida_contramarco : itemOrcamento.medida_contramarco,
                    arremate: this.arremate != null ? this.arremate : itemOrcamento.arremate,
                }));

                if (dadosParaAtualizar.length === 0) {
                    console.warn("Nenhum item para atualizar.");
                    return;
                }

                const response = await axios.patch(
                    `orcamentos/item-orcamento/atualizar-itens/`,
                    { itens: dadosParaAtualizar },
                    {
                        headers: {
                            'Authorization': `Token ${token}`,
                        },
                    }
                );

                console.log("Itens atualizados com sucesso:", response.data);

                // Exibe mensagem de sucesso
                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('fecharModal');
                    this.$emit('atualizarLista');
                }, 3000);

            } catch (error) {
                console.error("Erro ao atualizar itens do orçamento:", error);
                this.erro = true;
                setTimeout(() => {
                    this.erro = false;
                }, 3000);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        }

    },
};
</script>

<style scoped>
.input-campos {
    @apply block w-full p-2 border rounded-md text-corBase;
}
</style>