<template>

  <div class="block">
    <div class="mb-4">
      <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
        <div class="px-2 w-1/3">
          <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
            <option value="codigo">Código</option>
            <option value="descricao">Descrição</option>
            <option value="cor">Cor</option>
            <option value="fase">Fase</option>
            <option value="aplicacao">Aplicação</option>
          </select>
        </div>

        <div class="w-full">

          <v-select v-if="filtro.campo === 'cor'" v-model="filtro.valor" :options="lista_cores" label="nome_razao"
            placeholder="Escolha uma opção" :reduce="cor => cor.id" />

          <v-select v-else-if="filtro.campo === 'fase'" v-model="filtro.valor" :options="fasesEsquadria"
            label="descricao" placeholder="Escolha uma opção" :reduce="fase => fase.descricao" />

          <v-select v-else-if="filtro.campo === 'aplicacao'" v-model="filtro.valor"
            :options="['A Definir', 'Produção', 'Obra']" placeholder="Escolha uma opção" />

          <input v-else v-model="filtro.valor" type="text" class="input-form-chapa" placeholder="Insira o valor" />
        </div>

        <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
      </div>

      <div class="flex w-full justify-center">
        <button class="text-ativo" @click.prevent="adicionarFiltro">
          <i class="fas fa-plus-circle"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="flex flex-wrap sm:flex-nowrap w-full justify-between items-center mt-6 gap-4 mb-2">
    <div class="flex flex-wrap sm:flex-nowrap items-center gap-3 w-full sm:w-auto">
      <button
        class="botoes bg-corBase text-white hover:bg-screenCor hover:border-corBase hover:text-corBase w-full sm:w-auto"
        @click.prevent="getListaComponentesProjeto()">
        Pesquisar
      </button>
      <button
        class="botoes bg-blue-500 text-white hover:bg-screenCor hover:border-blue-500 hover:text-blue-500 w-full sm:w-auto"
        @click.prevent="alterarSelecionados()">
        Alterar Selecionados
      </button>
      <button
        class="botoes bg-red-500 text-white hover:bg-screenCor hover:border-red-500 hover:text-red-500 w-full sm:w-auto"
        @click.prevent="verificarExclusao()">
        Excluir Selecionados
      </button>
      <button
        class="botoes bg-orange-500 text-white hover:bg-screenCor hover:border-orange-500 hover:text-orange-500 w-full sm:w-auto"
        @click.prevent="exportarComponentes()">
        Exportar Componentes
      </button>
      <button
        class="botoes bg-yellow-500 text-white hover:bg-screenCor hover:border-yellow-500 hover:text-yellow-500 w-full sm:w-auto"
        @click.prevent="triggerFileInput">
        Importar Componentes
      </button>
      <button
        class="botoes bg-gray-500 text-white hover:bg-screenCor hover:border-gray-500 hover:text-gray-500 w-full sm:w-auto"
        @click.prevent="toggleFuncoesECondicoes()">
        Ver funções e condições
      </button>
    </div>

    <input type="file" ref="fileInput" class="hidden" @change="importarComponentes" accept=".csv" />

  </div>


  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
        <tr class="border-b">
          <th scope="col" class="px-6 py-2 text-center">#</th>
          <th scope="col" class="px-6 py-2 text-center">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-2 text-start">Opções</th>
          <th scope="col" class="px-6 py-2 text-center">Código</th>
          <th scope="col" class="px-6 py-2 text-start">Imagem</th>
          <th scope="col" class="px-16 py-2 text-center">Descrição</th>
          <th scope="col" class="px-28 py-2 text-start">Condição</th>
          <th scope="col" class="px-6 py-2 text-start">Quantidade</th>
          <th scope="col" class="px-6 py-2 text-start">Medida</th>
          <th scope="col" class="px-20 py-2 text-start">Observação</th>
          <th scope="col" class="px-28 py-2 text-start">Cor</th>
          <th scope="col" class="px-10 py-2 text-start">Fase</th>
          <th scope="col" class="px-6 py-2 text-start">Aplicação</th>
        </tr>
      </thead>


      <draggable :list="componentes" tag="tbody" handle=".handle" itemKey="id">
        <template v-slot:item="{ element, index }">
          <tr :key="index" class="escopo-tabela">
            <th scope="row" class="px-3 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              <div class="handle cursor-pointer">
                <span>☰</span> <!-- Handle para arrastar -->
                {{ element.index }}
              </div>
            </th>
            <td class="text-center">
              <div class="selec">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                  v-model="element.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <td class="w-full text-center">
              <i class="fa-solid fa-magnifying-glass cursor-pointer mr-2"
                @click.prevent="toggleMenuProdutos(element.id)"></i>
              <i class="fa-solid fa-copy cursor-pointer" @click.prevent="toggleMenuCopiar(element)"></i>
            </td>
            <td scope="col" class="text-start text-xs">
              <v-select v-model="element.componente_id" :options="componentesPesquisados" label="codigo_base"
                class="bg-white w-32" :clearable="false" @input="(s) => buscarComponentes(s.target.value)"
                @keydown.enter="atualizarUnicoComponente(element.componente_id, element.index)">
                <template v-slot:option="option">
                  <div @click.prevent="atualizarUnicoComponente(option, element.index)">
                    <span class="font-bold">
                      {{ option.codigo_base }}
                    </span> -
                    {{ option.nome_base }}
                  </div>
                </template>
              </v-select>
              Medição: {{ element.componente_id.unidade_medida?.medicao }} <br>
            </td>
            <td scope="col" class="px-6 py-2">
              <div class="flex justify-center items-center">
                <img v-if="element.componente_id.imagem" :src="element.componente_id.imagem.url_documento"
                  class="cursor-pointer w-10 h-10"
                  @click.prevent="openPreviewImage(element.componente_id.imagem.url_documento)">

                <img v-else
                  src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                  class="w-10 h-10">
              </div>
            </td>
            <td scope="col" class="text-center text-xs">
              {{ element.componente_id.nome_base }}
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.condicao">
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.qtd_formula">
            </td>
            <td scope="col" class="text-start text-xs">
              <div class="relative">
                <input class="input-campos" v-model="element.medida"
                  :disabled="!element.componente_id.unidade_medida?.medicao" :class="{
                    'border border-red-500 bg-gray-300 text-gray-700 opacity-70 cursor-not-allowed': !element.componente_id.unidade_medida?.medicao
                  }" />

                <!-- Ícone de cadeado para indicar bloqueio -->
                <i v-if="!element.componente_id.unidade_medida?.medicao"
                  class="fa-solid fa-lock text-red-600 absolute right-2 top-1/2 transform -translate-y-1/2">
                </i>
              </div>
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.observacao">
            </td>
            <td scope="col" class="text-start text-xs">
              <v-select v-model="element.cor"
                :options="element.componente_id.bloquear_cor ? element.componente_id.cor_base : coresComponente"
                label="descricao" class="bg-white w-full custom-width"
                :disabled="element.componente_id.bloquear_cor && element.componente_id.cor_base.length == 1"
                placeholder="Escolha uma opção" />
              <!-- :options="element.componente_id.cor_base" -->
              <!-- Cor Bloqueada: {{ element.componente_id.bloquear_cor }} <br>
              Cor: {{element.componente_id.cor_base.map(cor => cor.descricao).join(', ') || '-'}} -->
            </td>
            <td scope="col" class="text-start text-xs">
              <select v-model="element.fase_esquadria" class="input-campos">
                <option v-for="fase in fasesEsquadria" :value="fase" :key="fase.id">
                  {{ fase.descricao }}
                </option>
              </select>
            </td>
            <td scope="col" class="text-start text-xs">
              <select v-model="element.aplicacao" class="input-campos">
                <option v-for="aplicacao in ['A Definir', 'Produção', 'Obra']" :key="aplicacao" :value="aplicacao">
                  {{ aplicacao }}
                </option>
              </select>
            </td>

          </tr>
        </template>
      </draggable>


      <tbody>
        <tr v-if="adicionarNovoComponente" class="escopo-tabela">
          <td class="w-full"></td>
          <td class="w-full"></td>
          <td class="w-full text-center">
            <i class="fa-solid fa-magnifying-glass cursor-pointer" @click.prevent="toggleMenuProdutos()"></i>
          </td>
          <td class="w-full">
            <v-select v-model="componenteSelecionado" label="codigo_base" :clearable="false"
              @input="(s) => buscarComponentes(s.target.value)" :options="componentesPesquisados" @select="onSelect()">
              <template v-slot:option="option">
                <div class="w-full" @click.prevent="onSelect(option)">
                  <span class="font-bold">
                    {{ option.codigo_base }}
                  </span> -
                  {{ option.nome_base }}
                </div>
              </template>
            </v-select>
            Medição: {{ novoComponente.medicao }}
          </td>
          <td scope="col" class="px-6 py-2">
            <div class="flex justify-center items-center">
              <img v-if="novoComponente.imagem" :src="novoComponente.imagem" class="cursor-pointer w-10 h-10"
                @click.prevent="openPreviewImage(novoComponente.imagem)">
              <img v-else
                src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                alt="Sem foto" class="w-10 h-10">
            </div>
          </td>
          <td class="w-full text-center">
            {{ novoComponente.descricao }}
          </td>
          <td class="w-full">
            <input class="input-campos" v-model="novoComponente.condicao">
          </td>
          <td class="w-full">
            <input class="input-campos" v-model="novoComponente.qtd_formula">
          </td>
          <td class="w-full relative">
            <input class="input-campos" v-model="novoComponente.medida" :disabled="!novoComponente.medicao" :class="{
              'border border-red-500 bg-gray-300 text-gray-700 opacity-70 cursor-not-allowed': !novoComponente.medicao
            }" />

            <!-- Ícone de cadeado para indicar bloqueio -->
            <i v-if="!novoComponente.medicao"
              class="fa-solid fa-lock text-red-600 absolute right-2 top-1/2 transform -translate-y-1/2">
            </i>
          </td>

          <td class="w-full">
            <input class="input-campos" v-model="novoComponente.observacao">
          </td>
          <td class="w-full">
            <v-select v-model="novoComponente.corComponente"
              :options="novoComponente.bloquear_cor ? novoComponente.lista_cores : coresComponente" label="descricao"
              class="bg-white w-full custom-width" placeholder="Escolha uma opção" :clearable="false"
              :reduce="cor_componente => cor_componente.id" :disabled="novoComponente.bloquear_cor" />
            <!-- Tem cor igual: {{ corProprioPerfil }} -->
            {{ novoComponente.bloquear_cor ? 'cor do perfil' : 'cores importadas' }}
          </td>
          <td class="w-full">
            <select v-model="novoComponente.fase_esquadria" class="input-campos">
              <option v-for="fase in fasesEsquadria" :key="fase.id" :value="fase.id">
                {{ fase.descricao }}
              </option>
            </select>
          </td>

          <td class="w-full">
            <select v-model="novoComponente.aplicacao" class="input-campos">
              <option v-for="aplicacao in ['A Definir', 'Produção', 'Obra']" :key="aplicacao" :value="aplicacao">
                {{ aplicacao }}
              </option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="adicionarNovoComponente" class="flex w-full justify-end itens-center mt-6 px-4 mb-5 gap-2">
      <button class="bg-red-500 hover:bg-screenCor border border-screenCor hover:border-red-500
        text-white hover:text-red-500 py-1 px-4" @click.prevent="toggleComponente">Cancelar</button>
      <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
      text-white hover:text-ativo py-1 px-4" @click.prevent="postNovoComponente">Adicionar</button>
    </div>

    <div v-else class="flex justify-center">
      <button class="text-ativo" @click.prevent="toggleComponente">
        <i class="fas fa-plus-circle"></i>
      </button>
    </div>
  </div>


  <div class="flex items-center w-full sm:w-auto justify-center sm:justify-end mt-2">
    <button class="botoes bg-ativo text-white hover:bg-screenCor hover:border-ativo hover:text-ativo w-full sm:w-auto"
      @click.prevent="atualizarComponentes(componentes)">
      Salvar/Atualizar
    </button>
  </div>

  <preview-image v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />

  <MensagemConfirmacaoExclusao mensagem="Deseja mesmo excluir os itens selecionados?" :abrir="modal_confirmacao"
    @fecharModal="fecharModalConfirmacao" @confirmarExclusao="excluirSelecionados" />

  <modal-produtos v-if="modalProdutos === true" produto="Componente" :itemListaSelecionado="itemListaSelecionado"
    @itemNovoProduto="onSelect" @atualizarLista="getListaComponentesProjeto()" @fecharModal="toggleMenuProdutos()" />

  <copiar-item-lista v-if="modalCopiar" produto="Componente" @fecharModal="toggleMenuCopiar()"
    @atualizarLista="getListaComponentesProjeto()" :itemParaCopiar="itemParaCopiar" />

  <alterar-componentes v-if="modalAlterarSelecionados === true" :componentes="componentesSelecionados"
    :fasesEsquadria="fasesEsquadria" @fecharModal="toggleAlterarSelecionados()"
    @atualizarLista="getListaComponentesProjeto()" />

  <ListagemErros v-if="exibirErros == true" :erros="erros" @fecharModal="toggleErros()" />

  <ListaFuncoesECondicoes v-if="exibirFuncoesECondicoes" @fecharModal="toggleFuncoesECondicoes" />

  <sucesso-rapido v-if="sucesso === true" :mensagem="sucessoMessage" />

</template>

<script>
import Search from '@/utils/Search.vue';
import axios from 'axios'
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue'
import ModalProdutos from '@/components/cadastros_projetos_componentes/alterar_projeto/ModalProdutos.vue'
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import CopiarItemLista from '@/components/midais/Editar/projeto/CopiarItemLista.vue';
import AlterarComponentes from '@/components/midais/Editar/projeto/alterar_selecionados/AlterarComponentes.vue';
import ListaFuncoesECondicoes from '@/components/midais/ajudas/ListaFuncoesECondicoes.vue';
import draggable from 'vuedraggable'
import Search2 from '@/utils/Search2.vue';
import vSelect from "vue-select";
import PreviewImage from '@/components/midais/ged/PreviewImage.vue';
import Papa from "papaparse";

export default {
  name: "ComponenteProjeto",

  props: ['projeto_id', 'dadosProjeto'],

  // emits: ['atualizarComponentes', 'getListaComponentesProjeto', 'atualizarUnicoComponente'],

  components: {
    Search,
    MensagemConfirmacaoExclusao,
    ModalProdutos,
    ListagemErros,
    SucessoRapido,
    CopiarItemLista,
    AlterarComponentes,
    ListaFuncoesECondicoes,
    draggable,
    Search2,
    vSelect,
    PreviewImage
  },

  computed: {
    checkSome() {
      return this.componentes.some(componente => componente.selected)
    }
  },

  data() {
    return {

      filtros: [{ campo: "codigo", valor: "" }],

      componentes: [],

      novoComponente: {
        projeto_id: this.projeto_id,
        componente_id: '',
        qtd_formula: '',
        medida: '',
        condicao: '',
        observacao: '',
        cor: '',
        fase_esquadria: '',
        aplicacao: 'A Definir',
        imagem: '',
        descricao: '',
        medicao: '',
        //index: this.componentes.length + 1
      },

      corProprioComponente: false,

      novosComponentes: [],
      componenteSelecionado: [],
      componentesPesquisados: [],
      coresComponente: [],
      fasesEsquadria: [],
      preview_imagem: '',
      itemParaCopiar: [],

      modalCopiar: false,

      modalProdutos: false,

      checkAll: false,
      modal_confirmacao: false,

      exibirFuncoesECondicoes: false,

      exibirErros: false,
      erros: [],

      adicionarNovoComponente: false,

      modalAlterarSelecionados: false,

      sucesso: false,
      sucessoMessage: '',
    }
  },

  created() {
    this.buscaFases()
    this.buscaCoresComponente()
    this.getListaComponentesProjeto()
  },

  methods: {

    atualizarFiltro(index) {
      this.filtros[index].valor = null;
    },
    adicionarFiltro() {
      this.filtros.push({ campo: "", valor: "" });
    },
    removerFiltro(index) {
      this.filtros.splice(index, 1);
    },

    async buscaCoresComponente() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get(`produtos/cor-componente/?page_size=9999`, {
          headers: { Authorization: `Token ${token}` }
        });
        this.coresComponente = response.data.results.map((cor_componente) => {
          return {
            id: cor_componente.cor_base,
            descricao: cor_componente.descricao,
          }
        });
        console.log(this.coresComponente)
      } catch (error) {
        console.error(error);
      }
    },

    closed_preview_image() {
      this.preview_imagem = ''
    },

    openPreviewImage(url) {
      this.preview_imagem = url
    },

    atualizarUnicoComponente(componente, index) {
      const componenteProjetoAAtualizarId = this.componentes[index - 1].id;

      // 🔒 Se a cor estiver bloqueada e só houver uma opção, define automaticamente
      if (componente.bloquear_cor && Array.isArray(componente.cor_base) && componente.cor_base.length === 1) {
        componente.cor = componente.cor_base[0].id;
      }

      this.componentesPesquisados = [];
      this.putComponente(componente.id, componenteProjetoAAtualizarId, componente.cor);
    },

    atualizarComponentes(componentesAAtualizar) {
      let erroCor = false;

      componentesAAtualizar.forEach((componente, index) => {
        componente.index = index + 1
        // componente.fase_esquadria = componente.fase_esquadria ? componente.fase_esquadria.id : ''

        if (!this.dadosProjeto.habilitar_cor_componente && !componente.cor) {
          erroCor = true;
        }
      })

      if (erroCor) {
        this.erros = { componente: 'Você precisa definir cor em todos os componentes, já que o Filtro de Cores de Componente está desabilitado.' }
        this.toggleErros()
        return;
      }

      const componentesParaSalvar = componentesAAtualizar.map(componente => ({
        ...componente,
        fase_esquadria: componente.fase_esquadria?.id || componente.fase_esquadria || '',
        cor: componente.cor?.id || componente.cor || ''
      }));

      this.putListaComponentes(componentesParaSalvar)
    },

    onSelect(componente) {
      console.log(componente)
      this.componenteSelecionado = `${componente.codigo_base}`

      console.log(componente.cor_base.length)

      if (componente.bloquear_cor && Array.isArray(componente.cor_base) && componente.cor_base.length === 1) {
        this.novoComponente.corComponente = componente.cor_base[0].id
      } else {
        this.novoComponente.corComponente = ''
      }

      // console.log(this.coresComponente)

      // if (componente.cor_base?.nome) {
      //   // Busca a cor correspondente no array `coresComponente`
      //   const corEncontrada = this.coresComponente.find(cor => cor.nome === componente.cor_base.nome);

      //   // Se encontrar, define o campo `novoComponente.cor` como o ID da cor encontrada
      //   if (corEncontrada) {
      //     this.novoComponente.cor = corEncontrada.id;
      //     this.corProprioComponente = true
      //     alert('achou a cor')
      //   } else {
      //     console.warn(`Cor com o nome "${componente.cor_base.nome}" não encontrada em coresComponente.`);
      //     this.corProprioComponente = false
      //     alert('n achou a cor')
      //   }
      // }


      this.novoComponente.medida = ''
      this.novoComponente.componente_id = componente.id
      this.novoComponente.descricao = componente.nome_base
      this.novoComponente.bloquear_cor = componente.bloquear_cor
      this.novoComponente.lista_cores = componente.cor_base
      this.novoComponente.imagem = componente.imagem?.url_documento
      this.novoComponente.medicao = componente.unidade_medida?.medicao
    },

    async getListaComponentesProjeto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      this.$store.dispatch("setLoading", true);

      const params = this.filtros
        .filter((filtro) => filtro.campo && filtro.valor)
        .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
        .join("&");

      await axios.get(`projeto/projeto-componente/?projeto_id=${this.projeto_id}&${params}&page_size=999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.componentes = response.data.results
          console.log('COMPONENTES')
          console.log(this.componentes)
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.$store.dispatch("setLoading", false);
        })
    },

    async putListaComponentes(componentes) {

      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      this.$store.dispatch("setLoading", true);

      const data = componentes.map(componente => {
        componente.componente_id ? componente.componente_id = componente.componente_id.id : ''
        componente.projeto_id ? componente.projeto_id = this.projeto_id : ''
        // componente.cor ? componente.cor = componente.cor.id : ''

        return componente
      })

      await axios.put(`projeto/projeto-componente-bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.getListaComponentesProjeto()
          this.sucesso = true
          this.sucessoMessage = "Dados do projeto atualizados com sucesso."
          setTimeout(() => {
            this.sucesso = false;
            this.sucessoMessage = ''
          }, 3000);
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.$store.dispatch("setLoading", false);
        })

    },

    async putComponente(componenteId, componenteProjetoId, corId = null) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      const data = { componente_id: componenteId };

      if (corId) {
        data.cor = corId;
      }

      await axios.patch(`projeto/projeto-componente/${componenteProjetoId}/`, data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      }).then(response => {
        this.getListaComponentesProjeto();
        this.sucesso = true;
        this.sucessoMessage = "Dados do projeto atualizados com sucesso.";
        setTimeout(() => {
          this.sucesso = false;
          this.sucessoMessage = '';
        }, 3000);
      }).catch(error => {
        console.error(error);
      });
    },

    selectAll() {
      // const checkboxes = document.getElementsByClassName('checkbox');
      // checkboxes.forEach((checkbox) => {
      //   checkbox.checked = this.checkAll;
      // });
      this.componentes.forEach((componente) => {
        componente.selected = this.checkAll;
      });
    },

    verificarExclusao() {
      if (this.checkSome || this.checkAll) {
        this.modal_confirmacao = true
      } else {
        this.erros = { componente: 'Selecione pelo menos um Componente para ser excluído.' }
        this.toggleErros()
      }
    },

    excluirSelecionados() {
      if (this.checkAll) {
        this.deletarComponentes(this.componentes.filter((componente) => componente.selected))
        this.modal_confirmacao = false
        this.checkAll = false
        return
      }
      const componentesADeletar = this.componentes.filter((componente) => {
        if (componente.selected) {
          return componente
        }
      })

      const componentesAAtualizar = this.componentes.filter(componente =>
        !componentesADeletar.some(deletar => deletar.id === componente.id)
      )

      this.deletarComponentes(componentesADeletar)
      this.modal_confirmacao = false
      this.atualizarComponentes(componentesAAtualizar)
    },

    async deletarComponentes(componentesADeletar) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      const componentesADeletarPromisses = []

      for (const componente of componentesADeletar) {

        componentesADeletarPromisses.push(
          axios.patch(`projeto/projeto-componente/${componente.id}/`, { deletado: 'True' }, {
            headers: {
              Authorization: `Token ${token}`
            }
          })
        )
      }

      await Promise.all(componentesADeletarPromisses)

      this.getListaComponentesProjeto()
    },


    async buscarComponentes(searchQuery) {
      if (searchQuery.length > 2) {
        try {
          const token = await this.$store.dispatch('getAuthTokenCookie');
          await this.$store.dispatch('fetchURLrequest')

          this.$store.dispatch("setLoading", true);

          const response = await axios.get(`produtos/produto/?tipo_produto=Componente&filtro_base=${searchQuery}&ativo=True&page_size=9999`, {
            headers: {
              Authorization: `Token ${token}`
            }
          })

          this.componentesPesquisados = response.data.results
        } catch (error) {
          console.error(error);
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      } else {
        this.componentesPesquisados = []
      }
    },

    toggleComponente() {
      this.adicionarNovoComponente = !this.adicionarNovoComponente
      this.limparCampos()
      this.novoComponente.index = this.componentes.length + 1 || 1
    },

    limparCampos() {
      this.componenteSelecionado = [];
      this.novoComponente.componente_id = ''
      this.novoComponente.index = ''
      this.novoComponente.descricao = ''
      this.novoComponente.imagem = ''
      this.novoComponente.medicao = ''

      this.novoComponente.condicao = ''
      this.novoComponente.qtd_formula = ''
      this.novoComponente.medida = ''
      this.novoComponente.observacao = ''
      this.novoComponente.cor = ''
      this.novoComponente.aplicacao = 'A Definir'

      const faseDefault = this.fasesEsquadria.find(item => item.descricao === 'A Definir');
      this.novoComponente.fase_esquadria = faseDefault ? faseDefault.id : null;
    },

    async buscaFases() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get(`projeto/fase-esquadria/?page_size=9999`, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
        this.fasesEsquadria = response.data.results;

        const faseDefault = this.fasesEsquadria.find(item => item.descricao === 'A Definir');
        console.log(faseDefault)
        this.novoComponente.fase_esquadria = faseDefault ? faseDefault.id : null;
      } catch (error) {
        console.error(error)
      }
    },

    async postNovoComponente() {

      if (!this.dadosProjeto.habilitar_cor_componente && !this.novoComponente.corComponente) {
        this.erros = { cor: 'Você precisa adicionar uma cor, já que o Filtro de Cores de Componente está desabilitado.' }
        this.toggleErros()
        return
      }

      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');
        this.$store.dispatch("setLoading", true);

        await axios.post('projeto/projeto-componente/', this.novoComponente, {
          headers: { Authorization: `Token ${token}` }
        });

        this.sucesso = true;
        this.sucessoMessage = 'Componente criado com sucesso!';
        setTimeout(() => this.sucesso = false, 3000);

        this.adicionarNovoComponente = false;
        this.componentesPesquisados = [];
        this.limparCampos();
        this.getListaComponentesProjeto();
      } catch (error) {
        console.error(error);
        this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
        this.toggleErros();
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros
    },

    toggleFuncoesECondicoes() {
      this.exibirFuncoesECondicoes = !this.exibirFuncoesECondicoes
    },

    toggleMenuProdutos(itemId) {
      // alert('Menu ' + index)
      this.itemListaSelecionado = itemId
      this.modalProdutos = !this.modalProdutos
    },

    fecharModalConfirmacao() {
      this.modal_confirmacao = false
    },

    alterarSelecionados() {
      if (this.checkSome || this.checkAll) {
        this.toggleAlterarSelecionados()
        this.componentesSelecionados = this.componentes.filter(componente => componente.selected)
        console.log(this.componentesSelecionados)
      } else {
        this.erros = { componente: 'Selecione pelo menos um Componente para ser alterado.' }
        this.toggleErros()
      }
    },

    toggleAlterarSelecionados() {
      this.modalAlterarSelecionados = !this.modalAlterarSelecionados
    },

    toggleMenuCopiar(item) {
      this.modalCopiar = !this.modalCopiar;
      this.itemParaCopiar = { ...item };
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    // SEM PAPAPARSE CASO DÊ ERRO
    // exportarComponentes() {
    //   const headers = [
    //     "Código",
    //     "Condição",
    //     "Quantidade",
    //     "Medida",
    //     "Observação",
    //     "Cor",
    //     "Fase Montagem",
    //     "Aplicação",
    //   ];

    //   const escapeCSV = (value) => {
    //     if (value == null) return ""; // Evita valores null/undefined
    //     const escaped = value.toString().replace(/"/g, '""'); // Escapa aspas duplas
    //     return `"${escaped}"`; // Envolve em aspas duplas
    //   };

    //   const rows = this.componentes.map((componente) => [
    //     escapeCSV(componente.componente_id?.codigo_base),
    //     escapeCSV(componente.condicao),
    //     escapeCSV(componente.qtd_formula),
    //     escapeCSV(componente.medida),
    //     escapeCSV(componente.observacao),
    //     escapeCSV(componente.cor?.nome),
    //     escapeCSV(componente.fase_esquadria?.descricao),
    //     escapeCSV(componente.aplicacao),
    //   ]);

    //   const csvContent = [headers, ...rows]
    //     .map((row) => row.join(",")) // Junta os valores com vírgula
    //     .join("\r\n"); // Usa \r\n para compatibilidade com Windows

    //   const bom = new Uint8Array([0xef, 0xbb, 0xbf]); // Adiciona BOM UTF-8 para compatibilidade
    //   const blob = new Blob([bom, csvContent], { type: "text/csv;charset=utf-8;" });

    //   const link = document.createElement("a");
    //   link.href = URL.createObjectURL(blob);
    //   link.download = "componentes.csv";
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // },

    exportarComponentes() {
      if (!this.componentes || this.componentes.length === 0) {
        console.warn("Nenhum componente disponível para exportação.");
        return;
      }

      const headers = [
        "Numeração",
        "Código",
        "Condição",
        "Quantidade",
        "Medida",
        "Observação",
        "Cor",
        "Fase Montagem",
        "Aplicação",
      ];

      const data = this.componentes.map((componente) => ({
        "Numeração": componente.index || "",
        "Código": componente.componente_id?.codigo_base || "",
        "Condição": componente.condicao || "",
        "Quantidade": componente.qtd_formula || "",
        "Medida": componente.medida || "",
        "Observação": componente.observacao || "",
        "Cor": componente.cor?.descricao || "",
        "Fase Montagem": componente.fase_esquadria?.descricao || "",
        "Aplicação": componente.aplicacao || "",
      }));

      // 🔥 Gera CSV formatado corretamente
      const csvContent = Papa.unparse(data, {
        quotes: true,
        delimiter: ";",
        header: true,
        skipEmptyLines: true,
      });

      const bom = new Uint8Array([0xef, 0xbb, 0xbf]); // 🔥 Adiciona BOM UTF-8 para compatibilidade
      const blob = new Blob([bom, csvContent], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "componentes.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },


    // Método para importar os dados
    importarComponentes(event) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = async (e) => {
        const content = e.target.result;

        const parsedData = Papa.parse(content, {
          header: true,
          skipEmptyLines: true,
          transform: (value) => (value === "" ? null : value),
        });

        const rows = parsedData.data;
        console.log("Dados importados:", rows);

        this.erros = { erros: [] };

        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');
        this.$store.dispatch("setLoading", true);

        try {
          for (const [index, row] of rows.entries()) {
            const componente = {
              index: row["Numeração"]?.trim() || null,
              codigo: row["Código"]?.trim() || null,
              condicao: row["Condição"]?.trim() || null,
              qtd_formula: row["Quantidade"]?.trim() || null,
              medida: row["Medida"]?.trim() || null,
              observacao: row["Observação"]?.trim() || null,
              cor: row["Cor"]?.trim() || null,
              fase_esquadria: row["Fase Montagem"]?.trim() || null,
              aplicacao: row["Aplicação"]?.trim() || null,
            };

            const camposFaltando = [];
            if (!componente.codigo) camposFaltando.push("Código");
            if (!componente.qtd_formula) camposFaltando.push("Quantidade");
            if (!componente.observacao) camposFaltando.push("Observação");
            if (!componente.fase_esquadria) camposFaltando.push("Fase Montagem");

            if (camposFaltando.length > 0) {
              this.erros.erros.push(
                `Linha ${index + 2}: Campos obrigatórios ausentes: ${camposFaltando.join(", ")}`
              );
              continue;
            }

            // Buscar IDs
            const codigoResponse = await this.getIDComponente(componente.codigo);
            componente.componente_id = codigoResponse?.data?.results[0]?.id || null;

            if (componente.cor) {
              const corResponse = await this.getIDCor(componente.cor);
              componente.cor_id = corResponse?.data?.results[0]?.cor_base || null;
            }

            if (componente.fase_esquadria) {
              const faseResponse = await this.getIDFaseMontagem(componente.fase_esquadria);
              componente.fase = faseResponse?.data?.results[0]?.id || null;
            }

            const novoComponente = {
              projeto_id: this.projeto_id,
              index: componente.index,
              componente_id: componente.componente_id,
              condicao: componente.condicao,
              qtd_formula: componente.qtd_formula,
              medida: componente.medida,
              observacao: componente.observacao,
              corComponente: componente.cor_id,
              fase_esquadria: componente.fase,
              aplicacao: componente.aplicacao,
            };

            await axios.post('projeto/projeto-componente/', novoComponente, {
              headers: { Authorization: `Token ${token}` }
            });

            console.log("Componente cadastrado:", novoComponente);
          }

          if (this.erros.erros.length > 0) {
            this.exibirErros = true;
          } else {
            this.sucesso = true;
            this.sucessoMessage = "Componentes importados e cadastrados com sucesso.";
            this.getListaComponentesProjeto();
            setTimeout(() => {
              this.sucesso = false;
              this.sucessoMessage = false;
            }, 3000);
          }

        } catch (error) {
          console.error("Erro geral ao importar componentes:", error);
          this.erros.erros.push("Erro inesperado ao processar componentes.");
          this.exibirErros = true;
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      };

      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }
      reader.readAsText(file);
    },


    async getIDComponente(codigo) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      return axios.get(`produtos/produto/?codigo_base=${codigo}`, {
        headers: { Authorization: `Token ${token}` }
      });
    },

    async getIDCor(cor) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      return axios.get(`produtos/cor-componente/?cor=${cor}`, {
        headers: { Authorization: `Token ${token}` }
      });
    },

    async getIDFaseMontagem(fase) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      return axios.get(`projeto/fase-esquadria/?fase=${fase}`, {
        headers: { Authorization: `Token ${token}` }
      });
    },

  }
}
</script>

<style scoped>
.botoes {
  @apply px-4 py-1 border
}
</style>