<template>
    <div>

        <div>
            <div>
                <div>
                    <input type="checkbox" v-model="dadosProjeto.contramarco"> Habilitar Contramarco
                    <div v-if="dadosProjeto.contramarco" class="flex flex-wrap items-end gap-4 w-full">
                        <div class="flex flex-col">
                            <label class="text-corBase">Medida do Contramarco:</label>
                            <v-select v-model="dadosProjeto.medida_contramarco" :options="lista_medida_contramarco"
                                label="label" placeholder="Escolha uma opção" class="bg-white"
                                :clearable="false" />
                        </div>

                        <div class="flex flex-col">
                            <label class="text-corBase">Desconto Altura:</label>
                            <input type="number" class="input-campos" v-model="dadosProjeto.desconto_altura"
                                placeholder="Digite um valor">
                        </div>

                        <div class="flex flex-col">
                            <label class="text-corBase">Desconto Largura:</label>
                            <input type="number" class="input-campos" v-model="dadosProjeto.desconto_largura"
                                placeholder="Digite um valor">
                        </div>
                    </div>

                </div>

                <div class="mt-2">
                    <input type="checkbox" v-model="dadosProjeto.arremate"> Habilitar Perfil de Arremate

                </div>
            </div>

            <!-- v-if="exibirCorPerfil || exibirCorComponente" -->
            <div class="flex w-full gap-8 justify-end mt-2">
                <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
      text-white hover:text-ativo py-1 px-4" @click.prevent="alterarDadosProjeto()">Salvar</button>
            </div>
        </div>

    </div>
    <SucessoRapido v-if="sucesso" mensagem="Dados do projeto atualizados com sucesso" />
    <listagem-erros v-if="exibirErros == true" :erros="erros" @fecharModal="toggleErros()" />
</template>


<script>
import axios from "axios";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import vSelect from "vue-select";

export default {
    name: 'ContramarcoArremateProjeto',

    components: {
        SucessoRapido,
        ListagemErros,
        vSelect,
    },

    data() {
        return {
            projeto_id: '',

            lista_medida_contramarco: ['Interna', 'Externa'],

            sucesso: false,

            exibirErros: false,
            erros: {}

        }
    },

    props: ['dadosProjeto'],
    emits: ['atualizarLista'],

    created() {
        this.projeto_id = this.$route.params.id
    },

    watch: {
        sucesso() {
            if (this.sucesso) {
                setTimeout(() => {
                    this.sucesso = false
                }, 2000)
            }
        },

    },

    methods: {

        async alterarDadosProjeto() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                console.log(this.tipos_componente)
                console.log(this.tipos_perfil)

                if (!this.dadosProjeto.contramarco) {
                    this.dadosProjeto.medida_contramarco = ''
                }

                const updatedData = {
                    contramarco: this.dadosProjeto.contramarco,
                    medida_contramarco: this.dadosProjeto.medida_contramarco,
                    desconto_altura: this.dadosProjeto.desconto_altura,
                    desconto_largura: this.dadosProjeto.desconto_largura,
                    arremate: this.dadosProjeto.arremate,
                }

                await axios.patch(`projeto/projeto/${this.dadosProjeto.id}/`, updatedData, {
                    headers: {
                        'Authorization': `Token ${token}`
                    },
                })
                this.sucesso = true
                this.$emit('atualizarLista')

            } catch (error) {
                console.error(error)
                if (error.response && error.response.data) {
                    this.erros = error.response.data;
                } else {
                    this.erros = { geral: ["Erro desconhecido ao salvar o projeto."] };
                }
                this.toggleErros();
                this.$store.dispatch("setLoading", false);

            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },

    }

}

</script>


<style lang="scss" scoped>
.nav-principal-projetos {
    @apply hidden md:block md:w-full justify-center
}

.btn-nav-projeto-inativo {
    @apply flex items-center py-2 text-sm w-full sm:w-40 text-center justify-center bg-corBase text-screenCor rounded-md shadow-sm hover:bg-screenCor hover:text-corBase hover:shadow-md transition-colors;
}

.btn-nav-projeto-ativo {
    @apply flex items-center py-2 border border-corBase text-sm w-full sm:w-40 text-center justify-center bg-screenCor text-corBase font-semibold rounded-md shadow-md;
}
</style>