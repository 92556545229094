<template>
    <div>
        <section>
            <!-- Cabeçalho com botão de retorno -->
            <!-- <div class="container-cabecalho flex justify-between mb-4">
                <span class="font-bold text-corBase text-xl mb-4">Editar {{ obra.descricao }}</span>
                <router-link to="/obras" class="text-xl text-corBase font-bold">
                    <i class="fas fa-chevron-left"></i>
                </router-link>
            </div> -->

            <form @submit.prevent="salvarObra">
                <div class="space-y-4">
                    <!-- Seção 1: Dados da Obra -->
                    <div>
                        <button @click.prevent="toggleSection(1)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Obra
                        </button>
                        <div v-if="activeSection === 1"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <Obra :obra="obra" />
                        </div>
                    </div>

                    <!-- Seção 2: Stakeholders -->
                    <div>
                        <button @click.prevent="toggleSection(2)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Stakeholders
                        </button>
                        <div v-if="activeSection === 2"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <ListarStakeholders :obra="obra" @atualizarStakeholders="atualizarStakeholders"
                                @atualizarStakeholdersObrigatorios="atualizarStakeholdersObrigatorios"
                                @clienteEditado="clienteEditado" @atualizarDados="buscarDadosPessoasObra" />
                        </div>
                    </div>

                    <!-- Seção 3: Endereço -->
                    <div>
                        <button @click.prevent="toggleSection(3)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Endereço
                        </button>
                        <div v-if="activeSection === 3"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <Endereco :obra="obra" />
                        </div>
                    </div>

                    <!-- Seção 4: Documentos -->
                    <div>
                        <button @click.prevent="toggleSection(4)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Documentos
                        </button>
                        <div v-if="activeSection === 4"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <Documentos :obra="obra" @atualizarLista="buscarDocumentosObra()" />
                        </div>
                    </div>

                    <!-- Seção 5: Especificações Gerais -->
                    <!-- <div>
                        <button @click.prevent="toggleSection(5)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Especificações Gerais
                        </button>
                        <div v-if="activeSection === 5"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <EspecificacoesGerais :obra="obra" />
                        </div>
                    </div> -->

                    <!-- Seção 5: Histórico Status -->
                    <div>
                        <button @click.prevent="toggleSection(6)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Histórico Status
                        </button>
                        <div v-if="activeSection === 6"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <HistoricoStatus :obra="obra" />
                        </div>
                    </div>

                    <!-- Seção 6: Financeiro -->
                    <div v-if="isadmin === true">
                        <button @click.prevent="toggleSection(7)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Financeiro
                        </button>
                        <div v-if="activeSection === 7"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <Financeiro :obra="obra" />
                        </div>
                    </div>
                </div>

                <!-- Botões para salvar ou cancelar -->
                <div class="flex w-full justify-end itens-center mt-6 gap-2">
                    <button class="btn-cancelar-obra" @click.prevent="$router.push('/obras')">Cancelar</button>
                    <button class="btn-salvar-obra" @click.prevent="salvarObra">Salvar</button>
                </div>
            </form>
        </section>


        <div v-if="exibirModalNovoCliente">
            <!-- Modal de listagem de erros -->
            <div class="fixed inset-0 bg-black/60 flex items-center justify-center z-[10000]">
                <div
                    class="bg-white rounded-lg shadow-lg p-6 w-[90%] sm:w-[600px] max-h-[80vh] overflow-y-auto transform transition-transform duration-300 ease-out scale-95 sm:scale-100">
                    <!-- Cabeçalho do modal -->
                    <div class="flex justify-between items-center mb-4 border-b pb-2">
                        <h2 class="text-lg font-bold text-yellow-500">Novo Cliente</h2>
                        <button @click.prevent="exibirModalNovoCliente = false"
                            class="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 ease-in-out hover:bg-gray-100">
                            <i class="fa-solid fa-x"></i>
                        </button>
                    </div>

                    <div>
                        <p>O endereço foi limpo pela alteração em Cliente.</p>
                    </div>
                </div>
            </div>
        </div>


        <SucessoRapido v-if="sucesso === true" :mensagem="sucessoMessage" />
        <ErroRapido v-if="erro === true" :mensagem="erroMessage" />

        <ListagemErros v-if="exibirErros === true" :erros="erros" @fecharModal="toggleErros" />
    </div>
</template>

<script>
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import Obra from './EtapasCriacaoObra/Obra.vue';
import Endereco from './EtapasCriacaoObra/Endereco.vue';
import ListarStakeholders from './EtapasCriacaoObra/ListarStakeholders.vue';
// import EspecificacoesGerais from './EtapasCriacaoObra/EspecificacoesGerais.vue';
import Documentos from './EtapasCriacaoObra/Documentos.vue';
import Financeiro from './EtapasCriacaoObra/Financeiro.vue';
import HistoricoStatus from './EtapasCriacaoObra/HistoricoStatus.vue';
import axios from 'axios';
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';

export default {
    name: 'EditarObra',
    components: {
        SucessoRapido,
        ErroRapido,
        Obra,
        Endereco,
        ListarStakeholders,
        // EspecificacoesGerais,
        Documentos,
        HistoricoStatus,
        Financeiro,
        ListagemErros,
    },
    props: ['obra'],
    data() {
        return {
            sucesso: false,
            sucessoMessage: '',

            erro: false,
            erroMessage: '',

            exibirModalNovoCliente: false,

            activeSection: 1,
            // obra: {
            //     descricao: '',
            //     cno: '',

            //     contramarco: false,
            //     medida_contramarco: '',

            //     arremate: false,

            //     lista_cor_componente: [],
            //     lista_cor_perfil: [],
            //     lista_vidro: [],
            //     lista_cor_chapa: [],

            //     imagem: null,
            //     imagemFile: null,
            //     endereco: '',
            //     numero: '',
            //     predio_condominio: '',
            //     complemento: '',
            //     bairro: '',
            //     cidade: '',
            //     estado: '',
            //     cep: '',
            //     localizacao: '',
            //     status_obra: '',
            //     comissao: 0,
            //     valor_rt: 0,

            //     responsavel_tecnico: {},
            //     contato_responsavel_tecnico: '',

            //     medidores: [],

            //     cliente: {},
            //     contato_cliente: '',

            //     patrocinador: {},
            //     contato_patrocinador: '',

            //     responsavel: {},
            //     contato_responsavel: '',

            //     stakeholders: [],
            //     arquivos: [],
            // },
            obraId: '',
            countArquivos: '',

            exibirErros: false,
            erros: [],
        };
    },
    computed: {
        isadmin() {
            return this.$store.state.isAdminUser;
        },

    },
    created() {
        this.buscarPessoasAlocadasObra();
        this.buscarDocumentosObra();
    },
    methods: {
        toggleSection(sectionNumber) {
            this.activeSection = this.activeSection === sectionNumber ? null : sectionNumber;
        },

        async buscarDadosPessoasObra() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            this.$store.dispatch("setLoading", true);
            try {
                const response = await axios.get(`/obra/obra/${this.obra.id}`, {
                    headers: { Authorization: `Token ${token}` },
                });
                this.obra.cliente = response.data.cliente;
                this.obra.contato_cliente = response.data.contato_cliente;
                this.obra.patrocinador = response.data.patrocinador;
                this.obra.contato_patrocinador = response.data.contato_patrocinador;
                this.obra.responsavel = response.data.responsavel;
                this.obra.contato_responsavel = response.data.contato_responsavel;
            } catch (error) {
                console.error(error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        // async buscarDadosObra() {
        //     const token = this.$store.state.token;
        //     await this.$store.dispatch('fetchURLrequest');
        //     this.$store.dispatch("setLoading", true);
        //     try {
        //         const response = await axios.get(`/obra/obra/${this.obra.id}`, {
        //             headers: { Authorization: `Token ${token}` },
        //         });
        //         this.obra = response.data;
        //         console.log(this.obra.imagemFile)
        //         await this.buscarPessoasAlocadasObra();
        //         await this.buscarDocumentosObra();
        //     } catch (error) {
        //         console.error(error);
        //     } finally {
        //         this.$store.dispatch("setLoading", false);
        //     }
        // },

        async buscarPessoasAlocadasObra() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            this.$store.dispatch("setLoading", true);
            try {
                const response = await axios.get(`/obra/pessoa-alocada/?ativo=True&obra=${this.obra.id}&page_size=9999`, {
                    headers: { Authorization: `Token ${token}` },
                });
                this.obra.stakeholders = response.data.results;
            } catch (error) {
                console.error(error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async buscarDocumentosObra() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            this.$store.dispatch("setLoading", true);
            try {
                const response = await axios.get(`/obra/documentos-obra/?obra=${this.obra.id}`, {
                    headers: { Authorization: `Token ${token}` },
                });
                this.obra.arquivos = response.data.results;
                this.countArquivos = response.data.count
            } catch (error) {
                console.error(error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },


        atualizarStakeholders(stakeholdersAtualizados) {
            this.obra.stakeholders = stakeholdersAtualizados;
        },

        atualizarStakeholdersObrigatorios(stakeholdersObrigatorios) {
            this.obra.cliente = stakeholdersObrigatorios.cliente;
            this.obra.patrocinador = stakeholdersObrigatorios.patrocinador;
            this.obra.responsavel = stakeholdersObrigatorios.responsavel;
        },

        clienteEditado() {
            this.obra.cep = ''
            this.obra.endereco = ''
            this.obra.numero = ''
            this.obra.predio_condominio = ''
            this.obra.bloco = ''
            this.obra.complemento = ''
            this.obra.estado = ''
            this.obra.cidade = ''

            this.exibirModalNovoCliente = true
        },

        async salvarObra() {
            console.log(this.obra);

            if (this.obra.status_obra == 'Cancelado' && !this.obra.motivo_cancelamento) {
                this.erros = { motivo_cancelamento: 'Defina um motivo para o cancelamento.' }
                this.toggleErros()
                return
            }

            // Monta o payload como um objeto normal
            const payload = {
                descricao: this.obra.descricao,
                status_obra: this.obra.status_obra,
                motivo_cancelamento: this.obra.motivo_cancelamento,
                cno: this.obra.cno,
                ativo: true,

                contramarco: this.obra.contramarco,
                medida_contramarco: this.obra.medida_contramarco,

                arremate: this.obra.arremate,

                // lista_cor_componente: this.obra.lista_cor_componente.map(item => item.id) || [],
                // lista_cor_perfil: this.obra.lista_cor_perfil.map(item => item.id) || [],
                // // lista_cor_vidro: this.obra.lista_cor_vidro.map(item => item.id) || [],
                // lista_vidro: this.obra.lista_vidro.map(item => item.id) || [],
                // lista_cor_chapa: this.obra.lista_cor_chapa.map(item => item.id) || [],

                endereco: this.obra.endereco,
                numero: this.obra.numero,
                predio_condominio: this.obra.predio_condominio,
                complemento: this.obra.complemento,
                bairro: this.obra.bairro,
                cidade: this.obra.cidade,
                estado: this.obra.estado,
                cep: this.obra.cep,
                localizacao: this.obra.localizacao,

                comissao: this.obra.comissao,
                valor_rt: this.obra.valor_rt,

                // cliente: this.obra.cliente?.id || null,
                // contato_cliente: this.obra.contato_cliente,

                // patrocinador: this.obra.patrocinador?.id || null,
                // contato_patrocinador: this.obra.contato_patrocinador,

                // responsavel: this.obra.responsavel?.id || null,
                // contato_responsavel: this.obra.contato_responsavel,

                responsavel_tecnico: this.obra.responsavel_tecnico?.id || null,
                contato_responsavel_tecnico: this.obra.contato_responsavel_tecnico,

                // medidores: this.obra.medidores || []

            };

            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            this.$store.dispatch("setLoading", true);

            try {
                await axios.patch(`obra/obra/${this.obra.id}/`, payload, {
                    headers: {
                        Authorization: `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                // Verifica se há stakeholders ou arquivos para salvar
                if (this.obra.stakeholders.length >= 4) {
                    this.salvarPessoasAlocadas(this.obra.id);
                }

                if (this.obra.arquivos.length > this.countArquivos) {
                    this.salvarArquivos(this.obra.id);
                }

                this.sucesso = true;
                this.sucessoMessage = 'Obra editada com sucesso.';
                setTimeout(() => {
                    this.sucesso = false;
                    this.sucessoMessage = '';
                    this.$router.push('/obras');
                }, 3000);

            } catch (error) {
                console.error('Erro ao editar a obra:', error);

                this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
                this.toggleErros();
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros;
        },

        async salvarPessoasAlocadas(id_obra) {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            const stakeholders = this.obra.stakeholders.filter(
                stakeholder => !['Cliente', 'Patrocinador', 'Responsável de Obra'].includes(stakeholder.funcao)
            );
            try {
                for (const stakeholder of stakeholders) {
                    if (!stakeholder.id) {
                        await axios.post(`obra/pessoa-alocada/`, {
                            obra: id_obra,
                            pessoa: stakeholder.pessoa.id,
                            responsabilidade: stakeholder.funcao.id,
                            contato: stakeholder.contato,
                        }, {
                            headers: { Authorization: `Token ${token}` },
                        });
                    } else {
                        await axios.patch(`obra/pessoa-alocada/${stakeholder.id}/`, {
                            obra: id_obra,
                            pessoa: stakeholder.pessoa.id,
                            responsabilidade: stakeholder.funcao,
                            contato: stakeholder.contato,
                        }, {
                            headers: { Authorization: `Token ${token}` },
                        });
                    }
                }

                // this.sucesso = true;
                // this.sucessoMessage = 'Stakeholders alocados com sucesso.';
                setTimeout(() => {
                    // this.sucesso = false;
                    // this.sucessoMessage = '';
                    this.$router.push('/obras');
                }, 3000);
            } catch (error) {
                console.error('Erro ao salvar pessoas alocadas:', error);
                this.erro = true;
                this.erroMessage = 'Ocorreu um erro ao salvar as pessoas alocadas.';
                setTimeout(() => {
                    this.erro = false;
                    this.erroMessage = '';
                }, 3000);
            }
        },

        async salvarArquivos(id_obra) {
            console.log(this.obra.arquivos)
            const arquivos = this.obra.arquivos.filter((arquivo) => arquivo.url_documento_temporaria)
            console.log(arquivos)

            try {
                const token = this.$store.state.token;
                await this.$store.dispatch('fetchURLrequest');
                this.$store.dispatch("setLoading", true);
                for (const file of arquivos) {

                    const formData = new FormData();
                    formData.append('obra', id_obra);
                    formData.append('ativo', 'True');
                    formData.append('name', `${file.name}`);
                    formData.append('url_documento', file.file);

                    for (let [key, value] of formData.entries()) {
                        console.log(`${key}:`, value);
                    }

                    const response = await axios.post(`obra/documentos-obra/`, formData, {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    });

                    console.log("Arquivo enviado:", response);

                    this.sucesso = true;
                    this.sucessoMessage = 'Obra editada com sucesso.';
                    setTimeout(() => {
                        this.sucesso = false;
                        this.sucessoMessage = '';
                        this.$router.push('/obras');
                    }, 3000);
                }
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        }
    },
};
</script>

<style scoped>
.btn-cancelar-obra {
    @apply px-4 py-1 cursor-pointer text-white bg-inativo border hover:bg-screenCor hover:text-inativo hover:border-inativo
}

.btn-salvar-obra {
    @apply px-4 py-1 cursor-pointer text-white bg-ativo border hover:bg-screenCor hover:text-ativo hover:border-ativo
}

input,
textarea,
select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
}

.form-group {
    margin-bottom: 15px;
}
</style>
