<template>
    <div>

        <div class="my-4">
            <h2 class="text-corBase text-xl font-bold">Perfis</h2>
        </div>

        <input type="checkbox" v-model="projeto.habilitar_produto_perfil"> Habilitar Produto Perfil

        <div v-if="projeto.habilitar_produto_perfil">
            <input type="checkbox" v-model="projeto.habilitar_cor_perfil"> Habilitar Filtro de Cores
        </div>

        <div v-if="projeto.habilitar_produto_perfil && projeto.habilitar_cor_perfil" class="mt-5 space-y-5">
            <!-- Listagem -->
            <div>
                <h2>Listagem:</h2>
                <div class="flex gap-2">
                    <input type="checkbox" v-model="projeto.listagem_perfil" @change="desativarOutro('listagem')">
                    <v-select class="w-full" :options="lista_grupos_cor" label="nome"
                        v-model="projeto.grupo_cor_perfis" />
                </div>
                <p class="text-sm text-gray-500">Cores do Grupo: {{projeto.grupo_cor_perfis?.cor_perfil.map(cor =>
                    cor.descricao).join(', ') || '-'}}</p>
            </div>

            <!-- Adicionado -->
            <div>
                <h2>Adicionado:</h2>
                <div class="flex gap-2">
                    <input type="checkbox" v-model="projeto.adicionado_perfil" @change="desativarOutro('adicionado')">
                    <v-select class="w-full" :options="lista_cores" :multiple="true" label="descricao"
                        v-model="projeto.cores_perfil" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import axios from 'axios';

export default {
    name: 'CorPerfilProjeto',

    props: ['projeto'],

    components: {
        vSelect,
    },

    data() {
        return {
            lista_grupos_cor: [],
            lista_cores: [],
        };
    },

    created() {
        console.log('projeto: ', this.projeto);

        this.getListaGrupoCorPerfil();
        this.getListaCorPerfil();
    },

    methods: {
        // 🔹 Método para garantir que apenas um checkbox esteja ativo
        desativarOutro(tipo) {
            if (tipo === 'listagem') {
                this.projeto.adicionado_perfil = false;
            } else if (tipo === 'adicionado') {
                this.projeto.listagem_perfil = false;
            }
        },

        async getListaGrupoCorPerfil() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            try {
                const response = await axios.get(`projeto/grupo-cor-perfil/?ativo=True&page_size=9999`, {
                    headers: { 'Authorization': `Token ${token}` }
                });

                this.lista_grupos_cor = response.data.results;
            } catch (error) {
                console.error(error);
            }
        },

        async getListaCorPerfil() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            try {
                const response = await axios.get(`produtos/cor-perfil/?ativo=True&page_size=9999`, {
                    headers: { 'Authorization': `Token ${token}` }
                });

                console.log(response.data.results)
                this.lista_cores = response.data.results.map((cor_perfil) => {
                    return {
                        id: cor_perfil.cor_base,
                        descricao: cor_perfil.descricao,
                    }
                });
                console.log(this.lista_cores)
            } catch (error) {
                console.error(error);
            }
        }
    }
};
</script>

<style scoped></style>
