<template>
  <div class="container-formulario">

    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Criação do Orçamento:</h1>

      <router-link to="/lista-orcamentos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <form @submit.prevent="cadastrarOrcamento">

      <div class="w-full mx-auto mt-8">
        <div class="space-y-4">
          <div>
            <div class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
              Dados
            </div>

            <div class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
              <DadosOrcamento :orcamento="orcamento" />
            </div>
          </div>

          <div>
            <div class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
              Endereço Orçamento
            </div>

            <div class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
              <EnderecoOrcamento :orcamento="orcamento" />
            </div>
          </div>

          <div>
            <div class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
              Endereço Obra
            </div>

            <div class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
              <EnderecoObraOrcamento :orcamento="orcamento" />
            </div>
          </div>

          <div class="flex w-full gap-2 justify-end">
            <button
              class="px-4 py-1 cursor-pointer text-white bg-inativo border hover:bg-screenCor hover:text-inativo hover:border-inativo"
              @click.prevent="$router.push(`/lista-orcamentos`)">
              Cancelar
            </button>
            <button
              class="px-4 py-1 cursor-pointer text-white bg-ativo border hover:bg-screenCor hover:text-ativo hover:border-ativo"
              type="submit">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <MenssagemErro v-if="modalMensagemErro" :mensagem="mensagemErro" rotas="/lista-orcamentos" />

  <MensagemSucessoOrcamento v-if="modalMensagemSucesso" :novoOrcamento="novoOrcamento" />

  <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select'
import MenssagemErro from '../toasts/toast_erro/MenssagemErro.vue'
import MensagemSucessoOrcamento from './sessoes_orcamento/MensagemSucessoOrcamento.vue'
import ListagemErros from '../toasts/toast_erro/ListagemErros.vue'
import DadosOrcamento from './sessoes_orcamento/DadosOrcamento.vue'
import EnderecoObraOrcamento from './sessoes_orcamento/EnderecoObraOrcamento.vue'
import EnderecoOrcamento from './sessoes_orcamento/EnderecoOrcamento.vue'

export default {
  name: 'CadastroOrcamentos',

  components: {
    vSelect,
    MenssagemErro,
    MensagemSucessoOrcamento,
    ListagemErros,
    DadosOrcamento,
    EnderecoObraOrcamento,
    EnderecoOrcamento,
  },

  data() {
    return {
      // variaveis formulario
      cliente: '',
      vendedor: '',
      status: '',
      cep: '',
      endereco: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: '',

      orcamento: [],

      listaTiposMedidas: ['Externa', 'Interna'],

      // guarda o response quando o orçamento for criado
      novoOrcamento: {},

      // controle de exibição
      modalMensagemErro: false,
      mensagemErro: '',
      modalMensagemSucesso: false,
      mensagemSucesso: '',

      exibirErros: false,
      erros: [],
    }
  },

  // watch: {
  //   'estado': {
  //     handler(novoEstado) {
  //       if (novoEstado) {
  //         this.buscarCidades(novoEstado);
  //       }
  //     },
  //     immediate: false
  //   }
  // },

  created() {
    // this.getListaVendedores()
    // this.getListaClientes()
    // this.carregarEstados()
  },

  methods: {

    async cadastrarOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const data = {
          cliente: this.orcamento.cliente?.id || '',
          vendedor: this.orcamento.vendedor || '',
          status: this.orcamento.status,
          referencia: this.orcamento.referencia,
          obra: this.orcamento.obra?.id || '',
          cep: this.orcamento.cep,
          logradouro: this.orcamento.logradouro,
          numero: this.orcamento.numero,
          complemento: this.orcamento.complemento,
          predio_condominio: this.orcamento.predio_condominio,
          bairro: this.orcamento.bairro,
          estado: this.orcamento.estado,
          cidade: this.orcamento.cidade,
        }

        this.$store.dispatch("setLoading", true);

        const response = await axios.post('orcamentos/', data, {
          headers: {
            Authorization: `Token ${token}`
          }
        })

        this.novoOrcamento = response.data
        console.log(this.novoOrcamento)

        this.mensagemSucesso = 'Orçamento criado com sucesso'
        this.modalMensagemSucesso = true

      } catch (error) {
        console.error(error)
        this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
        this.toggleErros()
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    // async getListaVendedores() {
    //   try {
    //     const token = await this.$store.dispatch('getAuthTokenCookie')
    //     await this.$store.dispatch('fetchURLrequest')

    //     const response = await axios.get('pessoa/pessoas-fisica-juridica/?ativo=True&page_size=9999', {
    //       headers: {
    //         Authorization: `Token ${token}`
    //       }
    //     })

    //     this.listaVendedores = response.data.results.map(item => ({
    //       id: item.id,
    //       text: item.nome || item.razao_social
    //     }))

    //   } catch (error) {
    //     console.error(error)
    //   }
    // },

    // async getListaClientes() {
    //   const token = await this.$store.dispatch('getAuthTokenCookie');
    //   await this.$store.dispatch('fetchURLrequest');
    //   try {
    //     const response = await axios.get(`pessoa/pessoas-fisica-juridica/?atividade=Cliente&ativo=True&page_size=9999`, {
    //       headers: {
    //         'Authorization': `Token ${token}`
    //       }
    //     })

    //     this.listaClientes = response.data.results.map(person => ({
    //       id: person.pessoa.id,
    //       text: person.nome || person.razao_social,
    //     }))
    //     console.log(this.listaClientes)
    //   } catch (error) {
    //     console.error(error)
    //   }
    // },

    // async getClientes(pesquisa) {
    //   try {
    //     const token = await this.$store.dispatch('getAuthTokenCookie')
    //     await this.$store.dispatch('fetchURLrequest')

    //     const response = await axios.get(`pessoas/?razao_social=${pesquisa}`, {
    //       headers: {
    //         Authorization: `Token ${token}`
    //       }
    //     })

    //     this.listaClientes = response.data.results
    //   } catch (error) {
    //     console.error(error)
    //   }
    // },

    toggleErros() {
      this.exibirErros = !this.exibirErros
    },

  }
}
</script>

<style scoped lang="scss">
.check-box {
  @apply flex justify-center gap-3
}

input,
select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
</style>
