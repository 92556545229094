<template>

  <div class="w-full mb-2 flex">
    <div class="px-2 mb-2 w-full md:w-1/4">
      <label class="text-xl text-corBase font-bold">Aplicados no Orçamento:</label>
      <v-select v-model="aplicado_orcamento" :options="lista_aplicados_orcamento" class="w-full" :clearable="false" />
    </div>

  </div>

  <div class="block">
    <div class="mb-4">
      <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
        <div class="px-2 w-1/3">
          <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
            <option value="area_vidro">Localização</option>
            <option value="vidro">Vidro</option>
            <option value="lado">Lado</option>
            <option value="furacao">Furação</option>
            <option value="fase">Fase</option>
            <option value="aplicacao">Aplicação</option>
            <!-- <option value="cor">Cor</option> -->
          </select>
        </div>

        <div class="w-full">
          <!-- <v-select v-if="filtro.campo === 'cor'" v-model="filtro.valor" :options="lista_cores" label="nome_razao"
            placeholder="Escolha uma opção" :reduce="cor => cor.id" /> -->

          <v-select v-if="filtro.campo === 'area_vidro'" v-model="filtro.valor" :options="lista_localizacoes"
            label="descricao" placeholder="Escolha uma opção" :reduce="area_vidro => area_vidro.id" />

          <v-select v-else-if="filtro.campo === 'lado'" v-model="filtro.valor"
            :options="['A Definir', 'Externo', 'Interno']" label="descricao" placeholder="Escolha uma opção" />

          <v-select v-else-if="filtro.campo === 'furacao'" v-model="filtro.valor" :options="['A Definir', 'Sim', 'Não']"
            label="descricao" placeholder="Escolha uma opção" />

          <v-select v-else-if="filtro.campo === 'fase'" v-model="filtro.valor" :options="lista_fases" label="descricao"
            placeholder="Escolha uma opção" :reduce="fase => fase.descricao" />

          <v-select v-else-if="filtro.campo === 'aplicacao'" v-model="filtro.valor" :options="lista_aplicacao"
            placeholder="Escolha uma opção" />

          <input v-else v-model="filtro.valor" type="text" class="input-form-chapa" placeholder="Insira o valor" />
        </div>

        <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
      </div>

      <div class="flex w-full justify-center">
        <button class="text-ativo" @click.prevent="adicionarFiltro">
          <i class="fas fa-plus-circle"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="mb-2">
    <button class="bg-gray-600 hover:bg-screenCor border border-screenCor hover:border-gray-600
    text-white hover:text-gray-600 py-2 px-4" @click.prevent="toggleFuncoesECondicoes">
      Ver funções e condições
    </button>
    <button @click.prevent="toggleEscolhaVidros()">
      Abrir modal de vidros
    </button>
  </div>

  <div class="flex">

    <div class="flex flex-wrap gap-2 mb-2">

      <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
text-white hover:text-corBase py-2 px-4" @click.prevent="getVidrosOrcamento()">
        Pesquisar
      </button>

      <!-- <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
text-white hover:text-ativo py-2 px-4" @click.prevent="$router.push(`/orcamento/selecionar-projeto/`)">
Adicionar
</button> -->

      <button class="bg-blue-500 hover:bg-screenCor border border-screenCor hover:border-blue-500
text-white hover:text-blue-500 py-2 px-4" @click.prevent="alterarSelecionadosVidros()">
        Alterar Selecionados
      </button>

      <button class="bg-red-500 hover:bg-screenCor border border-screenCor hover:border-red-500
text-white hover:text-red-500 py-2 px-4" @click.prevent="verificarExclusao()">
        Excluir Selecionados
      </button>

      <button class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500
text-white hover:text-yellow-500 py-2 px-4" @click.prevent="editarTodos()">
        {{ editandoTodos ? 'Salvar Todos' : 'Editar Todos' }}
      </button>

      <!-- <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
text-white hover:text-ativo py-2 px-4" @click.prevent="salvarEdicoes()">
Salvar
</button> -->

      <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
    text-white hover:text-ativo py-2 px-4" @click.prevent="recalcularTudoItemOrcamento()">
        Recalcular
      </button>

    </div>
  </div>

  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
        <tr class="border-b">
          <th scope="col" class="px-3 py-3">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th class="px-6 py-2 text-center">
            Localização
          </th>
          <th class="px-28 py-2 text-center">
            Vidro
          </th>
          <th class="px-6 py-2 text-center">
            Espessura
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Condição
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            <!-- v-if="aplicado_orcamento === 'Todos'" -->
            Condição Resultado
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Quantidade Fórmula
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Quantidade Resultado
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Largura Fórmula
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Largura Resultado
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Altura Fórmula
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Altura Resultado
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Observação
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Lado
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Furação
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Fase
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Aplicação
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Acréscimo (%)
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Acréscimo (R$)
          </th>

        </tr>
      </thead>

      <!-- VIDROS EXISTENTES -->

      <tbody>
        <tr v-for="(vidro, index) in vidros" :key="vidro.id" class="escopo-tabela text-left">
          <!-- Checkbox -->
          <td class="w-4 px-3 py-3">
            <div class="flex items-center">
              <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox" v-model="vidro.selected">
              <label class="sr-only">checkbox</label>
            </div>
          </td>

          <!-- Localização -->
          <td class="py-2 text-center">
            <v-select v-if="editandoTodos" v-model="vidro.editado.area_vidro" :options="lista_localizacoes"
              label="descricao" class="bg-white w-full rounded-md shadow-sm" />
            <span v-else>{{ vidro.area_vidro?.descricao ?? '-' }}</span>
          </td>

          <!-- Vidro -->
          <td class="py-2 text-center">
            <v-select v-if="editandoTodos" v-model="vidro.editado.vidro" :options="lista_vidros" label="descricao"
              class="bg-white w-full rounded-md shadow-sm" />
            <span v-else>
              {{ vidro.vidro?.descricao ? vidro.vidro?.descricao : vidro.sem_vidro ? 'Sem Vidro' : '-' }}
              <br> Fornecedor: {{ vidro.vidro?.fornecedor?.nome_razao || '-' }}
            </span>
            <!-- <p class="text-red-500 text-xs">
              vidro.vidro: {{ vidro.vidro?.descricao }}
              vidro.sem_vidro: {{ vidro.sem_vidro }}
            </p> -->
          </td>

          <!-- Espessura -->
          <td class="py-2 text-center">
            <v-select v-if="editandoTodos && vidro.sem_vidro" v-model="vidro.editado.espessura_vidro"
              :options="lista_espessuras" label="espessura" class="bg-white w-full rounded-md shadow-sm" />
            <span v-else>{{ vidro.espessura_referencia?.espessura ?? '-' }}mm</span>
          </td>

          <!-- Condição -->
          <td class="py-2 text-center">
            <input v-if="editandoTodos" v-model="vidro.editado.condicao_formula" type="text"
              class="w-full p-2 border rounded-lg">
            <span v-else>{{ vidro.condicao_formula ?? '-' }}</span>
          </td>

          <td class="py-2 text-center">
            <!-- v-if="aplicado_orcamento === 'Todos'" -->
            {{ vidro.condicao_calculada ? 'Verdadeiro' : 'Falso' }}
          </td>

          <!-- Quantidade Fórmula -->
          <td class="py-2 text-center">
            <input v-if="editandoTodos" v-model="vidro.editado.qtd_formula" type="text"
              class="w-full p-2 border rounded-lg">
            <span v-else>{{ vidro.qtd_formula ?? '-' }}</span>
          </td>

          <!-- Quantidade Calculada -->
          <td class="py-2 text-center">
            {{ vidro.qtd_calculada ?? 'Não calculado' }}
          </td>

          <!-- Largura Fórmula -->
          <td class="py-2 text-center">
            <input v-if="editandoTodos" v-model="vidro.editado.largura_formula" type="text"
              class="w-full p-2 border rounded-lg">
            <span v-else>{{ vidro.largura_formula ?? '-' }}</span>
          </td>

          <!-- Largura Calculada -->
          <td class="py-2 text-center">
            {{ vidro.largura_calculada ?? 'Não calculado' }}
          </td>

          <!-- Altura Fórmula -->
          <td class="py-2 text-center">
            <input v-if="editandoTodos" v-model="vidro.editado.altura_formula" type="text"
              class="w-full p-2 border rounded-lg">
            <span v-else>{{ vidro.altura_formula ?? '-' }}</span>
          </td>

          <!-- Altura Calculada -->
          <td class="py-2 text-center">
            {{ vidro.altura_calculada ?? 'Não calculado' }}
          </td>

          <!-- Observação -->
          <td class="py-2 text-center whitespace-nowrap">
            <input v-if="editandoTodos" v-model="vidro.editado.observacao" type="text"
              class="w-full p-2 border rounded-lg">
            <span v-else>{{ vidro.observacao ?? '-' }}</span>
          </td>

          <!-- Lado -->
          <td class="py-2 text-center">
            <select v-if="editandoTodos" v-model="vidro.editado.lado"
              class="input-campos block w-full p-2 border rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option v-for="lado in ['A Definir', 'Externo', 'Interno']" :key="lado" :value="lado">{{ lado }}</option>
            </select>
            <span v-else>{{ vidro.lado ?? '-' }}</span>
          </td>

          <!-- Furação -->
          <td class="py-2 text-center">
            <select v-if="editandoTodos" v-model="vidro.editado.furacao"
              class="input-campos block w-full p-2 border rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option v-for="furacao in ['A Definir', 'Sim', 'Não']" :key="furacao" :value="furacao">{{ furacao }}
              </option>
            </select>
            <span v-else>{{ vidro.furacao ?? '-' }}</span>
          </td>

          <!-- Fase Esquadria -->
          <td class="py-2 text-center">
            <v-select v-if="editandoTodos" v-model="vidro.editado.fase_esquadria" :options="lista_fases"
              label="descricao" class="bg-white w-full rounded-md shadow-sm" placeholder="Escolha uma opção" />
            <span v-else>{{ vidro.fase_esquadria?.descricao ?? '-' }}</span>
          </td>

          <!-- Aplicação -->
          <td class="py-2 text-center">
            <select v-if="editandoTodos" v-model="vidro.editado.aplicacao"
              class="input-campos block w-full p-2 border rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option v-for="aplicacao in ['A Definir', 'Produção', 'Obra']" :key="aplicacao" :value="aplicacao">
                {{ aplicacao }}
              </option>
            </select>
            <span v-else>{{ vidro.aplicacao ?? '-' }}</span>
          </td>

          <!-- Acréscimo em Porcentagem -->
          <td class="py-2 text-center">
            <input v-if="editandoTodos" v-model="vidro.editado.acrescimoPorcentagem" type="text"
              class="w-full p-2 border rounded-lg">
            <span v-else>
              <p v-if="vidro.acrescimoPorcentagem">
                {{ vidro.acrescimoPorcentagem ?? '-' }}%
              </p>
              <p v-else>-</p>
            </span>
          </td>

          <!-- Acréscimo em Reais -->
          <td class="py-2 text-center">
            <input v-if="editandoTodos" v-model="vidro.editado.acrescimoReais" type="text"
              class="w-full p-2 border rounded-lg">
            <span v-else>
              <p v-if="vidro.acrescimoReais">
                R$ {{ vidro.acrescimoReais ?? '-' }}
              </p>
              <p v-else>-</p>
            </span>
          </td>
        </tr>
      </tbody>

      <!-- NOVO VIDRO -->

      <tr v-if="adicionarNovoVidro" class="escopo-tabela">
        <td class="w-full text-center">
          <i class="fa-solid fa-magnifying-glass cursor-pointer" @click.prevent="toggleMenuProdutos()"></i>
        </td>
        <td class="w-full">
          <v-select v-model="novoVidro.area_vidro" :options="lista_localizacoes" label="descricao"
            class="bg-white w-full rounded-md shadow-sm" :reduce="area_vidro => area_vidro.id" />
        </td>
        <td class="text-red-500 text-xs text-center">
          Definir automaticamente de acordo com outro vidro de mesma localização já existente - testado
        </td>
        <td class="text-center"> - </td>
        <!-- <td class="w-full">
          <v-select v-model="novoVidro.nome_base" label="nome_base" :options="vidrosPesquisados"
            @search="buscarVidros" @update:modelValue="onSelectNovoVidro" placeholder="Busque um vidro..."
            class="w-full">
            <template v-slot:option="{ nome_base }">
              <div class="w-full">
                {{ nome_base }} 
              </div>
            </template>
          </v-select>
        </td> -->
        <!-- <td class="px-6 py-2 text-center"> {{ novoVidro.espessura || '-' }}mm </td> -->
        <td class="w-full">
          <input class="input-campos" v-model="novoVidro.condicao">
        </td>
        <td class="px-6 py-2 text-center"> - </td>
        <!-- v-if="aplicado_orcamento === 'Todos'" -->
        <td class="w-full">
          <input class="input-campos" v-model="novoVidro.qtd_formula">
        </td>
        <td class="px-6 py-2 text-center"> - </td>
        <td class="w-full">
          <input class="input-campos" v-model="novoVidro.largura_formula">
        </td>
        <td class="px-6 py-2 text-center"> - </td>
        <td class="w-full">
          <input class="input-campos" v-model="novoVidro.altura_formula">
        </td>
        <td class="px-6 py-2 text-center"> - </td>
        <td class="w-full">
          <input class="input-campos" v-model="novoVidro.observacao">
        </td>
        <!-- <td class="w-full">
          <v-select v-model="novoVidro.cor" :options="coresPerfil" label="nome"
            class="bg-white w-full custom-width" :reduce="cor => cor.id" placeholder="Escolha uma opção" />
        </td> -->
        <td class="w-full">
          <select v-model="novoVidro.lado"
            class="input-campos block w-full p-2 border rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-blue-500">
            <option v-for="lado in ['A Definir', 'Externo', 'Interno']" :key="lado" :value="lado">{{ lado }}</option>
          </select>
        </td>
        <td class="w-full">
          <select v-model="novoVidro.furacao"
            class="input-campos block w-full p-2 border rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-blue-500">
            <option v-for="furacao in ['A Definir', 'Sim', 'Não']" :key="furacao" :value="furacao">{{ furacao }}
            </option>
          </select>
        </td>
        <td class="w-full">
          <v-select v-model="novoVidro.fase_esquadria" :options="lista_fases" label="descricao"
            class="bg-white w-full custom-width" :reduce="fase => fase.id" :clearable="false"
            placeholder="Escolha uma opção" />
        </td>
        <td class="w-full">
          <select v-model="novoVidro.aplicacao" class="input-campos">
            <option v-for="aplicacao in ['A Definir', 'Produção', 'Obra']" :key="aplicacao" :value="aplicacao">
              {{ aplicacao }}
            </option>
          </select>
        </td>
        <td class="w-full">
          <input class="input-campos" v-model="novoVidro.acrescimoPorcentagem">
        </td>
        <td class="w-full">
          <input class="input-campos" v-model="novoVidro.acrescimoReais">
        </td>
      </tr>
    </table>

    <div v-if="adicionarNovoVidro" class="flex w-full justify-end itens-center mt-6 px-4 mb-5 gap-2">
      <button class="bg-red-500 hover:bg-screenCor border border-screenCor hover:border-red-500
      text-white hover:text-red-500 py-2 px-4" @click.prevent="toggleVidro">
        Cancelar
      </button>
      <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
      text-white hover:text-ativo py-2 px-4" @click.prevent="postNovoVidro">
        Adicionar
      </button>
    </div>
    <div v-else class="flex justify-center">
      <button class="text-ativo" @click.prevent="toggleVidro">
        <i class="fas fa-plus-circle"></i>
      </button>
    </div>

  </div>

  <AlterarSelecionadosVidrosOrcamento v-if="exibirAlterarSelecionados" :listaAreasVidro="lista_localizacoes"
    :lista_fases="lista_fases" :vidros="vidrosSelecionados" @fecharModal="toggleAlterarSelecionados()"
    @atualizarLista="getVidrosOrcamento()" />
  <MensagemConfirmacaoExclusao mensagem="Deseja mesmo excluir os itens selecionados?" :abrir="modal_confirmacao"
    @fecharModal="toggleModalConfirmacao()" @confirmarExclusao="deletarVidros" />
  <ModalEscolhaVidros v-if="escolherVidros" :item_orcamento="itemOrcamentoId" @fecharModal="toggleEscolhaVidros()"
    @atualizarLista="getVidrosOrcamento()" />
  <ListaFuncoesECondicoes v-if="exibirFuncoesECondicoes" @fecharModal="toggleFuncoesECondicoes" />
  <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />
  <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />
</template>

<script>
import axios from 'axios'
import AlterarSelecionadosVidrosOrcamento from '../modais/AlterarSelecionados/AlterarSelecionadosVidrosOrcamento.vue';
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue';
import ListaFuncoesECondicoes from '@/components/midais/ajudas/ListaFuncoesECondicoes.vue';
import ModalEscolhaVidros from '../../ModalEscolhaVidros.vue';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';
import vSelect from 'vue-select';


export default {
  name: 'AlterarVidroOrcamento',

  components: {
    AlterarSelecionadosVidrosOrcamento,
    MensagemConfirmacaoExclusao,
    ListaFuncoesECondicoes,
    ModalEscolhaVidros,
    SucessoRapido,
    ListagemErros,
    vSelect,
  },

  computed: {
    checkSome() {
      return this.vidros.some(vidro => vidro.selected)
    }
  },

  props: ['itemOrcamentoId', 'dadosProjeto'],
  emits: ['atualizarLista'],

  data() {
    return {
      checkAll: false,

      filtros: [{ campo: "area_vidro", valor: "" }],

      lista_aplicados_orcamento: ['Sim', 'Não', 'Todos'],
      lista_aplicacao: ['A Definir', 'Obra', 'Produção'],
      lista_fases: [],
      lista_localizacoes: [],
      lista_vidros: [],
      lista_espessuras: [],
      aplicado_orcamento: 'Sim',

      vidrosPesquisados: [],

      vidros: [],
      countItens: 0,
      coresVidro: [],

      modal_confirmacao: false,

      escolherVidros: false,

      exibirFuncoesECondicoes: false,

      vidrosSelecionados: [],
      exibirAlterarSelecionados: false,

      editandoTodos: false,
      editandoVidro: null,

      novoVidro: [],
      adicionarNovoVidro: false,

      sucesso: false,
      sucessoMessage: '',

      exibirErros: false,
      erros: [],
    }
  },

  created() {
    this.getListaFaseEsquadria()
    this.getListaLocalizacoes()
    this.getListaEspessuras()
    this.getListaVidros()
    this.getVidrosOrcamento()
  },

  methods: {

    atualizarFiltro(index) {
      this.filtros[index].valor = null;
    },
    adicionarFiltro() {
      this.filtros.push({ campo: "", valor: "" });
    },
    removerFiltro(index) {
      this.filtros.splice(index, 1);
    },

    selectAll() {
      this.vidros.forEach((vidro_orcamento) => {
        vidro_orcamento.selected = this.checkAll;
      });
    },

    async getListaFaseEsquadria() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`projeto/fase-esquadria/?ativo=True&page_size=9999`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        this.lista_fases = response.data.results
      } catch (error) {
        console.error(error)
      }
    },

    async getListaLocalizacoes() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`projeto/area-vidro/?ativo=True&page_size=9999`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        this.lista_localizacoes = response.data.results
      } catch (error) {
        console.error(error)
      }
    },

    async getListaVidros() {
      try {

        this.$store.dispatch("setLoading", true);

        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`produtos/vidro/?ativo=True&disponibilidade=Todos&fornecedor=&page_size=9999`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })

        this.lista_vidros = response.data.results.map((vidro) => {
          return {
            id: vidro.id,
            descricao: `${vidro.descricao} - ${vidro.fornecedor?.nome_razao || '-'}`,
            espessura: vidro.espessura,
          }
        })
        this.checkAll = false

      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    async getListaEspessuras() {
      try {

        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`produtos/espessura/?ativo=True&page_size=9999`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.lista_espessuras = response.data.results

      } catch (error) {
        console.error(error);
      }
    },

    async getVidrosOrcamento() {
      try {

        this.$store.dispatch("setLoading", true);

        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const params = this.filtros
          .filter((filtro) => filtro.campo && filtro.valor)
          .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
          .join("&");

        const response = await axios.get(`orcamentos/vidros-orcamento/?item_orcamento_id=${this.itemOrcamentoId}&ativo=True&aplicado_orcamento=${this.aplicado_orcamento}&${params}&page_size=9999`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })

        this.vidros = response.data.results.map(vidro => ({
          ...vidro,
          editado: { ...vidro },
        }));
        this.countItens = response.data.count
        this.checkAll = false

      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    async alterarSelecionadosVidros() {
      if (this.checkSome) {
        this.erros = { 1: 'existe um box marcado' }
        this.toggleErros()

      } else {
        this.erros = { vidro: 'Selecione pelo menos um vidro para alterar.' }
        this.toggleErros()
      }
    },

    async buscarVidros(searchQuery) {
      if (searchQuery.length > 2) {
        try {

          this.$store.dispatch("setLoading", true);
          const token = await this.$store.dispatch('getAuthTokenCookie');
          await this.$store.dispatch('fetchURLrequest');

          const response = await axios.get(`produtos/vidro/?tipo_produto=Vidro&filtro_base=${searchQuery}&page_size=9999`, {
            headers: { Authorization: `Token ${token}` }
          });

          this.vidrosPesquisados = response.data.results
        } catch (error) {
          console.error("Erro ao buscar vidros:", error);
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      } else {
        this.vidrosPesquisados = [];
      }
    },

    onSelect(vidro, selecionado) {
      if (!selecionado) return;

      vidro.editado.vidro = selecionado;
      vidro.editado.vidro_id = selecionado.id;
      vidro.editado.descricao = selecionado.nome_base;
      vidro.editado.peso = selecionado.peso_base;
    },


    async alterarSelecionadosVidros() {
      if (this.checkSome) {
        this.vidrosSelecionados = this.vidros.filter(vidro => vidro.selected);
        console.log(this.vidrosSelecionados)
        this.toggleAlterarSelecionados()

      } else {
        this.erros = { vidro: 'Selecione pelo menos um vidro para alterar.' }
        this.toggleErros()
      }
    },

    toggleAlterarSelecionados() {
      this.exibirAlterarSelecionados = !this.exibirAlterarSelecionados
    },

    verificarExclusao() {
      if (this.checkSome) {
        this.toggleModalConfirmacao()
      } else {
        this.erros = { vidro: 'Selecione pelo menos um Vidro para ser excluído.' }
        this.toggleErros()
      }
    },

    toggleModalConfirmacao() {
      this.modal_confirmacao = !this.modal_confirmacao
    },

    async deletarVidros() {
      try {
        this.$store.dispatch("setLoading", true);

        const vidrosADeletar = this.vidros.filter(vidro => vidro.selected);

        if (vidrosADeletar.length === 0) {
          console.warn("Nenhum vidro selecionado para deletar.");
          return;
        }

        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        await axios.patch(
          `orcamentos/atualizar-vidros-orcamento/`,
          { itens: vidrosADeletar.map(vidro => ({ id: vidro.id, ativo: false })) },
          { headers: { Authorization: `Token ${token}` } }
        );

        this.sucesso = true
        this.sucessoMessage = 'Vidros excluídos com sucesso.'
        this.toggleModalConfirmacao()
        this.getVidrosOrcamento()
        setTimeout(() => {
          this.sucesso = false;
          this.sucessoMessage = ''
        }, 3000);
      } catch (error) {
        console.error("Erro ao deletar vidros:", error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    // editarVidrol(vidro) {
    //   if (this.editandoVidro) {
    //     this.salvarEdicoes()
    //   } else {
    //     this.editandoVidro = vidro.id;
    //   }
    // },

    editarTodos() {
      if (this.countItens == 0) {
        this.erros = { editar_todos: 'Busque os Vidros para editar.' }
        this.toggleErros()
        return
      } else {
        this.editandoTodos = !this.editandoTodos;
        if (!this.editandoTodos) {
          // this.editandoVidro = null
          this.salvarEdicoes()
        };
      }
    },

    fecharTodos() {
      this.editandoTodos = false
    },

    async salvarEdicoes() {
      try {
        this.$store.dispatch("setLoading", true);
        const token = await this.$store.dispatch("getAuthTokenCookie");
        await this.$store.dispatch("fetchURLrequest");

        // Filtra apenas vidros que foram editados
        const vidrosAtualizados = this.vidros
          .filter(vidro => {
            const original = vidro;
            const editado = vidro.editado;

            console.log(vidro.editado.vidro.espessura)

            return (
              original.area_vidro !== editado.area_vidro ||
              original.vidro !== editado.vidro ||
              original.condicao_formula !== editado.condicao_formula ||
              original.largura_formula !== editado.largura_formula ||
              original.altura_formula !== editado.altura_formula ||
              original.qtd_formula !== editado.qtd_formula ||
              original.observacao !== editado.observacao ||
              original.lado !== editado.lado ||
              original.furacao !== editado.furacao ||
              original.fase_esquadria !== editado.fase_esquadria ||
              original.aplicacao !== editado.aplicacao ||
              original.acrescimoPorcentagem !== editado.acrescimoPorcentagem ||
              original.acrescimoReais !== editado.acrescimoReais ||
              original.cor !== editado.cor
            );
          })
          .map(vidro => ({
            id: vidro.id,
            area_vidro: vidro.editado.area_vidro?.id || '',
            vidro: vidro.editado.vidro?.id || '',
            espessura: vidro.editado.vidro?.espessura || '',
            condicao_formula: vidro.editado.condicao_formula,
            largura_formula: vidro.editado.largura_formula,
            altura_formula: vidro.editado.altura_formula,
            qtd_formula: vidro.editado.qtd_formula,
            observacao: vidro.editado.observacao,
            lado: vidro.editado.lado,
            furacao: vidro.editado.furacao,
            fase_esquadria: vidro.editado.fase_esquadria?.id || '',
            aplicacao: vidro.editado.aplicacao,
            acrescimoPorcentagem: vidro.editado.acrescimoPorcentagem,
            acrescimoReais: vidro.editado.acrescimoReais,
            cor: vidro.editado.cor,
          }));

        if (vidrosAtualizados.length === 0) {
          this.fecharTodos()
          // this.editandoVidro = null
          return;
        }

        // Enviar os vidro atualizados em uma única requisição PATCH
        await axios.patch(
          `orcamentos/atualizar-vidros-orcamento/`,
          { itens: vidrosAtualizados },
          { headers: { Authorization: `Token ${token}` } }
        );

        this.sucesso = true;
        this.sucessoMessage = 'Vidros editados e salvos com sucesso.'
        this.getVidrosOrcamento();
        setTimeout(() => {
          this.fecharTodos()
          this.sucesso = false;
          // this.editandoVidro = null
        }, 3000);
      } catch (error) {
        console.error("[ERRO] Falha ao salvar edições:", error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    async recalcularItemOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(
          `engine/recalcular-item-orcamento/${this.itemOrcamentoId}/?vidros=true`,
          {
            headers: { 'Authorization': `Token ${token}` },
          }
        );

        console.log("Itens atualizados com sucesso:", response.data);
        this.sucesso = true;
        this.sucessoMessage = 'Vidros recalculados com sucesso.';
        this.getVidrosOrcamento()
        setTimeout(() => {
          this.sucesso = false;
          this.sucessoMessage = '';
        }, 3000);

      } catch (error) {
        console.error("Erro ao atualizar itens do orçamento:", error);
        this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
        this.toggleErros();
      }
    },

    async recalcularTudoItemOrcamento() {

      try {

        this.$store.dispatch("setLoading", true);

        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`engine/${this.itemOrcamentoId}/`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        console.log(response.data)
        this.sucesso = true
        this.sucessoMessage = 'Item do Orçamento recalculado com sucesso.'
        this.getVidrosOrcamento()
        setTimeout(() => {
          this.sucesso = false
          this.sucessoMessage = ''
        }, 3000)

      } catch (error) {
        console.error(error)
        this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
        this.toggleErros()
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    onSelectNovoVidro(vidro) {
      this.novoVidro.vidro = vidro.id
      this.novoVidro.codigo_base = vidro.codigo_base
      this.novoVidro.nome_base = vidro.nome_base
      this.novoVidro.peso_base = vidro.peso_base
      this.novoVidro.espessura = vidro.espessura
    },

    toggleVidro() {
      this.adicionarNovoVidro = !this.adicionarNovoVidro;
      this.limparCampos();
    },

    limparCampos() {
      this.novoVidro = []

      this.novoVidro.aplicacao = 'A Definir'
      this.novoVidro.lado = 'A Definir'
      this.novoVidro.furacao = 'A Definir'

      const faseDefault = this.lista_fases.find(item => item.descricao === 'A Definir');
      this.novoVidro.fase_esquadria = faseDefault ? faseDefault.id : null;
    },

    async postNovoVidro() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');
        this.$store.dispatch("setLoading", true);

        console.log('NOVOVIDRO: ', this.novoVidro)
        const vidroAdicionar = {
          item_orcamento: this.itemOrcamentoId,
          area_vidro: this.novoVidro.area_vidro,
          vidro: this.novoVidro.vidro,
          espessura_referencia: this.novoVidro.espessura?.id,
          condicao_formula: this.novoVidro.condicao,
          largura_formula: this.novoVidro.largura_formula,
          altura_formula: this.novoVidro.altura_formula,
          qtd_formula: this.novoVidro.qtd_formula,
          observacao: this.novoVidro.observacao,
          lado: this.novoVidro.lado,
          furacao: this.novoVidro.furacao,
          fase_esquadria: this.novoVidro.fase_esquadria,
          aplicacao: this.novoVidro.aplicacao,
          acrescimoPorcentagem: this.novoVidro.acrescimoPorcentagem,
          acrescimoReais: this.novoVidro.acrescimoReais,
          cor: this.novoVidro.cor,
          index: this.vidros.length + 1 || 1
        }

        await axios.post('orcamentos/vidros-orcamento/', vidroAdicionar, {
          headers: { Authorization: `Token ${token}` }
        });

        this.sucesso = true;
        this.sucessoMessage = 'Vidro criado com sucesso!';
        setTimeout(() => this.sucesso = false, 3000);

        this.adicionarNovoVidro = false;
        this.vidrosPesquisados = [];
        this.limparCampos();
        this.getVidrosOrcamento()
      } catch (error) {
        console.error(error);
        this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
        this.toggleErros();
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    toggleEscolhaVidros() {
      this.escolherVidros = !this.escolherVidros
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros
    },

    toggleFuncoesECondicoes() {
      this.exibirFuncoesECondicoes = !this.exibirFuncoesECondicoes
    },


  }
}
</script>

<style scoped lang="scss"></style>
