<template>
    <div class="container-formulario">
        <!-- Cabeçalho -->
        <div class="cabecalho-dashboard">
            <h1>Controle de Gestor/Linha</h1>
            <button @click.prevent="$router.push(`/projetos`)" class="text-2xl">
                <i class="fas fa-chevron-left mr-1"></i>
            </button>
        </div>

        <!-- Seção de Gestor -->
        <div class="mt-4">
            <div class="w-full bg-gray-200 p-4 text-left border font-bold text-lg border-corBase/60">
                Habilitação Gestor
            </div>
            <div class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                <SessaoGestor @proprioGestorHabilitado="proprioGestorHabilitado" @gestorIntegracaoHabilitado="gestorIntegracaoHabilitado" />
            </div>
        </div>

        <!-- Sessão de Linhas/Sistemista -->
        <div class="mt-4" v-if="exibirLinhasSistemista">
            <div class="w-full bg-gray-200 p-4 text-left border font-bold text-lg border-corBase/60">
                Linha/Sistemista Privado
            </div>
            <div class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                <SessaoLinhaSistemista />
            </div>
        </div>

        <div class="mt-4" v-if="exibirTenantsIntegracao">
            <div class="w-full bg-gray-200 p-4 text-left border font-bold text-lg border-corBase/60">
                Controle de Integração
            </div>
            <div class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                <SessaoControleIntegracao />
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import SessaoGestor from "./SessaoGestor.vue";
import SessaoLinhaSistemista from "./SessaoLinhaSistemista.vue";
import SessaoControleIntegracao from "./SessaoControleIntegracao.vue";

export default {
    name: "ControleGestor",

    components: {
        SessaoGestor,
        SessaoLinhaSistemista,
        SessaoControleIntegracao,
    },

    data() {
        return {
            exibirLinhasSistemista: false,
            exibirTenantsIntegracao: false,
        };
    },

    created() {

    },

    methods: {
        proprioGestorHabilitado(value) {
            // console.log('value: ', value)
            this.exibirLinhasSistemista = value
        },

        gestorIntegracaoHabilitado(value) {
            this.exibirTenantsIntegracao = value
        },
    },
};
</script>

<style scoped></style>
