<template>
  <div>

    <form @submit.prevent="atualizarDadosOrcamento">

      <div class="w-full mx-auto mt-8">
        <div class="space-y-4">
          <div>
            <div class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
              Dados do Produto
            </div>

            <div class="dados-orcamento">
              <div class="flex gap-8 w-full flex-col md:flex-row">
                <!-- Seção de Dados do Orçamento (Esquerda) -->
                <div class="w-3/4 flex flex-col">
                  <div class="flex gap-2">
                    <div class="flex flex-col w-full">
                      <label class="font-bold text-corBase">Descrição</label>
                      <input type="text" class="input-campos" v-model="itemOrcamento.descricao"> <!-- required -->
                    </div>
                  </div>

                  <div class="flex gap-2">
                    <div class="flex flex-col w-full">
                      <label class="font-bold text-corBase">Quantidade</label>
                      <input type="text" class="input-campos" v-model="itemOrcamento.quantidade"> <!-- required -->
                    </div>

                    <div class="flex flex-col w-full">
                      <label class="font-bold text-corBase">Largura (MM)</label>
                      <input type="text" class="input-campos" v-model="itemOrcamento.largura" @blur="validarLargura()">
                      <!-- required -->
                    </div>

                    <div class="flex flex-col w-full">
                      <label class="font-bold text-corBase">Altura (MM)</label>
                      <input type="text" class="input-campos" v-model="itemOrcamento.altura" @blur="validarAltura()">
                      <!-- required -->
                    </div>
                  </div>

                  <div class="flex gap-4 w-full mt-2">

                    <div class="w-1/2">
                      <div class="flex text-center items-center flex-col">
                        <input type="checkbox" v-model="itemOrcamento.sem_perfis">
                        <label class="font-bold text-corBase">Sem Perfis</label>
                      </div>

                      <div class="flex flex-col w-full">
                        <label class="font-bold text-corBase">Cor Perfis</label>
                        <v-select v-model="itemOrcamento.cor_perfis" class="w-full z-10" :options="listaCorPerfis"
                          label="descricao" :disabled="itemOrcamento.sem_perfis" />
                      </div>
                    </div>

                    <div class="w-1/2">
                      <div class="flex text-center items-center flex-col">
                        <input type="checkbox" v-model="itemOrcamento.sem_componentes">
                        <label class="font-bold text-corBase">Sem Componentes</label>
                      </div>

                      <div class="flex flex-col w-full">
                        <label class="font-bold text-corBase">Cor Componentes</label>
                        <v-select v-model="itemOrcamento.cor_componentes" class="w-full z-10"
                          :options="listaCorComponentes" label="descricao" :disabled="itemOrcamento.sem_componentes" />
                      </div>
                    </div>

                    <!-- <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase">Cor Vidros</label>
                    <v-select v-model="itemOrcamento.corVidros" class="w-full z-10" :options="listaCorVidros" label="descricao"
                      :reduce="option => option.id" />
                  </div>

                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase">Cor Chapas/Telas</label>
                    <v-select v-model="itemOrcamento.corChapas" class="w-full z-10" :options="listaCorChapas" label="descricao"
                      :reduce="option => option.id" />
                  </div> -->
                  </div>


                  <div class="flex gap-2">
                    <div class="flex flex-col w-full">
                      <label class="text-corBase">Contramarco:</label>
                      <v-select v-model="itemOrcamento.contramarco" :options="lista_contramarco_arremate" label="label"
                        placeholder="Escolha uma opção" class="bg-white" :reduce="contramarco => contramarco.value"
                        :clearable="false" />
                    </div>

                    <div v-if="itemOrcamento.contramarco" class="flex flex-col w-full">
                      <label class="text-corBase">Medida do Contramarco:</label>
                      <v-select v-model="itemOrcamento.medida_contramarco" :options="lista_medida_contramarco"
                        label="label" placeholder="Escolha uma opção" class="bg-white" :clearable="false" />
                    </div>

                    <div class="flex flex-col w-full">
                      <label class="text-corBase">Perfil de Arremate:</label>
                      <v-select v-model="itemOrcamento.arremate" :options="lista_contramarco_arremate" label="label"
                        placeholder="Escolha uma opção" class="bg-white" :reduce="arremate => arremate.value"
                        :clearable="false" />
                    </div>
                  </div>

                  <div class="flex gap-2 justify-center items-center w-full mt-4">
                    <div class="w-full">
                      <label class="mr-2 text-corBase">Observação:</label>
                      <input v-model="itemOrcamento.observacao" type="text" id="descricao" placeholder="Digite aqui..."
                        class="input-form-chapa" />
                    </div>
                  </div>

                </div>

                <div class="w-1/4 flex justify-center items-center">
                  <img v-if="itemOrcamento.imagem" :src="itemOrcamento.imagem.url_documento"
                    class="w-96 md:w-96 cursor-pointer"
                    @click.prevent="openPreviewImage(itemOrcamento.imagem.url_documento)">
                  <img v-else
                    src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                    class="w-52">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full mx-auto mt-8">
        <div class="space-y-4">
          <div>
            <button class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
              Adicionar Serviços
            </button>

            <div class="dados-orcamento">
              <div class="flex gap-2">
                <div class="flex flex-col w-full">
                  <!-- <label class="font-bold text-corBase text-xs">Status das Medidas</label>
                    <select name="status-medidas" class="input-campos text-xs" v-model="itemOrcamento.statusMedidas">
                      <option value="1" selected>1 - Medidas Informadas pelo cliente(a medir)</option>
                      <option value="2">2 - Medidas Informadas pelo cliente(vão acabado)</option>
                      <option value="3">3 - Medidas do vão sem acabamento</option>
                      <option value="4">4 - Medidas do vão acabado</option>
                    </select> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full mx-auto mt-8">
        <div class="space-y-4">
          <div>
            <button class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
              Dados do Vão
            </button>

            <div class="dados-orcamento">
              <div class="flex gap-2">
                <div class="flex flex-col w-full">
                  <div>

                    <div class="flex gap-2 justify-center items-center w-full">

                      <div class="w-full">
                        <label class="text-corBase pt-1">Ambiente:</label>
                        <div class="flex justify-center">
                          <v-select v-model="itemOrcamento.ambiente" :options="lista_ambientes" label="nome"
                            class="bg-white pb-2 w-full" placeholder="Escolha uma opção" />
                        </div>
                      </div>

                      <div class="w-full">
                        <label class="text-corBase pb-0">Referência:</label>
                        <input v-model="itemOrcamento.referencia" type="text" maxlength="255"
                          placeholder="Digite aqui..." class="input-form-chapa" />
                      </div>

                      <div class="w-full">
                        <label class="text-corBase pb-0">Código Arquitetônico:</label>
                        <input v-model="itemOrcamento.codigo_arquitetonico" type="number" min="0" maxlength="20"
                          placeholder="Digite aqui..." class="input-form-chapa" />
                      </div>

                    </div>

                    <div class="flex gap-2 justify-center items-center w-full mt-4">
                      <div class="w-full">
                        <label class="mr-2 text-corBase">Situação do Vão:</label>
                        <v-select :options="lista_estado_vao" label="label" :reduce="situacao_vao => situacao_vao.value"
                          v-model="itemOrcamento.situacao_vao" placeholder="Escolha uma opção" />
                      </div>

                      <div class="w-full">
                        <label class="text-corBase pt-1">Medidas:</label>
                        <v-select :options="[{ label: 'Sim', value: true }, { label: 'Não', value: false }]"
                          label="label" :reduce="medidas => medidas.value" v-model="itemOrcamento.medidas"
                          placeholder="Escolha uma opção" />
                      </div>

                      <div class="w-full" v-if="itemOrcamento.medidas == true">
                        <label class="text-corBase pt-1">Responsável Medida:</label>
                        <v-select
                          :options="[{ label: 'Contratante', value: 'CT' }, { label: 'Contratada', value: 'CD' }]"
                          label="label" :reduce="medidas => medidas.value" v-model="itemOrcamento.quem_mediu"
                          placeholder="Escolha uma opção" />
                      </div>

                      <div class="w-full" v-if="itemOrcamento.medidas == true">
                        <label class="text-corBase pt-1">Medidor:</label>
                        <v-select :options="quem_mediu === 'CT' ? lista_stakeholders :
                          quem_mediu === 'CD' ? lista_medidores : []" label="nome_razao"
                          v-model="itemOrcamento.medidor" placeholder="Escolha uma opção">
                          <template #no-options>
                            <span>
                              {{ quem_mediu === 'CT' || quem_mediu === 'CD' ? 'Nenhuma pessoa encontrada.' :
                                'Selecione quem mediu.' }}
                            </span>
                          </template>
                        </v-select>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex w-full gap-2 justify-end">
            <button
              class="border border-gray-200 bg-red-500 text-white px-4 py-2 hover:bg-screenCor hover:text-red-500 hover:border-red-500"
              @click.prevent="$router.push(`/orcamento/${orcamentoId}`)">Cancelar</button>
            <button
              class="border border-gray-200 bg-ativo text-white px-4 py-2 hover:bg-screenCor hover:text-ativo hover:border-ativo"
              type="submit">
              Salvar
            </button>
          </div>
        </div>
      </div>

    </form>

    <!-- debug dadosProjeto: <br>
    altura_minima: {{ dadosProjeto.altura_minima }} | altura_maxima: {{ dadosProjeto.altura_maxima }} <br>
    largura_minima: {{ dadosProjeto.largura_minima }} | largura_maxima: {{ dadosProjeto.largura_maxima }} <br>
    bloquear_orcamento: {{ dadosProjeto.bloquear_orcamento }} <br> <br>
    habilitar_produto_perfil: {{ dadosProjeto.habilitar_produto_perfil }} | habilitar_cor_perfil: {{
      dadosProjeto.habilitar_cor_perfil }} <br>
    habilitar_produto_componente: {{ dadosProjeto.habilitar_produto_componente }} | habilitar_cor_componente: {{
      dadosProjeto.habilitar_cor_componente }} <br>
    habilitar_produto_vidro: {{ dadosProjeto.habilitar_produto_vidro }} | habilitar_cor_vidro: {{
      dadosProjeto.habilitar_cor_vidro }} <br> -->
  </div>

  <ModalVariaveisOrcamento v-if="modalVariaveis" :id="itemOrcamento.id" :altura="itemOrcamento.altura"
    :largura="itemOrcamento.largura" :quantidade="itemOrcamento.quantidade"
    :codigo_orcamento="itemOrcamento.orcamento.codigo" :descricao_detalhada="itemOrcamento.descricao"
    :imagem="itemOrcamento.imagem" @fecharModalVariaveis="toggleModalVariaveis()" />

  <PreviewImage v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />
  <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />
  <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />

</template>

<script>
import axios from 'axios'
import ModalVariaveisOrcamento from '../ModalVariaveisOrcamento.vue'
import PreviewImage from '@/components/midais/ged/PreviewImage.vue'
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue'
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue'
import vSelect from 'vue-select'

export default {
  name: 'AlterarDadosItemOrcamento',

  props: ['itemOrcamento', 'dadosProjeto', 'dadosAntigos'],
  emits: ['atualizarItemOrcamento'],

  components: {
    ModalVariaveisOrcamento,
    PreviewImage,
    SucessoRapido,
    ListagemErros,
    vSelect,
  },

  data() {
    return {

      listaCorComponentes: [],
      listaCorPerfis: [],
      // listaCorVidros: [],
      lista_contramarco_arremate: [
        { label: 'Sim', value: true },
        { label: 'Não', value: false }
      ],
      lista_medida_contramarco: ['Interna', 'Externa'],
      lista_estado_vao: [
        { label: 'A Construir', value: 'AC' },
        { label: 'Em Construção', value: 'EC' },
        { label: 'Construído e Sem Acabamento', value: 'CSA' },
        { label: 'Construído e Acabado', value: 'CA' },
      ],
      lista_ambientes: [],

      lista_stakeholders: [],
      lista_medidores: [],

      larguraErrada: false,
      alturaErrada: false,

      activeSection: 1,

      modalVariaveis: false,

      preview_imagem: null,

      sucesso: false,
      sucessoMessage: '',

      erros: [],
      exibirErros: false,

    }
  },

  watch: {
    itemOrcamento: {
      handler(newValue) {
        if (newValue.sem_perfis) {
          this.itemOrcamento.cor_perfis = ''
        }
        if (newValue.sem_componentes) {
          this.itemOrcamento.cor_componentes = ''
        }
      },
      deep: true,
    }
  },


  created() {
    // console.log('projeto: ', this.projetoAtual)
    // console.log(this.projetoId);
    // console.log('gestor projeto: ', this.gestorProjeto);
    // console.log(this.orcamentoId);
    // console.log(this.descricaoItemOrcamento);

    // console.log('altura minima: ', this.projetoAtual.altura_minima)
    // console.log('altura maxima: ', this.projetoAtual.altura_maxima)

    // console.log('largura minima: ', this.projetoAtual.largura_minima)
    // console.log('largura maxima: ', this.projetoAtual.largura_maxima)

    // this.getDadosProjeto()
    this.getlistaCorComponentes();
    this.getlistaCorPerfis();
    this.getListaLocaisVao()
    console.log('dados antigos: ', this.dadosAntigos)
    // this.getlistaCorVidros();
  },

  methods: {

    validarLargura() {
      if (!this.dadosProjeto || (!this.dadosProjeto.largura_minima && !this.dadosProjeto.largura_maxima)) return;
      const larguraDigitada = Number(this.largura);
      const larguraMinima = Number(this.dadosProjeto.largura_minima);
      const larguraMaxima = Number(this.dadosProjeto.largura_maxima);

      if (larguraMinima && !larguraMaxima && larguraDigitada < larguraMinima) {
        this.erros = { largura: `O valor deve ser, no mínimo, ${larguraMinima}mm.` };
        this.toggleErros();
        this.larguraErrada = true;
        return;
      }

      if (!larguraMinima && larguraMaxima && larguraDigitada > larguraMaxima) {
        this.erros = { largura: `O valor deve ser, no máximo, ${larguraMaxima}mm.` };
        this.toggleErros();
        this.larguraErrada = true;
        return;
      }

      if (larguraMinima && larguraMaxima && (larguraDigitada < larguraMinima || larguraDigitada > larguraMaxima)) {
        this.erros = { largura: `A largura deve estar entre ${larguraMinima}mm e ${larguraMaxima}mm.` };
        this.toggleErros();
        this.larguraErrada = true;
        return;
      }
    },

    validarAltura() {
      if (!this.dadosProjeto || (!this.dadosProjeto.altura_minima && !this.dadosProjeto.altura_maxima)) return;
      const alturaDigitada = Number(this.altura);
      const alturaMinima = Number(this.dadosProjeto.altura_minima);
      const alturaMaxima = Number(this.dadosProjeto.altura_maxima);

      if (alturaMinima && !alturaMaxima && alturaDigitada < alturaMinima) {
        this.erros = { altura: `O valor deve ser, no mínimo, ${alturaMinima}mm.` };
        this.toggleErros();
        this.alturaErrada = true;
        return;
      }

      if (!alturaMinima && alturaMaxima && alturaDigitada > alturaMaxima) {
        this.erros = { altura: `O valor deve ser, no máximo, ${alturaMaxima}mm.` };
        this.toggleErros();
        this.alturaErrada = true;
        return;
      }

      if (alturaMinima && alturaMaxima && (alturaDigitada < alturaMinima || alturaDigitada > alturaMaxima)) {
        this.erros = { altura: `A altura deve estar entre ${alturaMinima}mm e ${alturaMaxima}mm.` };
        this.toggleErros();
        this.alturaErrada = true;
        return;
      }
    },

    abrirModal() {
      this.modalVariaveis = true
    },

    toggleSection(sectionNumber) {
      this.activeSection = this.activeSection === sectionNumber ? null : sectionNumber;
    },

    // async getDadosProjeto() {
    //   try {
    //     const token = await this.$store.dispatch('getAuthTokenCookie')
    //     await this.$store.dispatch('fetchURLrequest')

    //     const response = await axios.get(`projeto/projeto/${this.projetoAtual}`, {
    //       headers: {
    //         'Authorization': `Token ${token}`
    //       }
    //     })

    //     console.log(response.data)
    //     this.dadosProjeto = response.data.results
    //   } catch (error) {
    //     console.error(error)
    //   }
    // },

    async getlistaCorComponentes() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get('produtos/cor-componente/?page_size=999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.listaCorComponentes = response.data.results
      } catch (error) {
        console.error(error)
      }
    },

    async getlistaCorPerfis() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get('produtos/cor-perfil/?page_size=999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.listaCorPerfis = response.data.results
      } catch (error) {
        console.error(error)
      }
    },

    // async getlistaCorVidros() {
    //   try {
    //     const token = await this.$store.dispatch('getAuthTokenCookie')
    //     await this.$store.dispatch('fetchURLrequest')

    //     const response = await axios.get('produtos/cor-vidro/?page_size=999', {
    //       headers: {
    //         'Authorization': `Token ${token}`
    //       }
    //     })

    //     this.listaCorVidros = response.data.results.map((cor) => {
    //       return {
    //         id: cor.cor_base.id,
    //         descricao: cor.descricao
    //       }})
    //   } catch (error) {
    //     console.error(error)
    //   }
    // },

    async getListaLocaisVao() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`obra/local-vao/?ativo=True&page_size=999`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.lista_ambientes = response.data.results
      } catch (error) {
        console.error(error)
      }
    },

    async atualizarDadosOrcamento() {

      if (this.dadosProjeto.bloquear_orcamento && (this.alturaErrada || this.larguraErrada)) {
        let mensagensErro = [];

        if (this.alturaErrada) {
          mensagensErro.push(`A altura deve estar entre ${this.dadosProjeto.altura_minima}mm e ${this.dadosProjeto.altura_maxima}mm.`);
        }

        if (this.larguraErrada) {
          mensagensErro.push(`A largura deve estar entre ${this.dadosProjeto.largura_minima}mm e ${this.dadosProjeto.largura_maxima}mm.`);
        }

        this.erros = { erro: mensagensErro.join(" ") }; // Junta todas as mensagens em uma string
        this.toggleErros();
        return;
      }

      console.log(!this.itemOrcamento.sem_perfis, !this.itemOrcamento.cor_perfis)
      if (!this.itemOrcamento.sem_perfis && !this.itemOrcamento.cor_perfis) {
        this.erros = { cor_perfil: 'Você precisa selecionar uma cor para os perfis.' }
        this.toggleErros()
        return
      }

      if (!this.itemOrcamento.sem_componentes && !this.itemOrcamento.cor_componentes) {
        this.erros = { cor_componente: 'Você precisa selecionar uma cor para os componentes.' }
        this.toggleErros()
        return
      }


      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const data = {
          // orcamento: this.orcamentoId,
          // projeto: this.projetoAtual.id,
          // gestor: this.gestorProjeto,

          descricao: this.itemOrcamento.descricao,
          quantidade: this.itemOrcamento.quantidade,
          largura: this.itemOrcamento.largura,
          altura: this.itemOrcamento.altura,

          sem_perfis: this.itemOrcamento.sem_perfis,
          cor_perfis: this.itemOrcamento.cor_perfis?.id || '',

          sem_componentes: this.itemOrcamento.sem_componentes,
          cor_componentes: this.itemOrcamento.cor_componentes?.id || '',
          // cor_vidros: this.itemOrcamento.corVidros?.id || '',

          contramarco: this.itemOrcamento.contramarco,
          medida_contramarco: this.itemOrcamento.medida_contramarco,
          arremate: this.itemOrcamento.arremate,

          ambiente: this.itemOrcamento.ambiente?.id || '',
          referencia: this.itemOrcamento.referencia,
          codigo_arquitetonico: this.itemOrcamento.codigo_arquitetonico,

          situacao_vao: this.itemOrcamento.situacao_vao.value ? this.itemOrcamento.situacao_vao.value : this.itemOrcamento.situacao_vao,
          medidas: this.itemOrcamento.medidas,
          quem_mediu: this.itemOrcamento.quem_mediu,
          medidor: this.itemOrcamento.medidor?.id || '',

          observacao: this.itemOrcamento.observacao,
        }

        const response = await axios.patch(`orcamentos/item-orcamento/${this.itemOrcamento.id}/`, data, {
          headers: {
            Authorization: `Token ${token}`
          }
        })

        console.log(response.data)
        const camposAlterados = (
          this.dadosAntigos.largura !== data.largura ||
          this.dadosAntigos.altura !== data.altura ||
          this.dadosAntigos.cor_perfis !== data.cor_perfis ||
          this.dadosAntigos.cor_componentes !== data.cor_componentes ||
          this.dadosAntigos.contramarco !== data.contramarco ||
          this.dadosAntigos.arremate !== data.arremate
        );

        console.log(camposAlterados)

        if (camposAlterados) {
          this.modalVariaveis = true;
        } else {
          this.sucesso = true
          this.sucessoMessage = 'Dados atualizados com sucesso.'
          setTimeout(() => {
            this.sucesso = false;
            this.sucessoMessage = ''
          }, 3000);
        }

      } catch (error) {
        console.error(error)
        this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
        this.toggleErros()
      }
    },

    toggleModalVariaveis() {
      this.modalVariaveis = !this.modalVariaveis
      this.$emit('atualizarItemOrcamento')
    },

    openPreviewImage(url) {
      this.preview_imagem = url
    },

    closed_preview_image() {
      this.preview_imagem = ''
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros
    }
  }
}
</script>

<style scoped lang="scss">
.dados-orcamento {
  @apply p-4 flex flex-col gap-5 border border-corBase
}
</style>