<template>
    <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[55] p-4">
        <div
            class="bg-white p-4 md:p-6 rounded-lg shadow-lg min-w-[60%] max-w-[70%] max-h-[80vh] overflow-y-auto flex flex-col">

            <!-- Cabeçalho -->
            <div class="border-b pb-2 mb-4 flex items-center justify-between">
                <!-- Título centralizado -->
                <h2 class="text-2xl font-semibold text-gray-800 text-center w-full">
                    Escolha dos Vidros
                </h2>

                <!-- Ícone "X" no lado direito -->
                <i class="fa-solid fa-x cursor-pointer text-gray-600 hover:text-gray-900 ml-auto"
                    @click.prevent="$emit('fecharModal')">
                </i>
            </div>

            <div class="flex-1 space-y-4 px-2">

                <div class="container-modelo-tabela">
                    <table class="container-tabela">
                        <thead class="cabecalho-tabela">
                            <tr class="border-b">
                                <th class="px-6 py-2 text-center">
                                    Localização
                                </th>
                                <th class="px-28 py-2 text-center">
                                    Vidro
                                </th>
                                <th class="px-6 py-2 text-center">
                                    Sem Vidro
                                </th>
                                <th class="px-6 py-2 text-center">
                                    Espessura de Referência
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="vidro in vidros" :key="vidro.id" class="escopo-tabela text-left">

                                <!-- Localização -->
                                <td class="py-2 text-center">
                                    {{ vidro.area_vidro?.descricao ?? '-' }}
                                </td>

                                <!-- Vidro -->
                                <td class="py-2 text-center">
                                    <v-select :options="lista_vidros" label="descricao" v-model="vidro.vidro"
                                        :disabled="vidro.sem_vidro" class="bg-white" />
                                </td>

                                <td class="py-2">
                                    <div class="flex justify-center">
                                        <input type="checkbox" class="w-4 h-4" v-model="vidro.sem_vidro">
                                    </div>
                                </td>

                                <td class="py-2">
                                    <v-select
                                        :options="espessurasPermitidas ? espessurasPermitidas : lista_espessuras"
                                        v-model="vidro.espessura_referencia" :disabled="vidro.vidro ? true : false"
                                        label="espessura" class="bg-white" />
                                </td>

                            </tr>
                        </tbody>

                    </table>
                </div>

                <!-- Botões -->
                <div class="mt-4 flex justify-end space-x-4 border-t pt-4">
                    <button
                        class="border border-gray-200 bg-ativo text-white px-4 py-2 hover:bg-screenCor hover:text-ativo hover:border-ativo"
                        @click.prevent="salvarVidros()">
                        Salvar
                    </button>
                </div>
            </div>
        </div>

        <SucessoRapido v-if="sucesso === true" mensagem="Vidros atualizados com sucesso!" />
        <!-- <ErroRapido v-if="erro === true" mensagem="Ocorreu um erro ao atualizar Vidros." /> -->
        <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />
    </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
// import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import ListagemErros from '../toasts/toast_erro/ListagemErros.vue';

export default {
    name: 'ModalEscolhaVidros',

    props: ['item_orcamento', 'rotaVoltar'],

    emits: ['fecharModal', 'atualizarLista'],

    components: {
        vSelect,
        SucessoRapido,
        // ErroRapido,
        ListagemErros,
    },

    computed: {
        projetoAtual() {
            return this.$store.state.projetoAtual;
        },
    },

    data() {
        return {
            vidros: [],
            lista_vidros: [],
            lista_espessuras: [],
            espessurasPermitidas: [],

            cancelarBusca: false,

            espessurasSelecionadas: [],
            tiposVidroSelecionados: [],

            sucesso: false,
            exibirErros: false,
            erros: [],
        };
    },

    created() {
        if (this.projetoAtual) {
            // alert('projeto atual')
            this.espessurasPermitidas = this.projetoAtual.espessuras_permitidas.map((espessura) => {
                    return {
                        id: espessura.id,
                        espessura: espessura.espessura
                    }
                })
            // this.espessurasSelecionadas = this.projetoAtual.espessuras_permitidas.map(espessura => espessura.id)
            this.tiposVidroSelecionados = this.projetoAtual.tipos_vidro_permitidos.map(tipo => tipo.id)
            this.getListaVidros()
        } else {
            // alert('dados projeto')
            this.getDadosProjetoItemOrcamento()
        }
        this.getListaEspessuras();
        // this.getListaVidros()
        this.getVidrosOrcamento()
    },

    watch: {
        vidros: {
            handler(newVal) {
                newVal.forEach((vidro, index) => {
                    // Quando marcar "sem vidro", limpa o campo vidro
                    if (vidro.sem_vidro && vidro.vidro) {
                        this.vidros[index].vidro = null;
                    }

                    // Quando selecionar um vidro, preencher espessura de referência automaticamente
                    if (vidro.vidro && !vidro.sem_vidro) {
                        const vidroSelecionado = this.lista_vidros.find(v => v.id === vidro.vidro.id);
                        console.log(vidroSelecionado)
                        const espessuraCorrespondente = this.lista_espessuras.find(e => e.id === vidroSelecionado?.espessura);
                        if (espessuraCorrespondente) {
                            this.vidros[index].espessura_referencia = espessuraCorrespondente;
                        }
                    }
                });
            },
            deep: true,
        }
    },


    methods: {

        async getDadosProjetoItemOrcamento() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                this.$store.dispatch("setLoading", true);

                const response = await axios.get(`orcamentos/dados-projeto-item-orcamento/?item_orcamento=${this.item_orcamento}`, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                })

                console.log(response.data.results[0])

                this.dadosProjeto = response.data.results[0]
                this.espessurasPermitidas = this.dadosProjeto.espessuras_permitidas.map((espessura) => {
                    return {
                        id: espessura.id,
                        espessura: espessura.espessura
                    }
                })
                this.tiposVidroSelecionados = this.dadosProjeto.tipos_vidro_permitidos
                console.log(this.espessurasPermitidas)
                console.log(this.tiposVidroSelecionados)
                this.getListaVidros()
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getListaEspessuras() {
            try {

                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get(`produtos/espessura/?ativo=True&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.lista_espessuras = response.data.results

            } catch (error) {
                console.error(error);
            }
        },

        async getVidrosOrcamento(attempt = 1) {
            try {
                if (this.cancelarBusca) return; // 🔸 Cancela se modal já foi fechado

                this.$store.dispatch("setLoading", true);

                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                const response = await axios.get(
                    `orcamentos/vidros-orcamento/?item_orcamento_id=${this.item_orcamento}&ativo=True&localizacao_repetida=True&aplicado_orcamento=Sim&page_size=9999`,
                    { headers: { Authorization: `Token ${token}` } }
                );

                if (this.cancelarBusca) return; // 🔸 Verifica novamente após o await

                if (response.data.count > 0) {
                    this.vidros = response.data.results;
                    this.checkAll = false;
                } else {
                    if (attempt < 3) {
                        console.warn(`Tentativa ${attempt}: Nenhum vidro encontrado, recalculando...`);
                        await this.recalcularItemOrcamento();
                        await this.getVidrosOrcamento(attempt + 1);
                    } else {
                        alert("Erro: Nenhum vidro foi encontrado após 3 tentativas.");
                        console.error("Erro: Nenhum vidro foi encontrado após 3 tentativas.");

                        this.cancelarBusca = true; // 🔹 Evita chamadas adicionais

                        if (this.rotaVoltar) {
                            this.$router.push(`${this.rotaVoltar}`);
                        } else {
                            this.$emit("fecharModal");
                            this.$emit("atualizarLista");
                        }
                    }
                }

            } catch (error) {
                console.error("Erro ao buscar vidros do orçamento:", error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },


        async recalcularItemOrcamento() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                this.$store.dispatch("setLoading", true);

                const response = await axios.get(
                    `engine/recalcular-item-orcamento/${this.item_orcamento}/?vidros=true`,
                    {
                        headers: { 'Authorization': `Token ${token}` },
                    }
                );

                console.log("Itens atualizados com sucesso:", response.data);
                this.getVidrosOrcamento()

            } catch (error) {
                console.error("Erro ao atualizar itens do orçamento:", error);
                this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
                this.toggleErros();
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getListaVidros() {
            try {
                this.$store.dispatch("setLoading", true);

                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                const params = new URLSearchParams();
                params.append('ativo', true);
                params.append('disponibilidade', 'Todos');
                params.append('fornecedor', '');
                params.append('page_size', 9999);

                if (this.espessurasPermitidas.length) {
                    this.espessurasPermitidas.map(espessura => espessura.id).forEach(esp => params.append('espessuras', esp));
                }

                if (this.tiposVidroSelecionados.length) {
                    this.tiposVidroSelecionados.forEach(tipo => params.append('tipos_vidro', tipo));
                }


                const response = await axios.get(`produtos/vidro/`, {
                    params,
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });

                this.lista_vidros = response.data.results.map((vidro) => {
                    return {
                        id: vidro.id,
                        descricao: `${vidro.descricao} - ${vidro.fornecedor?.nome_razao || 'Fornecedor não informado'}`,
                        espessura: vidro.espessura
                    };
                });

                this.checkAll = false;
            } catch (error) {
                console.error(error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async salvarVidros() {
            try {

                //Validação: Se não marcar "sem vidro", tem que selecionar um vidro
                const semVidroSelecionadoSemVidro = this.vidros.filter(vidro =>
                    !vidro.sem_vidro && !vidro.vidro
                );

                if (semVidroSelecionadoSemVidro.length > 0) {
                    this.erros = { vidro: 'Você precisa selecionar um Vidro ou marcar "Sem Vidro".' }
                    this.toggleErros()
                    return;
                }

                //Validação: Se marcar "sem vidro", tem que ter espessura definida
                const semVidroSemEspessura = this.vidros.filter(vidro =>
                    vidro.sem_vidro === true && (!vidro.espessura_referencia || !vidro.espessura_referencia.id)
                );

                if (semVidroSemEspessura.length > 0) {
                    this.erros = { vidro: 'Você precisa definir uma Espessura de Referência nos itens marcados como "Sem Vidro".' }
                    this.toggleErros()
                    return;
                }

                this.$store.dispatch("setLoading", true);

                const token = await this.$store.dispatch("getAuthTokenCookie");
                await this.$store.dispatch("fetchURLrequest");

                // Estruturando os dados para envio
                const vidrosAtualizados = this.vidros.map(vidro => ({
                    id: vidro.id,
                    vidro: vidro.vidro?.id || null,
                    sem_vidro: vidro.sem_vidro,
                    espessura: vidro.vidro?.vidro_base?.espessura?.id || vidro.espessura_referencia?.id || null,
                }));

                // Enviando a requisição PATCH
                await axios.patch(
                    `orcamentos/atualizar-vidros-orcamento/`,
                    { itens: vidrosAtualizados },
                    { headers: { Authorization: `Token ${token}` } }
                );

                this.sucesso = true;
                this.recalcularItemOrcamento()
                setTimeout(() => {
                    this.sucesso = false;
                    if (this.rotaVoltar) {
                        this.$router.push(`${this.rotaVoltar}`);
                    } else {
                        this.$emit("fecharModal");
                        this.$emit("atualizarLista");
                    }
                }, 3000);

            } catch (error) {
                console.error("Erro ao salvar alterações:", error);
                this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
                this.toggleErros()
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async recalcularItemOrcamento() {

            try {

                this.$store.dispatch("setLoading", true);

                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get(`engine/${this.item_orcamento}/`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                })

                console.log(response.data)
                // this.sucesso = true
                // this.sucessoMessage = 'Item do Orçamento recalculado com sucesso.'
                // this.getPerfisOrcamento()
                // setTimeout(() => {
                //     this.sucesso = false
                //     this.sucessoMessage = ''
                // }, 3000)

            } catch (error) {
                console.error(error)
                this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
                this.toggleErros()
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        }


    },
};
</script>

<style scoped>
.input-campos {
    @apply block w-full p-2 border rounded-md text-corBase;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    @apply bg-[#888] rounded-lg border border-[#f1f1f1]
}

::-webkit-scrollbar-thumb:hover {
    @apply bg-corBase
}
</style>