<template>

    <!-- formulario para mais de 768 pixels -->
    <section class="">
        <!-- Escopo do formulario de cadastro mobile-->
        <form @submit.prevent="alterarDadosProjeto" enctype="multipart/form-data">
            <div class="w-full mx-auto mt-8">
                <div class="space-y-4">

                    <div>
                        <div class="p-4 flex flex-col border border-corBase/60">

                            <div class="flex gap-5 pb-4">

                                <div class="w-full">
                                    <label class="text-corBase">Espessuras de Vidro do Projeto</label>
                                    <v-select v-model="espessuras" :options="lista_espessuras" label="text" multiple
                                        class="w-full z-10 bg-white" />

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="flex w-full gap-8 justify-end mt-2">
                <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
      text-white hover:text-ativo py-1 px-4" type="submit">Salvar</button>
            </div>
        </form>
    </section>

    <SucessoRapido v-if="sucesso" mensagem="Dados do projeto atualizados com sucesso" />
    <listagem-erros v-if="exibirErros == true" :erros="erros" @fecharModal="toggleErros()" />
</template>


<script>
import axios from "axios";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import vSelect from "vue-select";

export default {
    name: 'ProjetoDadosComponent',

    components: {
        SucessoRapido,
        ListagemErros,
        vSelect,
    },

    data() {
        return {
            projeto_id: '',
            projeto: {},

            espessuras: [],
            lista_espessuras: [],

            sucesso: false,

            exibirErros: false,
            erros: {}

        }
    },

    props: ['dadosProjeto'],

    created() {
        this.projeto_id = this.$route.params.id
        this.getListaEspessuras()
        this.defineVariaveisProjeto()
    },

    watch: {
        sucesso() {
            if (this.sucesso) {
                setTimeout(() => {
                    this.sucesso = false
                }, 2000)
            }
        },

    },

    methods: {

        defineVariaveisProjeto() {
            console.log('---------------------')
            console.log('Dados do projeto:')
            console.log(this.dadosProjeto)
            this.espessuras = this.dadosProjeto.espessuras_permitidas.map(espessura => {
                return { id: espessura.id, text: espessura.espessura }
            })
        },

        async alterarDadosProjeto() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                // console.log(this.espessuras_permitidas)

                const updatedData = {
                    espessuras_permitidas: this.espessuras.map(espessura => (espessura.id))
                }

                await axios.patch(`projeto/projeto/${this.dadosProjeto.id}/`, updatedData, {
                    headers: {
                        'Authorization': `Token ${token}`
                    },
                })
                this.sucesso = true

            } catch (error) {
                console.error(error)
                if (error.response && error.response.data) {
                    this.erros = error.response.data;
                } else {
                    this.erros = { geral: ["Erro desconhecido ao salvar o projeto."] };
                }
                this.toggleErros();
                this.$store.dispatch("setLoading", false);

            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },

        async getListaEspessuras() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            await axios.get('produtos/espessura/?ativo=True&page_size=9999', {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    this.lista_espessuras = response.data.results.map(item => ({
                        id: item.id,
                        text: item.espessura
                    }));
                    console.log(this.lista_espessuras)
                })
                .catch(error => {
                    console.error(error)
                })
        },
    }

}

</script>


<style lang="scss"></style>