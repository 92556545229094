<template>
    <!-- <div class="flex">

        <div class="flex flex-wrap gap-2 mb-2">

            <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
            text-white hover:text-corBase py-2 px-4" @click.prevent="mudarPagina(1)">
                Pesquisar
            </button>

            <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
            text-white hover:text-ativo py-2 px-4" @click.prevent="$router.push(`/orcamento/selecionar-projeto/`)">
                Adicionar
            </button>

            <button class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500
            text-white hover:text-yellow-500 py-2 px-4" @click.prevent="alterarStatusSelecionados()">
                Alterar Status
            </button>

            <button class="bg-blue-500 hover:bg-screenCor border border-screenCor hover:border-blue-500
            text-white hover:text-blue-500 py-2 px-4" @click.prevent="alterarSelecionados()">
                Alterar Selecionados
            </button>

            <button class="bg-red-500 hover:bg-screenCor border border-screenCor hover:border-red-500
            text-white hover:text-red-500 py-2 px-4" @click.prevent="confirmacaoExcluirSelecionados()">
                Excluir Selecionados
            </button>

            <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
            text-white hover:text-ativo py-2 px-4" @click.prevent="salvarItensOrcamento()">
                Salvar
            </button>

            <button class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500
            text-white hover:text-yellow-500 py-2 px-4" @click.prevent="calcularOrcamento()">
                Calcular
            </button>

            <button class="bg-orange-500 hover:bg-screenCor border border-screenCor hover:border-orange-500
            text-white hover:text-orange-500 py-2 px-4" @click.prevent="imprimirEnviarOrcamento()">
                Imprimir/Enviar
            </button>

            <button class="bg-green-500 hover:bg-screenCor border border-screenCor hover:border-green-500
            text-white hover:text-green-500 py-2 px-4" @click.prevent="venderOrcamento()">
                Vender
            </button>

            <button @click.prevent="getOrcamentosIguais()">getOrcamentosIguais</button>

        </div>
    </div> -->

    <div class="w-full block justify-center">
        <div class="flex flex-wrap gap-2 justify-center">
            <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
                dashboardAtivo == 'PerfilDashboard'
                    ? 'bg-screenCor text-corBase border border-corBase'
                    : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
                @click.prevent="abrirDashboard('PerfilDashboard')">
                Perfil
            </button>

            <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
                dashboardAtivo == 'ComponenteDashboard'
                    ? 'bg-screenCor text-corBase border border-corBase'
                    : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
                @click.prevent="abrirDashboard('ComponenteDashboard')">
                Componente
            </button>

            <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
                dashboardAtivo == 'VidroDashboard'
                    ? 'bg-screenCor text-corBase border border-corBase'
                    : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
                @click.prevent="abrirDashboard('VidroDashboard')">
                Vidro
            </button>

            <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
                dashboardAtivo == 'ChapaTelaDashboard'
                    ? 'bg-screenCor text-corBase border border-corBase'
                    : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
                @click.prevent="abrirDashboard('ChapaTelaDashboard')">
                Chapa/Tela
            </button>
        </div>
    </div>

    <CustosMargensPerfil v-if="dashboardAtivo === 'PerfilDashboard'" :listaPerfis="listaPerfis" :pesoTotalPerfis="pesoTotalPerfis" />
    <CustosMargensComponente v-if="dashboardAtivo === 'ComponenteDashboard'" :listaComponentes="listaComponentes" :pesoTotalComponentes="pesoTotalComponentes" />
    <CustosMargensVidro v-if="dashboardAtivo === 'VidroDashboard'" :listaVidros="listaVidros" :pesoTotalVidros="pesoTotalVidros" />

    <!-- <div class="container-modelo-tabela">
        <table class="container-tabela overflow-auto">
            <thead class="cabecalho-tabela">
                <tr>
                    <th scope="col" class="px-3 py-3"></th>
                    <th scope="col" class="px-3 py-3">
                        #
                    </th>
                    <th scope="col" class="px-3 py-3">
                        <div class="flex items-center">
                            <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
                            <label class="sr-only">checkbox</label>
                        </div>
                    </th>
                    <th scope="col" class="px-3 py-3 text-center">
                        Ações
                    </th>
                    <th scope="col" class="px-3 py-3 text-center">
                        Status
                    </th>
                    <th scope="col" class="px-3 py-3 text-center">
                        Imagem
                    </th>
                    <th scope="col" class="px-3 py-3">
                        Projeto
                    </th>
                    <th scope="col" class="px-3 py-3 text-center">
                        Largura x Altura
                    </th>
                    <th scope="col" class="px-3 py-3 text-center">
                        Quantidade
                    </th>
                    <th scope="col" class="px-3 py-3 text-center">
                        Ambiente
                    </th>
                    <th scope="col" class="px-3 py-3 text-center">
                        $ Unitário
                    </th>
                    <th scope="col" class="px-3 py-3 text-center">
                        $ Total
                    </th>
                    <th scope="col" class="px-3 py-3 text-center">
                        $ Alterado
                    </th>

                </tr>
            </thead>
            <draggable :list="listaItensOrcamento" tag="tbody" handle=".handle" itemKey="id">
                <template v-slot:item="{ element, index }">
                    <tr :key="index" class="escopo-tabela">
                        <td class="px-3 py-3 text-xs text-center relative">
                            <button @click="toggleDetalhes(element.id)" class="text-gray-500">
                                <i class="fa-solid"
                                    :class="{ 'fa-solid fa-caret-down': expandedRow !== element.id, 'fa-solid fa-caret-up': expandedRow === element.id }"></i>
                            </button>

                            <div v-if="expandedRow === element.id"
                                class="absolute left-0 top-full w-[50vw] text-center bg-gray-100 p-4 shadow-lg rounded-lg z-10">
                                <div class="flex justify-between gap-4">

                                    <div class="w-1/3">
                                        <strong class="flex justify-center mb-2">Dados do Produto:</strong>
                                        <p>
                                            <strong>Cor Perfil: </strong>
                                            <span v-if="element.sem_perfis">Sem Perfis</span>
                                            <span v-else>{{ element.cor_perfis?.descricao || '-' }}</span>
                                        </p>
                                        <p>
                                            <strong>Cor Componente: </strong>
                                            <span v-if="element.sem_componentes">Sem Componentes</span>
                                            <span v-else>{{ element.cor_componentes?.descricao || '-' }}</span>
                                        </p>
                                        <p><strong>Contramarco:</strong> {{ element.contramarco ? 'Sim' : 'Não' }}</p>
                                        <p v-if="element.contramarco"><strong>Medida Contramarco:</strong> 
                                        {{ element.medida_contramarco }}</p>
                                        <p><strong>Perfil de Arremate:</strong> {{ element.arremate ? 'Sim' : 'Não' }}
                                        </p>
                                        <p><strong>Observação:</strong> {{ element.observacao || '-' }}</p>
                                    </div>

                                    <div class="w-1/3">
                                        <strong class="flex justify-center mb-2">Serviços:</strong>
                                        
                                    </div>

                                    <div class="w-1/3">
                                        <strong class="flex justify-center mb-2">Dados do Vão:</strong>
                                        <p><strong>Ambiente:</strong> {{ element.ambiente?.nome || '-' }}</p>
                                        <p><strong>Referência:</strong> {{ element.referencia || '-' }}</p>
                                        <p><strong>Código Arquitetônico:</strong> {{ element.codigo_arquitetonico || '-'
                                            }}</p>
                                        <p><strong>Situação do Vão:</strong> {{
                                            replaceSiglaSituacaoVao(element.situacao_vao) }}</p>
                                        <p><strong>Medidas:</strong> {{ element.medidas ? 'Sim' : 'Não' }}</p>
                                        <p v-if="element.medidas"><strong>Responsável Medida:</strong> {{
                                            replaceSiglaResponsavelMedida(element.quem_mediu) }}</p>
                                        <p v-if="element.medidas"><strong>Medidor:</strong> {{ element.medidor || '-' }}
                                        </p>
                                    </div>

                                </div>
                            </div>

                        </td>
                        <th scope="col"
                            class="text-center font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                            <div class="handle cursor-pointer">
                                <span>☰</span>
                                {{ element.index }}
                            </div>
                        </th>
                        <td class="w-4 px-3 py-3">
                            <div class="flex items-center">
                                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                                    v-model="element.selected">
                                <label class="sr-only">checkbox</label>
                            </div>
                        </td>
                        <td class="px-3 py-3 text-xs text-center">
                            <div class="flex justify-center items-center gap-2">
                                <i class="fa-solid fa-pen-to-square cursor-pointer"
                                    @click.prevent="$router.push(`/item-orcamento/${element.id}`)"
                                    title="Editar Item Orçamento"></i>

                                <i class="fa-solid fa-code-fork cursor-pointer"
                                    @click.prevent="mostrarVariaveis(element)" title="Variáveis Item Orçamento"></i>

                                <i class="fa-solid fa-copy cursor-pointer" title="Duplicar Item Orçamento"></i>
                            </div>
                        </td>
                        <td class="px-6 py-3 text-center">
                            <i v-if="element.ativo" class="fa-solid fa-circle-check text-ativo"></i>
                            <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                        </td>
                        <td scope="col" class="px-6 py-2">
                            <div class="flex justify-center items-center">
                                <img v-if="element.imagem" :src="element.imagem.url_documento"
                                    class="cursor-pointer w-10 h-10"
                                    @click="openPreviewImage(element.imagem.url_documento)">
                                <img v-else
                                    src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                                    alt="Sem foto" class="w-10 h-10">
                            </div>
                        </td>
                        <td class="px-3 py-3 text-xs">
                            {{ element.descricao }}
                        </td>
                        <td class="px-3 py-3 text-xs text-center">
                            {{ element.largura }} x {{ element.altura }}
                        </td>
                        <td class="px-3 py-3 text-xs text-center">
                            {{ element.quantidade }}
                        </td>
                        <td class="px-3 py-3 text-xs text-center">
                            {{ element.ambiente?.nome || '-' }}
                        </td>
                        <td class="px-3 py-3 text-xs text-center">
                            {{ element.valor_unitario || '-' }}
                        </td>
                        <td class="px-3 py-3 text-xs text-center">
                            {{ element.valor_total || '-' }}
                        </td>
                        <td class="px-3 py-3 text-xs text-center">
                            <input type="number" v-model="element.valor_alterado" class="input-form-chapa">
                        </td>
                    </tr>
                </template>
</draggable>
</table>
<Pagination v-if="listaItensOrcamento.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
    @change-page="mudarPagina" />
</div> -->

    <!-- <PreviewImage v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />

    <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />

    <ModalVariaveisOrcamento v-if="exibirVariaveis" :id="itemOrcamentoSelecionado.id"
        @fecharModalVariaveis="toggleModalVariaveis()" :descricao_detalhada="itemOrcamentoSelecionado.descricao"
        :codigo_orcamento="orcamento.codigo" :imagem="itemOrcamentoSelecionado.imagem"
        :largura="itemOrcamentoSelecionado.largura" :altura="itemOrcamentoSelecionado.altura"
        :quantidade="itemOrcamentoSelecionado.quantidade" />

    <MensagemConfirmacaoExclusao :abrir="modalExcluirSelecionados"
        mensagem="Deseja excluir o(s) Item(ns) do Orçamento selecionado(s)?" @confirmarExclusao="excluirSelecionados()"
        @fecharModal="toggleExcluirSelecionados()" />

    <AlterarStatusVaosOrcamento v-if="modalAlterarStatusSelecionados" :itensOrcamento="itensOrcamentoSelecionados"
        @fecharModal="toggleAlterarStatusSelecionados()" @atualizarLista="mudarPagina(paginaAtual)" />

    <AlterarSelecionadosVaoOrcamento v-if="modalAlterarSelecionados" :itensOrcamento="itensOrcamentoSelecionados"
        @fecharModal="toggleAlterarSelecionados()" @atualizarLista="mudarPagina(paginaAtual)" />

    <OrcamentosIguais v-if="exibirOrcamentosIguais" :orcamentos="orcamentosIguais"
        :countOrcamento="countOrcamentosIguais" @fecharModal="toggleModalOrcamentosIguais()" />

    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" /> -->


</template>

<script>
import axios from 'axios'
import CustosMargensPerfil from '../custos_margens/CustosMargensPerfil.vue'
import CustosMargensComponente from '../custos_margens/CustosMargensComponente.vue'
import CustosMargensVidro from '../custos_margens/CustosMargensVidro.vue'

export default {
    name: 'VaosOrcamento',

    props: ['orcamento'],

    components: {
        CustosMargensPerfil,
        CustosMargensComponente,
        CustosMargensVidro,

    },

    data() {
        return {
            checkAll: false,

            // filtros: [{ campo: "descricao", valor: "" }],

            dashboardAtivo: null,

            listaPerfis: [],
            pesoTotalPerfis: '',
            listaComponentes: [],
            pesoTotalComponentes: '',
            listaVidros: [],
            pesoTotalVidros: '',

            sucesso: false,
            sucessoMessage: '',
            exibirErros: false,
            erros: [],
        }
    },

    // computed: {
    //     checkSome() {
    //         return this.listaItensOrcamento.some(itemOrcamento => itemOrcamento.selected)
    //     }
    // },

    created() {

        this.getMargensCustosPerfil()
        this.getMargensCustosComponente()
        this.getMargensCustosVidro()
    },

    methods: {

        abrirDashboard(nome) {
            this.dashboardAtivo = nome;
        },

        async getMargensCustosPerfil() {
            try {

                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                this.$store.dispatch("setLoading", true);

                const response = await axios.get(`orcamentos/margens-custos/perfil/?orcamento_id=${this.orcamento.id}`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                })

                console.log(response.data)

                this.listaPerfis = response.data.dados
                this.pesoTotalPerfis = response.data.peso_total


            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getMargensCustosComponente() {
            try {

                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                this.$store.dispatch("setLoading", true);

                const response = await axios.get(`orcamentos/margens-custos/componente/?orcamento_id=${this.orcamento.id}`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                })

                console.log(response.data)

                this.listaComponentes = response.data.dados
                this.pesoTotalComponentes = response.data.peso_total


            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getMargensCustosVidro() {
            try {

                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                this.$store.dispatch("setLoading", true);

                const response = await axios.get(`orcamentos/margens-custos/vidro/?orcamento_id=${this.orcamento.id}`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                })

                console.log(response.data)

                this.listaVidros = response.data.dados
                this.pesoTotalVidros = response.data.peso_total


            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        }

    }
}
</script>