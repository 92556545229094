<template>

  <div>

    <div class="container-formulario">
      <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
      <div class="cabecalho-formulario">
        <h1 class="text-xl text-corBase font-bold">Projeto:</h1>

        <!-- <router-link 
          class="btn-salvar"
          :to="'/incluir-item-orcamento/' + projeto_id"
        >
          Validar
        </router-link> -->

        <router-link to="/lista-projetos" class="text-xl text-corBase font-bold">
          <i class="fas fa-chevron-left"></i>
        </router-link>
      </div>

      <div v-if="dadosProjeto">

        <navgecacao-projeto @navegar-opcoes="navegarOpcoes" @fecharTodasAbas="fecharTodasAbas" :dadosProjeto="dadosProjeto" />

        <template v-if="opcoes.dados === true">
          <dados-projeto :dadosProjeto="dadosProjeto" />
        </template>


        <template v-if="opcoes.vendas === true">
          <vendas-projeto :dadosProjeto="dadosProjeto" />
        </template>


        <template v-if="opcoes.producao === true">
          <producao-projeto :dadosProjeto="dadosProjeto" />
        </template>

        <template v-if="opcoes.nota_fiscal === true">
          <nota-fiscal-projeto :dadosProjeto="dadosProjeto" />
        </template>

        <template v-if="opcoes.instalacao === true">
          <instalacao-projeto :dadosProjeto="dadosProjeto" />
        </template>

        <!-- Estruturas -->

        <template v-if="opcoes.definicoes === true">
          <definicoes-projeto :projeto_id="projeto_id" />
        </template>

        <template v-if="opcoes.formulas === true">
          <formula-projeto :projeto_id="projeto_id" />
        </template>

        <template v-if="opcoes.variaveis === true">
          <variaveis-projeto :lista_variaveis_locais="lista_variaveis_locais" :projeto_id="projeto_id"
            @atualizarVariaveis="putlistaVariaveis" @getListaVariaveisLocais="getListaVariaveisLocais" />
        </template>


        <template v-if="opcoes.validacoes === true">
          <validacao-projeto :validacoes="lista_validacoes" :projeto_id="projeto_id"
            @atualizarValidacoes="putListaValidacoes" @getListaValidacaoProjeto="getListaValidacaoProjeto" />
        </template>

        <template v-if="opcoes.medidas === true">
          <medidas-projeto :dadosProjeto="dadosProjeto" @atualizarLista="getDadosProjeto" />
        </template>

        <template v-if="opcoes.contramarco_arremate === true">
          <contramarco-arremate-projeto :dadosProjeto="dadosProjeto" @atualizarLista="getDadosProjeto" />
        </template>

        <template v-if="opcoes.cores === true">
          <cores-projeto :dadosProjeto="dadosProjeto" @atualizarLista="getDadosProjeto" />
        </template>

        <template v-if="opcoes.tipo_vidro === true">
          <tipo-vidro-projeto :dadosProjeto="dadosProjeto" @atualizarLista="getDadosProjeto" />
        </template>

        <template v-if="opcoes.espessuras === true">
          <espessuras-projeto :dadosProjeto="dadosProjeto" />
        </template>


        <template v-if="opcoes.imagem_capa === true">
          <div>
            <imagem-capa-projeto :projeto_id="projeto_id" :dadosProjeto="dadosProjeto" />
          </div>
        </template>

        <template v-if="opcoes.imagens_variaveis === true">
          <div>
            <imagens-variaveis-projeto :projeto_id="projeto_id" />
          </div>
        </template>


        <template v-if="opcoes.perfis === true">
          <perfil-projeto :projeto_id="projeto_id" :dadosProjeto="dadosProjeto" />
        </template>

        <template v-if="opcoes.componentes === true">
          <componente-projeto :projeto_id="projeto_id" :dadosProjeto="dadosProjeto" />
        </template>

        <template v-if="opcoes.vidros === true">
          <vidro-projeto :projeto_id="projeto_id" />
        </template>

        <template v-if="opcoes.chapas_telas === true">
          <chapas-telas-projeto :vidros="vidros" :projeto_id="projeto_id"
            @getListaVidrosProjeto="getListaVidrosProjeto" @atualizarUnicoVidro="putVidro" />
        </template>

        <template v-if="opcoes.servicos === true">
          <div>
            <servico-projeto :servicos="servicos" :projeto_id="projeto_id" @atualizarServicos="putListaServicos" />
          </div>
        </template>


      </div>
    </div>

  </div>

  <SucessoRapido v-if="sucesso" mensagem="Dados do projeto atualizados com sucesso." />

</template>

<script>
import axios from "axios";

// import ModalFormulaProjeto from "@/components/midais/alterar_projeto/ModalFormulaProjeto";
// import ProjetoDadosCompornent from "@/components/cadastros_projetos_componentes/alterar_projeto/ProjetoDadosCompornent";

import NavgecacaoProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/NavgecacaoProjeto";

import DadosProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/Dados Iniciais/DadosProjeto.vue";

import VendasProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/Configurações/VendasProjeto.vue";
import ProducaoProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/Configurações/ProducaoProjeto.vue";
import NotaFiscalProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/Configurações/NotaFiscalProjeto.vue";
import InstalacaoProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/Configurações/InstalacaoProjeto.vue";

import DefinicoesProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/Cálculos/DefinicoesProjeto";
import FormulaProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/Cálculos/FormulaProjeto";
import VariaveisProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/Cálculos/VariaveisProjeto";

import MedidasProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/Permissões/MedidasProjeto";
import ContramarcoArremateProjeto from "./Permissões/ContramarcoArremateProjeto.vue";
import CoresProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/Permissões/CoresProjeto";
import TipoVidroProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/Permissões/TipoVidroProjeto";
import EspessurasProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/Permissões/EspessurasProjeto";
import ValidacaoProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/Permissões/ValidacaoProjeto";

import ImagemCapaProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/Imagens/ImagemCapaProjeto.vue";
import ImagensVariaveisProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/Imagens/ImagensVariaveisProjeto.vue";


import PerfilProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/Produtos/PerfilProjeto";
import ComponenteProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/Produtos/ComponenteProjeto";
import VidroProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/Produtos/VidroProjeto";
import ChapasTelasProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/Produtos/ChapasTelasProjeto.vue";
import ServicoProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/Produtos/ServicoProjeto";

import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";

export default {
  name: "AlterarProjeto",

  components: {
    NavgecacaoProjeto,

    DadosProjeto,

    VendasProjeto,
    ProducaoProjeto,
    NotaFiscalProjeto,
    InstalacaoProjeto,

    DefinicoesProjeto,
    FormulaProjeto,
    VariaveisProjeto,

    MedidasProjeto,
    ContramarcoArremateProjeto,
    CoresProjeto,
    TipoVidroProjeto,
    EspessurasProjeto,
    ValidacaoProjeto,

    ImagemCapaProjeto,
    ImagensVariaveisProjeto,

    PerfilProjeto,
    ComponenteProjeto,
    VidroProjeto,
    ChapasTelasProjeto,
    ServicoProjeto,

    SucessoRapido,
  },

  data() {
    return {
      // variaveis dados dos produtos
      dadosProjeto: '',
      tipologia: '',
      // cod_produto: '',
      // descricao_fixa: '',
      // descricao_variavel: '',
      // unidade: '',
      // ncm: '',
      // lib_venda: '',
      // kit: '',
      // kit_cor_vidro: '',
      // kit_cor_perfil: '',
      // kit_cor_acessorio: '',
      // cfop_saida: '',
      // cfop_fora_uf: '',
      // temp_producao: '',
      // temp_instalacao: '',
      // status_projeto: '',
      // perfis: [],
      // vidros: [],
      servicos: [],
      // componentes: [],
      // altura_minima: '',
      // altura_maxima: '',
      // largura_minima: '',
      // largura_maxima: '',
      espessuras: [],

      opcoes: [],

      formulas: false,
      variaveis: false,

      // variaveis para salvar as listas
      lista_variaveis_locais: [],
      // lista_tipologias: [],
      // lista_componentes: [],
      // lista_perfis: [],
      // lista_vidros: [],
      // lista_servicos: [],
      lista_validacoes: [],
      // lista_espessuras: [],

      // variaveis para dados do produto
      activeSection: 1,
      checkAll: false,
      sucesso: false

    }
  },


  created() {

    // faz as requisições das listas e projeto para poder usar nas alterações
    this.getDadosProjeto()
    // this.getListaPerfisProjeto()
    this.getListaServicoProjeto()
    this.getListaValidacaoProjeto()
    // this.getListaVidrosProjeto()
    // this.getListaComponentesProjeto()
    // this.getListaStatus()
    // this.getListaTipologia()
    this.getListaVariaveisLocais()
    // this.getListaFormulasProjeto()
    // this.getListaDefinicoesProjeto()
  },

  computed: {

    // pega o id passado como parametro na rota
    projeto_id() {
      return this.$route.params.id
    },

    // pega a empresa que esta logada de dentro da store
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  watch: {
    sucesso() {
      if (this.sucesso) {
        setTimeout(() => {
          this.sucesso = false
        }, 2000)
      }
    }
  },


  methods: {

    // evento que é ativado pelo filho
    navegarOpcoes(nomeVariavel) {
      for (const key in this.opcoes) {
        if (key !== nomeVariavel) {
          this.opcoes[key] = false; // Define todas as opções como false
        }
      }
      this.opcoes[nomeVariavel] = !this.opcoes[nomeVariavel]; // Alterna a opção selecionada
    },

    fecharTodasAbas() {
      this.opcoes = []
    },


    async getDadosProjeto() {
      try {

        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        this.$store.dispatch("setLoading", true);
        const response = await axios.get(`projeto/projeto/${this.projeto_id}`, {
          headers: {
            Authorization: `Token ${token}`
          }
        })

        this.dadosProjeto = response.data
        console.log('DADOS PROJETO')
        console.log(this.dadosProjeto)
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    // busca a lista de variaveis relacionadas ao projeto
    async getListaVariaveisLocais() {

      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/variavel-local/?projeto=${this.projeto_id}&page_size=999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_variaveis_locais = response.data.results
        })
        .catch(error => {
          console.error(error)
        })

    },

    async putlistaVariaveis(lista_variaveis_locais) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = lista_variaveis_locais.map(variavel => {
        variavel.projeto ? variavel.projeto = this.projeto_id : ''

        return variavel
      })

      await axios.put(`projeto/variavel-local-bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.getListaVariaveisLocais()
          this.sucesso = true
        })
        .catch(error => {
          console.error(error)
        })

    },

    // busca as tipologias
    async getListaTipologia() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/tipologia', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_tipologias = response.data.results.map(item => ({
            id: item.id,
            text: item.nome
          }));
          // console.log('TIPOLOGIAS')
          // console.log(this.lista_tipologias)
        })
        .catch(error => {
          console.error(error)
        })

    },


    // atualiza os servico do projeto
    async putListaServicos(servicos) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = servicos
      // console.log(data)

      await axios.put(`projeto/projeto-servico-bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.getListaServicosProjeto()
          this.sucesso = true
        })
        .catch(error => {
          console.error(error)
        })

    },

    // atualiza as validacoes do projeto
    async putListaValidacoes(validacoes) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = validacoes
      // console.log(data)

      await axios.put(`projeto/projeto-validacao-bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.getListaValidacaoProjeto()
          this.sucesso = true
        })
        .catch(error => {
          console.error(error)
        })

    },

    // atualiza as imagens do projeto
    async putListaImagens(imagem) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = imagem
      // console.log(data)

      await axios.put(`projeto/projeto-imagem-bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.getListaImagensProjeto()
          this.sucesso = true
        })
        .catch(error => {
          console.error(error)
        })

    },


    // busca as listas
    // async getListaStatus() {
    //   const token = await this.$store.dispatch('getAuthTokenCookie')
    //   await this.$store.dispatch('fetchURLrequest')

    //   await axios.get('projeto/status-projeto', {
    //     headers: {
    //       'Authorization': `Token ${token}`
    //     }
    //   })
    //     .then(response => {
    //       this.lista_status = response.data.results.map(item => ({
    //         id: item.id,
    //         text: item.descricao
    //       }));
    //       // console.log(this.lista_status)
    //     })
    //     .catch(error => {
    //       console.error(error)
    //     })
    // },

    // async getListaPerfisCompleta() {
    //   const token = await this.$store.dispatch('getAuthTokenCookie')
    //   await this.$store.dispatch('fetchURLrequest')

    //   await axios.get('produtos/perfil', {
    //     headers: {
    //       'Authorization': `Token ${token}`
    //     }
    //   })
    //     .then(response => {
    //       this.lista_perfis = response.data.results.map(item => ({
    //         id: item.id,
    //         text: item.nome_especifico
    //       }));
    //       // console.log(this.lista_perfis)
    //     })
    //     .catch(error => {
    //       console.error(error)
    //     })
    // },


    async getListaServicoProjeto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto-servico/?projeto_id=${this.projeto_id}&page_size=999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.servico = response.data.results
        })
        .catch(error => {
          console.error(error)
        })
    },


    async getListaServicosProjeto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/servico/?projeto=${this.projeto_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.servicos = response.data.results
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getListaValidacaoProjeto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto-validacao/?projeto=${this.projeto_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_validacoes = response.data.results
          console.log('LISTA VALIDAÇÕES')
          console.log(this.lista_validacoes)
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getListaImagensProjeto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/imagem/?projeto=${this.projeto_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.servicos = response.data.results
        })
        .catch(error => {
          console.error(error)
        })
    },

  }

}


</script>


<style lang="scss">
// style mobile
.nav-principal-projetos-mobile {
  @apply flex gap-4 w-full flex-wrap mt-8
}

.btn-nav-projeto-mobile {
  @apply flex items-center bg-corBase/10 text-corBase px-1 py-0.5
}
</style>