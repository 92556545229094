<template>
    <div>
        <div class="">
            <CorPerfil :projeto="dadosProjeto" />
            <hr class="my-4">
            <CorComponente :projeto="dadosProjeto" />
            <hr class="my-4">
            <CorVidro :projeto="dadosProjeto" />
            <hr class="my-4">
            <CorChapasTelas :projeto="dadosProjeto" />
            <hr class="my-4">
            <RegrasCor :projeto="dadosProjeto" :regras="regras" :lista_regras="lista_regras" @atualizarLista="getListaRegraCores" />

            <!-- v-if="exibirCorPerfil || exibirCorComponente" -->
            <div class="flex w-full gap-8 justify-end mt-2">
                <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
      text-white hover:text-ativo py-1 px-4" @click.prevent="alterarDadosProjeto()">Salvar</button>
            </div>
        </div>

    </div>
    <SucessoRapido v-if="sucesso" mensagem="Dados do projeto atualizados com sucesso" />
    <listagem-erros v-if="exibirErros == true" :erros="erros" @fecharModal="toggleErros()" />
</template>


<script>
import axios from "axios";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import CorPerfil from "./cores_projetos/CorPerfil.vue";
import CorComponente from "./cores_projetos/CorComponente.vue";
import CorVidro from "./cores_projetos/CorVidro.vue";
import CorChapasTelas from "./cores_projetos/CorChapasTelas.vue";
import RegrasCor from "./cores_projetos/RegrasCor.vue";
import vSelect from "vue-select";

export default {
    name: 'CoresProjetoComponent',

    components: {
        SucessoRapido,
        ListagemErros,
        CorPerfil,
        CorComponente,
        CorVidro,
        CorChapasTelas,
        RegrasCor,
        vSelect,
    },

    data() {
        return {

            regras: [{ produto: "", cor1: "", cor2: "" }],
            lista_regras: [],

            perfisSemCor: false,
            componentesSemCor: false,
            vidrosSemCor: false,
            chapasSemCor: false,

            sucesso: false,

            exibirErros: false,
            erros: {}

        }
    },

    props: ['dadosProjeto'],
    emits: ['atualizarLista'],

    created() {
        this.getListaPerfisProjeto()
        this.getListaComponentesProjeto()
        this.getListaVidrosProjeto()
        this.getListaRegraCores()
    },

    watch: {
        sucesso() {
            if (this.sucesso) {
                setTimeout(() => {
                    this.sucesso = false
                }, 2000)
            }
        },

    },

    methods: {

        async getListaPerfisProjeto() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            this.$store.dispatch("setLoading", true);

            await axios.get(`projeto/projeto-perfil/?projeto_id=${this.dadosProjeto.id}&page_size=999`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    this.perfisSemCor = response.data.results.some(perfil => !perfil.cor);
                    console.log(this.perfisSemCor)
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.$store.dispatch("setLoading", false);
                })
        },

        async getListaComponentesProjeto() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            this.$store.dispatch("setLoading", true);

            await axios.get(`projeto/projeto-componente/?projeto_id=${this.dadosProjeto.id}&page_size=999`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    this.componentesSemCor = response.data.results.some(componente => !componente.cor);
                    console.log(this.componentesSemCor)
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.$store.dispatch("setLoading", false);
                })
        },

        async getListaVidrosProjeto() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            this.$store.dispatch("setLoading", true);

            await axios.get(`projeto/projeto-vidro/?projeto_id=${this.dadosProjeto.id}&page_size=999`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    this.vidrosSemCor = response.data.results.some(vidro => !vidro.cor);
                    console.log(this.vidrosSemCor)
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.$store.dispatch("setLoading", false);
                })
        },

        async getListaRegraCores() {

            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get(`projeto/regra-cores-projeto/?projeto=${this.dadosProjeto.id}&ativo=True&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.lista_regras = response.data.results
            } catch (error) {
                console.error(error)
            }
        },

        // async getListaChapasTelasProjeto() {
        //     const token = await this.$store.dispatch('getAuthTokenCookie')
        //     await this.$store.dispatch('fetchURLrequest')

        //     this.$store.dispatch("setLoading", true);

        //     await axios.get(`projeto/projeto-chapas/?projeto_id=${this.dadosProjeto.id}&page_size=999`, {
        //         headers: {
        //             'Authorization': `Token ${token}`
        //         }
        //     })
        //         .then(response => {
        //             this.chapas_telas = response.data.results
        //         })
        //         .catch(error => {
        //             console.error(error)
        //         })
        //         .finally(() => {
        //             this.$store.dispatch("setLoading", false);
        //         })
        // },

        async alterarDadosProjeto() {
            try {

                this.dadosProjeto.habilitar_cor_perfil = this.dadosProjeto.habilitar_produto_perfil ? this.dadosProjeto.habilitar_cor_perfil : false
                this.dadosProjeto.habilitar_cor_componente = this.dadosProjeto.habilitar_produto_componente ? this.dadosProjeto.habilitar_cor_componente : false
                this.dadosProjeto.habilitar_cor_vidro = this.dadosProjeto.habilitar_produto_vidro ? this.dadosProjeto.habilitar_cor_vidro : false
                this.dadosProjeto.habilitar_cor_chapa_tela = this.dadosProjeto.habilitar_produto_chapa_tela ? this.dadosProjeto.habilitar_cor_chapa_tela : false


                if (!this.dadosProjeto.habilitar_cor_perfil && this.perfisSemCor) {
                    this.erros = { perfil: 'Você não pode desabilitar o Filtro de Cores sem definir cor nos itens.' };
                    this.toggleErros();
                    return;
                }

                if (!this.dadosProjeto.habilitar_cor_componente && this.componentesSemCor) {
                    this.erros = { componente: 'Você não pode desabilitar o Filtro de Cores sem definir cor nos itens.' };
                    this.toggleErros();
                    return;
                }

                // if (this.dadosProjeto.habilitar_produto_vidro && !this.dadosProjeto.habilitar_cor_vidro && this.vidrosSemCor){
                //     this.erros = { vidro: 'Você não pode desabilitar o Filtro de Cores sem definir cor nos itens.' };
                //     this.toggleErros();
                //     return;
                // }

                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                this.$store.dispatch("setLoading", true);

                const updatedData = {
                    // Se o habilitar_produto_* for falso, o habilitar_cor_* também será falso
                    habilitar_produto_perfil: this.dadosProjeto.habilitar_produto_perfil,
                    habilitar_cor_perfil: this.dadosProjeto.habilitar_cor_perfil,

                    listagem_perfil: this.dadosProjeto.listagem_perfil,
                    grupo_cor_perfis: this.dadosProjeto.grupo_cor_perfis?.id || '',

                    adicionado_perfil: this.dadosProjeto.adicionado_perfil,
                    cores_perfil: Array.isArray(this.dadosProjeto.cores_perfil)
                        ? this.dadosProjeto.cores_perfil.map(cor => cor.id || null)
                        : [],

                    habilitar_produto_componente: this.dadosProjeto.habilitar_produto_componente,
                    habilitar_cor_componente: this.dadosProjeto.habilitar_cor_componente,

                    listagem_componente: this.dadosProjeto.listagem_componente,
                    grupo_cor_componentes: this.dadosProjeto.grupo_cor_componentes?.id || '',

                    adicionado_componente: this.dadosProjeto.adicionado_componente,
                    cores_componente: Array.isArray(this.dadosProjeto.cores_componente)
                        ? this.dadosProjeto.cores_componente.map(cor => cor.id || null)
                        : [],

                    habilitar_produto_vidro: this.dadosProjeto.habilitar_produto_vidro,
                    habilitar_cor_vidro: this.dadosProjeto.habilitar_cor_vidro,

                    listagem_vidro: this.dadosProjeto.listagem_vidro,
                    grupo_cor_vidros: this.dadosProjeto.grupo_cor_vidros?.id || '',

                    adicionado_vidro: this.dadosProjeto.adicionado_vidro,
                    cores_vidro: Array.isArray(this.dadosProjeto.cores_vidro)
                        ? this.dadosProjeto.cores_vidro.map(cor => cor.id || null)
                        : [],

                    habilitar_produto_chapa_tela: this.dadosProjeto.habilitar_produto_chapa_tela,
                    habilitar_cor_chapa_tela: this.dadosProjeto.habilitar_cor_chapa_tela,

                    listagem_chapa_tela: this.dadosProjeto.listagem_chapa_tela,
                    grupo_cor_chapa_telas: this.dadosProjeto.grupo_cor_chapa_telas?.id || '',

                    adicionado_chapa_tela: this.dadosProjeto.adicionado_chapa_tela,
                    cores_chapa_tela: Array.isArray(this.dadosProjeto.cores_chapa_tela)
                        ? this.dadosProjeto.cores_chapa_tela.map(cor => cor.id || null)
                        : [],
                };

                await axios.patch(`projeto/projeto/${this.dadosProjeto.id}/`, updatedData, {
                    headers: {
                        'Authorization': `Token ${token}`
                    },
                });

                this.salvarRegrasCor()

                // this.sucesso = true;
                // this.$emit('atualizarLista');

            } catch (error) {
                console.error(error);
                if (error.response && error.response.data) {
                    this.erros = error.response.data;
                } else {
                    this.erros = { geral: ["Erro desconhecido ao salvar o projeto."] };
                }
                this.toggleErros();
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async salvarRegrasCor() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');
                this.$store.dispatch("setLoading", true);

                const regrasParaSalvar = this.regras
                    .filter(regra => regra.produto && regra.cor1 && regra.cor2)
                    .map(regra => ({
                        projeto: this.dadosProjeto.id,
                        produto: regra.produto.id || regra.produto,
                        cor1: regra.cor1,
                        cor2: regra.cor2,
                    }));

                if (regrasParaSalvar.length > 0) {
                    await axios.post(`projeto/regra-cores-projeto/`, regrasParaSalvar, {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    });
                }

                this.sucesso = true;
                this.regras = [{ produto: "", cor1: "", cor2: "" }],
                this.$emit('atualizarLista');
                this.getListaRegraCores()

            } catch (error) {
                console.error(error);
                this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
                this.toggleErros();
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },

    }

}

</script>


<style lang="scss" scoped>
.nav-principal-projetos {
    @apply hidden md:block md:w-full justify-center
}

.btn-nav-projeto-inativo {
    @apply flex items-center py-2 text-sm w-full sm:w-40 text-center justify-center bg-corBase text-screenCor rounded-md shadow-sm hover:bg-screenCor hover:text-corBase hover:shadow-md transition-colors;
}

.btn-nav-projeto-ativo {
    @apply flex items-center py-2 border border-corBase text-sm w-full sm:w-40 text-center justify-center bg-screenCor text-corBase font-semibold rounded-md shadow-md;
}
</style>