<template>
    <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
        <div class="bg-white p-6 md:p-8 rounded-lg shadow-lg w-full sm:w-full md:w-2/3 lg:w-1/3 max-w-lg">
            <div class="border-b mb-4">
                <h2 class="text-2xl font-semibold text-gray-800 text-center">Alterar Selecionados Perfil</h2>
            </div>

            <div class="space-y-4">

                <div class="w-full flex flex-col md:flex-row gap-2 md:gap-4">
                    <div class="text-left w-full">
                        <label class="block text-sm font-medium text-corBase mb-1">Tamanho Mínimo Corte (mm):</label>
                        <input v-model="tamanho_minimo_corte" type="number" min="0" maxlength="100"
                            class="w-full border rounded p-2" placeholder="Digite aqui...">
                    </div>

                    <div class="text-left w-full">
                        <label class="block text-sm font-medium text-corBase mb-1">Perda Tratamento (mm):</label>
                        <input v-model="perda_tratamento" type="number" min="0" maxlength="100"
                            class="w-full border rounded p-2" placeholder="Digite aqui...">
                    </div>
                </div>


                <div class="w-full flex gap-2">

                    <div class="text-left w-full">
                        <label class="block text-sm font-medium text-corBase mb-1">Tamanho Máximo Sucata (mm):</label>
                        <input v-model="sucata_maxima" type="number" min="0" maxlength="100"
                            class="w-full border rounded p-2" placeholder="Digite aqui...">
                    </div>

                    <div class="text-center flex flex-col items-center w-full">
                        <label class="block text-sm font-medium text-corBase mb-1">Status:</label>
                        <v-select :options="lista_status" label="label" placeholder="Escolha uma opção" v-model="ativo"
                            :reduce="status => status.value" class="w-full" />
                    </div>
                </div>

            </div>

            <!-- Botões -->
            <div class="mt-6 flex justify-center flex-wrap space-x-4">
                <button class="btn-cancelar" @click.prevent="$emit('fecharModal')">
                    Fechar
                </button>
                <button class="btn-salvar" @click="salvarAlteracoes()">
                    Salvar Alterações
                </button>
            </div>
            <SucessoRapido v-if="sucesso" mensagem="Perfis atualizados com sucesso!" />
            <ErroRapido v-if="erro" mensagem="Ocorreu um erro ao atualizar Perfis." />
        </div>
    </div>

</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';

export default {
    name: 'AlterarStatusSelecionadosPerfil',
    data() {
        return {
            tamanho_minimo_corte: '',
            perda_tratamento: '',
            sucata_maxima: '',
            ativo: '',

            lista_status: [
                { label: 'Ativo', value: true },
                { label: 'Inativo', value: false },
            ],

            sucesso: false,
            erro: false,
        };
    },

    components: {
        vSelect,
        SucessoRapido,
        ErroRapido,
    },

    props: ['perfis'],

    emits: ['fecharModal', 'atualizarLista'],

    methods: {

        async salvarAlteracoes() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            try {
                // Filtra apenas as perfis que possuem alterações
                const patchPromises = this.perfis.map(perfil => {


                    const camposAtualizados = {};

                    if (this.tamanho_minimo_corte) {
                        camposAtualizados.tamanho_minimo_corte = this.tamanho_minimo_corte;
                    }
                    if (this.perda_tratamento) {
                        camposAtualizados.perda_tratamento = this.perda_tratamento;
                    }
                    if (this.sucata_maxima) {
                        camposAtualizados.sucata_maxima = this.sucata_maxima;
                    }
                    if (this.ativo != '') {
                        camposAtualizados.ativo = this.ativo;
                    }

                    return axios.patch(
                        `produtos/perfil/${perfil.id}/`,
                        camposAtualizados,
                        {
                            headers: {
                                'Authorization': `Token ${token}`,
                            },
                        }
                    );
                });

                // Aguarda todas as requisições serem resolvidas
                if (patchPromises.length > 0) {
                    await Promise.all(patchPromises);
                }

                // Exibe mensagem de sucesso
                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('fecharModal');
                    this.$emit('atualizarLista');
                }, 3000);
            } catch (error) {
                // Tratamento de erros
                console.error('Erro ao salvar alterações:', error);
                this.erro = true;
                setTimeout(() => {
                    this.erro = false;
                }, 3000);
            }
        },

    },
};
</script>

<style scoped>
.input-campos {
    @apply block w-full p-2 border rounded-md text-corBase;
}
</style>