<template>
    <div>
        <div class="fixed inset-0 bg-black/60 flex items-center justify-center z-[10000]">
            <div
                class="bg-white rounded-lg shadow-lg p-6 w-[90%] sm:w-[600px] min-h-[50vh] max-h-[80vh] overflow-y-auto transform transition-transform duration-300 ease-out scale-95 sm:scale-100">
                <!-- Cabeçalho do Modal -->
                <div class="flex justify-between items-center mb-4 border-b pb-2">
                    <h2 class="text-lg font-bold text-red-600">Alterar Status</h2>
                    <button @click.prevent="$emit('fecharModal')"
                        class="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 ease-in-out hover:bg-gray-100">
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>

                <div class="mb-2 flex flex-col">
                    <span class="font-semibold">Status:</span>
                    <v-select :options="lista_status" v-model="orcamento.status" placeholder="Escolha uma opção" />
                </div>

                <div class="mb-2 flex flex-col" v-if="orcamento.status === 'Entregue'">
                    <span class="font-semibold">Prazo de Validade (DIAS):</span>
                    <input type="number" min="1" class="input-form-chapa" v-model="orcamento.validade" />
                </div>

                <div class="mb-2 flex flex-col" v-if="orcamento.status === 'Cancelado'">
                    <span class="font-semibold">Motivo do Cancelamento:</span>
                    <v-select :options="lista_motivos" v-model="orcamento.motivo_cancelamento"
                        placeholder="Escolha uma opção" />
                </div>



                <div class="flex justify-end">
                    <button @click.prevent="salvarNovoStatus()" class="btn-salvar">Salvar</button>
                </div>

            </div>
            <SucessoRapido v-if="sucesso" mensagem="Status alterado com sucesso." />
            <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';

export default {
    name: 'MudarStatus',

    props: ['orcamento'],
    emits: ['fecharModal', 'atualizarLista'],

    components: {
        vSelect,
        SucessoRapido,
        ListagemErros,
    },

    data() {
        return {
            lista_status: [],
            lista_motivos: ['Cliente comprou com a concorrência', 'Desistência do Cliente', 'Decisão Empresarial'],

            sucesso: false,

            exibirErros: false,
            erros: [],

        }
    },

    // watch: {
    // 'orcamento.status': {
    //         handler(novoStatus) {
    //             if (novoStatus) {
    //                 this.getStatus(novoStatus);
    //             }
    //         },
    //         immediate: false
    //     },
    // },

    created() {
        this.getStatus(this.orcamento.status);
    },

    methods: {

        async salvarNovoStatus() {
            console.log('validade: >', this.orcamento.validade, '<')
            if (this.orcamento.status === 'Entregue' && this.orcamento.validade < 0) {
                this.erros = { validade: 'Defina um dia de validade válido.' }
                this.toggleErros()
                return
            }

            if (this.orcamento.status === 'Cancelado' && !this.orcamento.motivo_cancelamento) {
                this.erros = { motivo_cancelamento: 'Defina um motivo para o cancelamento.' }
                this.toggleErros()
                return
            }

            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.patch(`orcamentos/orcamento/${this.orcamento.id}/`, {
                    status: this.orcamento.status,
                    validade: this.orcamento.validade || null,
                    motivo_cancelamento: this.orcamento.motivo_cancelamento,
                }, {
                    headers: { Authorization: `Token ${token}` }
                }
                )

                console.log(response)
                // this.getStatus(this.orcamento.status)
                this.sucesso = true
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('atualizarLista')
                    this.$emit('fecharModal')
                }, 3000);
            } catch (error) {
                console.error(error)
                this.erros = error.response?.data || {geral: 'Ocorreu um erro desconhecido.'}
                this.toggleErros()
            }
        },

        async getStatus(status_atual) {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get(`orcamentos/status-orcamento/?status_atual=${status_atual}`, {
                    headers: { Authorization: `Token ${token}` }
                })

                this.lista_status = response.data.status_permitidos
            } catch (error) {
                console.error(error)
            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        }
    }

}
</script>

<style scoped></style>