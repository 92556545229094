<template>
    <div class="container-modal-editar z-[60]">
        <div class="sombra-modal">
            <div class="conteudo-modal-editar">
                <div class="flex justify-end">
                    <button @click.prevent="this.$emit('fecharModal')">
                        <i class="fas fa-close text-2xl text-corBase"></i>
                    </button>
                </div>

                <div>
                    <div class="cabecalho-formulario">
                        <h1 class="text-xl text-corBase font-bold">Imagens da Opção da Variável</h1>
                    </div>

                    <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        <div v-for="(imagem, index) in imagens" :key="index"
                            class="relative flex flex-col items-center justify-center">

                            <img :src="imagem.url_documento" alt="Imagem da opção da variável"
                                class="w-52 h-52 object-cover rounded-lg shadow-md cursor-pointer"
                                @click.prevent="openPreviewImage(imagem.url_documento)">

                            <div class="flex justify-center items-center gap-5 mt-2">
                                <i class="fa-solid fa-trash cursor-pointer text-corBase" title="Deletar imagem"
                                    @click.prevent="removerImagem(imagem)"></i>

                                <!-- <i class="fa-solid fa-pencil-alt cursor-pointer text-corBase" title="Editar imagem"
                                    @click.prevent="editarImagem(imagem, index)"></i> -->
                            </div>
                        </div>
                    </div>


                    <div class="flex flex-wrap sm:flex-nowrap w-full justify-between items-center mt-6 gap-4">

                        <button class="btn-salvar w-full sm:w-auto" @click.prevent="abrir_galeria()">
                            Adicionar
                        </button>

                        <div class="flex items-center w-full sm:w-auto justify-center sm:justify-end">
                            <button class="btn-salvar w-full sm:w-auto" @click.prevent="salvarImagens()">
                                Salvar
                            </button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>

    <preview-image v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />

    <listagem-erros v-if="exibirErros === true" :erros="erros" @fecharModal="toggleErros()" />

    <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />

    <Galeria v-if="galeria === true" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput" />
</template>

<script>
import axios from 'axios'
import Galeria from '../ged/Galeria.vue';
import PreviewImage from '@/components/midais/ged/PreviewImage.vue'
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue';
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';

export default {
    name: 'ModalListaImagens',

    data() {
        return {

            galeria: false,

            imagemEmEdicaoIndex: null,

            previewImage: {},
            preview_imagem: '',

            imagens: [],

            erros: [],
            exibirErros: false,

            sucesso: false,
            sucessoMessage: '',

            checkAll: false,
        }
    },

    props: ['opcao_variavel', 'existente'],

    emits: ['fecharModal', 'atualizarImagens', 'definirImagensNovaOpcao'],

    components: {
        Galeria,
        PreviewImage,
        MensagemConfirmacaoExclusao,
        ListagemErros,
        SucessoRapido
    },

    created() {
        console.log(this.opcao_variavel)
        console.log(this.existente)
        this.imagens = this.opcao_variavel.imagem
    },

    methods: {

        async salvarImagens() {
            if (this.existente == true) {
                try {
                    const token = await this.$store.dispatch('getAuthTokenCookie');
                    await this.$store.dispatch('fetchURLrequest');

                    this.$store.dispatch("setLoading", true);

                    const imagensIds = this.imagens.map(imagem => imagem.id);

                    await axios.patch(`projeto/opcao-variavel-local/${this.opcao_variavel.id}/`, { imagem: imagensIds }, {
                        headers: { Authorization: `Token ${token}` }
                    });

                    this.sucesso = true
                    this.sucessoMessage = 'Imagens da Opção da Variável salvas com sucesso.'
                    this.$emit('atualizarImagens')
                    setTimeout(() => {
                        this.sucesso = false;
                    }, 3000);

                } catch (error) {
                    console.error("Erro ao salvar imagens:", error);
                } finally {
                    this.$store.dispatch("setLoading", false);
                }
            } else {
                // this.opcao_variavel.imagem = this.imagem
                console.log(this.imagem)
                this.$emit("definirImagensNovaOpcao", this.imagens)
                this.$emit('fecharModal')
            }
        },

        removerImagem(imagem) {
            this.imagens = this.imagens.filter(img => img.id !== imagem.id);

            if (this.existente) {
                this.salvarImagens();  // Só salva se for uma opção existente
            }
        },

        // editarImagem(imagem, index) {
        //     this.imagemEmEdicaoIndex = index;
        //     this.abrir_galeria();
        // },

        closed_preview_image() {
            this.preview_imagem = ''
        },

        openPreviewImage(url) {
            this.preview_imagem = url
        },

        atualizarFileInput(objeto) {
            // if (this.imagemEmEdicaoIndex !== null) {
            //     this.imagens[this.imagemEmEdicaoIndex] = objeto;
            //     this.imagemEmEdicaoIndex = null;
            // } else {
            // }
            this.imagens.push(objeto)
        },

        abrir_galeria() {
            this.galeria = !this.galeria
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        }
    }
}

</script>

<style scoped lang="scss"></style>