<template>
    <div>
        <div class="container-modal-editar">
            <div class="sombra-modal">
                <div class="conteudo-modal-editar">
                    <form class="form-editar text-white">
                        <div class="flex justify-end">
                            <button @click.prevent="$emit('fecharModal')">
                                <i class="fas fa-close text-2xl text-corBase"></i>
                            </button>
                        </div>
                        <h2 class="text-lg text-corBase font-bold mb-2">Editar Colaborador</h2>

                        <div class="container-campos">
                            <div class="campos campos-mobile label-campos-popup">
                                <label class="label-campos label-campos-popup">Colaborador:</label>
                                {{ colaborador.pessoa?.nome_razao }}
                            </div>

                            <div class="flex flex-col mt-2 w-full justify-start">
                                <label class="text-base text-corBase font-bold mt-2">Produtos:</label>
                                <v-select v-model="funcoes_colaborador" :options="lista_funcoes_colaborador" label="descricao"
                                    :multiple="true" class="w-full bg-white" />
                            </div>

                            <label class="text-base text-corBase font-bold mt-2">Ativo:</label>

                            <span @click.prevent="toggleAtivo" class="text-start pt-1 text-xl cursor-pointer">
                                <i v-if="colaborador.ativo == true" class="fa-solid fa-circle-check text-ativo"></i>
                                <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                            </span>
                        </div>

                        <!--Botão-->
                        <div class="btn-formulario-modal">
                            <button class="btn-formulario-salvar" @click.prevent="putDadosEditado">
                                Salvar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <SucessoRapido v-if="sucesso" mensagem="Colaborador atualizado com sucesso!" />
            <ErroRapido v-if="erro" mensagem="Ocorreu um erro ao atualizar colaborador." />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";
import vSelect from "vue-select";

export default {
    name: "EditarChapas",
    data() {
        return {
            statusModal: false,
            sucesso: false,
            erro: false,

            lista_produtos_sistema: [],
            funcoes_colaborador: []
        }
    },
    components: { SucessoRapido, ErroRapido, vSelect, },
    props: ["colaborador", "lista_funcoes_colaborador"],
    emits: ["fecharModal", "atualizarLista"],

    created() {
        console.log(this.colaborador)
        this.funcoes_colaborador = this.colaborador.funcao_colaborador?.map(funcao => {
            return { id: funcao.id, descricao: funcao.descricao }
        })
    },

    methods: {

        toggleAtivo() {
            this.colaborador.ativo = !this.colaborador.ativo
        },


        async putDadosEditado() {

            const funcoesIds = Array.isArray(this.funcoes_colaborador)
                ? this.funcoes_colaborador.map(funcao => funcao.id)
                : [];

            const dadosAtualizados = {
                ativo: this.colaborador.ativo,
                funcao_colaborador: funcoesIds
            };

            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest')
                const response = await axios.patch(`rh/colaborador/${this.colaborador.id}/`, dadosAtualizados, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                });
                console.log(response);
                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('fecharModal')
                    this.$emit('atualizarLista')
                }, 3000);

            } catch (error) {
                console.error(error)
                this.erro = true
                setTimeout(() => {
                    this.erro = false;
                }, 3000);
            }
        }
    }
}
</script>

<style scoped>
.container-modal-editar {
    @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full
}

.conteudo-modal-editar {
    @apply bg-screenCor p-6 w-[75%] shadow-xl shadow-screenCor/30 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[80vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-screenCor/40
}

.form-editar {
    @apply text-corBase
}
</style>