<template>
    <div class="container-formulario">
        <!-- Cabeçalho -->
        <div class="cabecalho-dashboard">
            <h1>Integração de Gestores</h1>

            <button @click="$router.push(`/configuracoes-sistema`)" class="text-2xl">
                <i class="fas fa-chevron-left mr-1 "></i>
            </button>
        </div>

        <!-- Seção de Seleção -->
        <div class="flex flex-wrap items-center gap-4 mb-6 mt-6">
            <span class="text-gray-700 font-medium">O gestor:</span>
            <v-select class="w-full sm:w-1/3" :options="lista_clientes" label="name" v-model="tenant_origem" />

            <span class="text-gray-700 font-medium">irá liberar seus projetos para o sistema:</span>
            <v-select class="w-full sm:w-1/3" :options="lista_clientes" label="name" v-model="tenant_destino" />
        </div>

        <!-- Botão de Confirmação -->
        <div class="flex justify-end">
            <button @click.prevent="confirmarIntegracao()"
                class="px-4 py-1 cursor-pointer bg-ativo text-white border hover:bg-screenCor hover:text-ativo hover:border-ativo">
                Confirmar Integração
            </button>
        </div>

        <button @click.prevent="mudarPagina(paginaAtual)">
            Atualizar lista
        </button>

        <div>
            <div class="container-modelo-tabela">
                <table class="container-tabela overflow-auto">
                    <thead class="cabecalho-tabela">
                        <tr>
                            <th scope="col" class="p-4">
                                <div class="flex items-center">
                                    <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
                                    <label class="sr-only">checkbox</label>
                                </div>
                            </th>
                            <th class="px-6 py-3">#</th>
                            <th class="px-6 py-3 text-center">Ações</th>
                            <th class="px-12 py-2 text-center">Origem</th>
                            <th class="px-12 py-2 text-center">Destino</th>
                            <th class="px-6 py-2 text-center">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(integracao, index) in lista_integracoes" :key="index" class="escopo-tabela">
                            <td class="w-4 p-4">
                                <div class="flex items-center">
                                    <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                                        v-model="integracao.selected">
                                    <label class="sr-only">checkbox</label>
                                </div>
                            </td>
                            <th scope="row"
                                class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                                {{ index + 1 }}
                            </th>
                            <th class="px-6 py-2 text-center">
                                <i class="fa-solid fa-rotate cursor-pointer" @click.prevent="toggleStatus()"
                                    title="Alterar Status"></i>
                            </th>
                            <td class="px-12 py-2 text-center">{{ integracao.tenant_origem?.name || 'ManagerGlass' }}
                            </td>
                            <td class="px-12 py-2 text-center">{{ integracao.tenant_destino?.name || 'ManagerGlass' }}
                            </td>
                            <td class="px-6 py-2 text-center">
                                <i v-if="integracao.ativo" class="fa-solid fa-circle-check text-ativo"></i>
                                <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Pagination v-if="lista_integracoes.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
                    @change-page="mudarPagina" />
            </div>
        </div>
    </div>

    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />
    <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />
</template>


<script>
import vSelect from 'vue-select';
import axios from 'axios'
import Pagination from '@/utils/Pagination.vue';
import ListagemErros from '../toasts/toast_erro/ListagemErros.vue';
import SucessoRapido from '../toasts/toast_sucesso/SucessoRapido.vue';

export default {
    name: 'IntegracaoGestor',

    components: {
        vSelect,
        Pagination,
        ListagemErros,
        SucessoRapido,
    },

    data() {
        return {
            checkAll: false,

            paginaAtual: 1,
            countItens: 0,

            lista_clientes: [],
            lista_integracoes: [],

            tenant_origem: null,
            tenant_destino: null,

            exibirErros: false,
            erros: [],

            sucesso: false,
            sucessoMessage: '',
        }
    },

    created() {
        this.getListaTenantsHabilitados()
        this.getListaIntegracoesGestor(1)
    },

    methods: {
        async getListaTenantsHabilitados() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            try {
                const response = await axios.get(`tenant/client/?habilitado_integracao=True&page_size=9999999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.lista_clientes = [
                    { id: null, name: "ManagerGlass" },
                    ...response.data.results
                ]
            } catch (error) {
                console.error(error)
            }
        },

        async getListaIntegracoesGestor(pagina) {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            try {

                this.$store.dispatch("setLoading", true);

                const response = await axios.get(`tenant/integracao-gestores/?page=${pagina}`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.lista_integracoes = response.data.results
                this.countItens = response.data.count
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async confirmarIntegracao() {
            if (!this.tenant_origem || !this.tenant_destino) {
                this.erros = { erro: 'Escolha o tenant de origem e de destino.' }
                this.toggleErros()
                return
            } else {
                try {

                    const token = await this.$store.dispatch('getAuthTokenCookie')
                    await this.$store.dispatch('fetchURLrequest')

                    this.$store.dispatch("setLoading", true);

                    const response = await axios.post(`tenant/integracao-gestores/`,
                        {
                            tenant_origem: this.tenant_origem.id || 0,
                            tenant_destino: this.tenant_destino.id || 0
                        },
                        { headers: { 'Authorization': `Token ${token}` } }
                    )

                    console.log(response)
                    this.tenant_origem = null
                    this.tenant_destino = null
                    this.sucessoMessage = 'Integração concluída com sucesso.'
                    this.sucesso = true
                    setTimeout(() => {
                        this.sucesso = false;
                        this.sucessoMessage = ''
                        this.mudarPagina(this.paginaAtual)
                    }, 3000);
                } catch (error) {
                    console.error(error)
                    if (typeof error.response.data === 'string' && error.response.data.includes('restrição de unicidade')) {
                        this.erros = {
                            integracao: [
                                `A integração entre '${this.tenant_origem?.name || 'ManagerGlass'}' e 
        '${this.tenant_destino?.name || 'ManagerGlass'}' já existe.`
                            ]
                        }
                    } else {
                        this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
                    }
                    this.toggleErros()
                } finally {
                    this.$store.dispatch("setLoading", false);
                }
            }
        },

        toggleStatus() {
            console.log('toggleStatus')
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },

        mudarPagina(pagina) {
            this.checkAll = false
            this.paginaAtual = pagina
            this.getListaIntegracoesGestor(pagina)
        },
    },
}
</script>

<style scoped></style>