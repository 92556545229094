<template>
    <div>
        <div class="flex justify-center mb-3">
            <button @click.prevent="buscarEnderecoCliente()" class="btn-puxar-endereco w-50">
                Importar endereço do cadastro do Cliente
            </button>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-4 gap-2">
            <!-- Primeira Linha -->
            <div class="form-group">
                <label class="font-bold">CEP</label>
                <input v-model="orcamento.cep" type="text" id="cep" @input="buscarEndereco" v-mask="'#####-###'"
                    placeholder="Digite aqui..." />
            </div>
            <div class="form-group">
                <label class="font-bold">Logradouro</label>
                <input v-model="orcamento.logradouro" type="text" placeholder="Digite aqui..." />
            </div>
            <div class="form-group">
                <label class="font-bold">Número</label>
                <input v-model="orcamento.numero" type="text" placeholder="Digite aqui..." />
            </div>

            <div class="form-group">
                <label>Prédio/Condomínio</label>
                <input v-model="orcamento.predio_condominio" type="text" placeholder="Digite aqui..." />
            </div>

            <!-- Terceira Linha -->

            <div class="form-group">
                <label>Complemento</label>
                <input v-model="orcamento.complemento" type="text" placeholder="Digite aqui..." />
            </div>

            <div class="form-group">
                <label class="font-bold">Bairro</label>
                <input v-model="orcamento.bairro" type="text" placeholder="Digite aqui..." />
            </div>

            <div class="form-group">
                <label class="font-bold">Estado</label>
                <v-select v-model="orcamento.estado" :options="estados" :reduce="estado => estado.sigla" label="nome"
                    placeholder="Selecione um estado" @select="buscarCidades()" />
            </div>
            <div class="form-group">
                <label class="font-bold">Cidade</label>
                <v-select v-model="orcamento.cidade" :options="cidades" :reduce="cidade => cidade.nome" label="nome"
                    placeholder="Selecione uma cidade" />
            </div>
        </div>

        <ModalListagemEnderecos v-if="exibirEnderecos" :lista_enderecos="enderecoCliente"
            @enderecoSelecionado="enderecoSelecionado" @fecharModal="toggleModalEnderecos" />

        <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />

    </div>
</template>

<script>
import ModalListagemEnderecos from '@/views/obras/EtapasCriacaoObra/Modais/ModalListagemEnderecos.vue'
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';
import axios from 'axios'
import vSelect from 'vue-select';

export default {
    name: 'EnderecoOrcamento',

    components: {
        ModalListagemEnderecos,
        ListagemErros,
        vSelect,
    },

    props: ['orcamento'],

    data() {
        return {
            enderecoCliente: [],
            exibirEnderecos: false,

            estados: [],
            cidades: [],

            exibirErros: false,
            erros: [],
        }
    },

    watch: {
        'orcamento.estado': {
            handler(novoEstado) {
                if (novoEstado) {
                    this.buscarCidades(novoEstado);
                }
            },
            immediate: true
        },

    },

    created() {
        this.carregarEstados()
    },

    methods: {

        async carregarEstados() {
            try {
                const token = await this.$store.dispatch("getAuthTokenCookie");
                await this.$store.dispatch("fetchURLrequest");
                const response = await axios.get(`obra/estados/`, {
                    headers: {
                        "Authorization": `Token ${token}`
                    }
                });

                this.estados = response.data.map((estado) => ({
                    sigla: estado.sigla,
                    nome: `${estado.nome} (${estado.sigla})`,
                }))

            } catch (error) {
                console.error("Erro ao carregar estados:", error);
            }
        },

        async buscarCidades(estado) {
            try {
                const token = await this.$store.dispatch("getAuthTokenCookie");
                await this.$store.dispatch("fetchURLrequest");
                const response = await axios.get(`obra/municipios/?estado=${estado}`, {
                    headers: {
                        "Authorization": `Token ${token}`
                    }
                });
                this.cidades = response.data.map((cidade) => ({
                    id: cidade.id,
                    nome: cidade.nome,
                }))

            } catch (error) {
                console.error("Erro ao carregar cidades:", error);
            }
        },

        async buscarEndereco() {
            const cep = this.orcamento.cep.replace(/\D/g, '');
            if (cep.length === 8) {
                try {
                    const token = this.$store.state.token;
                    await this.$store.dispatch('fetchURLrequest');
                    this.$store.dispatch("setLoading", true);
                    const response = await axios.get(`cep/${cep}`, {
                        headers: {
                            'Authorization': `Token ${token}`
                        }
                    });
                    if (!response.data.erro) {
                        this.orcamento.logradouro = response.data.logradouro;
                        this.orcamento.bairro = response.data.bairro;
                        this.orcamento.cidade = response.data.localidade;
                        this.orcamento.estado = response.data.uf;
                    }
                } catch (error) {
                    console.error(error)
                } finally {
                    this.$store.dispatch("setLoading", false);
                }
            }
        },

        toggleModalEnderecos() {
            this.exibirEnderecos = !this.exibirEnderecos;
        },

        async buscarEnderecoCliente() {
            if (!this.orcamento.cliente) {
                this.erros = { cliente: 'Selecione um cliente para buscar o endereço.' }
                this.toggleErros()
                return
            }
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`pessoas/enderecos/?pessoa=${this.orcamento.cliente.id}`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                console.log(response.data.count)

                if (response.data.count == 1) {
                    this.enderecoCliente = response.data.results[0]
                    this.orcamento.cep = this.enderecoCliente.cep
                    this.orcamento.logradouro = this.enderecoCliente.logadouro
                    this.orcamento.numero = this.enderecoCliente.numero
                    this.orcamento.complemento = this.enderecoCliente.complemento
                    this.orcamento.bairro = this.enderecoCliente.bairro
                    this.orcamento.estado = this.enderecoCliente.uf
                    this.orcamento.cidade = this.enderecoCliente.cidade
                } else if (response.data.count > 1) {
                    this.enderecoCliente = response.data.results
                    this.toggleModalEnderecos()
                } else {
                    this.erros = { endereço: ["O Cliente selecionado não possui um endereço cadastrado."] };
                    this.toggleErros()
                    return
                }
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch('setLoading', false)
            }
        },

        enderecoSelecionado(endereco) {
            this.orcamento.cep = endereco.cep
            this.orcamento.logradouro = endereco.logadouro
            this.orcamento.numero = endereco.numero
            this.orcamento.complemento = endereco.complemento
            this.orcamento.bairro = endereco.bairro
            this.orcamento.estado = endereco.uf
            this.orcamento.cidade = endereco.cidade
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },

    }
}
</script>

<style scoped>
.btn-puxar-endereco {
    @apply px-4 py-1 cursor-pointer text-white bg-ativo border hover:bg-screenCor hover:text-ativo hover:border-ativo
}

input,
select {
    @apply w-full p-1 border border-[#ccc] rounded-md
}
</style>