<template>
  <section class=" flex justify-center md:block md:justify-end md:mr-10">
    <div class="container-formulario">

      <div class="container-cabecalho flex justify-between mb-4">
        <div>
          <h1 class="text-lg text-corBase font-bold">Orçamento #{{ orcamentoAtual.codigo }} | Projeto {{
            projetoAtual.codigo }} : {{ projetoAtual.descricao_fixa }} </h1>
        </div>

        <router-link :to="orcamentoId ? `/orcamento/${orcamentoId}` : '/lista-orcamentos/'"
          class="text-xl text-corBase font-bold">
          <i class="fas fa-chevron-left"></i>
        </router-link>

      </div>

      <form @submit.prevent="cadastrarItemOrcamento"> <!--  @submit.prevent="cadastrarItemOrcamento" -->

        <ModalVariaveisOrcamento v-if="modalMensagemSucesso" :id="idNovoOrcamento" :projeto="projetoAtual"
          :codigo_orcamento="orcamentoAtual.codigo" :imagem="projetoAtual.imagem" :largura="largura" :altura="altura"
          :quantidade="quantidade" :rotaVoltar="`/orcamento/${orcamentoId}`" />

        <!-- <button @click.prevent="() => { modalMensagemSucesso = true }">
          abrir variaveis
        </button> -->

        <MenssagemErro v-if="modalMensagemErro" :mensagem="mensagemErro" rotas="/orcamentos" />

        <div class="w-full mx-auto mt-8">
          <div class="space-y-4">
            <div>
              <div class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                Dados do Produto
              </div>

              <div class="dados-orcamento">
                <div class="flex gap-8 w-full flex-col md:flex-row">
                  <!-- Seção de Dados do Orçamento (Esquerda) -->
                  <div class="w-3/4 flex flex-col">
                    <div class="flex gap-2">
                      <div class="flex flex-col w-full">
                        <label class="font-bold text-corBase">Descrição</label>
                        <input type="text" class="input-campos" v-model="descricao"> <!-- required -->
                      </div>
                    </div>

                    <div class="flex gap-2">
                      <div class="flex flex-col w-full">
                        <label class="font-bold text-corBase">Quantidade</label>
                        <input type="text" class="input-campos" v-model="quantidade"> <!-- required -->
                      </div>

                      <div class="flex flex-col w-full">
                        <label class="font-bold text-corBase">Largura (MM)</label>
                        <input type="text" class="input-campos" v-model="largura" @blur="validarLargura()">
                        <!-- required -->
                      </div>

                      <div class="flex flex-col w-full">
                        <label class="font-bold text-corBase">Altura (MM)</label>
                        <input type="text" class="input-campos" v-model="altura" @blur="validarAltura()">
                        <!-- required -->
                      </div>
                    </div>

                    <div class="flex gap-4 w-full mt-2">

                      <div class="w-1/2">
                        <div class="flex text-center items-center flex-col">
                          <input type="checkbox" v-model="semPerfil">
                          <label class="font-bold text-corBase">Sem Perfis</label>
                        </div>

                        <div class="flex flex-col w-full">
                          <label class="font-bold text-corBase">Cor Perfis</label>
                          <v-select v-model="corPerfis" class="w-full z-10" :options="listaCorPerfis" label="descricao"
                            :reduce="option => option.id" :disabled="semPerfil" />
                        </div>
                      </div>

                      <div class="w-1/2">
                        <div class="flex text-center items-center flex-col">
                          <input type="checkbox" v-model="semComponente">
                          <label class="font-bold text-corBase">Sem Componentes</label>
                        </div>

                        <div class="flex flex-col w-full">
                          <label class="font-bold text-corBase">Cor Componentes</label>
                          <v-select v-model="corComponentes" class="w-full z-10" :options="listaCorComponentes"
                            label="descricao" :reduce="option => option.id" :disabled="semComponente" />
                        </div>
                      </div>

                      <!-- <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase">Cor Vidros</label>
                    <v-select v-model="corVidros" class="w-full z-10" :options="listaCorVidros" label="descricao"
                      :reduce="option => option.id" />
                  </div>

                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase">Cor Chapas/Telas</label>
                    <v-select v-model="corChapas" class="w-full z-10" :options="listaCorChapas" label="descricao"
                      :reduce="option => option.id" />
                  </div> -->
                    </div>


                    <div class="flex gap-2">
                      <div class="flex flex-col w-full">
                        <label class="text-corBase">Contramarco:</label>
                        <v-select v-model="contramarco" :options="lista_contramarco_arremate" label="label"
                          placeholder="Escolha uma opção" class="bg-white" :reduce="contramarco => contramarco.value"
                          :clearable="false" :disabled="!projetoAtual.contramarco" />
                      </div>

                      <div v-if="contramarco" class="flex flex-col w-full">
                        <label class="text-corBase">Medida do Contramarco:</label>
                        <v-select v-model="medida_contramarco" :options="lista_medida_contramarco" label="label"
                          placeholder="Escolha uma opção" class="bg-white"
                          :reduce="medida_contramarco => medida_contramarco.value" :clearable="false" />
                      </div>

                      <div class="flex flex-col w-full">
                        <label class="text-corBase">Perfil de Arremate:</label>
                        <v-select v-model="arremate" :options="lista_contramarco_arremate" label="label"
                          placeholder="Escolha uma opção" class="bg-white" :reduce="arremate => arremate.value"
                          :clearable="false" :disabled="!projetoAtual.arremate" />
                      </div>
                    </div>

                    <div class="flex gap-2 justify-center items-center w-full mt-4">
                      <div class="w-full">
                        <label class="mr-2 text-corBase">Observação:</label>
                        <input v-model="observacao" type="text" id="descricao" placeholder="Digite aqui..."
                          class="input-form-chapa" />
                      </div>
                    </div>

                  </div>

                  <div class="w-1/4 flex justify-center items-center">
                    <img v-if="imgProjeto" :src="imgProjeto.url_documento" class="w-96 md:w-96 cursor-pointer"
                      @click.prevent="openPreviewImage(imgProjeto.url_documento)">
                    <img v-else
                      src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                      class="w-52">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full mx-auto mt-8">
          <div class="space-y-4">
            <div>
              <button class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                Adicionar Serviços
              </button>

              <div class="dados-orcamento">
                <div class="flex gap-2">
                  <div class="flex flex-col w-full">
                    <!-- <label class="font-bold text-corBase text-xs">Status das Medidas</label>
                    <select name="status-medidas" class="input-campos text-xs" v-model="statusMedidas">
                      <option value="1" selected>1 - Medidas Informadas pelo cliente(a medir)</option>
                      <option value="2">2 - Medidas Informadas pelo cliente(vão acabado)</option>
                      <option value="3">3 - Medidas do vão sem acabamento</option>
                      <option value="4">4 - Medidas do vão acabado</option>
                    </select> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full mx-auto mt-8">
          <div class="space-y-4">
            <div>
              <button class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                Dados do Vão
              </button>

              <div class="dados-orcamento">
                <div class="flex gap-2">
                  <div class="flex flex-col w-full">
                    <div>

                      <div class="flex gap-2 justify-center items-center w-full">

                        <div class="w-full">
                          <label class="text-corBase pt-1">Ambiente:</label>
                          <div class="flex justify-center">
                            <v-select v-model="ambiente" :options="lista_ambientes" label="nome"
                              class="bg-white pb-2 w-full" placeholder="Escolha uma opção" />
                          </div>
                        </div>

                        <div class="w-full">
                          <label class="text-corBase pb-0">Referência:</label>
                          <input v-model="referencia" type="text" maxlength="255" placeholder="Digite aqui..."
                            class="input-form-chapa" />
                        </div>

                        <div class="w-full">
                          <label class="text-corBase pb-0">Código Arquitetônico:</label>
                          <input v-model="codigo_arquitetonico" type="number" min="0" maxlength="20"
                            placeholder="Digite aqui..." class="input-form-chapa" />
                        </div>

                      </div>

                      <div class="flex gap-2 justify-center items-center w-full mt-4">
                        <div class="w-full">
                          <label class="mr-2 text-corBase">Situação do Vão:</label>
                          <v-select :options="lista_estado_vao" label="label"
                            :reduce="situacao_vao => situacao_vao.value" v-model="situacao_vao"
                            placeholder="Escolha uma opção" />
                        </div>

                        <div class="w-full">
                          <label class="text-corBase pt-1">Medidas:</label>
                          <v-select :options="[{ label: 'Sim', value: true }, { label: 'Não', value: false }]"
                            label="label" :reduce="medidas => medidas.value" v-model="medidas"
                            placeholder="Escolha uma opção" />
                        </div>

                        <div class="w-full" v-if="medidas == true">
                          <label class="text-corBase pt-1">Responsável Medida:</label>
                          <v-select
                            :options="[{ label: 'Contratante', value: 'CT' }, { label: 'Contratada', value: 'CD' }]"
                            label="label" :reduce="medidas => medidas.value" v-model="quem_mediu"
                            placeholder="Escolha uma opção" />
                        </div>

                        <div class="w-full" v-if="medidas == true">
                          <label class="text-corBase pt-1">Medidor:</label>
                          <v-select :options="quem_mediu === 'CT' ? lista_stakeholders :
                            quem_mediu === 'CD' ? lista_medidores : []" label="nome_razao" v-model="medidor"
                            placeholder="Escolha uma opção">
                            <template #no-options>
                              <span>
                                {{ quem_mediu === 'CT' || quem_mediu === 'CD' ? 'Nenhuma pessoa encontrada.' :
                                  'Selecione quem mediu.' }}
                              </span>
                            </template>
                          </v-select>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex w-full gap-2 justify-end">
              <button
                class="border border-gray-200 bg-red-500 text-white px-4 py-2 hover:bg-screenCor hover:text-red-500 hover:border-red-500"
                @click.prevent="$router.push(`/orcamento/${orcamentoId}`)">Cancelar</button>
              <button
                class="border border-gray-200 bg-ativo text-white px-4 py-2 hover:bg-screenCor hover:text-ativo hover:border-ativo"
                type="submit">
                Salvar
              </button>
            </div>
          </div>
        </div>

      </form>
    </div>
  </section>

  <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />
  <PreviewImage v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />
</template>

<script>
import axios from 'axios'
import MenssagemErro from '../toasts/toast_erro/MenssagemErro.vue'
import MensagemSucesso from '../toasts/toast_sucesso/MensagemSucesso.vue'
import ModalVariaveisOrcamento from './ModalVariaveisOrcamento.vue'
import Select2 from "vue3-select2-component"
import PreviewImage from '../midais/ged/PreviewImage.vue'
import ListagemErros from '../toasts/toast_erro/ListagemErros.vue'
import vSelect from 'vue-select'

export default {
  name: 'IncluirItemOrcamento',

  components: {
    MenssagemErro,
    MensagemSucesso,
    Select2,
    ModalVariaveisOrcamento,
    PreviewImage,
    ListagemErros,
    vSelect,
  },

  data() {
    return {
      descricao: '',
      quantidade: '',
      largura: '',
      altura: '',
      semPerfil: false,
      semComponente: false,
      corComponentes: null,
      corPerfis: null,
      corVidros: '',
      corChapas: '',
      contramarco: false,
      medida_contramarco: '',
      arremate: false,
      ambiente: '',
      referencia: '',
      codigo_arquitetonico: '',
      situacao_vao: '',
      medidas: false,
      quem_mediu: '',
      medidor: '',
      observacao: '',


      listaCorComponentes: [],
      listaCorPerfis: [],
      listaCorVidros: [],
      listaCorChapas: [],
      lista_contramarco_arremate: [
        { label: 'Sim', value: true },
        { label: 'Não', value: false }
      ],
      lista_medida_contramarco: [
        { label: 'Interna', value: 'Interna' },
        { label: 'Externa', value: 'Externa' }
      ],
      lista_estado_vao: [
        { label: 'A Construir', value: 'AC' },
        { label: 'Em Construção', value: 'EC' },
        { label: 'Construído e Sem Acabamento', value: 'CSA' },
        { label: 'Construído e Acabado', value: 'CA' },
      ],
      lista_ambientes: [],

      novoItemOrcamento: {},
      idNovoOrcamento: '',

      larguraErrada: false,
      alturaErrada: false,

      mensagemSucesso: '',
      modalMensagemSucesso: false,
      mensagemErro: '',
      modalMensagemErro: false,
      erros: [],
      exibirErros: false,

      preview_imagem: null,
    }
  },

  watch: {
    semPerfil(value) {
      if (value) {
        this.corPerfis = '';
      }
    },

    semComponente(value) {
      if (value) {
        this.corComponentes = '';
      }
    }
  },

  created() {
    console.log('projeto: ', this.projetoAtual)
    this.descricao = this.descricaoItemOrcamento
    // console.log(this.projetoId);
    // console.log('gestor projeto: ', this.gestorProjeto);
    // console.log(this.orcamentoId);
    // console.log(this.descricaoItemOrcamento);

    // console.log('altura minima: ', this.projetoAtual.altura_minima)
    // console.log('altura maxima: ', this.projetoAtual.altura_maxima)

    // console.log('largura minima: ', this.projetoAtual.largura_minima)
    // console.log('largura maxima: ', this.projetoAtual.largura_maxima)

    // this.getDadosProjeto()
    // this.getlistaCorComponentes();
    // this.getlistaCorPerfis();
    // this.getlistaCorVidros();



    if (this.projetoAtual.habilitar_cor_componente) {
      if (this.projetoAtual.listagem_componente) {
        if (this.projetoAtual.grupo_cor_componentes) {
          this.listaCorComponentes = this.projetoAtual.grupo_cor_componentes.cor_componente
        }
      }

      else if (this.projetoAtual.adicionado_componente) {
        if (this.projetoAtual.cores_componente.length > 0) {
          this.listaCorComponentes = this.projetoAtual.cores_componente
        } else {
          this.getlistaCorComponentes();
        }
      } else {
        this.getlistaCorComponentes();
      }
    }

    if (this.projetoAtual.habilitar_cor_perfil) {
      if (this.projetoAtual.listagem_perfil) {
        if (this.projetoAtual.grupo_cor_perfis) {
          this.listaCorPerfis = this.projetoAtual.grupo_cor_perfis.cor_perfil
        }
      }

      if (this.projetoAtual.adicionado_perfil) {
        if (this.projetoAtual.cores_perfil.length > 0) {
          this.listaCorPerfis = this.projetoAtual.cores_perfil
        } else {
          this.getlistaCorPerfis();
        }
      } else {
        this.getlistaCorPerfis();
      }
    }

    if (this.projetoAtual.habilitar_cor_vidro) {
      if (this.projetoAtual.listagem_vidro) {
        if (this.projetoAtual.grupo_cor_vidros) {
          this.listaCorVidros = this.projetoAtual.grupo_cor_vidros.cor_vidro
        }
      }

      if (this.projetoAtual.adicionado_vidro) {
        if (this.projetoAtual.cores_vidro) {
          this.listaCorVidros = this.projetoAtual.cores_vidro
        }
      }
    }

    if (this.projetoAtual.habilitar_cor_chapa_tela) {
      if (this.projetoAtual.listagem_chapa_tela) {
        if (this.projetoAtual.grupo_cor_chapa_telas) {
          this.listaCorChapas = this.projetoAtual.grupo_cor_chapa_telas.cor_chapa
        }
      }

      if (this.projetoAtual.adicionado_chapa_tela) {
        if (this.projetoAtual.cores_chapa_tela) {
          this.listaCorChapas = this.projetoAtual.cores_chapa_tela
        }
      }
    }

    if (this.projetoAtual.contramarco) {
      this.contramarco = this.projetoAtual.contramarco;
      if (this.projetoAtual.medida_contramarco) {
        this.medida_contramarco = this.projetoAtual.medida_contramarco;
      }
    }

    if (this.projetoAtual.arremate) {
      this.arremate = this.projetoAtual.arremate;
    }

    this.getListaLocaisVao()
  },

  computed: {
    projetoAtual() {
      return this.$store.state.projetoAtual;
    },

    projetoId() {
      return this.$store.state.projetoIdAtual;
    },

    gestorProjeto() {
      return this.$store.state.gestorProjetoAtual;
    },

    orcamentoId() {
      return this.$store.state.orcamentoIdAtual;
    },

    orcamentoAtual() {
      return this.$store.state.orcamentoAtual;
    },

    descricaoItemOrcamento() {
      return this.$store.state.descricaoItemOrcamento;
    },

    imgProjeto() {
      return this.$store.state.projetoImagem;
    },
  },

  methods: {

    validarLargura() {
      if (!this.projetoAtual || (!this.projetoAtual.largura_minima && !this.projetoAtual.largura_maxima)) return;
      const larguraDigitada = Number(this.largura);
      const larguraMinima = Number(this.projetoAtual.largura_minima);
      const larguraMaxima = Number(this.projetoAtual.largura_maxima);

      if (larguraMinima && !larguraMaxima && larguraDigitada < larguraMinima) {
        this.erros = { largura: `O valor deve ser, no mínimo, ${larguraMinima}mm.` };
        this.toggleErros();
        this.larguraErrada = true;
        return;
      }

      if (!larguraMinima && larguraMaxima && larguraDigitada > larguraMaxima) {
        this.erros = { largura: `O valor deve ser, no máximo, ${larguraMaxima}mm.` };
        this.toggleErros();
        this.larguraErrada = true;
        return;
      }

      if (larguraMinima && larguraMaxima && (larguraDigitada < larguraMinima || larguraDigitada > larguraMaxima)) {
        this.erros = { largura: `A largura deve estar entre ${larguraMinima}mm e ${larguraMaxima}mm.` };
        this.toggleErros();
        this.larguraErrada = true;
        return;
      }
    },

    validarAltura() {
      if (!this.projetoAtual || (!this.projetoAtual.altura_minima && !this.projetoAtual.altura_maxima)) return;
      const alturaDigitada = Number(this.altura);
      const alturaMinima = Number(this.projetoAtual.altura_minima);
      const alturaMaxima = Number(this.projetoAtual.altura_maxima);

      if (alturaMinima && !alturaMaxima && alturaDigitada < alturaMinima) {
        this.erros = { altura: `O valor deve ser, no mínimo, ${alturaMinima}mm.` };
        this.toggleErros();
        this.alturaErrada = true;
        return;
      }

      if (!alturaMinima && alturaMaxima && alturaDigitada > alturaMaxima) {
        this.erros = { altura: `O valor deve ser, no máximo, ${alturaMaxima}mm.` };
        this.toggleErros();
        this.alturaErrada = true;
        return;
      }

      if (alturaMinima && alturaMaxima && (alturaDigitada < alturaMinima || alturaDigitada > alturaMaxima)) {
        this.erros = { altura: `A altura deve estar entre ${alturaMinima}mm e ${alturaMaxima}mm.` };
        this.toggleErros();
        this.alturaErrada = true;
        return;
      }
    },

    abrirModal() {
      this.modalMensagemSucesso = true
    },

    // async getDadosProjeto() {
    //   try {
    //     const token = await this.$store.dispatch('getAuthTokenCookie')
    //     await this.$store.dispatch('fetchURLrequest')

    //     const response = await axios.get(`projeto/projeto/${this.projetoAtual}`, {
    //       headers: {
    //         'Authorization': `Token ${token}`
    //       }
    //     })

    //     console.log(response.data)
    //     this.dadosProjeto = response.data.results
    //   } catch (error) {
    //     console.error(error)
    //   }
    // },

    async getlistaCorComponentes() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get('produtos/cor-componente/?page_size=999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.listaCorComponentes = response.data.results.map(response => {
          return {
            id: response.cor_base,
            descricao: response.descricao
          }
        })
      } catch (error) {
        console.error(error)
      }
    },

    async getlistaCorPerfis() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get('produtos/cor-perfil/?page_size=999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.listaCorPerfis = response.data.results.map(response => {
          return {
            id: response.cor_base,
            descricao: response.descricao
          }
        })
      } catch (error) {
        console.error(error)
      }
    },

    async getlistaCorVidros() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get('produtos/cor-vidro/?page_size=999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.listaCorVidros = response.data.results.map(response => {
          return {
            id: response.id,
            descricao: response.descricao
          }
        })
      } catch (error) {
        console.error(error)
      }
    },

    async getListaLocaisVao() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`obra/local-vao/?ativo=True&page_size=999`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.lista_ambientes = response.data.results
      } catch (error) {
        console.error(error)
      }
    },

    async cadastrarOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.post('orcamentos/', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

      } catch (error) {
        this.mensagemErro = error.message
        this.modalMensagemErro = true
      }
    },

    // ESSE AQUI
    async cadastrarItemOrcamento() {

      if (!this.quantidade || this.quantidade <= 0) {
        this.erros = { quantidade: 'Digite um valor válido.' }
        this.toggleErros()
        return
      }

      if (!this.largura || this.largura <= 0) {
        this.erros = { largura: 'Digite um valor válido.' }
        this.toggleErros()
        return
      }

      if (!this.altura || this.altura <= 0) {
        this.erros = { altura: 'Digite um valor válido.' }
        this.toggleErros()
        return
      }

      if (this.projetoAtual.bloquear_orcamento && (this.alturaErrada || this.larguraErrada)) {
        let mensagensErro = [];

        if (this.alturaErrada) {
          mensagensErro.push(`A altura deve estar entre ${this.projetoAtual.altura_minima}mm e ${this.projetoAtual.altura_maxima}mm.`);
        }

        if (this.larguraErrada) {
          mensagensErro.push(`A largura deve estar entre ${this.projetoAtual.largura_minima}mm e ${this.projetoAtual.largura_maxima}mm.`);
        }

        this.erros = { erro: mensagensErro.join(" ") };
        this.toggleErros();
        return;
      }

      if (!this.semPerfil && !this.corPerfis) {
        this.erros = { cor_perfil: 'Você precisa selecionar uma cor para os perfis.' }
        this.toggleErros()
        return
      }

      console.log(this.corComponentes)

      if (!this.semComponente && !this.corComponentes) {
        this.erros = { cor_componente: 'Você precisa selecionar uma cor para os componentes.' }
        this.toggleErros()
        return
      }

      if (!this.contramarco) {
        this.medida_contramarco = ''
      }


      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const data = {
          orcamento: this.orcamentoId,
          projeto: this.projetoAtual.id,
          imagem: this.projetoAtual.imagem?.id || '',
          gestor: this.gestorProjeto,
          descricao: this.descricao,
          quantidade: this.quantidade,
          largura: this.largura,
          altura: this.altura,

          sem_perfis: this.semPerfil,
          cor_perfis: this.corPerfis,

          sem_componentes: this.semComponente,
          cor_componentes: this.corComponentes,
          // cor_vidros: this.corVidros,

          contramarco: this.contramarco,
          medida_contramarco: this.medida_contramarco,
          desconto_altura: this.projetoAtual.desconto_altura,
          desconto_largura: this.projetoAtual.desconto_largura,
          arremate: this.arremate,

          ambiente: this.ambiente?.id || '',
          referencia: this.referencia,
          codigo_arquitetonico: this.codigo_arquitetonico,

          situacao_vao: this.situacao_vao,
          medidas: this.medidas,
          quem_mediu: this.quem_mediu,
          medidor: this.medidor?.id || '',

          observacao: this.observacao,
        }

        const response = await axios.post('orcamentos/item-orcamento/', data, {
          headers: {
            Authorization: `Token ${token}`
          }
        })

        this.novoItemOrcamento = response.data

        this.idNovoOrcamento = this.novoItemOrcamento.id

        await this.cadastrarDadosProjeto()

        // this.mensagemSucesso = 'Item do orçamento criado com sucesso'
        // this.modalMensagemSucesso = true

      } catch (error) {
        console.error(error)
        this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
        this.toggleErros()
        // this.mensagemErro = error.message
        // this.modalMensagemErro = true
      }
    },

    async cadastrarDadosProjeto() {

      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const data = {
          item_orcamento: this.idNovoOrcamento,
          projeto: this.projetoAtual.id,
          largura_minima: this.projetoAtual.largura_minima,
          largura_maxima: this.projetoAtual.largura_maxima,
          altura_minima: this.projetoAtual.altura_minima,
          altura_maxima: this.projetoAtual.altura_maxima,

          contramarco: this.projetoAtual.contramarco,
          medida_contramarco: this.projetoAtual.medida_contramarco,
          arremate: this.projetoAtual.arremate,

          habilitar_produto_perfil: this.projetoAtual.habilitar_produto_perfil,
          habilitar_produto_componente: this.projetoAtual.habilitar_produto_componente,
          habilitar_produto_vidro: this.projetoAtual.habilitar_produto_vidro,
          habilitar_produto_chapa_tela: this.projetoAtual.habilitar_produto_chapa_tela,

          espessuras_permitidas: this.projetoAtual.espessuras_permitidas.map(espessura => (espessura.id)),
          tipos_vidro_permitidos: this.projetoAtual.tipos_vidro_permitidos.map(tipo_vidro => (tipo_vidro.id)),

        }

        const response = await axios.post('orcamentos/dados-projeto-item-orcamento/', data, {
          headers: {
            Authorization: `Token ${token}`
          }
        })

        this.mensagemSucesso = 'Item do orçamento criado com sucesso.'
        this.modalMensagemSucesso = true

      } catch (error) {
        console.error(error)
        this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
        this.toggleErros()
        // this.mensagemErro = error.message
        // this.modalMensagemErro = true
      }
    },

    openPreviewImage(url) {
      this.preview_imagem = url
    },

    closed_preview_image() {
      this.preview_imagem = ''
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros
    }
  }
}
</script>

<style scoped lang="scss">
.dados-orcamento {
  @apply p-4 flex flex-col gap-5 border border-corBase
}
</style>