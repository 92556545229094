<template>
  <div class="w-full mb-2 flex">
    <div class="px-2 mb-2 w-full md:w-1/4">
      <label class="text-xl text-corBase font-bold">Aplicados no Orçamento:</label>
      <v-select v-model="aplicado_orcamento" :options="lista_aplicados_orcamento" class="w-full" :clearable="false" />
    </div>

  </div>

  <div class="block">
    <div class="mb-4">
      <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
        <div class="px-2 w-1/3">
          <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
            <option value="codigo">Código</option>
            <option value="descricao">Descrição</option>
            <option value="corte">Corte</option>
            <option value="cor">Cor</option>
            <option value="fase">Fase</option>
            <option value="aplicacao">Aplicação</option>
          </select>
        </div>

        <div class="w-full">
          <v-select v-if="filtro.campo === 'corte'" v-model="filtro.valor" :options="lista_cortes"
            placeholder="Escolha uma opção" />

          <v-select v-else-if="filtro.campo === 'cor'" v-model="filtro.valor" :options="coresPerfil" label="descricao"
            placeholder="Escolha uma opção" :reduce="cor => cor.id" />

          <v-select v-else-if="filtro.campo === 'fase'" v-model="filtro.valor" :options="lista_fases" label="descricao"
            placeholder="Escolha uma opção" :reduce="fase => fase.descricao" />

          <v-select v-else-if="filtro.campo === 'aplicacao'" v-model="filtro.valor" :options="lista_aplicacao"
            placeholder="Escolha uma opção" />

          <input v-else v-model="filtro.valor" type="text" class="input-form-chapa" placeholder="Insira o valor" />
        </div>

        <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
      </div>

      <div class="flex w-full justify-center">
        <button class="text-ativo" @click.prevent="adicionarFiltro">
          <i class="fas fa-plus-circle"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="mb-2">
    <button class="bg-gray-600 hover:bg-screenCor border border-screenCor hover:border-gray-600
    text-white hover:text-gray-600 py-2 px-4" @click.prevent="toggleFuncoesECondicoes">
      Ver funções e condições
    </button>
  </div>

  <div class="flex">

    <div class="flex flex-wrap gap-2 mb-2">

      <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
    text-white hover:text-corBase py-2 px-4" @click.prevent="getPerfisOrcamento()">
        Pesquisar
      </button>

      <!-- <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
    text-white hover:text-ativo py-2 px-4" @click.prevent="$router.push(`/orcamento/selecionar-projeto/`)">
        Adicionar
      </button> -->

      <button class="bg-blue-500 hover:bg-screenCor border border-screenCor hover:border-blue-500
    text-white hover:text-blue-500 py-2 px-4" @click.prevent="alterarSelecionadosPerfis()">
        Alterar Selecionados
      </button>

      <button class="bg-red-500 hover:bg-screenCor border border-screenCor hover:border-red-500
    text-white hover:text-red-500 py-2 px-4" @click.prevent="verificarExclusao()">
        Excluir Selecionados
      </button>

      <button class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500
    text-white hover:text-yellow-500 py-2 px-4" @click.prevent="editarTodos()">
        {{ editandoTodos ? 'Salvar Todos' : 'Editar Todos' }}
      </button>

      <!-- <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
    text-white hover:text-ativo py-2 px-4" @click.prevent="salvarEdicoes()">
        Salvar
      </button> -->

      <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
    text-white hover:text-ativo py-2 px-4" @click.prevent="recalcularTudoItemOrcamento()">
        Recalcular
      </button>

    </div>
  </div>


  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
        <tr class="border-b">
          <th scope="col" class="px-3 py-3">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <!-- <th scope="col" class="px-6 py-2 text-center">
            Ações
          </th> -->
          <th class="px-8 py-2">
            Código
          </th>
          <th scope="col" class="px-6 py-2 text-start">
            Imagem
          </th>
          <th scope="col" class="px-6 py-2">
            Descrição
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Condição
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            <!-- v-if="aplicado_orcamento === 'Todos'" -->
            Condição Resultado
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Peso
          </th>
          <th scope="col" class="px-6 py-2">
            Corte
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Quantidade Fórmula
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Quantidade Resultado
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Medida Fórmula
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Medida Resultado
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Posição
          </th>
          <th scope="col" class="px-12 py-2 text-center">
            Observação
          </th>
          <th scope="col" class="px-12 py-2 text-center">
            Cor
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Fase
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Aplicação
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(perfil, index) in perfis" :key="perfil.id" class="escopo-tabela">
          <td class="w-4 px-3 py-3">
            <div class="flex items-center">
              <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox" v-model="perfil.selected">
              <label class="sr-only">checkbox</label>
            </div>
          </td>
          <!-- <td class="px-6 py-2">
            <div class="flex justify-center gap-2">
              <i class="fa-solid fa-pen-to-square cursor-pointer" @click="editarPerfil(perfil)"></i>
            </div>
          </td> -->

          <!-- Código Base -->
          <td class="py-2 whitespace-nowrap">
            <v-select v-if="editandoTodos" v-model="perfil.editado.perfil" label="codigo_base"
              :options="perfisPesquisados" @search="buscarPerfis"
              @update:modelValue="(selecionado) => onSelect(perfil, selecionado)" placeholder="Busque um perfil..."
              class="bg-white w-full rounded-md shadow-sm">
              <template v-slot:option="{ codigo_base, nome_base }">
                <div class="w-full">
                  <span class="font-bold">{{ codigo_base }}</span> - {{ nome_base }}
                </div>
              </template>
            </v-select>
            <span v-else class="px-8 text-xs">{{ perfil.perfil?.codigo_base ?? '-' }}</span>
          </td>

          <td scope="col" class="px-6 py-2">
            <div class="flex justify-center items-center">
              <img v-if="perfil.perfil.imagem" :src="perfil.perfil.imagem.url_documento"
                class="cursor-pointer w-10 h-10" @click.prevent="openPreviewImage(perfil.perfil.imagem.url_documento)">
              <img v-else
                src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                alt="Sem foto" class="w-10 h-10">
            </div>
          </td>

          <!-- Nome Base -->
          <td class="px-6 py-2">
            <span v-if="editandoTodos">{{ perfil.editado?.perfil?.nome_base ?? '-'
            }}</span>
            <span v-else class="text-xs">{{ perfil.perfil?.nome_base ?? '-' }}</span>
          </td>

          <!-- Condição -->
          <td class="py-2 text-center">
            <input v-if="editandoTodos" v-model="perfil.editado.condicao_formula" type="text"
              class="w-full p-2 border rounded-lg">
            <span v-else class="text-xs">{{ perfil.condicao_formula ?? '-' }}</span>
          </td>
          <td class="px-6 py-2 text-center text-xs">
            <!-- v-if="aplicado_orcamento === 'Todos'" -->
            {{ perfil.condicao_calculada ? 'Verdadeiro' : 'Falso' }}
          </td>

          <!-- Peso Base -->
          <td class="px-6 py-2 text-center">
            <span v-if="editandoTodos">{{ perfil.editado?.perfil?.peso_base ?? '-'
            }}</span>
            <span v-else class="text-xs">{{ perfil.perfil?.peso_base ?? '-' }}</span>
          </td>

          <!-- Corte -->
          <td class="py-2 text-center">
            <select v-if="editandoTodos" v-model="perfil.editado.corte"
              class="input-campos block w-full p-2 border rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option v-for="corte in ['45/45', '45/90', '90/45', '90/90']" :key="corte" :value="corte">
                {{ corte }}
              </option>
            </select>
            <span v-else class="text-xs">{{ perfil.corte ?? '-' }}</span>
          </td>

          <!-- Quantidade Fórmula -->
          <td class="py-2 text-center">
            <input v-if="editandoTodos" v-model="perfil.editado.qtd_formula" type="text"
              class="w-full p-2 border rounded-lg">
            <span v-else class="text-xs">{{ perfil.qtd_formula ?? '-' }}</span>
          </td>

          <!-- Quantidade Resultado -->
          <td class="px-6 py-2 text-center text-xs">{{ perfil.qtd_calculada ?? 'Não calculado' }}</td>

          <!-- Medida Fórmula -->
          <td class="py-2 text-center">
            <input v-if="editandoTodos" v-model="perfil.editado.medida_formula" type="text"
              class="w-full p-2 border rounded-lg">
            <span v-else class="text-xs">{{ perfil.medida_formula ?? '-' }}</span>
          </td>

          <!-- Medida Resultado -->
          <td class="px-6 py-2 text-center text-xs">{{ perfil.medida_calculada ?? 'Não calculado' }}</td>

          <!-- Posição -->
          <td class="py-2 text-center">
            <select v-if="editandoTodos" v-model="perfil.editado.posicao"
              class="input-campos block w-full p-2 border rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option v-for="posicao in ['H', 'L', 'V']" :key="posicao" :value="posicao">
                {{ posicao }}
              </option>
            </select>
            <span v-else class="text-xs">{{ perfil.posicao || '-' }}</span>
          </td>

          <!-- Observação -->
          <td class="py-2 text-center">
            <input v-if="editandoTodos" v-model="perfil.editado.observacao" type="text"
              class="w-full p-2 border rounded-lg">
            <span v-else>{{ perfil.observacao || '-' }}</span>
          </td>

          <!-- Cor -->
          <td class="py-2 text-center">
            <v-select v-if="editandoTodos" v-model="perfil.editado.cor" :options="perfil.cor_definida_projeto ? perfil.perfil.cor_base : coresPerfil" label="descricao"
              class="bg-white w-full rounded-md shadow-sm" placeholder="Escolha uma opção" />
            <span v-else class="text-xs">{{ perfil.cor?.descricao || '-' }}</span>
            <div class="text-red-500 text-xs">
              <p>
                cor_definida_projeto: {{ perfil.cor_definida_projeto }}
              </p>
              <p>
                lista usada: {{ perfil.cor_definida_projeto ? "perfil.cor_base" : "coresPerfil" }}
              </p>
              <p>
                cores base: {{ perfil.perfil.cor_base.map(cor => cor.descricao).join(', ') || '-' }}
              </p>
            </div>

          </td>

          <!-- Fase Esquadria -->
          <td class="py-2 text-center">
            <v-select v-if="editandoTodos" v-model="perfil.editado.fase_esquadria" :options="lista_fases"
              label="descricao" class="bg-white w-full rounded-md shadow-sm" placeholder="Escolha uma opção" />
            <span v-else class="text-xs">{{ perfil.fase_esquadria?.descricao || '-' }}</span>
          </td>

          <!-- Aplicação -->
          <td class="py-2 text-center">
            <select v-if="editandoTodos" v-model="perfil.editado.aplicacao"
              class="input-campos block w-full p-2 border rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option v-for="aplicacao in ['A Definir', 'Produção', 'Obra']" :key="aplicacao" :value="aplicacao">
                {{ aplicacao }}
              </option>
            </select>
            <span v-else class="text-xs">{{ perfil.aplicacao || '-' }}</span>
          </td>
        </tr>
      </tbody>

      <tr v-if="adicionarNovoPerfil" class="escopo-tabela">
        <td class="w-full text-center">
          <i class="fa-solid fa-magnifying-glass cursor-pointer" @click.prevent="toggleMenuProdutos()"></i>
        </td>
        <td class="w-full">
          <v-select v-model="novoPerfil.codigo_base" label="codigo_base" :options="perfisPesquisados"
            @search="buscarPerfis" @update:modelValue="onSelectNovoPerfil" placeholder="Busque um perfil..."
            class="w-full">
            <template v-slot:option="{ codigo_base, nome_base }">
              <div class="w-full">
                <span class="font-bold">{{ codigo_base }}</span> - {{ nome_base }}
              </div>
            </template>
          </v-select>

        </td>

        <td scope="col" class="px-6 py-2">
          <div class="flex justify-center items-center">
            <img v-if="novoPerfil.imagem" :src="novoPerfil.imagem.url_documento"
              class="cursor-pointer w-10 h-10"
              @click.prevent="openPreviewImage(novoPerfil.imagem.url_documento)">
            <img v-else
              src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
              alt="Sem foto" class="w-10 h-10">
          </div>
        </td>

        <td class="w-full text-center">
          {{ novoPerfil.nome_base ?? '' }}
        </td>

        <td class="w-full">
          <input class="input-campos" v-model="novoPerfil.condicao">
        </td>

        <td class="px-6 py-2 text-center"> - </td>
        <!-- v-if="aplicado_orcamento === 'Todos'" -->

        <td class="w-full text-center">
          {{ novoPerfil.peso_base ?? '' }}
        </td>

        <td class="w-full">
          <select v-model="novoPerfil.corte" class="input-campos">
            <option v-for="corte in ['45/45', '45/90', '90/45', '90/90']" :key="corte" :value="corte">
              {{ corte }}
            </option>
          </select>
        </td>

        <td class="w-full">
          <input class="input-campos" v-model="novoPerfil.qtd_formula">
        </td>

        <td class="px-6 py-2 text-center"> - </td>

        <td class="w-full">
          <input class="input-campos" v-model="novoPerfil.medida_formula">
        </td>

        <td class="px-6 py-2 text-center"> - </td>

        <td class="w-full">
          <select v-model="novoPerfil.posicao" class="input-campos">
            <option v-for="posicao in ['H', 'L', 'V']" :key="posicao" :value="posicao">
              {{ posicao }}
            </option>
          </select>
        </td>

        <td class="w-full">
          <input class="input-campos" v-model="novoPerfil.observacao">
        </td>

        <td class="w-full">
          <v-select v-model="novoPerfil.cor" :options="coresPerfil" label="nome" class="bg-white w-full custom-width"
            :reduce="cor => cor.id" placeholder="Escolha uma opção" />
        </td>

        <td class="w-full">
          <v-select v-model="novoPerfil.fase_esquadria" :options="lista_fases" label="descricao"
            class="bg-white w-full custom-width" :reduce="fase => fase.id" :clearable="false"
            placeholder="Escolha uma opção" />
        </td>

        <td class="w-full">
          <select v-model="novoPerfil.aplicacao" class="input-campos">
            <option v-for="aplicacao in ['A Definir', 'Produção', 'Obra']" :key="aplicacao" :value="aplicacao">
              {{ aplicacao }}
            </option>
          </select>
        </td>

      </tr>
    </table>

    <div v-if="adicionarNovoPerfil" class="flex w-full justify-end itens-center mt-6 px-4 mb-5 gap-2">
      <button class="bg-red-500 hover:bg-screenCor border border-screenCor hover:border-red-500
      text-white hover:text-red-500 py-2 px-4" @click.prevent="togglePerfil">
        Cancelar
      </button>
      <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
      text-white hover:text-ativo py-2 px-4" @click.prevent="postNovoPerfil">
        Adicionar
      </button>
    </div>
    <div v-else class="flex justify-center">
      <button class="text-ativo" @click.prevent="togglePerfil">
        <i class="fas fa-plus-circle"></i>
      </button>
    </div>

  </div>

  <AlterarSelecionadosPerfisOrcamento v-if="exibirAlterarSelecionados" :fasesEsquadria="lista_fases"
    :perfis="perfisSelecionados" @fecharModal="toggleAlterarSelecionados()" @atualizarLista="getPerfisOrcamento()" />
  <MensagemConfirmacaoExclusao mensagem="Deseja mesmo excluir os itens selecionados?" :abrir="modal_confirmacao"
    @fecharModal="toggleModalConfirmacao()" @confirmarExclusao="deletarPerfis" />
  <PreviewImage v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />
  <ListaFuncoesECondicoes v-if="exibirFuncoesECondicoes" @fecharModal="toggleFuncoesECondicoes" />
  <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />
  <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />
</template>

<script>
import axios from 'axios'
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue';
import PreviewImage from '@/components/midais/ged/PreviewImage.vue';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';
import AlterarSelecionadosPerfisOrcamento from '../modais/AlterarSelecionados/AlterarSelecionadosPerfisOrcamento.vue';
import ListaFuncoesECondicoes from '@/components/midais/ajudas/ListaFuncoesECondicoes.vue';
import vSelect from 'vue-select';


export default {
  name: 'AlterarPerfilOrcamento',
  components: {
    MensagemConfirmacaoExclusao,
    PreviewImage,
    SucessoRapido,
    ListagemErros,
    AlterarSelecionadosPerfisOrcamento,
    ListaFuncoesECondicoes,
    vSelect,
  },

  computed: {
    checkSome() {
      return this.perfis.some(perfil => perfil.selected)
    }
  },

  props: ['itemOrcamentoId'],
  emits: ['atualizarLista'],

  data() {
    return {
      checkAll: false,

      filtros: [{ campo: "codigo", valor: "" }],

      lista_aplicados_orcamento: ['Sim', 'Não', 'Todos'],
      lista_cortes: ['45/45', '45/90', '90/45', '90/90'],
      lista_aplicacao: ['A Definir', 'Obra', 'Produção'],
      lista_fases: [],
      coresPerfil: [],
      aplicado_orcamento: 'Sim',

      perfisPesquisados: [],

      preview_imagem: null,

      perfis: [],
      countItens: 0,
      // coresPerfil: [],

      modal_confirmacao: false,

      exibirFuncoesECondicoes: false,

      perfisSelecionados: [],
      exibirAlterarSelecionados: false,

      editandoTodos: false,
      editandoPerfil: null,

      novoPerfil: [],
      adicionarNovoPerfil: false,

      sucesso: false,
      sucessoMessage: '',

      exibirErros: false,
      erros: [],
    }
  },

  created() {
    this.getListaFaseEsquadria()
    this.getListaCorPerfil()
    this.getPerfisOrcamento()
  },

  methods: {

    atualizarFiltro(index) {
      this.filtros[index].valor = null;
    },
    adicionarFiltro() {
      this.filtros.push({ campo: "", valor: "" });
    },
    removerFiltro(index) {
      this.filtros.splice(index, 1);
    },

    selectAll() {
      this.perfis.forEach((perfil_orcamento) => {
        perfil_orcamento.selected = this.checkAll;
      });
    },

    async getListaFaseEsquadria() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`projeto/fase-esquadria/?ativo=True&page_size=9999`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        this.lista_fases = response.data.results
      } catch (error) {
        console.error(error)
      }
    },

    async getListaCorPerfil() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`produtos/cor-perfil/?ativo=True&page_size=9999`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        this.coresPerfil = response.data.results
      } catch (error) {
        console.error(error)
      }
    },

    async getPerfisOrcamento() {
      try {

        this.$store.dispatch("setLoading", true);

        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const params = this.filtros
          .filter((filtro) => filtro.campo && filtro.valor)
          .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
          .join("&");

        const response = await axios.get(`orcamentos/perfis-orcamento/?item_orcamento_id=${this.itemOrcamentoId}&ativo=True&aplicado_orcamento=${this.aplicado_orcamento}&${params}&page_size=9999`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })

        this.perfis = response.data.results.map(perfil => ({
          ...perfil,
          editado: { ...perfil }, // Clona os dados para edição
        }));
        this.countItens = response.data.count
        this.checkAll = false

      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    async buscarPerfis(searchQuery) {
      if (searchQuery.length > 2) {
        try {

          this.$store.dispatch("setLoading", true);
          const token = await this.$store.dispatch('getAuthTokenCookie');
          await this.$store.dispatch('fetchURLrequest');

          const response = await axios.get(`produtos/produto/?tipo_produto=Perfil&filtro_base=${searchQuery}&page_size=9999`, {
            headers: { Authorization: `Token ${token}` }
          });

          this.perfisPesquisados = response.data.results
        } catch (error) {
          console.error("Erro ao buscar perfis:", error);
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      } else {
        this.perfisPesquisados = [];
      }
    },

    onSelect(perfil, selecionado) {
      if (!selecionado) return;

      perfil.editado.perfil = selecionado;
      perfil.editado.perfil_id = selecionado.id;
      perfil.editado.descricao = selecionado.nome_base;
      perfil.editado.peso = selecionado.peso_base;
    },


    async alterarSelecionadosPerfis() {
      if (this.checkSome) {
        this.perfisSelecionados = this.perfis.filter(perfil => perfil.selected);
        console.log(this.perfisSelecionados)
        this.toggleAlterarSelecionados()

      } else {
        this.erros = { perfil: 'Selecione pelo menos um perfil para alterar.' }
        this.toggleErros()
      }
    },

    toggleAlterarSelecionados() {
      this.exibirAlterarSelecionados = !this.exibirAlterarSelecionados
    },

    verificarExclusao() {
      if (this.checkSome) {
        this.toggleModalConfirmacao()
      } else {
        this.erros = { perfil: 'Selecione pelo menos um Perfil para ser excluído.' }
        this.toggleErros()
      }
    },

    toggleModalConfirmacao() {
      this.modal_confirmacao = !this.modal_confirmacao
    },

    async deletarPerfis() {
      try {
        this.$store.dispatch("setLoading", true);

        const perfisADeletar = this.perfis.filter(perfil => perfil.selected);

        if (perfisADeletar.length === 0) {
          console.warn("Nenhum perfil selecionado para deletar.");
          return;
        }

        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        await axios.patch(
          `orcamentos/atualizar-perfis-orcamento/`,
          { itens: perfisADeletar.map(perfil => ({ id: perfil.id, ativo: false })) },
          { headers: { Authorization: `Token ${token}` } }
        );

        this.sucesso = true
        this.sucessoMessage = 'Perfis excluídos com sucesso.'
        this.toggleModalConfirmacao()
        setTimeout(() => {
          this.sucesso = false;
          this.sucessoMessage = ''
        }, 3000);
        this.getPerfisOrcamento()
      } catch (error) {
        console.error("Erro ao deletar perfis:", error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    // editarPerfil(perfil) {
    //   if (this.editandoPerfil) {
    //     this.salvarEdicoes()
    //   } else {
    //     this.editandoPerfil = perfil.id;
    //   }
    // },

    editarTodos() {
      if (this.countItens == 0) {
        this.erros = { editar_todos: 'Busque os Perfis para editar.' }
        this.toggleErros()
        return
      } else {
        this.editandoTodos = !this.editandoTodos;
        if (!this.editandoTodos) {
          // this.editandoPerfil = null
          this.salvarEdicoes()
        };
      }
    },

    fecharTodos() {
      this.editandoTodos = false
    },

    async salvarEdicoes() {
      try {
        this.$store.dispatch("setLoading", true);
        const token = await this.$store.dispatch("getAuthTokenCookie");
        await this.$store.dispatch("fetchURLrequest");

        // Filtra apenas perfis que foram editados
        const perfisAtualizados = this.perfis
          .filter(perfil => {
            const original = perfil;
            const editado = perfil.editado;

            return (
              original.perfil !== editado.perfil ||
              original.condicao_formula !== editado.condicao_formula ||
              original.corte !== editado.corte ||
              original.qtd_formula !== editado.qtd_formula ||
              original.medida_formula !== editado.medida_formula ||
              original.observacao !== editado.observacao ||
              original.posicao !== editado.posicao ||
              original.cor !== editado.cor ||
              original.fase_esquadria !== editado.fase_esquadria ||
              original.aplicacao !== editado.aplicacao
            );
          })
          .map(perfil => ({
            id: perfil.id,
            perfil: perfil.editado.perfil.id,
            condicao_formula: perfil.editado.condicao_formula,
            corte: perfil.editado.corte,
            qtd_formula: perfil.editado.qtd_formula,
            medida_formula: perfil.editado.medida_formula,
            posicao: perfil.editado.posicao,
            observacao: perfil.editado.observacao || null,
            cor: perfil.editado.cor?.id || null,
            fase_esquadria: perfil.editado.fase_esquadria?.id || null,
            aplicacao: perfil.editado.aplicacao,
          }));

        if (perfisAtualizados.length === 0) {
          this.fecharTodos()
          // this.editandoPerfil = null
          return;
        }

        // Enviar os perfis atualizados em uma única requisição PATCH
        await axios.patch(
          `orcamentos/atualizar-perfis-orcamento/`,
          { itens: perfisAtualizados },
          { headers: { Authorization: `Token ${token}` } }
        );

        this.sucesso = true;
        this.sucessoMessage = 'Perfis editados e salvos com sucesso.'
        this.getPerfisOrcamento();
        setTimeout(() => {
          this.fecharTodos()
          this.sucesso = false;
          // this.editandoPerfil = null
        }, 3000);
      } catch (error) {
        console.error("[ERRO] Falha ao salvar edições:", error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    async recalcularItemOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(
          `engine/recalcular-item-orcamento/${this.itemOrcamentoId}/?perfis=true`,
          {
            headers: { 'Authorization': `Token ${token}` },
          }
        );

        console.log("Itens atualizados com sucesso:", response.data);
        this.sucesso = true;
        this.sucessoMessage = 'Perfis recalculados com sucesso.';
        this.getPerfisOrcamento()
        setTimeout(() => {
          this.sucesso = false;
          this.sucessoMessage = '';
        }, 3000);

      } catch (error) {
        console.error("Erro ao atualizar itens do orçamento:", error);
        this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
        this.toggleErros();
      }
    },

    async recalcularTudoItemOrcamento() {

      try {

        this.$store.dispatch("setLoading", true);

        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`engine/${this.itemOrcamentoId}/`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        console.log(response.data)
        this.sucesso = true
        this.sucessoMessage = 'Item do Orçamento recalculado com sucesso.'
        this.getPerfisOrcamento()
        setTimeout(() => {
          this.sucesso = false
          this.sucessoMessage = ''
        }, 3000)

      } catch (error) {
        console.error(error)
        this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
        this.toggleErros()
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },


    onSelectNovoPerfil(perfil) {
      this.novoPerfil.perfil = perfil.id
      this.novoPerfil.imagem = perfil.imagem
      this.novoPerfil.codigo_base = perfil.codigo_base
      this.novoPerfil.nome_base = perfil.nome_base
      this.novoPerfil.peso_base = perfil.peso_base
    },

    togglePerfil() {
      this.adicionarNovoPerfil = !this.adicionarNovoPerfil;
      this.limparCampos();
    },

    limparCampos() {
      this.novoPerfil = []

      this.novoPerfil.aplicacao = 'A Definir'

      const faseDefault = this.lista_fases.find(item => item.descricao === 'A Definir');
      this.novoPerfil.fase_esquadria = faseDefault ? faseDefault.id : null;
    },

    async postNovoPerfil() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');
        this.$store.dispatch("setLoading", true);

        console.log('NOVOPERFIL: ', this.novoPerfil)
        const perfilAdicionar = {
          item_orcamento: this.itemOrcamentoId,
          perfil: this.novoPerfil.perfil,
          condicao_formula: this.novoPerfil.condicao,
          corte: this.novoPerfil.corte,
          qtd_formula: this.novoPerfil.qtd_formula,
          medida_formula: this.novoPerfil.medida_formula,
          posicao: this.novoPerfil.posicao,
          observacao: this.novoPerfil.observacao,
          cor: this.novoPerfil.cor,
          fase_esquadria: this.novoPerfil.fase_esquadria,
          aplicacao: this.novoPerfil.aplicacao,
        }

        await axios.post('orcamentos/perfis-orcamento/', perfilAdicionar, {
          headers: { Authorization: `Token ${token}` }
        });

        this.sucesso = true;
        this.sucessoMessage = 'Perfil criado com sucesso!';
        setTimeout(() => this.sucesso = false, 3000);

        this.adicionarNovoPerfil = false;
        this.perfisPesquisados = [];
        this.limparCampos();
        this.getPerfisOrcamento()
      } catch (error) {
        console.error(error);
        this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
        this.toggleErros();
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros
    },

    openPreviewImage(url) {
      this.preview_imagem = url
    },

    closed_preview_image() {
      this.preview_imagem = ''
    },

    toggleFuncoesECondicoes() {
      this.exibirFuncoesECondicoes = !this.exibirFuncoesECondicoes
    },


  }

}
</script>

<style scoped lang="scss"></style>
