<template>
    <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div class="bg-white p-8 rounded-lg shadow-lg w-1/3 max-w-lg">
            <div class="border-b mb-4">
                <h2 class="text-2xl font-semibold text-gray-800 text-center">Alterar Perfis</h2>
            </div>

            <!-- <p>
                Corte, Posição, Cor, Fase, Aplicação
            </p> -->

            <div class="space-y-4">
                <div class="w-full flex gap-2">
                    <div class="text-left w-full">
                        <label for="corte" class="block text-sm font-medium text-corBase mb-1">Corte</label>
                        <select v-model="corte"
                            class="input-campos block w-full p-2 border rounded-md text-corBase focus:outline-none focus:ring-2 focus:ring-blue-500">
                            <option v-for="corte in ['45/45', '45/90', '90/45', '90/90']" :key="corte" :value="corte">
                                {{ corte }}
                            </option>
                        </select>
                    </div>

                    <div class="text-left w-full">
                        <label for="posicao" class="block text-sm font-medium text-corBase mb-1">Posição</label>
                        <select v-model="posicao"
                            class="input-campos block w-full p-2 border rounded-md text-corBase focus:outline-none focus:ring-2 focus:ring-blue-500">
                            <option v-for="posicao in ['H', 'L', 'V']" :key="posicao" :value="posicao">
                                {{ posicao }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="w-full flex gap-2">
                    <div class="text-left w-full">
                        <label for="fase_esquadria" class="block text-sm font-medium text-corBase mb-1">Fase</label>
                        <v-select v-model="fase_esquadria" :options="fasesEsquadria" label="descricao"
                            class="bg-white w-full custom-width rounded-md shadow-sm" :reduce="fase => fase.id"
                            placeholder="Escolha uma opção" />
                    </div>

                    <div class="text-left w-full">
                        <label for="aplicacao" class="block text-sm font-medium text-corBase mb-1">Aplicação</label>
                        <select v-model="aplicacao"
                            class="input-campos block w-full p-2 border rounded-md text-corBase focus:outline-none focus:ring-2 focus:ring-blue-500">
                            <option v-for="aplicacao in ['A Definir', 'Produção', 'Obra']" :key="aplicacao"
                                :value="aplicacao">
                                {{ aplicacao }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="text-left">
                    <label for="cor" class="block text-sm font-medium text-corBase mb-1">Cor</label>
                    <v-select v-model="cor" :options="coresPerfil" label="nome"
                        class="bg-white w-full custom-width rounded-md shadow-sm" :reduce="cor => cor.id"
                        placeholder="Escolha uma opção" />
                </div>

            </div>

            <!-- Botões -->
            <div class="mt-6 flex justify-center space-x-4">
                <button class="btn-cancelar" @click.prevent="$emit('fecharModal')">
                    Fechar
                </button>
                <button class="btn-salvar" @click="salvarAlteracoes()">
                    Salvar Alterações
                </button>
            </div>
        </div>
    </div>

    <SucessoRapido v-if="sucesso === true" mensagem="Perfis atualizados com sucesso!" />
    <ErroRapido v-if="erro === true" mensagem="Ocorreu um erro ao atualizar Perfis." />
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';

export default {
    name: 'AlterarPerfis',
    data() {
        return {
            corte: '',
            posicao: '',
            cor: '',
            fase_esquadria: '',
            aplicacao: '',

            sucesso: false,
            erro: false,
        };
    },

    components: {
        vSelect,
        SucessoRapido,
        ErroRapido,
    },

    props: ['perfis', 'coresPerfil', 'fasesEsquadria'],

    emits: ['fecharModal', 'atualizarLista'],

    methods: {
        async salvarAlteracoes() {
            try {
                this.$store.dispatch("setLoading", true);

                const token = await this.$store.dispatch("getAuthTokenCookie");
                await this.$store.dispatch("fetchURLrequest");

                // 🔍 Filtra perfis que realmente precisam ser atualizados
                const perfisAtualizados = this.perfis.map(perfil => {
                    const camposAtualizados = {};

                    if (this.corte) {
                        camposAtualizados.corte = this.corte;
                    }
                    if (this.posicao) {
                        camposAtualizados.posicao = this.posicao;
                    }
                    if (this.cor) {
                        camposAtualizados.cor = this.cor;
                    }
                    if (this.fase_esquadria) {
                        camposAtualizados.fase_esquadria = this.fase_esquadria;
                    }
                    if (this.aplicacao) {
                        camposAtualizados.aplicacao = this.aplicacao;
                    }

                    // Adiciona o ID do perfil apenas se houver alterações
                    return Object.keys(camposAtualizados).length > 0 ? { id: perfil.id, ...camposAtualizados } : null;
                }).filter(item => item !== null); // Remove perfis sem alterações

                if (perfisAtualizados.length === 0) {
                    console.warn("Nenhum perfil atualizado.");
                    this.$store.dispatch("setLoading", false);
                    return;
                }

                // 🔥 Envia apenas UM PATCH com todos os perfis de uma vez (evita múltiplas requisições)
                await axios.patch(
                    `orcamentos/atualizar-perfis-orcamento/`,
                    { itens: perfisAtualizados },
                    { headers: { Authorization: `Token ${token}` } }
                );

                // Exibe mensagem de sucesso e fecha o modal
                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit("fecharModal");
                    this.$emit("atualizarLista");
                }, 3000);
            } catch (error) {
                console.error("Erro ao salvar alterações:", error);
                this.erro = true;
                setTimeout(() => {
                    this.erro = false;
                }, 3000);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },


    },
};
</script>

<style scoped>
.input-campos {
    @apply block w-full p-2 border rounded-md text-corBase;
}
</style>