<template>
    <div>
        <div class="mb-4">
            <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
                <div class="px-2 w-1/2">
                    <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
                        <option value="gestor_destino">Gestor Destino</option>
                        <option value="linha">Linha</option>
                    </select>
                </div>

                <div class="w-full">
                    <v-select v-if="filtro.campo === 'gestor_destino'" v-model="filtro.valor"
                        :options="proprio_tenant_origem" label="tenant_destino" :reduce="tenant => tenant.id"
                        placeholder="Escolha uma opção" />

                    <input v-else v-model="filtro.valor" type="text" class="input-form-chapa"
                        placeholder="Digite o desejado" />
                </div>

                <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
            </div>

            <div class="flex w-full justify-center">
                <button class="text-ativo" @click.prevent="adicionarFiltro">
                    <i class="fas fa-plus-circle"></i>
                </button>
            </div>
        </div>

        <!-- Botões de ação -->
        <div class="flex flex-wrap gap-2 mb-6">

            <button @click.prevent="toggleGestorDestino()"
                class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500 text-white hover:text-yellow-500 py-2 px-4">
                Habilitar Gestor Destino
            </button>

            <button
                class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase text-white hover:text-corBase py-2 px-4"
                @click.prevent="toggleAdicionarLinhas()">
                Adicionar Linhas
            </button>
        </div>


        <div class="space-y-8">

            <!-- Exibição do Sistema Origem -->
            <div class="">
                <h2 class="text-xl font-bold text-corBase mb-4">Seu sistema origem</h2>

                <ul class="space-y-4">
                    <li v-for="origem in linhas_habilitadas_origem" :key="origem.id"
                        class="flex flex-col bg-gray-50 p-4 border border-gray-300 rounded-lg">

                        <div class="flex items-center justify-between">
                            <span class="font-semibold text-gray-700 w-1/3 text-left">
                                {{ origem.tenant_origem?.name || 'ManagerGlass' }}
                            </span>

                            <i class="fa-solid fa-arrow-right text-gray-500 text-lg"></i>

                            <span class="font-semibold text-gray-700 w-1/3 text-right">
                                {{ origem.tenant_destino?.name || 'ManagerGlass' }}
                            </span>
                        </div>

                        <div class="mt-2 text-center text-sm">
                            <span class="font-bold text-gray-600">Linhas: </span>
                            <span class="text-gray-700">{{ origem.linhas.map(l => l.linha).join(', ') || '-' }}</span>
                        </div>
                    </li>
                </ul>
            </div>

            <!-- Separador -->
            <div class="border-t border-gray-300"></div>

            <!-- Exibição do Sistema Destino -->
            <div class="">
                <h2 class="text-xl font-bold text-corBase mb-4">Seu sistema destino</h2>

                <ul class="space-y-4">
                    <li v-for="destino in linhas_habilitadas_destino" :key="destino.id"
                        class="flex flex-col bg-gray-50 p-4 border border-gray-300 rounded-lg">

                        <div class="flex items-center justify-between">
                            <span class="font-semibold text-gray-700 w-1/3 text-left">
                                {{ destino.tenant_destino?.name || 'ManagerGlass' }}
                            </span>

                            <i class="fa-solid fa-arrow-left text-gray-500 text-lg"></i>

                            <span class="font-semibold text-gray-700 w-1/3 text-right">
                                {{ destino.tenant_origem?.name || 'ManagerGlass' }}
                            </span>
                        </div>

                        <div class="mt-2 text-center text-sm">
                            <span class="font-bold text-gray-600">Linhas: </span>
                            <span class="text-gray-700">{{ destino.linhas.map(l => l.linha).join(', ') || '-' }}</span>
                        </div>
                    </li>
                </ul>
            </div>

        </div>

    </div>

    <AdicionarGestorDestino v-if="adicionarGestorDestino" @fecharModal="toggleGestorDestino()"
        @atualizarLista="getLinhasHabilitadas()" />
    <AdicionarLinhasGestores v-if="adicionarLinhas" @fecharModal="toggleAdicionarLinhas()"
        @atualizarLista="getLinhasHabilitadas()" />
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select';
import AdicionarGestorDestino from './modais/AdicionarGestorDestino.vue';
import AdicionarLinhasGestores from './modais/AdicionarLinhasGestores.vue';

export default {
    name: 'SessaoControleIntegracao',

    components: {
        vSelect,
        AdicionarGestorDestino,
        AdicionarLinhasGestores,
    },

    data() {
        return {

            filtros: [{ campo: "gestor_destino", valor: "" }],

            tenant: [],

            adicionarGestorDestino: false,
            adicionarLinhas: false,

            proprio_tenant_origem: [],
            proprio_tenant_destino: [],
            linhas_habilitadas: [],

            linhas_habilitadas_origem: [],
            linhas_habilitadas_destino: [],

        }
    },

    created() {
        this.getTenantId()
    },

    methods: {

        atualizarFiltro(index) {
            this.filtros[index].valor = null;
        },
        adicionarFiltro() {
            this.filtros.push({ campo: "", valor: "" });
        },
        removerFiltro(index) {
            this.filtros.splice(index, 1);
        },

        // getLinhasPorTenantOrigem(tenant_origem_id) {
        //     // console.log('Buscando linhas para tenant origem:', tenant_origem_id);
        //     return this.linhas_habilitadas_origem
        //         .filter(l => l.tenant_origem?.id === tenant_origem_id)
        //         .map(l => l.linhas) // Retorna apenas as linhas corretas
        //         .flat(); // Transforma lista de listas em uma única lista
        // },

        // getLinhasPorTenant(tenant_destino_id) {
        //     // console.log('Buscando linhas para tenant destino:', tenant_destino_id);
        //     return this.linhas_habilitadas_destino
        //         .filter(l => l.tenant_destino?.id === tenant_destino_id)
        //         .map(l => l.linhas) // Retorna apenas as linhas corretas
        //         .flat();
        // },


        async getTenantId() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                // this.$store.dispatch("setLoading", true);

                const hostname = window.location.hostname;
                const partes = hostname.split(".");
                const tenant_name = partes.length >= 2 ? partes[0] : null;

                if (tenant_name === "admin") {
                    this.tenant = { id: 0, nome: "ManagerGlass" };
                    // await this.getProprioSistemaDestino()
                    // await this.getProprioSistemaOrigem()
                    await this.getLinhasHabilitadas()
                } else {
                    const response = await axios.get(`tenant/client/?nome=${tenant_name}`, {
                        headers: { 'Authorization': `Token ${token}` }
                    });

                    if (response.data.results.length > 0) {
                        this.tenant = response.data.results[0];

                        // await this.getProprioSistemaDestino()
                        // await this.getProprioSistemaOrigem()
                        await this.getLinhasHabilitadas()
                    }
                }

            } catch (error) {
                console.error("Erro ao buscar tenant:", error);
            } finally {
                // this.$store.dispatch("setLoading", false);
            }
        },

        async getProprioSistemaDestino() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                // this.$store.dispatch("setLoading", true);


                const response = await axios.get(`tenant/integracao-gestores/?tenant_destino=${this.tenant.id}`, {
                    headers: { 'Authorization': `Token ${token}` }
                });

                // console.log('proprio_tenant_destino ', response.data.results)
                this.proprio_tenant_destino = response.data.results.map((proprio_tenant_destino) => {
                    return {
                        id: proprio_tenant_destino.id,
                        tenant_origem: proprio_tenant_destino.tenant_origem?.name || 'ManagerGlass',
                        tenant_origem_id: proprio_tenant_destino.tenant_origem?.id || 0,
                        tenant_destino_id: proprio_tenant_destino.tenant_destino?.id || 0,
                        tenant_destino: proprio_tenant_destino.tenant_destino?.name || 'ManagerGlass',
                    }
                })


            } catch (error) {
                console.error("Erro ao buscar tenant:", error);
            } finally {
                // this.$store.dispatch("setLoading", false);
            }
        },

        async getProprioSistemaOrigem() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                // this.$store.dispatch("setLoading", true);


                const response = await axios.get(`tenant/integracao-gestores/?tenant_origem=${this.tenant.id}`, {
                    headers: { 'Authorization': `Token ${token}` }
                });

                // console.log('proprio_tenant_origem: ', response.data.results)
                this.proprio_tenant_origem = response.data.results
                    .map((proprio_tenant_origem) => {
                        return {
                            id: proprio_tenant_origem.id,
                            tenant_origem: proprio_tenant_origem.tenant_origem?.name || 'ManagerGlass',
                            tenant_origem_id: proprio_tenant_origem.tenant_origem?.id || 0,
                            tenant_destino: proprio_tenant_origem.tenant_destino?.name || 'ManagerGlass',
                        }
                    })


            } catch (error) {
                console.error("Erro ao buscar tenant:", error);
            } finally {
                // this.$store.dispatch("setLoading", false);
            }
        },

        // async getLinhasHabilitadas() {
        //     try {
        //         const token = await this.$store.dispatch('getAuthTokenCookie');
        //         await this.$store.dispatch('fetchURLrequest');

        // this.$store.dispatch("setLoading", true);


        //         const response = await axios.get(`projeto/linhas-tenant-integrado/?ativo=True&page_size=9999`, {
        //             headers: { 'Authorization': `Token ${token}` }
        //         });

        //         console.log('linhas: ', response.data.results)
        //         this.linhas_habilitadas = response.data.results



        //     } catch (error) {
        //         console.error("Erro ao buscar tenant:", error);
        //     } finally {
        // this.$store.dispatch("setLoading", false);
        //     }
        // },


        async getLinhasHabilitadas() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                // this.$store.dispatch("setLoading", true);

                const response = await axios.get(`projeto/linhas-tenant-integrado/?ativo=True&page_size=9999`, {
                    headers: { 'Authorization': `Token ${token}` }
                });

                console.log('Linhas recebidas:', response.data.results);

                // Filtrando as linhas de acordo com o tenant atual
                const tenantIdAtual = this.tenant.id;
                console.log('tenantIdAtual: ', tenantIdAtual)

                this.linhas_habilitadas_origem = response.data.results.filter(linha =>
                    (linha.tenant_origem?.id || 0) === tenantIdAtual
                );

                console.log('this.linhas_habilitadas_origem: ', this.linhas_habilitadas_origem);

                this.linhas_habilitadas_destino = response.data.results.filter(linha =>
                    (linha.tenant_destino?.id || 0) === tenantIdAtual
                );

                console.log('this.linhas_habilitadas_destino: ', this.linhas_habilitadas_destino);


                console.log('Linhas onde sou ORIGEM:', this.linhas_habilitadas_origem);
                console.log('Linhas onde sou DESTINO:', this.linhas_habilitadas_destino);

            } catch (error) {
                console.error("Erro ao buscar tenant:", error);
            } finally {
                // this.$store.dispatch("setLoading", false);
            }
        },

        toggleGestorDestino() {
            this.adicionarGestorDestino = !this.adicionarGestorDestino
        },

        toggleAdicionarLinhas() {
            this.adicionarLinhas = !this.adicionarLinhas
        }
    }

}
</script>

<style scoped></style>