<template>
    <div>
        <div class="fixed inset-0 bg-black/60 flex items-center justify-center z-[10000]">
            <div
                class="bg-white rounded-lg shadow-lg p-6 w-[90%] sm:w-[600px] max-h-[80vh] overflow-y-auto transform transition-transform duration-300 ease-out scale-95 sm:scale-100">
                <!-- Cabeçalho do Modal -->
                <div class="flex justify-between items-center mb-4 border-b pb-2">
                    <h2 class="text-lg font-bold text-red-600">Lista de Contatos</h2>
                    <button @click.prevent="$emit('fecharModal')"
                        class="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 ease-in-out hover:bg-gray-100">
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>

                <div v-if="lista_telefones.length > 0">
                    <div v-for="telefone in lista_telefones" :key="telefone.id" class="border-b last:border-none py-3">
                        <!-- {{ telefone }} -->

                        <!-- Dados do Telefone -->
                        <p class="text-base text-gray-700">
                            <span class="font-semibold">Telefone:</span>
                            ({{ telefone.ddd }}) {{ telefone.telefone || 'Sem telefone' }}
                        </p>
                        <div class="text-base text-gray-700 flex">
                            <span class="font-semibold">Whatsapp:</span>
                                <i v-if="telefone.whatsapp" class="fa-solid fa-circle-check  text-ativo ml-1"></i>
                                <i v-else class="fa-solid fa-circle-xmark  text-inativo ml-1"></i>
                        </div>
                        <div class="text-base text-gray-700 flex">
                            <span class="font-semibold">Telegram:</span>
                                <i v-if="telefone.telegram" class="fa-solid fa-circle-check  text-ativo ml-1"></i>
                                <i v-else class="fa-solid fa-circle-xmark  text-inativo ml-1"></i>
                        </div>
                        <p class="text-sm text-gray-500 mt-1">
                            <span class="font-semibold">Tipo de Telefone:</span>
                            {{ telefone.tipo_telefone?.descricao || "Não especificado" }}
                        </p>
                    </div>
                </div>
                <div v-else>
                    <p>Sem telefone cadastrado.</p>
                </div>

                <hr>

                <div v-if="lista_emails.length > 0">
                    <div v-for="email in lista_emails" :key="email.id" class="border-b last:border-none py-3">
                        <!-- Dados do Email -->
                        <p class="text-base text-gray-700">
                            <span class="font-semibold">Email:</span>
                            {{ email.email_pessoa }}
                        </p>
                        <p class="text-sm text-gray-500 mt-1">
                            <span class="font-semibold">Tipo de Email:</span>
                            {{ email.tipo_email?.descricao || "Não especificado" }}
                        </p>
                    </div>
                </div>
                <div v-else>
                    <p>Sem email cadastrado.</p>
                </div>

                <hr>

                <!-- Lista de Interações -->
                <div v-if="lista_interacoes.length > 0">
                    <div v-for="interacao in lista_interacoes" :key="interacao.id"
                        class="border-b last:border-none py-3">
                        <!-- Dados da Interação Online -->
                        <p class="text-base text-gray-700">
                            <span class="font-semibold mr-2">Endereço da Interação:</span>
                            <a :href="interacao.endereco_interacao" class="text-blue-500" target="_blank"
                                rel="noopener noreferrer">
                                {{ interacao.endereco_interacao }}
                            </a>
                        </p>
                        <p class="text-sm text-gray-500 mt-1">
                            <span class="font-semibold">Tipo de Interação:</span>
                            {{ interacao.tipo_interacao?.descricao || "Não especificado" }}
                        </p>
                    </div>
                </div>
                <div v-else>
                    <p>Sem interação online cadastrada.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "ListarEnderecos",
    props: ['pessoa'],
    emits: ['fecharModal'],

    data() {
        return {
            lista_interacoes: [],
            lista_emails: [],
            lista_telefones: [],
        }
    },


    created() {
        this.getInteracoes()
        this.getEmails()
        this.getTelefones()
    },

    methods: {
        async getInteracoes() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`pessoas/interacoes/?pessoa=${this.pessoa}`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                })

                this.lista_interacoes = response.data.results
                console.log(this.lista_interacoes)
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getEmails() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`pessoas/email/?pessoa=${this.pessoa}`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                })

                this.lista_emails = response.data.results
                console.log(this.lista_emails)
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getTelefones() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`pessoas/telefones/?pessoa=${this.pessoa}`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                })

                this.lista_telefones = response.data.results
                console.log(this.lista_telefones)
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        }
    }
};
</script>

<style scoped></style>