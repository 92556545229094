<template>
    <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
        <div class="bg-white p-6 md:p-8 rounded-lg shadow-lg w-full sm:w-full md:w-2/3 lg:w-1/3 max-w-lg">
            <div class="border-b mb-4">
                <h2 class="text-2xl font-semibold text-gray-800 text-center">Alterar Status Vãos Orçamento</h2>
            </div>

            <div class="space-y-4">

                <div class="w-full flex gap-2">

                    <div class="text-center flex flex-col items-center w-full">
                        <label class="block text-sm font-medium text-corBase mb-1">Status:</label>
                        <v-select :options="lista_status" label="label" placeholder="Escolha uma opção" v-model="ativo"
                            :reduce="status => status.value" class="w-full" />
                    </div>
                </div>

            </div>

            <!-- Botões -->
            <div class="mt-6 flex justify-center flex-wrap space-x-4">
                <button class="btn-cancelar" @click.prevent="$emit('fecharModal')">
                    Fechar
                </button>
                <button class="btn-salvar" @click="salvarAlteracoes()">
                    Salvar Alterações
                </button>
            </div>
            <SucessoRapido v-if="sucesso" mensagem="Itens do Orçamento atualizados com sucesso!" />
            <ErroRapido v-if="erro" mensagem="Ocorreu um erro ao atualizar os Itens do Orçamento." />
        </div>
    </div>

</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';

export default {
    name: 'AlterarStatusItemOrcamento',
    data() {
        return {
            ativo: null,

            lista_status: [
                { label: 'Ativo', value: true },
                { label: 'Inativo', value: false },
            ],

            sucesso: false,
            erro: false,
        };
    },

    components: {
        vSelect,
        SucessoRapido,
        ErroRapido,
    },

    props: ['itensOrcamento'],

    emits: ['fecharModal', 'atualizarLista'],

    created() {
    },

    methods: {

        async salvarAlteracoes() {
            try {

                this.$store.dispatch("setLoading", true);

                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                const dadosParaAtualizar = this.itensOrcamento.map(itemOrcamento => ({
                    id: itemOrcamento.id,
                    status: this.ativo,
                }));

                if (dadosParaAtualizar.length === 0) {
                    console.warn("Nenhum item para atualizar.");
                    return;
                }

                const response = await axios.patch(
                    `orcamentos/item-orcamento/atualizar-itens/`,
                    { itens: dadosParaAtualizar },
                    {
                        headers: {
                            'Authorization': `Token ${token}`,
                        },
                    }
                );

                console.log("Itens atualizados com sucesso:", response.data);

                // Exibe mensagem de sucesso
                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('fecharModal');
                    this.$emit('atualizarLista');
                }, 3000);

            } catch (error) {
                console.error("Erro ao atualizar itens do orçamento:", error);
                this.erro = true;
                setTimeout(() => {
                    this.erro = false;
                }, 3000);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

    },
};
</script>

<style scoped>
.input-campos {
    @apply block w-full p-2 border rounded-md text-corBase;
}
</style>