<template>
  <section class=" flex justify-center md:block md:justify-end md:mr-10">

    <div class="container-formulario">
      <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
      <div class="cabecalho-formulario">
        <h1 class="text-xl text-corBase font-bold">Alterar Item do Orçamento:</h1>

        <router-link :to="`/orcamento/${orcamentoId}`" class="text-xl text-corBase font-bold">
          <i class="fas fa-chevron-left"></i>
        </router-link>
      </div>

      <div class="w-full block justify-center mb-8">
        <div class="flex flex-wrap gap-2 justify-center">

          <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
            opcoes.dados
              ? 'bg-screenCor text-corBase border border-corBase'
              : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
            @click.prevent="ativaDados()">
            Dados
          </button>

          <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
            opcoes.perfis
              ? 'bg-screenCor text-corBase border border-corBase'
              : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
            v-if="dadosProjeto.habilitar_produto_perfil" @click.prevent="ativaPerfis()">
            Perfis
          </button>

          <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
            opcoes.componentes
              ? 'bg-screenCor text-corBase border border-corBase'
              : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
            v-if="dadosProjeto.habilitar_produto_componente" @click.prevent="ativaComponentes()">
            Componentes
          </button>

          <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
            opcoes.vidros
              ? 'bg-screenCor text-corBase border border-corBase'
              : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
            v-if="dadosProjeto.habilitar_produto_vidro" @click.prevent="ativaVidros()">
            Vidros
          </button>

          <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
            opcoes.chapas_telas
              ? 'bg-screenCor text-corBase border border-corBase'
              : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
            v-if="dadosProjeto.habilitar_produto_perfil" @click.prevent="ativaChapasTelas()">
            Chapas/Telas
          </button>

          <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
            opcoes.servicos
              ? 'bg-screenCor text-corBase border border-corBase'
              : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
            @click.prevent="ativaServicos()">
            Serviços
          </button>

          <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
            opcoes.variaveis
              ? 'bg-screenCor text-corBase border border-corBase'
              : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
            @click.prevent="ativaVariaveis()">
            Variáveis
          </button>

          <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
            opcoes.definicoes
              ? 'bg-screenCor text-corBase border border-corBase'
              : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
            @click.prevent="ativaDefinicoes()">
            Definições
          </button>

          <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
            opcoes.formulas
              ? 'bg-screenCor text-corBase border border-corBase'
              : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
            @click.prevent="ativaFormulas()">
            Fórmulas
          </button>

        </div>
      </div>

      <!-- <div class="nav-principal-projetos pt-6 pb-6 justify-around">
        <button @click.prevent="ativaPerfis()">
          Perfis
        </button>

        <button @click.prevent="ativaComponentes()">
          Componentes
        </button>

        <button @click.prevent="ativaVidros()">
          Vidros
        </button>

        <button @click.prevent="ativaServicos()">
          Serviços
        </button>

        <button @click.prevent="ativaVariaveis()">
          Variáveis
        </button>

        <button @click.prevent="ativaFormulas()">
          Formulas
        </button>
      </div> -->

      <AlterarDadosItemOrcamento v-if="opcoes.dados" :itemOrcamento="itemOrcamento" :dadosProjeto="dadosProjeto"
      :dadosAntigos="dadosAntigos"  @atualizarItemOrcamento="getItemOrcamento()" />

      <AlterarPerfilOrcamento v-if="opcoes.perfis" :itemOrcamentoId="itemOrcamento.id" />

      <AlterarComponenteOrcamento v-if="opcoes.componentes" :itemOrcamentoId="itemOrcamento.id" />

      <AlterarVidroOrcamento v-if="opcoes.vidros" :itemOrcamentoId="itemOrcamento.id" :dadosProjeto="dadosProjeto" />

      <AlterarChapasTelasOrcamento v-if="opcoes.chapas_telas" :itemOrcamentoId="itemOrcamento.id" />

      <AlterarServicoOrcamento v-if="opcoes.servicos" :servicos="servicos" />

      <AlterarVariaveisOrcamento v-if="opcoes.variaveis" :itemOrcamentoId="itemOrcamento.id" :itemOrcamento="itemOrcamento" />

      <AlterarDefinicoesOrcamento v-if="opcoes.definicoes" :itemOrcamentoId="itemOrcamento.id" />

      <AlterarFormulasOrcamento v-if="opcoes.formulas" :itemOrcamentoId="itemOrcamento.id" />
    </div>

  </section>
</template>

<script>
import axios from 'axios'
import AlterarDadosItemOrcamento from './AlterarDadosItemOrcamento.vue'
import AlterarPerfilOrcamento from './produtos/AlterarPerfilOrcamento.vue'
import AlterarComponenteOrcamento from './produtos/AlterarComponenteOrcamento.vue'
import AlterarVidroOrcamento from './produtos/AlterarVidroOrcamento.vue'
import AlterarChapasTelasOrcamento from './produtos/AlterarChapasTelasOrcamento.vue'
import AlterarServicoOrcamento from './AlterarServicoOrcamento.vue'
import AlterarVariaveisOrcamento from './calculos/AlterarVariaveisOrcamento.vue'
import AlterarDefinicoesOrcamento from './calculos/AlterarDefinicoesOrcamento.vue'
import AlterarFormulasOrcamento from './calculos/AlterarFormulasOrcamento.vue'

export default {
  name: 'AlterarItemOrcamento',

  data() {
    return {
      orcamentoId: '',
      itemOrcamentoId: this.$route.params.id,

      dadosProjeto: [],

      dadosAntigos: {},

      perfis: [],
      componentes: [],
      vidros: [],
      servicos: [],
      variaveis: [],
      formulas: [],
      itemOrcamento: {},

      opcoes: {}
    }
  },

  components: {
    AlterarDadosItemOrcamento,
    AlterarPerfilOrcamento,
    AlterarComponenteOrcamento,
    AlterarVidroOrcamento,
    AlterarChapasTelasOrcamento,
    AlterarServicoOrcamento,
    AlterarVariaveisOrcamento,
    AlterarDefinicoesOrcamento,
    AlterarFormulasOrcamento,
  },

  created() {

    this.getItemOrcamento()

    this.opcoes = {
      dados: true,
      perfis: false,
      componentes: false,
      vidros: false,
      chapas_telas: false,
      servicos: false,
      variaveis: false,
      definicoes: false,
      formulas: false,
    }
  },

  methods: {

    desativaTodasOpcoes() {
      this.opcoes.dados = false
      this.opcoes.perfis = false
      this.opcoes.componentes = false
      this.opcoes.vidros = false
      this.opcoes.chapas_telas = false
      this.opcoes.servicos = false
      this.opcoes.variaveis = false
      this.opcoes.definicoes = false
      this.opcoes.formulas = false
    },

    ativaDados() {
      this.desativaTodasOpcoes()

      this.opcoes.dados = true
    },

    ativaPerfis() {
      this.desativaTodasOpcoes()

      this.opcoes.perfis = true
    },

    ativaComponentes() {
      this.desativaTodasOpcoes()

      this.opcoes.componentes = true
    },

    ativaVidros() {
      this.desativaTodasOpcoes()

      this.opcoes.vidros = true
    },

    ativaChapasTelas() {
      this.desativaTodasOpcoes()

      this.opcoes.chapas_telas = true
    },

    ativaServicos() {
      this.desativaTodasOpcoes()

      this.opcoes.servicos = true
    },

    ativaVariaveis() {
      this.desativaTodasOpcoes()

      this.opcoes.variaveis = true
    },

    ativaDefinicoes() {
      this.desativaTodasOpcoes()

      this.opcoes.definicoes = true
    },

    ativaFormulas() {
      this.desativaTodasOpcoes()

      this.opcoes.formulas = true
    },

    async getItemOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        this.$store.dispatch("setLoading", true);

        const response = await axios.get(`orcamentos/item-orcamento/${this.itemOrcamentoId}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })

        console.log(response.data)

        this.itemOrcamento = response.data
        this.orcamentoId = this.itemOrcamento.orcamento.id

        this.dadosAntigos = {
          largura: this.itemOrcamento.largura,
          altura: this.itemOrcamento.altura,
          cor_perfis: this.itemOrcamento.cor_perfis?.id || '',
          cor_componentes: this.itemOrcamento.cor_componentes?.id || '',
          contramarco: this.itemOrcamento.contramarco,
          arremate: this.itemOrcamento.arremate
        }

        this.getDadosProjetoItemOrcamento()
        // this.getPerfisOrcamento()
        // this.getComponentesOrcamento()
        // this.getVidrosOrcamento()
        this.getServicosOrcamento()
        // this.getFormulasOrcamento()
        // this.getVariaveisOrcamento()

      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    async getDadosProjetoItemOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        this.$store.dispatch("setLoading", true);

        const response = await axios.get(`orcamentos/dados-projeto-item-orcamento/?item_orcamento=${this.itemOrcamentoId}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })

        console.log(response.data.results[0])

        this.dadosProjeto = response.data.results[0]
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    // async getPerfisOrcamento() {
    //   try {
    //     const token = await this.$store.dispatch('getAuthTokenCookie')
    //     await this.$store.dispatch('fetchURLrequest')

    //     const response = await axios.get(`orcamentos/perfis-orcamento/?item_orcamento_id=${this.itemOrcamento.id}&page_size=9999`, {
    //       headers: {
    //         Authorization: `Token ${token}`,
    //       },
    //     })

    //     this.perfis = response.data.results

    //   } catch (error) {
    //     console.error(error)
    //   }
    // },

    // async getComponentesOrcamento() {
    //   try {
    //     const token = await this.$store.dispatch('getAuthTokenCookie')
    //     await this.$store.dispatch('fetchURLrequest')

    //     const response = await axios.get(`orcamentos/componentes-orcamento/?item_orcamento_id=${this.itemOrcamento.id}&page_size=9999`, {
    //       headers: {
    //         Authorization: `Token ${token}`,
    //       },
    //     })

    //     this.componentes = response.data.results

    //   } catch (error) {
    //     console.error(error)
    //   }
    // },

    async getVidrosOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`orcamentos/vidros-orcamento/?item_orcamento_id=${this.itemOrcamento.id}&page_size=9999`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })

        this.vidros = response.data.results

      } catch (error) {
        console.error(error)
      }
    },

    async getServicosOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`orcamentos/servicos-orcamento/?item_orcamento_id=${this.itemOrcamento.id}&page_size=9999`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })

        this.servicos = response.data.results

      } catch (error) {
        console.error(error)
      }
    },

    // async getFormulasOrcamento() {
    //   try {
    //     const token = await this.$store.dispatch('getAuthTokenCookie')
    //     await this.$store.dispatch('fetchURLrequest')

    //     const response = await axios.get(`orcamentos/formula-orcamento/?item_orcamento_id=${this.itemOrcamento.id}&page_size=9999`, {
    //       headers: {
    //         Authorization: `Token ${token}`,
    //       },
    //     })

    //     this.formulas = response.data.results

    //   } catch (error) {
    //     console.error(error)
    //   }
    // },

    async getVariaveisOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`orcamentos/variavel-local-orcamento/?item_orcamento_id=${this.itemOrcamento.id}&page_size=9999`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })

        this.variaveis = response.data.results

      } catch (error) {
        console.error(error)
      }
    },
  }
}

</script>

<style scoped lang="scss"></style>
