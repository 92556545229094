<template>
    <div class="container-modal-editar">
        <div class="sombra-modal">
            <div class="conteudo-modal-editar">
                <div class="flex justify-end">
                    <button @click.prevent="this.$emit('fecharModal')">
                        <i class="fas fa-close text-2xl text-corBase"></i>
                    </button>
                </div>

                <div>
                    <div class="cabecalho-formulario">
                        <h1 class="text-xl text-corBase font-bold">Projetos:</h1>
                    </div>

                    <div class="flex gap-4 mb-6 items-end w-[100%]">
                        <div class="w-full">
                            <label class="text-corBase">Sistemista</label>
                            <v-select v-model="sistemista" class="bg-white" :options="lista_sistemista" label="text"
                                :reduce="(option) => option.id">
                                <template #no-options>
                                    <span>
                                        {{ gestorAtual ? 'Nenhum sistemista encontrado' : 'Selecionar Gestor' }}
                                    </span>
                                </template>
                            </v-select>
                        </div>
                        <div class="w-full">
                            <label class="text-corBase">Linha</label>
                            <v-select v-model="linha" class="bg-white" :options="lista_linha" label="text"
                                :reduce="(option) => option.id">
                                <template #no-options>
                                    <span>
                                        {{ sistemista ? 'Nenhuma linha encontrada para este sistemista.' :
                                            'Selecionar Sistemista' }}
                                    </span>
                                </template>
                            </v-select>
                        </div>
                        <div class="w-full">
                            <label class="text-corBase">Modelo</label>
                            <v-select v-model="modelo" class="bg-white" :options="lista_modelo" label="text"
                                :reduce="(option) => option.id" @open="fetchModelos">
                                <!-- @open="checkPreconditions" -->
                                <template #no-options>
                                    <span>
                                        {{ sistemista && linha ? 'Nenhum modelo encontrado.' :
                                            'Selecionar Sistemista e Linha' }}
                                    </span>
                                </template>
                            </v-select>
                        </div>
                        <div class="w-full">
                            <label class="text-corBase">Código ou descrição</label>
                            <input type="text" class="input-form-chapa" v-model="filtro_base">
                        </div>
                    </div>

                    <div class="flex flex-wrap sm:flex-nowrap w-full justify-between items-center mt-6 gap-4">
                        <div class="flex flex-wrap sm:flex-nowrap items-center gap-3 w-full sm:w-auto">

                            <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
                text-white hover:text-corBase py-2 px-4" @click.prevent="getListaProjetos()">
                                Pesquisar
                            </button>

                        </div>
                    </div>

                    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-4 px-4">
                        <div v-for="(projeto, index) in lista_projetos" :key="index"
                            class="border border-gray-300 rounded-lg shadow-md p-4 flex flex-col items-center bg-white">

                            <!-- CABEÇALHO DO CARD -->
                            <!-- <div class="w-full flex justify-between items-center border-b pb-2 mb-2">
                                <input type="checkbox" class="w-5 h-5 cursor-pointer">
                                <p class="text-xs font-bold text-gray-700">
                                    {{ projeto.gestor?.name.toUpperCase() || 'MANAGERGLASS' }}
                                </p>
                                <div class="flex bg-corBase divide-x rounded">
                                    <button><i class="fas fa-wrench text-sm text-white px-2 py-1"></i></button>
                                    <button @click.prevent="togglePrivado(projeto)">
                                        <i :class="{
                                            'fas fa-lock text-sm text-white px-2 py-1': projeto.privado,
                                            'fas fa-unlock text-sm text-white px-2 py-1': !projeto.privado
                                        }"></i>
                                    </button>
                                </div>
                            </div> -->

                            <!-- IMAGEM DO PROJETO -->
                            <div class="w-full flex flex-col items-center">
                                <img :src="projeto.imagem?.url_documento" v-if="projeto.imagem?.url_documento"
                                    alt="Imagem de Capa" class="w-full h-40 object-cover rounded-md cursor-pointer"
                                    @click.prevent="openPreviewImage(`${projeto.imagem?.url_documento}`)" />
                                <img v-else
                                    src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                                    alt="Sem foto" class="w-full h-40 object-cover rounded-md">
                            </div>

                            <!-- INFORMAÇÕES DO PROJETO -->
                            <div class="text-center text-sm py-3 w-full">
                                <p><span class="font-semibold">Código:</span> {{ projeto.codigo }}</p>
                                <p><span class="font-semibold">Nome:</span> {{ projeto.descricao_fixa }}</p>
                                <p><span class="font-semibold">Modelo:</span> {{ projeto.modelo_variacao?.descricao ||
                                    'Sem modelo' }}</p>
                                <p><span class="font-semibold">Sistemista:</span> {{ projeto.sistemista?.nome ||
                                    'Sem Sistemista' }}</p>
                                <p><span class="font-semibold">Linha:</span> {{ projeto.linha_sistema?.linha ||
                                    'Sem Linha' }}</p>
                                <p><span class="font-semibold">Tipologia:</span> {{ projeto.tipologia?.nome ||
                                    'Sem tipologia' }}</p>
                                <p><span class="font-semibold">Status:</span> {{ projeto.status_projeto?.descricao ||
                                    'Sem status' }}</p>
                                <p>
                                    <span class="font-semibold">Privado:</span>
                                    <i v-if="projeto.privado" class="fa-solid fa-circle-check text-ativo ml-2"></i>
                                    <i v-else class="fa-solid fa-circle-xmark text-inativo ml-2"></i>
                                </p>
                            </div>

                            <!-- BOTÃO DE AÇÃO -->
                            <div class="flex justify-center w-full mt-2">
                                <button @click.prevent="selecionarProjeto(projeto)"
                                    class="bg-corBase text-white text-xs text-center px-4 py-2 rounded-lg">
                                    Selecionar Projeto
                                </button>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>


    <PreviewImage v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />

    <SelecionarVariaveisImportar v-if="exibirVariaveisProjeto" :projetoSelecionado="projetoSelecionado"
        @fecharModal="$emit('fecharModal')" @atualizarLista="$emit('atualizarLista')" />

    <ListagemErros v-if="exibirErros === true" :erros="erros" @fecharModal="toggleErros()" />

    <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />

</template>

<script>
import axios from 'axios'
import PreviewImage from '@/components/midais/ged/PreviewImage.vue'
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import SelecionarVariaveisImportar from './SelecionarVariaveisImportar.vue';
import vSelect from 'vue-select';

export default {
    name: 'SelecionarProjetoImportarVariaveis',

    data() {
        return {
            gestorAtual: '',

            lista_projetos: [],

            sistemista: '',
            linha: '',
            modelo: '',
            filtro_base: '',

            preview_imagem: null,

            projetoSelecionado: null,
            exibirVariaveisProjeto: false,

            erros: [],
            exibirErros: false,

            sucesso: false,
            sucessoMessage: '',

            checkAll: false,
        }
    },

    emits: ['fecharModal', 'atualizarLista'],

    components: {
        PreviewImage,
        ListagemErros,
        SucessoRapido,
        SelecionarVariaveisImportar,
        vSelect,
    },

    computed: {

        checkSome() {
            return this.opcoesVariavel.some(opcao => opcao.selected)
        },
    },

    created() {
        this.definirGestorAtual()
    },

    methods: {

        definirGestorAtual() {
            const host = window.location.hostname; // Captura o domínio
            const partes = host.split('.'); // Separa pelo ponto

            if (partes.length >= 2) {
                this.gestorAtual = partes[0];

                if (this.gestorAtual == 'admin') {
                    this.gestorAtual = 'ManagerGlass'
                }
            }

            console.log(this.gestorAtual)
            this.getListaProjetos();
        },

        async getListaProjetos() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest')
                this.$store.dispatch("setLoading", true);

                const response = await axios.get(`/projeto/projeto/?sistemista=${this.sistemista || ''}&modelo=${this.modelo || ''}&linha=${this.linha || ''}&filtro_base=${this.filtro_base}&gestor=${this.gestorAtual || ''}&page_size=9999`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                })

                this.lista_projetos = response.data.results

            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },


        selecionarProjeto(projeto) {
            this.projetoSelecionado = projeto
            console.log('this.projetoselecionado selecionar: ', this.projetoSelecionado)
            this.toggleVariaveisProjeto()
        },

        toggleVariaveisProjeto() {
            this.exibirVariaveisProjeto = !this.exibirVariaveisProjeto
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },

        closed_preview_image() {
            this.preview_imagem = ''
        },

        openPreviewImage(url) {
            this.preview_imagem = url
        },
    }
}

</script>

<style scoped lang="scss"></style>