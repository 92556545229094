<template>
    <div>
        <div class="">
            <div class="grid grid-cols-1 lg:grid-cols-4 gap-6">
                

                <!-- CEP -->
                <div class="info-card">
                    <span class="info-title">CEP:</span>
                    <span class="info-value">{{ orcamento.obra?.cep || '-' }}</span>
                </div>

                <!-- Logradouro -->
                <div class="info-card">
                    <span class="info-title">Logradouro:</span>
                    <span class="info-value">{{ orcamento.obra?.endereco || '-' }}</span>
                </div>

                <!-- Número -->
                <div class="info-card">
                    <span class="info-title">Número:</span>
                    <span class="info-value">{{ orcamento.obra?.numero || '-' }}</span>
                </div>

                <!-- Prédio/Condomínio -->
                <div class="info-card">
                    <span class="info-title">Prédio/Condomínio:</span>
                    <span class="info-value">{{ orcamento.obra?.predio_condominio || '-' }}</span>
                </div>

                <!-- Complemento -->
                <div class="info-card">
                    <span class="info-title">Complemento:</span>
                    <span class="info-value">{{ orcamento.obra?.complemento || '-' }}</span>
                </div>

                <!-- Bairro -->
                <div class="info-card">
                    <span class="info-title">Bairro:</span>
                    <span class="info-value">{{ orcamento.obra?.bairro || '-' }}</span>
                </div>

                <!-- Estado -->
                <div class="info-card">
                    <span class="info-title">Estado:</span>
                    <span class="info-value">{{ orcamento.obra?.estado || '-' }}</span>
                </div>

                <!-- Cidade -->
                <div class="info-card">
                    <span class="info-title">Cidade:</span>
                    <span class="info-value">{{ orcamento.obra?.cidade || '-' }}</span>
                </div>
            </div>
        </div>

        <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />
    </div>
</template>

<script>
// import ModalListagemEnderecos from '@/views/obras/EtapasCriacaoObra/Modais/ModalListagemEnderecos.vue'
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';
import axios from 'axios'
import vSelect from 'vue-select';

export default {
    name: 'DadosOrcamento',

    components: {
        // ModalListagemEnderecos,
        ListagemErros,
        vSelect,
    },

    props: ['orcamento'],

    data() {
        return {
            enderecoCliente: [],
            exibirEnderecos: false,

            searchQuery: "",
            buscando: false,

            estados: [],
            cidades: [],
            // listaStatus: ['Orçamento', 'Agendado', 'Venda', 'Faturado', 'Cancelado'],
            lista_obras: [],

            exibirErros: false,
            erros: [],
        }
    },

    // watch: {
    //     'orcamento.estado': {
    //         handler(novoEstado) {
    //             if (novoEstado) {
    //                 this.buscarCidades(novoEstado);
    //             }
    //         },
    //         immediate: false
    //     }
    // },

    created() {
        // this.getListaObras()
        // this.carregarEstados()
    },

    methods: {

        // async carregarEstados() {
        //     try {
        //         const token = await this.$store.dispatch("getAuthTokenCookie");
        //         await this.$store.dispatch("fetchURLrequest");
        //         const response = await axios.get(`obra/estados/`, {
        //             headers: {
        //                 "Authorization": `Token ${token}`
        //             }
        //         });

        //         this.estados = response.data.map((estado) => ({
        //             sigla: estado.sigla,
        //             nome: `${estado.nome} (${estado.sigla})`,
        //         }))

        //     } catch (error) {
        //         console.error("Erro ao carregar estados:", error);
        //     }
        // },

        // async buscarCidades(estado) {
        //     try {
        //         const token = await this.$store.dispatch("getAuthTokenCookie");
        //         await this.$store.dispatch("fetchURLrequest");
        //         const response = await axios.get(`obra/municipios/?estado=${estado}`, {
        //             headers: {
        //                 "Authorization": `Token ${token}`
        //             }
        //         });
        //         this.cidades = response.data.map((cidade) => ({
        //             id: cidade.id,
        //             nome: cidade.nome,
        //         }))

        //     } catch (error) {
        //         console.error("Erro ao carregar cidades:", error);
        //     }
        // },

        // async buscarEndereco() {
        //     const cep = this.cep.replace(/\D/g, '');
        //     if (cep.length === 8) {
        //         try {
        //             const token = this.$store.state.token;
        //             await this.$store.dispatch('fetchURLrequest');
        //             this.$store.dispatch("setLoading", true);
        //             const response = await axios.get(`cep/${cep}`, {
        //                 headers: {
        //                     'Authorization': `Token ${token}`
        //                 }
        //             });
        //             if (!response.data.erro) {
        //                 this.endereco = response.data.logradouro;
        //                 this.bairro = response.data.bairro;
        //                 this.cidade = response.data.localidade;
        //                 this.estado = response.data.uf;
        //             }
        //         } catch (error) {
        //             console.error(error)
        //         } finally {
        //             this.$store.dispatch("setLoading", false);
        //         }
        //     }
        // },

        async handleSearchObra(searchTerm) {
            this.searchQuery = searchTerm; // Atualiza o termo de busca

            if (!searchTerm || searchTerm.length < 3) {
                this.lista_obras = []; // Limpa a lista se não atender o critério
                return;
            }

            try {

                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                this.buscando = true
                // this.$store.dispatch("setLoading", true);

                const response = await axios.get(`obra/?cliente=${this.orcamento?.cliente?.id}&descricao=${searchTerm}&page_size=9999`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                this.lista_obras = response.data.results;
            } catch (error) {
                console.error("Erro ao buscar obras:", error);
            } finally {
                this.buscando = false
                // this.$store.dispatch("setLoading", false);
            }
        },

        toggleModalEnderecos() {
            this.exibirEnderecos = !this.exibirEnderecos;
        },

        async buscarEnderecoCliente() {
            if (!this.orcamento.cliente) {
                this.erros = { cliente: 'Selecione um cliente para buscar o endereço.' }
                this.toggleErros()
                return
            }
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`pessoas/enderecos/?pessoa=${this.orcamento.cliente.id}`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                console.log(response.data.count)

                if (response.data.count == 1) {
                    this.enderecoCliente = response.data.results[0]
                    this.orcamento.cep = this.enderecoCliente.cep
                    this.orcamento.endereco = this.enderecoCliente.logadouro
                    this.orcamento.numero = this.enderecoCliente.numero
                    this.orcamento.complemento = this.enderecoCliente.complemento
                    this.orcamento.bairro = this.enderecoCliente.bairro
                    this.orcamento.estado = this.enderecoCliente.uf
                    this.orcamento.cidade = this.enderecoCliente.cidade
                } else if (response.data.count > 1) {
                    this.enderecoCliente = response.data.results
                    this.toggleModalEnderecos()
                } else {
                    this.erros = { endereço: ["O Cliente selecionado não possui um endereço cadastrado."] };
                    this.toggleErros()
                    return
                }
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch('setLoading', false)
            }
        },

        enderecoSelecionado(endereco) {
            this.orcamento.cep = endereco.cep
            this.orcamento.endereco = endereco.logadouro
            this.orcamento.numero = endereco.numero
            this.orcamento.complemento = endereco.complemento
            this.orcamento.bairro = endereco.bairro
            this.orcamento.estado = endereco.uf
            this.orcamento.cidade = endereco.cidade
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },

    }
}
</script>

<style scoped>
/* Estilizando os cartões de informações */
.info-card {
    @apply flex flex-col bg-gray-50 p-3 rounded-lg border border-gray-200 shadow-sm;
}

.info-title {
    @apply text-sm font-semibold text-gray-600;
}

.info-value {
    @apply text-base font-medium text-gray-800 mt-1;
}

</style>