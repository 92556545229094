<template>
    <div>
        <div class="fixed inset-0 bg-black/60 flex items-center justify-center z-[9998]">
            <div class="bg-white rounded-lg max-w-[90%] shadow-lg p-6 max-h-[80vh] overflow-y-auto ">
                <!-- Cabeçalho do modal -->
                <div class="flex justify-between items-center mb-4 border-b pb-2">
                    <h2 class="text-lg font-bold text-orange-600">Habilitar Linhas</h2>
                    <button @click.prevent="$emit('fecharModal')"
                        class="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 ease-in-out hover:bg-gray-100">
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>

                <!-- Filtros -->
                <div class="mb-4">
                    <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
                        <div class="px-2 w-1/2">
                            <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
                                <option value="linha">Linha</option>
                                <option value="projetista_sistemista_id">Sistemista</option>
                            </select>
                        </div>

                        <div class="w-full">
                            <v-select v-if="filtro.campo === 'projetista_sistemista_id'" v-model="filtro.valor"
                                :options="lista_sistemistas" label="nome" :reduce="linha => linha.id"
                                placeholder="Escolha uma opção" />

                            <input v-else v-model="filtro.valor" type="text" class="input-form-chapa"
                                placeholder="Digite o desejado" />
                        </div>

                        <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
                    </div>

                    <div class="flex w-full justify-center">
                        <button class="text-ativo" @click.prevent="adicionarFiltro">
                            <i class="fas fa-plus-circle"></i>
                        </button>
                    </div>
                </div>

                <!-- Botões de ação -->
                <div class="flex flex-wrap gap-2 mb-6">
                    <button
                        class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase text-white hover:text-corBase py-2 px-4"
                        @click.prevent="mudarPagina(1)">
                        Pesquisar
                    </button>

                    <button @click.prevent="habilitarLinhas()"
                        class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500 text-white hover:text-yellow-500 py-2 px-4">
                        Importar
                    </button>
                </div>

                <!-- Tabela de importação -->
                <div class="container-modelo-tabela">
                    <table class="container-tabela overflow-auto">
                        <thead class="cabecalho-tabela">
                            <tr class="border-b">
                                <th class="w-4 p-4">
                                    <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
                                </th>
                                <th class="px-6 py-3">#</th>
                                <th class="px-10 py-3">Linha</th>
                                <th class="px-16 py-3">Sistemista</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(produto, index) in lista_linhas" :key="produto.id"
                                class="border-b hover:bg-gray-50">
                                <td class="w-4 p-4">
                                    <input type="checkbox" class="checkbox" v-model="produto.selected">
                                </td>
                                <th
                                    class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                                    {{ index + 1 }}
                                </th>
                                <td class="px-10 py-3 whitespace-nowrap">{{ produto.linha || '-' }}</td>
                                <td class="px-16 py-3 whitespace-nowrap">{{ produto.projetistasistemistas?.nome || '-'
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <Pagination v-if="lista_linhas.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
                        @change-page="mudarPagina" />
                </div>

            </div>
            <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />
        </div>
    </div>
</template>

<script>
import axios from "axios"
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import vSelect from "vue-select";
import Pagination from "@/utils/Pagination.vue";

export default {
    name: 'HabilitarLinhas',

    emits: ['fecharModal', 'atualizarLista'],

    components: {
        ListagemErros,
        SucessoRapido,
        vSelect,
        Pagination,
    },

    data() {
        return {
            checkAll: false,

            filtros: [{ campo: "linha", valor: "" }],

            lista_linhas: [],

            lista_sistemistas: [],

            exibirErros: false,
            erros: [],

            paginaAtual: 1,
            countItens: '',

            sucesso: false,
            sucessoMessage: '',
        }
    },

    computed: {
        checkSome() {
            return this.lista_linhas.some(produto => produto.selected)
        }
    },

    created() {
        this.getListaSistemistas()
    },

    methods: {

        atualizarFiltro(index) {
            this.filtros[index].valor = null;
        },
        adicionarFiltro() {
            this.filtros.push({ campo: "", valor: "" });
        },
        removerFiltro(index) {
            this.filtros.splice(index, 1);
        },

        selectAll() {
            // Atualiza o estado dos demais checkboxes
            this.lista_linhas.forEach((vidro) => {
                vidro.selected = this.checkAll;
            });
        },

        async getListaSistemistas() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            try {
                const response = await axios.get(`projeto/projetista-sistemista/?page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                })

                this.lista_sistemistas = response.data.results
            } catch (error) {
                console.error(error)
            }
        },

        async buscarListaLinhas(pagina) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {

                this.$store.dispatch("setLoading", true);
                const params = this.filtros
                    .filter((filtro) => filtro.campo && filtro.valor)
                    .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
                    .join("&");

                const response = await axios.get(
                    `projeto/linha-sistema/?page=${pagina}&${params}&importado=False`,
                    {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    }
                );

                this.lista_linhas = response.data.results
                console.log(this.lista_linhas)
                this.countItens = response.data.count;

            } catch (error) {
                console.error("Erro ao buscar produtos:", error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async habilitarLinhas() {
            if (!this.checkSome) {
                this.erros = { linha: ["Selecione pelo menos uma linha para habilitar."] };
                this.toggleErros();
                return;
            }

            try {
                const linhasSelecionadas = this.lista_linhas
                    .filter(linha => linha.selected)
                    .map(linha => ({
                        id: linha.id,  // Corrigido para manter coerência com o backend
                        importado: true,
                    }));

                if (linhasSelecionadas.length === 0) {
                    this.erros = { linha: ["Nenhuma linha válida foi selecionada."] };
                    this.toggleErros();
                    return;
                }

                const token = await this.$store.dispatch('getAuthTokenCookie');

                // 🔹 Criar um array de promessas para atualizar cada linha separadamente
                const requests = linhasSelecionadas.map(linha =>
                    axios.patch(`projeto/linha-sistema/${linha.id}/`, { importado: true }, {
                        headers: { Authorization: `Token ${token}` }
                    })
                );

                // 🔹 Aguardar todas as requisições serem concluídas
                await Promise.all(requests);

                this.sucesso = true;
                this.sucessoMessage = 'Linhas habilitadas com sucesso!';
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('atualizarLista');
                    this.$emit('fecharModal');
                }, 3000);

            } catch (error) {
                this.erros = error.response?.data || { linha: ["Erro ao habilitar linhas."] };
                this.toggleErros();
            }
        },


        toggleErros() {
            this.exibirErros = !this.exibirErros;
        },

        mudarPagina(pagina) {
            this.checkAll = false
            this.paginaAtual = pagina;
            this.buscarListaLinhas(pagina);
        },
    }
}
</script>

<style scoped>
.btn-modal-importacao {
    @apply px-4 py-1 cursor-pointer
}

input[type=text] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
}

input[type=number] {
    @apply w-full p-1 border border-[#ccc] rounded-md
}
</style>