<template>

    <!-- <div class="mt-2">
        <div v-for="(perfis, index) in listaPerfis" :key="index"
            :class="index % 2 === 0 ? 'bg-gray-100' : 'bg-gray-200'" class="mb-2 p-4 rounded-lg">
            <h2 class="text-base font-semibold text-gray-700 mb-2">
                {{ perfis.perfil__codigo_base }} - {{ perfis.perfil__nome_base }}
                <span class="text-sm text-gray-600 font-normal">
                    ({{ perfis.cor__descricao }})
                </span>
            </h2>
            <div class="flex gap-5">
                <p> QTD: {{ perfis.total_qtd }} </p>
                <p> MEDIDA TOTAL: {{ perfis.total_medida }} </p>
                <p> PESO UN: {{ perfis.perfil__peso_base }} </p>
                <p> PESO: {{ perfis.peso }} </p>
                <p> BARRAS: </p>
                <div>
                    <ul class="">
                        <li v-for="(barras, idx) in perfis.barras_breakdown" :key="idx" class="flex items-center gap-1">
                            <span v-if="barras.barra_padrao" class="text-red-500"
                                title="Perfil sem barra definida, usando padrão de 6000mm">*</span>
                            {{ barras.bar_length }}mm - {{ barras.num_barras }}x <p>- Sobras: {{ barras.sobras ?
                                barras.sobras : 'Sem sobras' }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        PESO TOTAL PERFIS: {{ pesoTotalPerfis }}
    </div> -->

    <div class="container-modelo-tabela mt-2">
        <table class="container-tabela overflow-auto">
            <thead class="cabecalho-tabela">
                <tr class="border-b">
                    <th class="px-12 py-2 text-center">Código</th>
                    <th class="px-20 py-2 text-center">Descrição</th>
                    <th class="px-8 py-2 text-center">Cor</th>
                    <th class="px-8 py-2 text-center">Quantidade</th>
                    <th class="px-8 py-2 text-center">Peso Un.</th>
                    <th class="px-8 py-2 text-center">Peso Total</th>
                    <th class="px-8 py-2 text-center">Medida Total</th>
                    <th class="px-8 py-2 text-center">Barras</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(perfis, index) in listaPerfis" :key="index" class="escopo-tabela">

                    <td class="px-8 py-2 text-center">
                        {{ perfis.perfil__codigo_base || '-' }}
                    </td>

                    <td class="px-8 py-2 text-center">
                        {{ perfis.perfil__nome_base || '-' }}
                    </td>

                    <td class="px-8 py-2 text-center">
                        {{ perfis.cor__descricao || '-' }}
                    </td>

                    <td class="px-8 py-2 text-center">
                        {{ perfis.total_qtd || '-' }}
                    </td>

                    <td class="px-8 py-2 text-center">
                        {{ formatarNumero(perfis.perfil__peso_base) }}
                    </td>

                    <td class="px-8 py-2 text-center">
                        {{ formatarNumero(perfis.peso) }}
                    </td>
                    <td class="px-8 py-2 text-center">
                        {{ formatarNumero(perfis.total_medida) }}
                    </td>

                    <td class="px-8 py-2 text-center whitespace-nowrap">
                        <ul class="flex flex-col items-center">
                            <li v-for="(barras, idx) in perfis.barras_breakdown" :key="idx"
                                class="flex items-center gap-1">
                                <span v-if="barras.barra_padrao" class="text-red-500"
                                    title="Perfil sem barra definida, usando padrão de 6000mm">*</span>
                                {{ barras.bar_length }}mm - {{ barras.num_barras }}x <p>- Sobras: {{ barras.sobras ?
                                    barras.sobras : 'Sem sobras' }}</p>
                            </li>
                        </ul>
                    </td>

                </tr>
            </tbody>
            <tr class="escopo-tabela">
                <td></td> <td></td> <td></td> <td></td> <td></td> 
                <td class="px-8 py-2 text-center text-black">
                    {{ formatarNumero(pesoTotalPerfis) }}
                </td>
                <td></td> <td></td> 
            </tr>
        </table>
    </div>


</template>

<script>
export default {
    name: 'CustosMargensPerfil',

    props: ['listaPerfis', 'pesoTotalPerfis'],

    data() {
        return {
            // listaPerfis: [],
            // pesoTotalPerfis: 0
        }
    },

    methods: {
        formatarNumero(valor) {
            if (valor == null) return '';
            return Number(valor).toLocaleString('pt-BR');
        }
    }


}
</script>
