<template>

  <div class="w-full mb-2 flex">
    <div class="px-2 mb-2 w-full md:w-1/4">
      <label class="text-xl text-corBase font-bold">Aplicados no Orçamento:</label>
      <v-select v-model="aplicado_orcamento" :options="lista_aplicados_orcamento" class="w-full" :clearable="false" />
    </div>

  </div>

  <div class="block">
    <div class="mb-4">
      <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
        <div class="px-2 w-1/3">
          <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
            <option value="codigo">Código</option>
            <option value="descricao">Descrição</option>
            <option value="cor">Cor</option>
            <option value="fase">Fase</option>
            <option value="aplicacao">Aplicação</option>
          </select>
        </div>

        <div class="w-full">
          <v-select v-if="filtro.campo === 'cor'" v-model="filtro.valor" :options="coresComponente" label="descricao"
            placeholder="Escolha uma opção" :reduce="cor => cor.id" />

          <v-select v-else-if="filtro.campo === 'fase'" v-model="filtro.valor" :options="lista_fases" label="descricao"
            placeholder="Escolha uma opção" :reduce="fase => fase.descricao" />

          <v-select v-else-if="filtro.campo === 'aplicacao'" v-model="filtro.valor" :options="lista_aplicacao"
            placeholder="Escolha uma opção" />

          <input v-else v-model="filtro.valor" type="text" class="input-form-chapa" placeholder="Insira o valor" />
        </div>

        <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
      </div>

      <div class="flex w-full justify-center">
        <button class="text-ativo" @click.prevent="adicionarFiltro">
          <i class="fas fa-plus-circle"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="mb-2">
    <button class="bg-gray-600 hover:bg-screenCor border border-screenCor hover:border-gray-600
    text-white hover:text-gray-600 py-2 px-4" @click.prevent="toggleFuncoesECondicoes">
      Ver funções e condições
    </button>
  </div>

  <div class="flex">

    <div class="flex flex-wrap gap-2 mb-2">

      <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
text-white hover:text-corBase py-2 px-4" @click.prevent="getComponentesOrcamento()">
        Pesquisar
      </button>

      <!-- <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
text-white hover:text-ativo py-2 px-4" @click.prevent="$router.push(`/orcamento/selecionar-projeto/`)">
    Adicionar
  </button> -->

      <button class="bg-blue-500 hover:bg-screenCor border border-screenCor hover:border-blue-500
text-white hover:text-blue-500 py-2 px-4" @click.prevent="alterarSelecionadosComponentes()">
        Alterar Selecionados
      </button>

      <button class="bg-red-500 hover:bg-screenCor border border-screenCor hover:border-red-500
text-white hover:text-red-500 py-2 px-4" @click.prevent="verificarExclusao()">
        Excluir Selecionados
      </button>

      <button class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500
text-white hover:text-yellow-500 py-2 px-4" @click.prevent="editarTodos()">
        {{ editandoTodos ? 'Salvar Todos' : 'Editar Todos' }}
      </button>

      <!-- <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
text-white hover:text-ativo py-2 px-4" @click.prevent="salvarEdicoes()">
    Salvar
  </button> -->


      <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
    text-white hover:text-ativo py-2 px-4" @click.prevent="recalcularTudoItemOrcamento()">
        Recalcular
      </button>

    </div>
  </div>


  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
        <tr class="border-b">
          <th scope="col" class="px-3 py-3">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th class="px-8 py-2">
            Código
          </th>
          <th scope="col" class="px-16 py-2">
            Descrição
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Condição
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            <!-- v-if="aplicado_orcamento === 'Todos'" -->
            Condição Resultado
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Quantidade Fórmula
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Quantidade Resultado
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Medida Fórmula
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Medida Resultado
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Observação
          </th>
          <th scope="col" class="px-16 py-2 text-center">
            Cor
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Fase
          </th>
          <th scope="col" class="px-6 py-2 text-center">
            Aplicação
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(componente, index) in componentes" :key="componente.id" class="escopo-tabela">
          <!-- Checkbox -->
          <td class="w-4 px-3 py-3">
            <div class="flex items-center">
              <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                v-model="componente.selected">
              <label class="sr-only">checkbox</label>
            </div>
          </td>

          <!-- Código Base -->
          <td class="py-2 whitespace-nowrap">
            <v-select v-if="editandoTodos" v-model="componente.editado.componente" label="codigo_base"
              :options="componentesPesquisados" @search="buscarComponentes"
              @update:modelValue="(selecionado) => onSelectComponente(componente, selecionado)"
              placeholder="Busque um componente..." class="bg-white w-full rounded-md shadow-sm">
              <template v-slot:option="{ codigo_base, nome_base }">
                <div class="w-full">
                  <span class="font-bold">{{ codigo_base }}</span> - {{ nome_base }}
                </div>
              </template>
            </v-select>
            <span v-else class="px-8">{{ componente.componente?.codigo_base || '-' }}</span>
          </td>

          <!-- Nome Base -->
          <td class="px-6 py-2">
            <span v-if="editandoTodos">{{ componente.editado?.componente?.nome_base || '-' }}</span>
            <span v-else>{{ componente.componente?.nome_base || '-' }}</span>
          </td>

          <!-- Condição -->
          <td class="py-2 text-center">
            <input v-if="editandoTodos" v-model="componente.editado.condicao_formula" type="text"
              class="w-full p-2 border rounded-lg">
            <span v-else>{{ componente.condicao_formula || '-' }}</span>
          </td>
          <td class="px-6 py-2 text-center">
            <!-- v-if="aplicado_orcamento === 'Todos'" -->
            {{ componente.condicao_calculada ? 'Verdadeiro' : 'Falso' }}
          </td>

          <!-- Quantidade Fórmula -->
          <td class="py-2 text-center">
            <input v-if="editandoTodos" v-model="componente.editado.qtd_formula" type="text"
              class="w-full p-2 border rounded-lg">
            <span v-else>{{ componente.qtd_formula || '-' }}</span>
          </td>

          <!-- Quantidade Calculada -->
          <td class="px-6 py-2 text-center">{{ componente.qtd_calculada ?? 'Não calculado' }}</td>

          <!-- Medida Fórmula -->
          <td class="py-2 text-center">
            <input v-if="editandoTodos" v-model="componente.editado.medida_formula" type="text"
              class="w-full p-2 border rounded-lg">
            <span v-else>{{ componente.medida_formula || '-' }}</span>
          </td>

          <!-- Medida Calculada -->
          <td class="px-6 py-2 text-center">
            {{
              componente.medida_calculada ??
              (componente.componente.unidade_medida?.medicao === false ? '-' : 'Não calculado')
            }}
          </td>

          <!-- Observação -->
          <td class="py-2 text-center">
            <input v-if="editandoTodos" v-model="componente.editado.observacao" type="text"
              class="w-full p-2 border rounded-lg">
            <span v-else>{{ componente.observacao || '-' }}</span>
          </td>

          <!-- Cor -->
          <!-- <td class="py-2 text-center">
             <input v-if="editandoTodos" v-model="componente.editado.cor" type="text"
              class="w-full p-2 border rounded-lg"> 
            <v-select v-if="editandoTodos" v-model="componente.editado.cor" :options="coresComponente" label="descricao"
              class="bg-white w-full custom-width" placeholder="Escolha uma opção" :clearable="false" />
            <span v-else class="px-6">{{ componente.cor?.descricao || '-' }}</span>
          </td> -->

          <td class="py-2 text-center">
            <v-select v-if="editandoTodos" v-model="componente.editado.cor"
              :options="componente.cor_definida_projeto ? componente.componente.cor_base : coresComponente"
              label="descricao" class="bg-white w-full rounded-md shadow-sm" placeholder="Escolha uma opção" />
            <span v-else>{{ componente.cor?.descricao || '-' }}</span>
            <div class="text-red-500 text-xs">
              <p>
                cor_definida_projeto: {{ componente.cor_definida_projeto }}
              </p>
              <p>
                lista usada: {{ componente.cor_definida_projeto ? "componente.cor_base" : "coresComponente" }}
              </p>
              <p>
                cores base: {{componente.componente.cor_base.map(cor => cor.descricao).join(', ') || '-'}}
              </p>
            </div>

          </td>

          <!-- Fase Esquadria -->
          <td class="py-2 text-center">
            <v-select v-if="editandoTodos" v-model="componente.editado.fase_esquadria" :options="lista_fases"
              label="descricao" class="bg-white w-full rounded-md shadow-sm" placeholder="Escolha uma opção" />
            <span v-else class="">{{ componente.fase_esquadria?.descricao || '-' }}</span>
          </td>

          <!-- Aplicação -->
          <td class="py-2 text-center">
            <select v-if="editandoTodos" v-model="componente.editado.aplicacao"
              class="input-campos block w-full p-2 border rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option v-for="aplicacao in ['A Definir', 'Produção', 'Obra']" :key="aplicacao" :value="aplicacao">
                {{ aplicacao }}
              </option>
            </select>
            <span v-else class="">{{ componente.aplicacao || '-' }}</span>
          </td>
        </tr>
      </tbody>
      <tr v-if="adicionarNovoComponente" class="escopo-tabela">
        <td class="w-full text-center">
          <i class="fa-solid fa-magnifying-glass cursor-pointer" @click.prevent="toggleMenuProdutos()"></i>
        </td>
        <td class="w-full">
          <v-select v-model="novoComponente.codigo_base" label="codigo_base" :options="componentesPesquisados"
            @search="buscarComponentes" @update:modelValue="onSelectNovoComponente"
            placeholder="Busque um componente..." class="w-full">
            <template v-slot:option="{ codigo_base, nome_base }">
              <div class="w-full">
                <span class="font-bold">{{ codigo_base }}</span> - {{ nome_base }}
              </div>
            </template>
          </v-select>

        </td>
        <td class="w-full text-center">
          {{ novoComponente.nome_base ?? '' }}
        </td>
        <td class="w-full">
          <input class="input-campos" v-model="novoComponente.condicao">
        </td>
        <!-- v-if="aplicado_orcamento === 'Todos'" -->
        <td class="px-6 py-2 text-center"> - </td>
        <td class="w-full">
          <input class="input-campos" v-model="novoComponente.qtd_formula">
        </td>
        <td class="px-6 py-2 text-center"> - </td>
        <td class="w-full">
          <input class="input-campos" v-model="novoComponente.medida_formula">
        </td>
        <td class="px-6 py-2 text-center"> - </td>
        <td class="w-full">
          <input class="input-campos" v-model="novoComponente.observacao">
        </td>
        <td class="w-full">
          <v-select v-model="novoComponente.cor" :options="coresPerfil" label="nome"
            class="bg-white w-full custom-width" :reduce="cor => cor.id" placeholder="Escolha uma opção" />
        </td>
        <td class="w-full">
          <v-select v-model="novoComponente.fase_esquadria" :options="lista_fases" label="descricao"
            class="bg-white w-full custom-width" :reduce="fase => fase.id" :clearable="false"
            placeholder="Escolha uma opção" />
        </td>
        <td class="w-full">
          <select v-model="novoComponente.aplicacao" class="input-campos">
            <option v-for="aplicacao in ['A Definir', 'Produção', 'Obra']" :key="aplicacao" :value="aplicacao">
              {{ aplicacao }}
            </option>
          </select>
        </td>
      </tr>
    </table>

    <div v-if="adicionarNovoComponente" class="flex w-full justify-end itens-center mt-6 px-4 mb-5 gap-2">
      <button class="bg-red-500 hover:bg-screenCor border border-screenCor hover:border-red-500
      text-white hover:text-red-500 py-2 px-4" @click.prevent="toggleComponente">
        Cancelar
      </button>
      <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
      text-white hover:text-ativo py-2 px-4" @click.prevent="postNovoComponente">
        Adicionar
      </button>
    </div>
    <div v-else class="flex justify-center">
      <button class="text-ativo" @click.prevent="toggleComponente">
        <i class="fas fa-plus-circle"></i>
      </button>
    </div>

  </div>


  <AlterarSelecionadosComponentesOrcamento v-if="exibirAlterarSelecionados" :fasesEsquadria="lista_fases"
    :componentes="componentesSelecionados" @fecharModal="toggleAlterarSelecionados()"
    @atualizarLista="getComponentesOrcamento()" />
  <MensagemConfirmacaoExclusao mensagem="Deseja mesmo excluir os itens selecionados?" :abrir="modal_confirmacao"
    @fecharModal="toggleModalConfirmacao()" @confirmarExclusao="deletarComponentes" />
  <ListaFuncoesECondicoes v-if="exibirFuncoesECondicoes" @fecharModal="toggleFuncoesECondicoes" />
  <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />
  <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />
</template>

<script>
import axios from 'axios'
import AlterarSelecionadosComponentesOrcamento from '../modais/AlterarSelecionados/AlterarSelecionadosComponentesOrcamento.vue';
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue';
import ListaFuncoesECondicoes from '@/components/midais/ajudas/ListaFuncoesECondicoes.vue';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';
import vSelect from 'vue-select';

export default {
  name: 'AlterarComponenteOrcamento',

  components: {
    AlterarSelecionadosComponentesOrcamento,
    MensagemConfirmacaoExclusao,
    ListaFuncoesECondicoes,
    SucessoRapido,
    ListagemErros,
    vSelect,
  },

  computed: {
    checkSome() {
      return this.componentes.some(componente => componente.selected)
    }
  },

  props: ['itemOrcamentoId'],
  emits: ['atualizarLista'],

  data() {
    return {
      checkAll: false,

      filtros: [{ campo: "codigo", valor: "" }],

      lista_aplicados_orcamento: ['Sim', 'Não', 'Todos'],
      lista_aplicacao: ['A Definir', 'Obra', 'Produção'],
      lista_fases: [],
      aplicado_orcamento: 'Sim',

      componentesPesquisados: [],

      componentes: [],
      countItens: 0,
      coresComponente: [],

      modal_confirmacao: false,

      exibirFuncoesECondicoes: false,

      componentesSelecionados: [],
      exibirAlterarSelecionados: false,

      editandoTodos: false,
      editandoComponente: null,

      novoComponente: [],
      adicionarNovoComponente: false,

      sucesso: false,
      sucessoMessage: '',

      exibirErros: false,
      erros: [],
    }
  },

  created() {
    this.getListaFaseEsquadria()
    this.getListaCoresComponente()
    this.getComponentesOrcamento()
  },

  methods: {

    atualizarFiltro(index) {
      this.filtros[index].valor = null;
    },
    adicionarFiltro() {
      this.filtros.push({ campo: "", valor: "" });
    },
    removerFiltro(index) {
      this.filtros.splice(index, 1);
    },

    selectAll() {
      this.componentes.forEach((componente_orcamento) => {
        componente_orcamento.selected = this.checkAll;
      });
    },

    async getListaFaseEsquadria() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`projeto/fase-esquadria/?ativo=True&page_size=9999`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        this.lista_fases = response.data.results
      } catch (error) {
        console.error(error)
      }
    },

    async getListaCoresComponente() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`produtos/cor-componente/?ativo=True&page_size=9999`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        this.coresComponente = response.data.results
      } catch (error) {
        console.error(error)
      }
    },

    async getComponentesOrcamento() {
      try {

        this.$store.dispatch("setLoading", true);

        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const params = this.filtros
          .filter((filtro) => filtro.campo && filtro.valor)
          .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
          .join("&");

        const response = await axios.get(`orcamentos/componentes-orcamento/?item_orcamento_id=${this.itemOrcamentoId}&ativo=True&aplicado_orcamento=${this.aplicado_orcamento}&${params}&page_size=9999`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })

        this.componentes = response.data.results.map(componente => ({
          ...componente,
          editado: { ...componente }, // Clona os dados para edição
        }));
        this.countItens = response.data.count
        this.checkAll = false

      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    async alterarSelecionadosComponentes() {
      if (this.checkSome) {
        this.erros = { 1: 'existe um box marcado' }
        this.toggleErros()

      } else {
        this.erros = { componente: 'Selecione pelo menos um componente para alterar.' }
        this.toggleErros()
      }
    },

    async buscarComponentes(searchQuery) {
      if (searchQuery.length > 2) {
        try {

          const token = await this.$store.dispatch('getAuthTokenCookie');
          await this.$store.dispatch('fetchURLrequest');
          this.$store.dispatch("setLoading", true);

          const response = await axios.get(`produtos/produto/?tipo_produto=Componente&filtro_base=${searchQuery}&page_size=9999`, {
            headers: { Authorization: `Token ${token}` }
          });

          this.componentesPesquisados = response.data.results
        } catch (error) {
          console.error("Erro ao buscar componentes:", error);
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      } else {
        this.componentesPesquisados = [];
      }
    },

    onSelect(componente, selecionado) {
      if (!selecionado) return;

      componente.editado.componente = selecionado;
      componente.editado.componente_id = selecionado.id;
      componente.editado.descricao = selecionado.nome_base;
      componente.editado.peso = selecionado.peso_base;
    },


    async alterarSelecionadosComponentes() {
      if (this.checkSome) {
        this.componentesSelecionados = this.componentes.filter(componente => componente.selected);
        console.log(this.componentesSelecionados)
        this.toggleAlterarSelecionados()

      } else {
        this.erros = { componente: 'Selecione pelo menos um componente para alterar.' }
        this.toggleErros()
      }
    },

    toggleAlterarSelecionados() {
      this.exibirAlterarSelecionados = !this.exibirAlterarSelecionados
    },

    verificarExclusao() {
      if (this.checkSome) {
        this.toggleModalConfirmacao()
      } else {
        this.erros = { componente: 'Selecione pelo menos um Componentes para ser excluído.' }
        this.toggleErros()
      }
    },

    toggleModalConfirmacao() {
      this.modal_confirmacao = !this.modal_confirmacao
    },

    async deletarComponentes() {
      try {
        this.$store.dispatch("setLoading", true);

        const componentesADeletar = this.componentes.filter(componente => componente.selected);

        if (componentesADeletar.length === 0) {
          console.warn("Nenhum componente selecionado para deletar.");
          return;
        }

        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        await axios.patch(
          `orcamentos/atualizar-componentes-orcamento/`,
          { itens: componentesADeletar.map(componente => ({ id: componente.id, ativo: false })) },
          { headers: { Authorization: `Token ${token}` } }
        );

        this.sucesso = true
        this.sucessoMessage = 'Componentes excluídos com sucesso.'
        this.toggleModalConfirmacao()
        setTimeout(() => {
          this.sucesso = false;
          this.sucessoMessage = ''
        }, 3000);
        this.getComponentesOrcamento()
      } catch (error) {
        console.error("Erro ao deletar componentes:", error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    // editarComponentel(componente) {
    //   if (this.editandoComponente) {
    //     this.salvarEdicoes()
    //   } else {
    //     this.editandoComponente = componente.id;
    //   }
    // },

    editarTodos() {
      if (this.countItens == 0) {
        this.erros = { editar_todos: 'Busque os Componentes para editar.' }
        this.toggleErros()
        return
      } else {
        this.editandoTodos = !this.editandoTodos;
        if (!this.editandoTodos) {
          // this.editandoComponente = null
          this.salvarEdicoes()
        };
      }
    },

    fecharTodos() {
      this.editandoTodos = false
    },

    async salvarEdicoes() {
      try {
        this.$store.dispatch("setLoading", true);
        const token = await this.$store.dispatch("getAuthTokenCookie");
        await this.$store.dispatch("fetchURLrequest");

        // Filtra apenas componentes que foram editados
        const componentesAtualizados = this.componentes
          .filter(componente => {
            const original = componente;
            const editado = componente.editado;

            return (
              original.componente !== editado.componente ||
              original.condicao_formula !== editado.condicao_formula ||
              original.qtd_formula !== editado.qtd_formula ||
              original.medida_formula !== editado.medida_formula ||
              original.observacao !== editado.observacao ||
              original.posicao !== editado.posicao ||
              original.cor !== editado.cor ||
              original.fase_esquadria !== editado.fase_esquadria ||
              original.aplicacao !== editado.aplicacao
            );
          })
          .map(componente => ({
            id: componente.id,
            componente: componente.editado.componente.id,
            condicao_formula: componente.editado.condicao_formula,
            qtd_formula: componente.editado.qtd_formula,
            medida_formula: componente.editado.medida_formula,
            posicao: componente.editado.posicao,
            observacao: componente.editado.observacao,
            cor: componente.editado.cor?.id || '',
            fase_esquadria: componente.editado.fase_esquadria?.id || '',
            aplicacao: componente.editado.aplicacao,
          }));

        if (componentesAtualizados.length === 0) {
          this.fecharTodos()
          // this.editandoComponente = null
          return;
        }

        // Enviar os componente atualizados em uma única requisição PATCH
        await axios.patch(
          `orcamentos/atualizar-componentes-orcamento/`,
          { itens: componentesAtualizados },
          { headers: { Authorization: `Token ${token}` } }
        );

        this.sucesso = true;
        this.sucessoMessage = 'Componentes editados e salvos com sucesso.'
        this.getComponentesOrcamento();
        setTimeout(() => {
          this.fecharTodos()
          this.sucesso = false;
          // this.editandoComponente = null
        }, 3000);
      } catch (error) {
        console.error("[ERRO] Falha ao salvar edições:", error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    async recalcularItemOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(
          `engine/recalcular-item-orcamento/${this.itemOrcamentoId}/?componentes=true`,
          {
            headers: { 'Authorization': `Token ${token}` },
          }
        );

        console.log("Itens atualizados com sucesso:", response.data);
        this.sucesso = true;
        this.sucessoMessage = 'Componentes recalculados com sucesso.';
        this.getComponentesOrcamento()
        setTimeout(() => {
          this.sucesso = false;
          this.sucessoMessage = '';
        }, 3000);

      } catch (error) {
        console.error("Erro ao atualizar itens do orçamento:", error);
        this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
        this.toggleErros();
      }
    },

    async recalcularTudoItemOrcamento() {

      try {

        this.$store.dispatch("setLoading", true);

        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`engine/${this.itemOrcamentoId}/`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        console.log(response.data)
        this.sucesso = true
        this.sucessoMessage = 'Item do Orçamento recalculado com sucesso.'
        this.getComponentesOrcamento()
        setTimeout(() => {
          this.sucesso = false
          this.sucessoMessage = ''
        }, 3000)

      } catch (error) {
        console.error(error)
        this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
        this.toggleErros()
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    onSelectNovoComponente(componente) {
      this.novoComponente.componente = componente.id
      this.novoComponente.codigo_base = componente.codigo_base
      this.novoComponente.nome_base = componente.nome_base
      this.novoComponente.peso_base = componente.peso_base
    },

    toggleComponente() {
      this.adicionarNovoComponente = !this.adicionarNovoComponente;
      this.limparCampos();
    },

    limparCampos() {
      this.novoComponente = []

      this.novoComponente.aplicacao = 'A Definir'

      const faseDefault = this.lista_fases.find(item => item.descricao === 'A Definir');
      this.novoComponente.fase_esquadria = faseDefault ? faseDefault.id : null;
    },

    async postNovoComponente() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');
        this.$store.dispatch("setLoading", true);

        console.log('NOVOCOMPONENTE: ', this.novoComponente)
        const componenteAdicionar = {
          item_orcamento: this.itemOrcamentoId,
          componente: this.novoComponente.componente,
          condicao_formula: this.novoComponente.condicao,
          qtd_formula: this.novoComponente.qtd_formula,
          medida_formula: this.novoComponente.medida_formula,
          posicao: this.novoComponente.posicao,
          observacao: this.novoComponente.observacao,
          cor: this.novoComponente.cor,
          fase_esquadria: this.novoComponente.fase_esquadria,
          aplicacao: this.novoComponente.aplicacao,
        }

        await axios.post('orcamentos/componentes-orcamento/', componenteAdicionar, {
          headers: { Authorization: `Token ${token}` }
        });

        this.sucesso = true;
        this.sucessoMessage = 'Componente criado com sucesso!';
        setTimeout(() => this.sucesso = false, 3000);

        this.adicionarNovoComponente = false;
        this.componentesPesquisados = [];
        this.limparCampos();
        this.getComponentesOrcamento()
      } catch (error) {
        console.error(error);
        this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
        this.toggleErros();
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros
    },

    toggleFuncoesECondicoes() {
      this.exibirFuncoesECondicoes = !this.exibirFuncoesECondicoes
    },


  }

}
</script>

<style scoped lang="scss"></style>