<template>
    <div>
        <div class="fixed inset-0 bg-black/60 flex items-center justify-center z-[10000]">
            <div
                class="bg-white rounded-lg shadow-lg p-6 w-[90%] sm:w-[600px] min-h-[50vh] max-h-[80vh] overflow-y-auto transform transition-transform duration-300 ease-out scale-95 sm:scale-100">
                <!-- Cabeçalho do Modal -->
                <div class="flex justify-between items-center mb-4 border-b pb-2">
                    <h2 class="text-lg font-bold text-red-600">Alterar Status</h2>
                    <button @click.prevent="$emit('fecharModal')"
                        class="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 ease-in-out hover:bg-gray-100">
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>

                <div class="mb-2 flex flex-col">
                    <span class="font-semibold">Status:</span>
                    <v-select :options="lista_status" v-model="venda.status" placeholder="Escolha uma opção" />
                </div>

                <div class="mb-2 flex flex-col" v-if="venda.status === 'Cancelado'">
                    <span class="font-semibold">Motivo do Cancelamento:</span>
                    <v-select :options="lista_motivos" v-model="venda.motivo_cancelamento"
                        placeholder="Escolha uma opção" />
                </div>



                <div class="flex justify-end">
                    <button @click.prevent="salvarNovoStatus()" class="btn-salvar">Salvar</button>
                </div>

            </div>
            <SucessoRapido v-if="sucesso" mensagem="Status alterado com sucesso." />
            <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';

export default {
    name: 'MudarStatus',

    props: ['venda'],
    emits: ['fecharModal', 'atualizarLista'],

    components: {
        vSelect,
        SucessoRapido,
        ListagemErros,
    },

    data() {
        return {
            lista_status: ['A Faturar', 'Faturado', 'Cancelado'],
            lista_motivos: ['Cliente comprou com a concorrência', 'Desistência do Cliente', 'Decisão Empresarial'],

            sucesso: false,

            exibirErros: false,
            erros: [],

        }
    },


    created() {
        // this.getStatus(this.venda.status);
    },

    methods: {

        async salvarNovoStatus() {
            
            if (this.venda.status === 'Cancelado' && !this.venda.motivo_cancelamento) {
                this.erros = { motivo_cancelamento: 'Defina um motivo para o cancelamento.' }
                this.toggleErros()
                return
            }

            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.patch(`vendas/venda/${this.venda.id}/`, {
                    status: this.venda.status,
                    motivo_cancelamento: this.venda.motivo_cancelamento,
                }, {
                    headers: { Authorization: `Token ${token}` }
                }
                )

                console.log(response)
                // this.getStatus(this.venda.status)
                this.sucesso = true
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('atualizarLista')
                    this.$emit('fecharModal')
                }, 3000);
            } catch (error) {
                console.error(error)
                this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
                this.toggleErros()
            }
        },

        // async getStatus(status_atual) {
        //     try {
        //         const token = await this.$store.dispatch('getAuthTokenCookie')
        //         await this.$store.dispatch('fetchURLrequest')

        //         const response = await axios.get(`vendas/status-venda/?status_atual=${status_atual}`, {
        //             headers: { Authorization: `Token ${token}` }
        //         })

        //         this.lista_status = response.data.status_permitidos
        //     } catch (error) {
        //         console.error(error)
        //     }
        // },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        }
    }

}
</script>

<style scoped></style>