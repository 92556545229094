<template>


    <div class="container-formulario">
  
      <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
      <div class="container-cabecalho flex justify-between mb-4">
        <div>
          <h1 class="text-xl text-corBase font-bold">Grupo Cores Vidros</h1>
        </div>
  
        <router-link to="/configuracoes/projetos" class="text-xl text-corBase font-bold">
          <i class="fas fa-chevron-left"></i>
        </router-link>
  
      </div>
  
      <div class="flex items-center gap-3 mb-10">
  
        <div class="flex gap-2">
          <div>
            <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
    text-white hover:text-corBase py-2 px-4" @click="mudarPagina(1)">Pesquisar
            </button>
          </div>
        </div>
        <div>
          <button @click="$router.push(`/cadastro-grupo-cor-vidro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
    text-white hover:text-ativo py-2 px-4 ">Adicionar
          </button>
        </div>
      </div>
  
      <!-- Container da exibiçao da lista de pessoas -->
      <div class="container-modelo-tabela">
        <table class="container-tabela overflow-auto">
          <thead class="cabecalho-tabela">
            <tr>
              <th scope="col" class="p-4">
                <div class="flex items-center">
                  <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
  
                  <label class="sr-only">checkbox</label>
                </div>
              </th>
              <th scope="col" class="px-6 py-3">
                #
              </th>
              <th class="px-6 py-3">
                Nome
              </th>
              <th class="px-6 py-3">
                Cores
              </th>
              <th scope="col" class="px-6 py-3 text-center">
                Status
              </th>
              <th scope="col" class="px-10 py-3">
                Ação
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(grupo, index) in lista_grupo_cor_vidro" :key="index" class="escopo-tabela">
  
              <td class="w-4 p-4">
                <div class="flex items-center">
                  <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox" v-model="grupo.selected">
                  <label class="sr-only">checkbox</label>
                </div>
              </td>
              <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                {{ index + 1 }}
              </th>
              <td class="px-6 py-2">
                {{ grupo.nome }}
              </td>
              <td class="px-6 py-2">
                {{grupo.cor_vidro.map(cor => cor.descricao).join(', ') || '-'}}
              </td>
  
              <td class="px-6 py-2 text-center">
                <i
                  :class="grupo.ativo ? 'fa-solid fa-circle-check text-ativo' : 'fa-solid fa-circle-xmark text-inativo'"></i>
              </td>
  
              <td class="px-6 py-2 text-xs">
                <editar-g-p-vidro :grupo="grupo" :paginaAtual="paginaAtual" />
              </td>
  
            </tr>
          </tbody>
        </table>
  
        <Pagination v-if="lista_grupo_cor_vidro.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
          @change-page="mudarPagina" />
      </div>
    </div>
  
  </template>
  
  <script>
  import axios from "axios";
  // import EditarGPVidro from "@/components/midais/Editar/grupo_aplicacao/EditarGPVidro";
  import Pagination from "@/utils/Pagination.vue";
  
  export default {
    name: "ListaGrupoAplicacaoVidro",
  
    components: {
      // EditarGPVidro,
      Pagination
    },
  
    data() {
      return {
        lista_grupo_cor_vidro: [],
  
        checkAll: false,
        // lista usada para salvar esse retorno depois de dividido
        paginaAtual: 1,
        countItens: '',
  
        error: [],
      }
    },
  
    computed: {
      minhaEmpresa() {
        return this.$store.state.minhaEmpresa;
      },
  
      isadmin() {
        return this.$store.state.isAdminUser
      },
    },
  
    created() {
  
    },
  
    methods: {
  
      selectAll() {
        // Atualiza o estado dos demais checkboxes
        const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
          checkbox.checked = this.checkAll;
        });
      },
  
      async getListaGrupoCoresVidro(pagina) {
  
        this.$store.dispatch("setLoading", true);
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');
  
        try {
          const response = await axios.get(`projeto/grupo-cor-vidro/?page=${pagina}`, {
            headers: {
              'Authorization': `Token ${token}`
            }
          });
  
          this.lista_grupo_cor_vidro = response.data.results;
          this.countItens = response.data.count;
          console.log(this.lista_grupo_cor_vidro);
  
        } catch (error) {
          console.error("Erro ao buscar lista de grupos de cores de vidro:", error);
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      },
  
      mudarPagina(pagina) {
        this.paginaAtual = pagina;
        this.getListaGrupoCoresVidro(pagina);
      },
  
    }
  }
  </script>
  
  <style lang="scss"></style>