<template>
    <div>

        <div class="my-4">
            <h2 class="text-corBase text-xl font-bold">Vidros</h2>
        </div>

        <input type="checkbox" v-model="projeto.habilitar_produto_vidro"> Habilitar Produto Vidro

        <div v-if="projeto.habilitar_produto_vidro">
            <input type="checkbox" v-model="projeto.habilitar_cor_vidro"> Habilitar Filtro de Cores
        </div>
            
        <div v-if="projeto.habilitar_produto_vidro && projeto.habilitar_cor_vidro" class="mt-5 space-y-5">
            <!-- Listagem -->
            <div>
                <h2>Listagem:</h2>
                <div class="flex gap-2">
                    <input type="checkbox" v-model="projeto.listagem_vidro" @change="desativarOutro('listagem')">
                    <v-select class="w-full" :options="lista_grupos_cor" label="nome" v-model="projeto.grupo_cor_vidros" />
                </div>
                <p v-if="projeto.grupo_cor_vidros" class="text-sm text-gray-500">Cores do Grupo: {{ projeto.grupo_cor_vidros?.cor_vidro.map(cor => cor.descricao).join(', ') || '-'}}</p>
            </div>

            <!-- Adicionado -->
            <div>
                <h2>Adicionado:</h2>
                <div class="flex gap-2">
                    <input type="checkbox" v-model="projeto.adicionado_vidro" @change="desativarOutro('adicionado')">
                    <v-select class="w-full" :options="lista_cores" :multiple="true" label="descricao" v-model="projeto.cores_vidro" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import axios from 'axios';

export default {
    name: 'CorVidroProjeto',

    props: ['projeto'],

    components: {
        vSelect,
    },

    data() {
        return {
            lista_grupos_cor: [],
            lista_cores: [],
        };
    },

    created() {
        console.log('projeto: ', this.projeto);

        this.getListaGrupoCorVidro();
        this.getListaCorVidro();
    },

    methods: {
        // 🔹 Método para garantir que apenas um checkbox esteja ativo
        desativarOutro(tipo) {
            if (tipo === 'listagem') {
                this.projeto.adicionado_vidro = false;
            } else if (tipo === 'adicionado') {
                this.projeto.listagem_vidro = false;
            }
        },

        async getListaGrupoCorVidro() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            try {
                const response = await axios.get(`projeto/grupo-cor-vidro/?ativo=True&page_size=9999`, {
                    headers: { 'Authorization': `Token ${token}` }
                });

                this.lista_grupos_cor = response.data.results;
            } catch (error) {
                console.error(error);
            }
        },

        async getListaCorVidro() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            try {
                const response = await axios.get(`produtos/cor-vidro/?ativo=True&page_size=9999`, {
                    headers: { 'Authorization': `Token ${token}` }
                });

                console.log(response.data.results)
                this.lista_cores = response.data.results.map((cor_vidro) => {
                    return {
                        id: cor_vidro.cor_base,
                        descricao: cor_vidro.descricao,
                    }
                });
                console.log(this.lista_cores)
            } catch (error) {
                console.error(error);
            }
        }
    }
};
</script>

<style scoped></style>
