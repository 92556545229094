<template>
    <div class="p-4 bg-gray-50 rounded-md shadow-sm">

        <ul class="space-y-2">
            <li v-for="historico in lista_historico_status" :key="historico.id"
                class="flex justify-between items-center bg-white rounded-lg shadow px-4 py-2 border border-gray-200">
                <span class="font-medium text-gray-700">{{ historico.status_obra }}</span>
                <span class="text-sm text-gray-500">{{ formataData(historico.criado_em) }}</span>
            </li>
        </ul>

    </div>
</template>

<script>
import axios from 'axios'
import formatarData from '@/utils/FormatarData';

export default {
    name: 'HistoricoStatus',

    props: ['obra'],
    data() {
        return {
            lista_historico_status: [],

        }
    },

    created() {
        this.getHistoricoStatus()
    },

    methods: {

        async getHistoricoStatus() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get(`obra/historico-status-obra/?obra=${this.obra.id}&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.lista_historico_status = response.data.results
            } catch (error) {
                console.error(error)
            }
        },

        formataData(dataIso) {
            return formatarData(dataIso)
        }

    }
}
</script>

<style scoped></style>