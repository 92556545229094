<template>

  <div class="w-full mb-2 flex">
    <div class="px-2 mb-2 w-full md:w-1/4">
      <label class="text-xl text-corBase font-bold">Aplicados no Orçamento:</label>
      <v-select v-model="aplicado_orcamento" :options="lista_aplicados_orcamento" class="w-full" :clearable="false" />
    </div>

  </div>

  <div class="block">
    <div class="mb-4">
      <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
        <div class="px-2 w-1/3">
          <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
            <option value="codigo">Código</option>
            <option value="descricao">Descrição</option>
          </select>
        </div>

        <div class="w-full">
          <!-- <v-select v-if="filtro.campo === 'cor'" v-model="filtro.valor" :options="lista_cores" label="nome_razao"
              placeholder="Escolha uma opção" :reduce="cor => cor.id" />
  
            <v-select v-else-if="filtro.campo === 'fase'" v-model="filtro.valor" :options="lista_fases" label="descricao"
              placeholder="Escolha uma opção" :reduce="fase => fase.descricao" />-->

          <!-- v-else -->
          <input v-model="filtro.valor" type="text" class="input-form-chapa" placeholder="Insira o valor" />
        </div>

        <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
      </div>

      <div class="flex w-full justify-center">
        <button class="text-ativo" @click.prevent="adicionarFiltro">
          <i class="fas fa-plus-circle"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="mb-2">
    <button class="bg-gray-600 hover:bg-screenCor border border-screenCor hover:border-gray-600
      text-white hover:text-gray-600 py-2 px-4" @click.prevent="toggleFuncoesECondicoes">
      Ver funções e condições
    </button>
  </div>

  <div class="flex">

    <div class="flex flex-wrap gap-2 mb-2">

      <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click.prevent="getDefinicoesOrcamento()">
        Pesquisar
      </button>

      <!-- <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4" @click.prevent="$router.push(`/orcamento/selecionar-projeto/`)">
      Adicionar
    </button> -->

      <button class="bg-blue-500 hover:bg-screenCor border border-screenCor hover:border-blue-500
  text-white hover:text-blue-500 py-2 px-4" @click.prevent="alterarSelecionadosComponentes()">
        Alterar Selecionados
      </button>

      <button class="bg-red-500 hover:bg-screenCor border border-screenCor hover:border-red-500
  text-white hover:text-red-500 py-2 px-4" @click.prevent="verificarExclusao()">
        Excluir Selecionados
      </button>

      <button class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500
  text-white hover:text-yellow-500 py-2 px-4" @click.prevent="editarTodos()">
        {{ editandoTodos ? 'Salvar Todos' : 'Editar Todos' }}
      </button>

      <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4" @click.prevent="salvarOrdem()">
        Salvar
      </button>

      <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
    text-white hover:text-ativo py-2 px-4" @click.prevent="recalcularTudoItemOrcamento()">
        Recalcular
      </button>

    </div>
  </div>

  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="px-3 py-3">
            #
          </th>
          <th scope="col" class="px-3 py-3">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-3 py-3 text-center">
            Código
          </th>
          <th scope="col" class="px-3 py-3 text-center">
            Descrição
          </th>
          <th scope="col" class="px-20 py-3 text-center">
            Fórmula
          </th>
          <th scope="col" class="px-3 py-3 text-center">
            Fórmula Resultado
          </th>
          <th scope="col" class="px-3 py-3 text-center">
            Condição
          </th>
          <th scope="col" class="px-3 py-3 text-center">
            Condição Resultado
          </th>
        </tr>
      </thead>
      <draggable :list="definicoes" tag="tbody" handle=".handle" itemKey="id">
        <template v-slot:item="{ element, index }">
          <tr :key="index" class="escopo-tabela">
            <th scope="col" class="text-center font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              <div class="handle cursor-pointer">
                <span>☰</span> <!-- Handle para arrastar -->
                {{ element.index }}
              </div>
            </th>
            <td class="w-4 px-3 py-3">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                  v-model="element.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <td class="px-3 py-2 text-center">
              <input v-if="editandoTodos" v-model="element.editado.codigo" @input="forcarPrefixo(element)"
                :placeholder="valorPadrao" type="text" class="w-full p-2 border rounded-lg">
              <span v-else>{{ element.codigo ?? '-' }}</span>
            </td>
            <td class="px-3 w-60 py-2 text-center">
              <input v-if="editandoTodos" v-model="element.editado.descricao" type="text"
                class="w-full p-2 border rounded-lg">
              <span v-else>{{ element.descricao ?? '-' }}</span>
            </td>
            <td class="py-2 text-center">
              <input v-if="editandoTodos" v-model="element.editado.formula" type="text"
                class="w-full p-2 border rounded-lg">
              <span v-else>{{ element.formula ?? '-' }}</span>
            </td>
            <td class="px-3 py-2 text-center">
              {{ element.formula_calculada || 'Não calculada' }}
            </td>
            <td class="px-3 py-2 text-center">
              <input v-if="editandoTodos" v-model="element.editado.condicao_formula" type="text"
                class="w-full p-2 border rounded-lg">
              <span v-else>{{ element.condicao_formula || '-' }}</span>
            </td>
            <td class="px-3 py-2 text-center">
              {{ element.condicao_calculada ? 'Verdadeiro' : 'Falso' }}
            </td>

          </tr>
        </template>
      </draggable>
    </table>
  </div>

  <!-- <AlterarSelecionadosComponentesOrcamento v-if="exibirAlterarSelecionados" :fasesEsquadria="lista_fases"
      :componentes="componentesSelecionados" @fecharModal="toggleAlterarSelecionados()"
      @atualizarLista="getComponentesOrcamento()" /> -->
  <MensagemConfirmacaoExclusao mensagem="Deseja mesmo excluir os itens selecionados?" :abrir="modal_confirmacao"
    @fecharModal="toggleModalConfirmacao()" @confirmarExclusao="deletarDefinicoes" />
  <ListaFuncoesECondicoes v-if="exibirFuncoesECondicoes" @fecharModal="toggleFuncoesECondicoes" />
  <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />
  <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />

</template>


<script>
import axios from 'axios'
// import AlterarSelecionadosComponentesOrcamento from './modais/AlterarSelecionados/AlterarSelecionadosComponentesOrcamento.vue';
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue';
import ListaFuncoesECondicoes from '@/components/midais/ajudas/ListaFuncoesECondicoes.vue';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';
import draggable from 'vuedraggable'
import vSelect from 'vue-select';

export default {
  name: 'AlterarDefinicoesOrcamento',

  components: {
    // AlterarSelecionadosVidrosOrcamento,
    MensagemConfirmacaoExclusao,
    ListaFuncoesECondicoes,
    SucessoRapido,
    ListagemErros,
    draggable,
    vSelect,
  },

  computed: {
    checkSome() {
      return this.definicoes.some(definicao => definicao.selected)
    }
  },

  props: ['itemOrcamentoId'],
  emits: ['atualizarLista'],

  data() {
    return {
      checkAll: false,

      filtros: [{ campo: "codigo", valor: "" }],

      lista_aplicados_orcamento: ['Sim', 'Não', 'Todos'],
      lista_aplicacao: ['A Definir', 'Obra', 'Produção'],
      lista_fases: [],
      lista_localizacoes: [],
      aplicado_orcamento: 'Sim',
      valorPadrao: 'DP',

      vidrosPesquisados: [],

      definicoes: [],
      countItens: 0,
      coresVidro: [],

      modal_confirmacao: false,

      exibirFuncoesECondicoes: false,

      vidrosSelecionados: [],
      exibirAlterarSelecionados: false,

      editandoTodos: false,
      editandoVidro: null,

      novoVidro: [],
      adicionarNovoVidro: false,

      sucesso: false,
      sucessoMessage: '',

      exibirErros: false,
      erros: [],
    }
  },

  created() {
    // this.getListaFaseEsquadria()
    // this.getListaLocalizacoes()
    this.getDefinicoesOrcamento()
  },

  methods: {

    atualizarFiltro(index) {
      this.filtros[index].valor = null;
    },
    adicionarFiltro() {
      this.filtros.push({ campo: "", valor: "" });
    },
    removerFiltro(index) {
      this.filtros.splice(index, 1);
    },

    selectAll() {
      this.definicoes.forEach((definicao_orcamento) => {
        definicao_orcamento.selected = this.checkAll;
      });
    },

    forcarPrefixo(element) {
      const prefixo = this.valorPadrao;
      console.log(element.editado.codigo)
      console.log(prefixo)
      console.log(element.editado.codigo.startsWith(prefixo))
      if (!element.editado.codigo.startsWith(prefixo)) {
        element.editado.codigo = (prefixo + element.editado.codigo).toUpperCase();
      } else {
        element.editado.codigo = element.editado.codigo.toUpperCase();
      }
    },

    forcarNovoPrefixo(element) {
      const prefixo = this.valorPadrao;
      if (!element.startsWith(prefixo)) {
        element = (prefixo + element).toUpperCase();
      } else {
        element = element.toUpperCase();
      }
      return element
    },

    async getDefinicoesOrcamento() {
      try {

        this.$store.dispatch("setLoading", true);

        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const params = this.filtros
          .filter((filtro) => filtro.campo && filtro.valor)
          .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
          .join("&");

        const response = await axios.get(`orcamentos/definicao-orcamento/?item_orcamento_id=${this.itemOrcamentoId}&ativo=True&aplicado_orcamento=${this.aplicado_orcamento}&${params}&page_size=9999`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })

        this.definicoes = response.data.results.map(definicao => ({
          ...definicao,
          editado: { ...definicao }, // Clona os dados para edição
        }));
        this.countItens = response.data.count
        this.checkAll = false

      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    verificarExclusao() {
      if (this.checkSome) {
        this.toggleModalConfirmacao()
      } else {
        this.erros = { definicao: 'Selecione pelo menos uma Definição para ser excluída.' }
        this.toggleErros()
      }
    },

    toggleModalConfirmacao() {
      this.modal_confirmacao = !this.modal_confirmacao
    },

    async deletarDefinicoes() {
      try {
        this.$store.dispatch("setLoading", true);

        const definicoesADeletar = this.definicoes.filter(definicao => definicao.selected);

        if (definicoesADeletar.length === 0) {
          console.warn("Nenhuma fórmula selecionada para deletar.");
          return;
        }

        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        await axios.patch(
          `orcamentos/atualizar-definicoes-orcamento/`,
          { itens: definicoesADeletar.map(definicao => ({ id: definicao.id, ativo: false })) },
          { headers: { Authorization: `Token ${token}` } }
        );

        this.sucesso = true
        this.sucessoMessage = 'Fórmulas excluídas com sucesso.'
        this.toggleModalConfirmacao()
        setTimeout(() => {
          this.sucesso = false;
          this.sucessoMessage = ''
        }, 3000);
        this.getDefinicoesOrcamento()
      } catch (error) {
        console.error("Erro ao deletar definicoes:", error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    editarTodos() {
      if (this.countItens == 0) {
        this.erros = { editar_todos: 'Busque as Fórmulas para editar.' }
        this.toggleErros()
        return
      } else {
        this.editandoTodos = !this.editandoTodos;
        if (!this.editandoTodos) {
          // this.editandoDefinicao = null
          this.salvarEdicoes()
        };
      }
    },

    fecharTodos() {
      this.editandoTodos = false
    },

    async salvarEdicoes() {
      try {
        this.$store.dispatch("setLoading", true);
        const token = await this.$store.dispatch("getAuthTokenCookie");
        await this.$store.dispatch("fetchURLrequest");

        // Filtra apenas fórmulas que foram editados
        const definicoesAtualizadas = this.definicoes
          .filter(definicao => {
            const original = definicao;
            const editado = definicao.editado;

            return (
              original.codigo !== editado.codigo ||
              original.descricao !== editado.descricao ||
              original.formula !== editado.formula ||
              original.condicao_formula !== editado.condicao_formula
            );
          })
          .map(definicao => ({
            id: definicao.id,
            codigo: definicao.editado.codigo,
            descricao: definicao.editado.descricao,
            formula: definicao.editado.formula,
            condicao_formula: definicao.editado.condicao_formula,
          }));

        if (definicoesAtualizadas.length === 0) {
          this.fecharTodos()
          // this.editandoDefinicao = null
          return;
        }

        // Enviar as fórmulas atualizados em uma única requisição PATCH
        await axios.patch(
          `orcamentos/atualizar-definicoes-orcamento/`,
          { itens: definicoesAtualizadas },
          { headers: { Authorization: `Token ${token}` } }
        );

        this.sucesso = true;
        this.sucessoMessage = 'Fórmulas editadas e salvas com sucesso.'
        this.getDefinicoesOrcamento();
        setTimeout(() => {
          this.fecharTodos()
          this.sucesso = false;
          // this.editandoDefinicao = null
        }, 3000);
      } catch (error) {
        console.error("[ERRO] Falha ao salvar edições:", error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    async salvarOrdem() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        // Preparando os dados para serem enviados
        const dadosParaSalvar = this.definicoes.map((item, index) => ({
          id: item.id,
          index: index + 1,
        }));

        const response = await axios.patch(
          `orcamentos/atualizar-definicoes-orcamento/`,
          { itens: dadosParaSalvar },
          {
            headers: {
              'Authorization': `Token ${token}`,
            }
          }
        );

        console.log("Itens atualizados com sucesso:", response.data);

        this.sucesso = true;
        this.sucessoMessage = 'Fórmulas salvas/atualizadas com sucesso.';
        setTimeout(() => {
          this.sucesso = false;
          this.sucessoMessage = ''
        }, 3000);

        this.getDefinicoesOrcamento();

      } catch (err) {
        console.error("Erro ao atualizar itens do orçamento:", err);
        this.erros = { salvar: "Erro ao salvar itens do orçamento." };
        this.toggleErros();
      }
    },

    async recalcularItemOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(
          `engine/recalcular-item-orcamento/${this.itemOrcamentoId}/?definicoes=true`,
          {
            headers: { 'Authorization': `Token ${token}` },
          }
        );

        console.log("Itens atualizados com sucesso:", response.data);
        this.sucesso = true;
        this.sucessoMessage = 'Fórmulas recalculadas com sucesso.';
        this.getDefinicoesOrcamento()
        setTimeout(() => {
          this.sucesso = false;
          this.sucessoMessage = '';
        }, 3000);

      } catch (error) {
        console.error("Erro ao atualizar itens do orçamento:", error);
        if (error.response && error.response.data.includes('Loop de dependências detectado entre as fórmulas.')) {
          this.erros = { erro: "Loop de dependências detectado entre as fórmulas." }
          this.toggleErros()
          return
        }
        this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
        this.toggleErros();
      }
    },

    async recalcularTudoItemOrcamento() {

      try {

        this.$store.dispatch("setLoading", true);

        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`engine/${this.itemOrcamentoId}/`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        console.log(response.data)
        this.sucesso = true
        this.sucessoMessage = 'Item do Orçamento recalculado com sucesso.'
        this.getDefinicoesOrcamento()
        setTimeout(() => {
          this.sucesso = false
          this.sucessoMessage = ''
        }, 3000)

      } catch (error) {
        console.error(error)
        this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
        this.toggleErros()
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros
    },

    toggleFuncoesECondicoes() {
      this.exibirFuncoesECondicoes = !this.exibirFuncoesECondicoes
    },
  }
}
</script>

<style scoped lang="scss"></style>