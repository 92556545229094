<template>
    <div class="flex justify-center items-center">
        <div>
            <div class="bg-white shadow-lg rounded-lg p-5 max-w-sm text-center mt-2">
                <h2 class="text-2xl font-bold mb-4">Imagem de Capa</h2>
                <img v-if="imagem" :src="imagem.url_documento" alt="Imagem de Capa"
                    class="object-cover w-full border rounded-lg cursor-pointer"
                    @click.prevent="openPreviewImage(`${imagem.url_documento}`)" />

                <img v-else
                    src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                    alt="Sem foto" class="object-cover w-full border rounded-lg cursor-pointer">

                <div class="flex gap-3 mt-4">
                    <button class="selecionar-arquivo" @click.prevent="toggleGaleria()">
                        Pesquisar Imagem
                    </button>
                </div>

            </div>

            <div class="flex itens-center mt-6 justify-center">
                <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
      text-white hover:text-ativo py-1 px-4" @click.prevent="atualizarImagem()">Salvar</button>
            </div>

        </div>
    </div>

    <PreviewImage v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />
    <Galeria v-if="mostrarGaleria" @fechar_galeria="toggleGaleria" @objetoClicado="atualizarFileInput" />
    <SucessoRapido v-if="sucesso" mensagem="Imagem de Capa atualizada com sucesso." />
    <ErroRapido v-if="erro" mensagem="Ocorreu um erro ao atualizar Imagem de Capa." />
</template>

<script>
import axios from 'axios'
import PreviewImage from '@/components/midais/ged/PreviewImage.vue';
import Galeria from '@/components/midais/ged/Galeria.vue';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';

export default {
    name: "ImagensProjeto",

    data() {
        return {
            preview_imagem: false,
            mostrarGaleria: false,
            imagem: '',

            sucesso: false,
            erro: false,
        }
    },

    props: ['projeto_id', 'dadosProjeto'],

    components: {
        PreviewImage,
        Galeria,
        SucessoRapido,
        ErroRapido,
    },

    created(){
        console.log(this.dadosProjeto)
        this.imagem = this.dadosProjeto.imagem
    },

    methods: {
        closed_preview_image() {
            this.preview_imagem = ''
        },

        openPreviewImage(url) {
            this.preview_imagem = url
        },

        atualizarFileInput(objeto) {
            console.log('objeto que chegou:' + objeto)
            console.log(objeto)
            this.imagem = objeto
        },

        toggleGaleria() {
            this.mostrarGaleria = !this.mostrarGaleria
        },

        async atualizarImagem() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')
            try {
                const response = await axios.patch(`projeto/projeto/${this.projeto_id}/`,
                    {
                        imagem: this.imagem.id
                    },
                    {
                        headers: {
                            'Authorization': `Token ${token}`
                        }
                    }
                )

                console.log(response)
                this.sucesso = true
                setTimeout(() => {
                    this.sucesso = false;
                }, 3000);
            } catch (error) {
                console.error(error)
            }
        },
    }
};
</script>

<style scoped></style>