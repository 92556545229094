<template>
    <div>
        <div class="flex">

            <div class="flex flex-wrap gap-2 mb-2">

                <button class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500
                text-white hover:text-yellow-500 py-2 px-4" @click.prevent="habilitarLinhas()">Habilitar Linhas
                </button>

                <button class="bg-blue-500 hover:bg-screenCor border border-screenCor hover:border-blue-500
                text-white hover:text-blue-500 py-2 px-4" @click.prevent="ativarDesativarLinhas()">Ativar/Desativar Linhas
                </button>

            </div>
        </div>
        <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
                <thead class="cabecalho-tabela">
                    <tr>
                        <th scope="col" class="p-4">
                            <div class="flex items-center">
                                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
                                <label class="sr-only">checkbox</label>
                            </div>
                        </th>
                        <th scope="col" class="px-6 py-3">#</th>
                        <th class="px-20 py-2">Linha</th>
                        <th class="px-12 py-2 text-center">Sistemista</th>
                        <th class="px-6 py-2 text-center">Linhas Ativas</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(linha_sistema, index) in lista_linha_sistema" :key="index" class="escopo-tabela">
                        <td class="w-4 p-4">
                            <div class="flex items-center">
                                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                                    v-model="linha_sistema.selected">
                                <label class="sr-only">checkbox</label>
                            </div>
                        </td>
                        <th scope="row"
                            class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                            {{ index + 1 }}
                        </th>
                        <td class="px-20 py-2 whitespace-nowrap">{{ linha_sistema.linha }}</td>
                        <td class="px-12 py-2 text-center">
                            {{ linha_sistema.projetistasistemistas?.nome || '-' }}
                            ({{ linha_sistema.projetistasistemistas?.codigo || '-' }})
                        </td>
                        <td class="px-6 py-2 text-center">
                            <i v-if="linha_sistema.ativo" class="fa-solid fa-circle-check text-ativo"></i>
                            <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Pagination v-if="lista_linha_sistema.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
                @change-page="mudarPagina" />
        </div>
    </div>

    <HabilitarLinhas v-if="exibirHabilitarLinhas" @fecharModal="habilitarLinhas()" @atualizarLista="mudarPagina(paginaAtual)" />
    <AtivarDesativarLinhas v-if="ativarDesativarLinha" :lista_linhas="linhasSelecionadas" @fecharModal="toggleAtivarDesativarLinha" @atualizarLista="mudarPagina(paginaAtual)" />

    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />
    <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />
</template>

<script>
import axios from 'axios'
import Pagination from '@/utils/Pagination.vue';
import ListagemErros from '../toasts/toast_erro/ListagemErros.vue';
import SucessoRapido from '../toasts/toast_sucesso/SucessoRapido.vue';
import HabilitarLinhas from './modais/HabilitarLinhas.vue';
import AtivarDesativarLinhas from './modais/AtivarDesativarLinhas.vue';

export default {
    name: 'SessaoTenant',
    components: {
        Pagination,
        ListagemErros,
        SucessoRapido,
        HabilitarLinhas,
        AtivarDesativarLinhas
    },

    data() {
        return {
            checkAll: false,

            lista_linha_sistema: [],

            paginaAtual: 1,
            countItens: 0,

            linhasSelecionadas: [],
            ativarDesativarLinha: false,

            exibirHabilitarLinhas: false,

            erros: [],
            exibirErros: false,

            sucesso: false,
            sucessoMessage: ''
        }
    },

    computed: {
        minhaEmpresa() {
            return this.$store.state.minhaEmpresa;
        },

        isadmin() {
            return this.$store.state.isAdminUser
        },

        checkSome() {
            return this.lista_linha_sistema.some(linha => linha.selected)
        },
    },

    created() {
        this.getListaLinhasSistemistas(1)
    },

    methods: {
        selectAll() {
            // Atualiza o estado dos demais checkboxes
            this.lista_linha_sistema.forEach((linha) => {
                linha.selected = this.checkAll;
            });
        },

        async getListaLinhasSistemistas(pagina) {
            try {

                // this.$store.dispatch("setLoading", true);
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                const response = await axios.get(`projeto/linha-sistema/?importado=True&page=${pagina}`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });

                this.lista_linha_sistema = response.data.results;
                this.countItens = response.data.count;
            } catch (error) {
                console.error("Erro ao buscar linhas sistemistas:", error);
            } finally {
                // this.$store.dispatch("setLoading", false);
            }
        },

        habilitarLinhas(){
            this.exibirHabilitarLinhas = !this.exibirHabilitarLinhas
        },

        async ativarDesativarLinhas() {
            if (this.checkSome || this.checkAll) {
                this.linhasSelecionadas = this.lista_linha_sistema.filter(linha => linha.selected);

                this.toggleAtivarDesativarLinha();
            } else {
                this.erros = {ativar_desativar_linhas: 'Selecione pelo menos uma linha para ativar/desativar.'}
                this.toggleErros()
                return
            }

            //     try {
            //         const token = await this.$store.dispatch('getAuthTokenCookie')
            //         await this.$store.dispatch('fetchURLrequest')

            //         this.$store.dispatch("setLoading", true);

            //         const requestsLinhas = linhasSelecionadas.map(linha =>
            //             axios.patch(`projeto/linha-sistema/${linha.id}/`, { ativo: true }, {
            //                 headers: { Authorization: `Token ${token}` }
            //             })
            //         );

            //         const projetistasSistemistasIDs = [...new Set(
            //             linhasSelecionadas.map(linha => linha.projetistasistemistas?.id).filter(id => id)
            //         )];

            //         const requestsProjetistas = projetistasSistemistasIDs.map(id =>
            //             axios.patch(`projeto/projetista-sistemista/${id}/`, { ativo: true }, {
            //                 headers: { Authorization: `Token ${token}` }
            //             })
            //         );

            //         await Promise.all([...requestsLinhas, ...requestsProjetistas]);

            //         this.sucesso = true;
            //         this.sucessoMessage = 'Linhas e Projetistas ativados com sucesso!';
            //         setTimeout(() => {
            //             this.sucesso = false;
            //         }, 3000);

            //         this.mudarPagina(this.paginaAtual);
            //     } catch (error) {
            //         this.erros = error.response?.data || { perfil: ["Erro ao ativar linhas e projetistas."] };
            //         this.toggleErros();
            //     } finally {
            //         this.$store.dispatch("setLoading", false);
            //     }
            // } else {
            //     this.erros = { ativar_selecionados: 'Selecione pelo menos uma Linha para ser ativada.' };
            //     this.toggleErros();
            // }
        },

        toggleAtivarDesativarLinha(){
            this.ativarDesativarLinha = !this.ativarDesativarLinha
        },

        mudarPagina(pagina) {
            this.checkAll = false
            this.paginaAtual = pagina
            this.getListaLinhasSistemistas(pagina)
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        }
    },

}

</script>

<style scoped></style>